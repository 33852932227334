import styled from 'react-emotion'

import { color } from 'bvdash/styles'
import { Box } from 'bvdash/ui/core'

export const VersionDate = styled.span`
  ${color.dodgerBlue};
  font-size: 18px;
  line-height: 1.25;

  margin-bottom: 8px;
`

export const VersionLabel = styled(VersionDate)`
  font-weight: normal;
`

export const VersionBox = styled(Box)`
  margin-bottom: 16px;
`
