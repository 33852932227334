import * as React from 'react'

import {
  Stepper,
  StepContainer,
  StepIcon,
  StepText,
  StepDescriptionContainer,
} from './styles'

import { FlexContainer } from 'bvdash/components/standard/containers'
import { Modal } from 'bvdash/components/modals'
import { MediumText } from 'bvdash/components/standard/texts'

import { INDIVIDUAL_SETUP } from 'bvdash/admin/setup/constants'

const setupTextsTeam = [
  {
    step: 1,
    header: 'Add Users',
    text:
      'Step 1 is to add users to your account. Click "Continue" to start adding users',
  },
  {
    step: 2,
    header: 'Create Projects',
    text:
      'Step 2 is to create a project. Click "Continue" to add your first project',
  },
  {
    step: 3,
    header: 'Assign Users',
    text:
      'Step 3 is to add users to your projects. From the "TEAM MEMBER" column, click on any "UNASSIGNED" users to assign them to projects. Click "Continue" to assign your team',
  },
  {
    step: 4,
    header: 'Send Emails',
    text:
      'The final step is to activate users. From the "STATUS" column use the buttons to send individual activiation emails, or use the "Send Validation Emails" button to send to all users who are assigned to projects',
  },
]

const setupTextsIndividual = [
  {
    step: 1,
    header: 'Create Projects',
    text:
      'Step 1 is to create a project. Click "Continue" to add your first project',
  },
]

const Step = props => {
  const { isCompleted, isCurrent, text, value } = props
  return (
    <StepContainer>
      <StepIcon isCompleted={isCompleted} isCurrent={isCurrent}>
        {value}
      </StepIcon>
      <StepText>{text}</StepText>
    </StepContainer>
  )
}

export const SetupModal = props => {
  const {
    currentModalProps: { setupOption, setupState },
    closeModal,
  } = props

  const index = setupState - 1

  const setupTexts =
    setupOption === INDIVIDUAL_SETUP ? setupTextsIndividual : setupTextsTeam

  let header,
    text = ''
  if (setupOption === INDIVIDUAL_SETUP) {
    header = setupTexts[0].header
    text = setupTexts[0].text
  } else {
    header = setupTexts[index < 0 ? 0 : index].header
    text = setupTexts[index < 0 ? 0 : index].text
  }

  const Content = (
    <FlexContainer alignItems="center">
      <Stepper>
        {setupTexts.map(item => {
          return (
            <Step
              isCompleted={item.step < setupState}
              isCurrent={item.step === setupState}
              key={item.header}
              text={item.header}
              value={item.step}
            />
          )
        })}
      </Stepper>
      <StepDescriptionContainer>
        <MediumText textAlign="left">{text}</MediumText>
      </StepDescriptionContainer>
    </FlexContainer>
  )

  const onSubmit = () => closeModal()

  return (
    <Modal
      headerText={header}
      onSubmit={onSubmit}
      submitFullWidth
      submitText="Continue"
      type="add"
    >
      {Content}
    </Modal>
  )
}
