import * as React from 'react'
import styled from 'react-emotion'

import { color } from 'bvdash/styles'
import { withCustomer } from 'bvdash/auth/queries'

function Customer({ customer }) {
  if (customer.isLoading) return null
  return <CustomerHeader>{customer.customer.name}</CustomerHeader>
}

const CustomerHeader = styled.div`
  ${color.sanJuan};
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.6px;
  line-height: 1.25;
  margin-bottom: 24px;
  text-transform: uppercase;
  max-width: 100%;
`

export default withCustomer(Customer)
