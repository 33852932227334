import { include } from 'named-urls'

export default {
  // This route is only used when user refreshes page. The same component
  // (AdminBase) is rendered. Program with :programKey is loaded instead
  // of the default program.

  admin: include('/admin/', {
    setup: 'setup/',

    settings: 'settings/',
    settingsUnits: 'settings/units/',
    settingsFeatures: 'settings/features/',
    settingsTitles: 'settings/titles/',

    programs: 'programs/',
    programAdd: 'programs/add/',
    programEdit: 'programs/:id/edit/',
    programSnapshots: 'programs/snapshots/',

    projects: 'projects/',

    projectData: 'program/projects/',
    projectAddItem: 'program/projects/add/',
    projectEditItem: 'program/projects/:id/',

    projectTeam: 'program/projects/team/',

    projectFields: 'projects/fields/',
    projectCodes: 'projects/codes/',
    projectAddCode: 'projects/codes/add/',
    projectEditCode: 'projects/codes/:code/',

    budget: 'budget/', // used for redirect only
    budgetCodes: 'budget/codes/',
    budgetFields: 'budget/fields/',
    budgetAddCode: 'budget/codes/add/',
    budgetEditCode: 'budget/code/:code/',
    budgetSnapshots: 'budget/snapshots/',
    budgetItems: 'program/budget/version/',
    budgetAddItem: 'program/budget/version/add/',
    budgetEditItem: 'program/budget/version/item/:id/',

    schedule: 'schedule/', // used for redirect only
    scheduleCodes: 'schedule/codes/',
    scheduleAddCode: 'schedule/codes/add/',
    scheduleEditCode: 'schedule/code/:code/',
    scheduleSnapshots: 'schedule/snapshots',
    scheduleItems: 'program/schedule/version/',
    scheduleAddItem: 'program/schedule/version/add/',
    scheduleEditItem: 'program/schedule/version/item/:id/',

    users: 'users/',
    usersAdd: 'users/add/',
    usersEdit: 'users/:userId/',

    account: 'account/',
    accountSubscriptions: 'account/subscriptions/',
    accountCompanyProfile: 'account/company/',
    accountPaymentMethods: 'account/payment/',
  }),
  tasks: include('/tasks/', {
    add: 'add/',
    detail: ':actionId/',
    edit: ':actionId/edit/',
  }),
  alerts: '/alerts/',
  conversations: include('/conversations/', {
    search: ':conversationId/search/',
    detail: ':conversationId?/',
    new: 'new/',
  }),
  auth: {
    login: '/login/',
    passwordReset: '/password/reset/',
    passwordNew: '/password/new/',
    passwordCreate: '/password/create/',
  },
  portfolio: '/portfolio/',
  program: include('/programs/:programKey/', {
    dashboard: '',
    budget: 'budget/',
    issues: 'issues/',
    updates: 'updates/',
    schedule: 'schedule/',
    team: 'team/',
  }),
  projects: include('/projects/', {
    all: '',
    favorite: 'favorite/',
    critical: 'critical/',
  }),
  project: include('/project/:projectKey/', {
    dashboard: '',
    budget: 'budget/',

    documents: 'documents/',
    documentAdd: 'documents/add/',
    documentEdit: 'documents/:documentId/edit/',
    documentDetail: 'documents/:documentId/',

    issues: 'issues/',
    issueAdd: 'issues/add/',
    issueEdit: 'issues/:issueId/edit/',
    issueDetail: 'issues/:issueId/',

    risks: 'risks/',
    riskAdd: 'risks/add/',
    riskEdit: 'risks/:riskId/edit/',
    riskDetail: 'risks/:riskId/',

    ncrs: 'ncrs/',
    ncrAdd: 'ncrs/add/',
    ncrEdit: 'ncrs/:ncrId/edit/',
    ncrDetail: 'ncrs/:ncrId/',

    updates: 'updates/',
    updateAdd: 'updates/add/',
    updateEdit: 'updates/edit/',

    schedule: 'schedule/',

    scope: 'scope/',
    scopeAdd: 'scope/add/',
    scopeEdit: 'scope/edit/',

    team: 'team/',

    survey: 'survey/',
  }),
  timesheets: include('/timesheets/', {
    add: 'add/',
    detail: ':timesheetId/',
    edit: ':timesheetId/edit/',
  }),
  register: include('/register/', {
    company: 'company',
    account: 'account',
    verify: 'verify',
    plan: 'plan',
  }),
  calendar: '/calendar/',
  profile: '/profile/',
  accounts: include('/accounts/', {
    list: 'list/',
    edit: ':accountId/edit/',
  }),
  superusers: include('/superusers/', {
    list: 'list/',
    add: 'add/',
  }),
}
