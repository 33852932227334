import * as React from 'react'
import { Logo } from 'bvdash-core/icons'

const overlayStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export default function Loading({ error, pastDelay, retry, timeout }) {
  if (!error && !pastDelay) return null

  return (
    <div style={overlayStyle}>
      {!error && !timeout ? (
        <Logo />
      ) : (
        <div style={{ textAlign: 'center', maxWidth: '35em' }}>
          <h1
            style={{
              fontSize: '4em',
              fontWeight: 'regular',
              marginBottom: '1.5em',
            }}
          >
            Something went wrong!
          </h1>

          <p
            style={{
              fontSize: '2em',
              fontWeight: 'bold',
              verticalAlign: 'middle',
            }}
          >
            Please try to <button onClick={retry}>refresh</button> the page.
          </p>

          {__DEV__ && (
            <p style={{ marginTop: '2em', lineHeight: '1.2' }}>
              The error was:{' '}
              <code style={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
                {error.message}
              </code>
            </p>
          )}
        </div>
      )}
    </div>
  )
}
