import * as React from 'react'
import ReactDropzone from 'react-dropzone'
import { FaFileUpload } from 'react-icons/fa'
import { withTheme } from 'styled-components'

import { Xls } from 'bvdash-core/icons'
import { Button } from 'bvdash/components/buttons'

import {
  DescriptionItem,
  InnerSection,
  LinkWrapper,
  Section,
  Spacer,
  UploadInputContainer,
  dropzoneStyle,
} from './styles'

import { Modal } from 'bvdash/components/modals'
import { Container, FlexContainer } from 'bvdash/components/standard/containers'
import {
  LargeHeaderText,
  StandardText,
  StandardHeaderText,
} from 'bvdash/components/standard/texts'

const Dropzone = withTheme(props => {
  const { handleDrop, hasFiles, file, setFile, theme } = props

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles[0])
    return handleDrop(acceptedFiles)
  }

  const color = hasFiles ? 'success' : 'accent'

  return (
    <UploadInputContainer>
      <ReactDropzone onDrop={onDrop} style={dropzoneStyle} />
      <Container textAlign="center">
        <LargeHeaderText color={color}>Import</LargeHeaderText>
        <FaFileUpload
          color={hasFiles ? theme.color[color] : theme.color[color]}
          size={100}
        />
        <Container marginBottom={16}>
          <StandardText>
            {!file ? 'Drag and drop your import files here' : file.name}
          </StandardText>
          {!file && <StandardText>or click to select files</StandardText>}
        </Container>
      </Container>
    </UploadInputContainer>
  )
})

const DemoFile = props => {
  const { fileUrl, text } = props
  return (
    <FlexContainer alignItems="center" marginBottom={16} marginTop={16}>
      <LinkWrapper href={fileUrl} download>
        <Xls size={24} />
        <StandardText marginLeft={8}>{text}</StandardText>
      </LinkWrapper>
    </FlexContainer>
  )
}

export const ImportModal = props => {
  const {
    currentModalProps: {
      description,
      handleClose,
      handleNotification,
      importArguments,
      importFunction,
      sampleFileUrl,
      templateFileUrl,
      type,
    },
  } = props

  const [activeFile, setActiveFile] = React.useState(null)
  const [files, setFiles] = React.useState([])

  const handleDrop = acceptedFiles => setFiles(acceptedFiles)

  const handleImport = async () => {
    const res = await importFunction({ file: files, ...importArguments })
    if (res.ok) {
      handleNotification({ type: 'success', text: 'Successful import' })
    } else {
      if (res.error) {
        handleNotification({ type: 'error', text: res.error })
      } else {
        handleNotification({ type: 'error', text: 'Error importing file' })
      }
    }
    setActiveFile(null)
    setFiles([])
    return handleClose()
  }

  const Steps = description.steps.map((d, i) => {
    return (
      <FlexContainer key={d}>
        <StandardHeaderText>Step {i + 1}: </StandardHeaderText>
        <DescriptionItem>{d}</DescriptionItem>
      </FlexContainer>
    )
  })

  const Notes = description.notes.map((d, i) => {
    return (
      <FlexContainer key={d}>
        <StandardHeaderText>Note: </StandardHeaderText>
        <DescriptionItem>{d}</DescriptionItem>
      </FlexContainer>
    )
  })

  const isImportDisabled = files.length === 0

  return (
    <Modal width={1024}>
      <>
        <FlexContainer flexWrap height="100%" overflow="auto">
          <Section>
            <InnerSection>
              <Dropzone
                handleDrop={handleDrop}
                hasFiles={files.length > 0}
                file={activeFile}
                setFile={setActiveFile}
              />
            </InnerSection>
            <FlexContainer justifyContent="space-between">
              <Button onClick={handleClose} type="button">
                Cancel
              </Button>
              <Button
                color="add"
                disabled={isImportDisabled}
                onClick={handleImport}
              >
                Import
              </Button>
            </FlexContainer>
          </Section>
          <Section>
            <InnerSection height="auto">
              <LargeHeaderText>How To</LargeHeaderText>
              <FlexContainer
                alignItems="center"
                flexWrap
                marginBottom={16}
                marginTop={16}
              >
                <div>
                  {Steps}
                  <Spacer />
                  {Notes}
                </div>
              </FlexContainer>
              <DemoFile
                fileUrl={templateFileUrl}
                text={`Empty ${type} Template`}
              />
              <DemoFile fileUrl={sampleFileUrl} text={`Sample ${type}`} />
            </InnerSection>
          </Section>
        </FlexContainer>
      </>
    </Modal>
  )
}
