import styled from 'styled-components'

import { marginLeft } from 'bvdash/css'
import { setColor } from 'bvdash/css'

export const Container = styled.div`
  ${({ theme }) => theme.shadow};
  display: flex;
  background: ${({ type, theme }) => setColor(type, theme)};
  justify-content: space-between;
  left: 0;
  padding: 8px 32px;
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 25;
`

export const Slot = styled.div`
  ${marginLeft};
  align-items: center;
  display: flex;
  ${({ flex }) => (flex ? `flex: ${flex}` : undefined)};
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};
`

export const Text = styled.p`
  color: ${({ type, theme }) =>
    type === 'neutral' ? theme.color.accent : theme.color.white};
  font-size: 14px;
  letter-spacing: 1.2px;
`
