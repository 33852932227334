import routes from 'bvdash/routes'

import { UserAdd, ListUsers } from 'bvdash/admin/users'
import { ProjectAddEdit, ProjectTeam } from 'bvdash/admin/projects'

import { Welcome } from 'bvdash/admin/setup/Welcome'

const projectRoutes = titles => {
  const title = titles.project
  return [
    {
      Component: ProjectAddEdit,
      hasProgram: true,
      label: `${title}`,
      path: routes.admin.projectAddItem,
    },
    {
      Component: ProjectTeam,
      hasProgram: true,
      label: `${title}`,
      path: routes.admin.projectTeam,
    },
  ]
}
const userRoutes = titles => {
  const title = 'user'
  return [
    {
      Component: ListUsers,
      label: `${title}`,
      path: routes.admin.users,
    },
    {
      Component: UserAdd,
      label: `${title}`,
      path: routes.admin.usersAdd,
    },
  ]
}

const welcomeRoutes = [{ path: routes.admin.setup, Component: Welcome }]

export const setupRoutes = ({ titles }) => [
  ...welcomeRoutes,
  ...projectRoutes(titles),
  ...userRoutes(titles),
]
