import styled from 'react-emotion'
import { color } from 'bvdash/styles'

export const Breadcrumbs = styled.div`
  ${({ color: c }) => color[c] || color.dodgerBlue};
  font-size: 18px;
  font-weight: normal;
  height: 44px;

  margin-bottom: 8px;
  margin-top: 8px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`

export const BreadcrumbsSelectProject = styled(Breadcrumbs)`
  ${color.waterloo};
`
