import * as React from 'react'

import { TableButton } from 'bvdash/components/buttons'

import { ToggleDropdown, withToggle } from 'bvdash/components/toggle'

import { ACTIVE, DISABLED, EMAIL_NOT_SENT, PENDING } from './constants'

const validatedTextType = userState => {
  if (userState.isActive) {
    return {
      label: 'Active',
      type: 'active',
    }
  }
  switch (userState.isValidated) {
    case EMAIL_NOT_SENT:
      return {
        label: 'Email not sent',
        type: 'caution',
      }
    case PENDING:
      return {
        label: 'Pending',
        type: 'warning',
      }
    case DISABLED:
      return {
        label: 'Disabled',
        type: 'muted',
      }
    default:
      return {
        label: 'Email not sent',
        type: 'caution',
      }
  }
}

export const Status = withToggle(props => {
  const { handleClick, isActive, isAuthUser, toggle, value } = props

  const type = value ? 'active' : 'inactive'
  const text = value ? 'active' : 'inactive'

  const choices = [{ value: '', label: 'Deactivate User' }]

  return (
    <>
      <TableButton
        type={type}
        isActive={isActive}
        onClick={() => toggle.toggle()}
        noShadow={!isActive || isAuthUser || handleClick == null}
      >
        {text}
      </TableButton>
      {!isAuthUser && isActive && handleClick != null ? (
        <ToggleDropdown
          choices={choices}
          handleClick={handleClick}
          toggle={toggle}
        />
      ) : null}
    </>
  )
})

export const Validated = withToggle(props => {
  const {
    displayDropdown,
    handleClick,
    titles,
    toggle,
    userState,
    width,
  } = props

  const choices =
    !userState.isAuthUser && userState.isActive
      ? [{ value: '', label: 'Deactivate User' }]
      : userState.isValidated === PENDING
      ? [{ value: '', label: 'Resend Validation Email' }]
      : userState.isValidated === EMAIL_NOT_SENT && !userState.hasProjects
      ? [
          {
            value: '',
            label: `User has no ${titles.project}. Click Here to add user to a ${titles.project}.`,
          },
        ]
      : [{ value: '', label: 'Send Validation Email' }]

  const { label, type } = validatedTextType(userState)

  return (
    <>
      <TableButton
        type={type}
        onClick={() => toggle.toggle()}
        noShadow={!displayDropdown}
      >
        {label}
      </TableButton>
      {displayDropdown ? (
        <ToggleDropdown
          choices={choices}
          handleClick={handleClick}
          toggle={toggle}
          wordWrap
          width={width}
        />
      ) : null}
    </>
  )
})

const projectTeamTextType = userState => {
  if (userState.isMember) {
    if (userState.isActive) {
      return {
        label: 'Assigned',
        type: 'active',
      }
    }
    switch (userState.isValidated) {
      case ACTIVE:
        return {
          label: 'Assigned',
          type: 'active',
        }
      case EMAIL_NOT_SENT:
        return {
          label: 'Assigned',
          type: 'caution',
        }
      case PENDING:
        return {
          label: 'Assigned',
          type: 'warning',
        }
      case DISABLED:
        return {
          label: 'Assigned',
          type: 'muted',
        }
      default:
        return {
          label: 'Assigned',
          type: 'muted',
        }
    }
  }
  return {
    label: 'Unassigned',
    type: 'muted',
  }
}

export const ProjectTeamDropdown = withToggle(props => {
  const { displayDropdown, handleClick, toggle, user } = props

  const choices = user.isMember
    ? [{ value: '', label: 'Unassign from Project' }]
    : [{ value: '', label: 'Assign to Project' }]

  const { label, type } = projectTeamTextType(user)

  return (
    <>
      <TableButton
        type={type}
        onClick={() => toggle.toggle()}
        noShadow={user.isManager}
      >
        {label}
      </TableButton>
      {displayDropdown ? (
        <ToggleDropdown
          choices={choices}
          handleClick={handleClick}
          toggle={toggle}
        />
      ) : null}
    </>
  )
})
