import { Form } from 'formik'
import styled from 'react-emotion'

import { InputContainer } from 'bvdash/components/inputs/styles'

export const StandardInputContainer = styled(InputContainer)`
  min-width: 0;
  width: 100%;
`

export const FormContainer = styled(Form)`
  width: 768px;
  max-width: 100%;
`
