import styled, { css } from 'react-emotion'
import { Link } from 'react-router-dom'

import { color } from 'bvdash/styles'

export const Container = styled.div`
  position: relative;
`

const buttonStyling = css`
  border: none;
  cursor: pointer;
  height: auto;
  line-height: 1.4;
  outline: none;
  padding: 5%;
  text-decoration: none;
  text-align: left;

  &:hover {
    ${color.dodgerBlue}
  }
`

export const BurgerLinkButton = styled(Link)`
  ${({ value }) => (value ? color.dodgerBlue : color.sanJuan)}
  ${buttonStyling};
`

export const BurgerButton = styled.p`
  ${({ value }) => (value ? color.dodgerBlue : color.sanJuan)}
  ${buttonStyling};
`

export const IconContainer = styled.span`
  display: inline-block;
  margin-right: 8px;
  width: 30px;
`
