import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { Button, renderLabelText } from 'bvdash/components'
import { DefaultEmpty, Layout } from 'bvdash/ui/core'

import routes from 'bvdash/routes'

// TODO(Erik): Update with new image when we have it.
import EmptyFavoriteProjects from 'bvdash-core/artwork/EmptyProjectsNew.png'
import EmptyIssues from 'bvdash-core/artwork/EmptyIssuesNew.png'
import EmptyBudget from 'bvdash-core/artwork/EmptyBudgetNew.png'
import EmptyUpdates from 'bvdash-core/artwork/EmptyUpdatesNew.png'

import EmptyProjects from 'bvdash-core/artwork/EmptyProjectsNew.png'

// TODO(Erik): Implement for projects.
const pageText = theme => {
  const programType = renderLabelText('program', theme)
  const projectType = renderLabelText('project', theme)

  const toAdminButton = {
    route: routes.admin.toString(),
    text: 'To Admin',
  }

  const toAdminDescription = 'Please visit the Admin dashboard'

  const defaultProgramProjectEmpty = {
    description: toAdminDescription,
    helmet: `${projectType}s`,
    image: EmptyFavoriteProjects,
    title: `No ${projectType}s Created Yet`,
    button: toAdminButton,
  }

  return {
    program: {
      budget: {
        description: toAdminDescription,
        helmet: `${programType} Budget`,
        image: EmptyBudget,
        title: `No ${programType} Budget`,
        button: toAdminButton,
      },
      issue: {
        description: toAdminDescription,
        helmet: `${programType} Issues`,
        image: EmptyIssues,
        title: `No ${programType} Issues`,
        button: toAdminButton,
      },
      project: defaultProgramProjectEmpty,
      schedule: {
        description: toAdminDescription,
        helmet: `${programType} Schedule`,
        title: `No ${programType} Schedule`,
        button: toAdminButton,
      },
      team: {
        description: toAdminDescription,
        helmet: `${programType} Team`,
        title: `No ${programType} Team`,
        button: toAdminButton,
      },
      updates: {
        description: toAdminDescription,
        helmet: `${programType} Updates`,
        image: EmptyUpdates,
        title: `No ${programType} Updates`,
        button: toAdminButton,
      },
      defaultEmpty: defaultProgramProjectEmpty,
    },
    project: {
      favorite: {
        description: `To add a ${projectType} to your favorites, click on the three dotted line on the far right hand side of any ${projectType} on the "${projectType}s" page.`,
        helmet: `Favorite ${projectType}s`,
        image: EmptyFavoriteProjects,
        title: `No Favorite ${projectType}s`,
      },
      project: {
        description: toAdminDescription,
        helmet: `${projectType}s`,
        image: EmptyProjects,
        title: `No ${projectType}s`,
        button: toAdminButton,
      },
      defaultEmpty: defaultProgramProjectEmpty,
    },
  }
}

export const EmptyScreen = props => {
  const { program, project, theme, type } = props

  const renderText = () => {
    if (program) {
      const prog = pageText(theme).program
      switch (type) {
        case 'budget':
          return prog.budget
        case 'issue':
          return prog.issue
        case 'project':
          return prog.project
        case 'schedule':
          return prog.schedule
        case 'team':
          return prog.team
        case 'updates':
          return prog.updates
        default:
          return prog.defaultEmpty
      }
    } else if (project) {
      const proj = pageText(theme).project
      switch (type) {
        case 'favorite':
          return proj.favorite
        case 'project':
          return proj.project
        default:
          return proj.defaultEmpty
      }
    }
  }

  const { description, helmet, image, title, button } = renderText()

  return (
    <Layout center>
      <Helmet>
        <title>{helmet}</title>
      </Helmet>
      <DefaultEmpty description={description} image={image} title={title} />
      {button != null && (
        <Link to={button.route}>
          <Button color="add">{button.text}</Button>
        </Link>
      )}
    </Layout>
  )
}
