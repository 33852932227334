import * as React from 'react'

import { Container, Slot, Text } from './styles'
import { Checkmark, Cross } from 'bvdash-core/icons'

import { IconButton } from 'bvdash/components/buttons'
import { colorMap } from 'bvdash/styles'

const setIcon = type => {
  switch (true) {
    case 'success':
      return <Checkmark color={colorMap.solidWhite} />
    case 'error':
    default:
      return null
  }
}

export const Notification = props => {
  const {
    alwaysDisplay,
    display,
    handleClose,
    LeftItem,
    RightItem,
    text,
    type,
  } = props

  React.useEffect(() => {
    if (display && !alwaysDisplay) {
      const timer = setTimeout(() => {
        handleClose()
      }, 5000)
      return () => clearTimeout(timer)
    }
  })

  const iconColor = type === 'neutral' ? 'accent' : type

  return display ? (
    <Container type={type}>
      <Slot justifyContent="flex-start">
        {setIcon(type)}
        <Text type={type}>{text}</Text>
      </Slot>
      {LeftItem != null ? (
        <Slot flex={1} justifyContent="flex-start">
          {LeftItem}
        </Slot>
      ) : null}
      {RightItem != null ? (
        <Slot flex={1} justifyContent="flex-end">
          {RightItem}
        </Slot>
      ) : null}
      <Slot justifyContent="flex-end" marginLeft={48}>
        <IconButton
          Icon={Cross}
          onClick={handleClose}
          background={type}
          color={iconColor}
        />
      </Slot>
    </Container>
  ) : null
}

Notification.defaultProps = {
  alwaysDisplay: false,
  text: '',
}
