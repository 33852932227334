/**
 * Connect :component:`Select` to Formik's Field.
 */

import * as React from 'react'
import { css } from 'react-emotion'

import Select from './Select'
import { color, textBold } from 'bvdash/styles'

function SelectField({
  // Field connected to Formik
  field,
  form,

  // Custom controlled and read-only fields
  value,
  onChange,

  label,
  labelClassName = defaultLabelClassName,
  emptyLabel,
  emptyValue,
  choices,
  multiple = false,
}) {
  return (
    <Select
      label={label}
      labelClass={labelClassName}
      emptyLabel={emptyLabel}
      emptyValue={emptyValue}
      onChange={
        form != null
          ? // Formik field
            value => () => form.setFieldValue(field.name, value)
          : // Check if we have read-only field
          typeof onChange === 'function'
          ? // Custom controlled field
            value => () => onChange(value)
          : // Read-only field
            undefined
      }
      value={form != null ? field.value : value}
      choices={choices}
      multiple={multiple}
    />
  )
}

const defaultLabelClassName = css`
  ${color.sanJuan};
  ${textBold};
`

export default SelectField
