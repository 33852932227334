import { handleEnter } from './handlers'

export function clickable(
  onClick,
  { role = 'button', tabIndex = 0, onKeyDown } = {}
) {
  if (!onClick) return {}

  return {
    onClick,
    role,
    tabIndex,
    onKeyDown: onKeyDown || handleEnter(onClick),
  }
}
