import styled from 'react-emotion'
import { colorMap } from 'bvdash/styles'
import Linkify from 'linkifyjs/react'

export const StyledLinkifyLinks = styled(Linkify)`
  a:link {
    color: ${colorMap.sanJuan};
    text-decoration: none;
  }
  a:visited {
    color: ${colorMap.sanJuan};
    text-decoration: none;
  }
  a:hover {
    color: ${colorMap.dodgerBlue};
    text-decoration: none;
  }
`
