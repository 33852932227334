import * as React from 'react'
import styled, { css } from 'react-emotion'

import { color, colorMap } from 'bvdash/styles'
import { Trash } from 'bvdash-core/icons'
import { Error } from 'bvdash/ui/forms'
import { ActionUpdateTitle } from 'bvdash/actions/ui'
import { DateComponent } from 'bvdash/components/DateComponent'
import { StyledLinkifyLinks } from 'bvdash/components/StyledLinkifyLinks'

function ActionUpdateList({
  updates = [],
  title,
  btnAdd,
  onDelete,
  onAddUpdate,

  field,
  form,
}) {
  // if there're no updates and we can't add one, hide this section completely
  if (!onAddUpdate && !updates.length) return null

  return (
    <Content isEmpty={!updates.length}>
      <ActionUpdateTitle
        title={title}
        btnAdd={btnAdd}
        onAddUpdate={onAddUpdate}
      />

      {updates.map(update => (
        <ActionUpdate key={update.id} update={update}>
          {update.canEdit && onDelete && (
            <Trash
              onClick={() => onDelete(update.id)}
              className={trashIconClass}
            />
          )}

          <dl>
            <PostedDateDiv>
              <dt>Posted by:</dt>
              <dd>{update.author.fullName}</dd>
            </PostedDateDiv>

            <PostedDateDiv>
              <dt>Date:</dt>
              <dd>{<DateComponent date={update.dateCreated} />}</dd>
            </PostedDateDiv>
          </dl>

          {!update.canEdit || !field ? (
            <StyledLinkifyLinks>{update.content}</StyledLinkifyLinks>
          ) : (
            <>
              <Textarea {...field} />
              {form != null && (
                <Error show={form.errors[field.name]}>
                  (Update content can't be left empty)
                </Error>
              )}
            </>
          )}
        </ActionUpdate>
      ))}
    </Content>
  )
}

export default ActionUpdateList

const Content = styled.section`
  border-top: 1px solid ${colorMap.dropShadow};
  ${props =>
    props.isEmpty &&
    `
  padding-bottom: 20px;
  `};
`

const PostedDateDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px;
`

const ActionUpdate = styled.div`
  padding: 20px;
  position: relative;
  white-space: pre-line;
  max-width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${colorMap.dropShadow};
  }

  dl {
    display: flex;
    margin-bottom: 15px;
    max-width: 100%;
    flex-wrap: wrap;
  }

  dt {
    ${color.waterloo};
    margin-right: 5px;
  }

  dd {
    ${color.sanJuan};
    margin-right: 15px;
  }
`

const trashIconClass = css`
  float: right;
`

const Textarea = styled.textarea`
  width: 100%;
  color: inherit;
  font-size: inherit;

  border: none;
  resize: none;
  outline: none;
`
