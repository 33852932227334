import * as React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import URLSearchParams from 'url-search-params'

const AuthRoute = props => {
  const {
    component,
    authUser: { isAuthenticated, loading },
  } = props

  if (isAuthenticated) {
    return <Route {...props} component={component} />
  } else if (loading) {
    return null
  } else {
    // redirect to login page and remember original location
    const search = new URLSearchParams()
    search.append('next', props.location.pathname)
    const next = props.location.pathname !== '/' ? `?${search.toString()}` : ''
    return <Redirect to={`/login${next}`} />
  }
}

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

export default AuthRoute
