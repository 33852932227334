import React from 'react'
import styled, { css, withTheme } from 'styled-components'

import { Trash } from 'bvdash-core/icons'

import { marginLeft, marginRight, marginTop, marginBottom } from 'bvdash/css'

import { setColor } from 'bvdash/css'

const standardStyle = css`
  ${marginLeft};
  ${marginRight};
  ${marginTop};
  ${marginBottom};
  ${({ noShadow, theme }) => (noShadow ? undefined : theme.shadow)};
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  filter: brightness(100%);
  transition: filter 0.2s ease;
  &:hover {
    filter: ${({ disabled, noShadow }) =>
      !disabled && !noShadow && `brightness(90%)`};
    transition: filter 0.2s ease;
  }
`
const Outer = styled.div`
  ${standardStyle};
  background: ${({ theme }) => theme.color.white};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`
const Container = styled.div`
  ${({ background, theme }) => {
    const buttonColor = setColor(background, theme)
    return background
      ? `background: ${buttonColor}${theme.opacity[25]}`
      : undefined
  }};
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => (padding >= 0 ? padding : 5)}px;
`

const TrashContainer = styled.div`
  ${standardStyle};
  background: ${({ theme }) => theme.color.white};
`

export const IconButton = withTheme(props => {
  const {
    children,
    color = 'accent',
    Icon,
    iconProps,
    onClick,
    noShadow,
    size,
    theme,
  } = props

  const iconColor = setColor(color, theme)

  const defaultIconSize = size || 14

  return (
    <Outer noShadow={noShadow} size={defaultIconSize + 5 * 2} {...props}>
      <Container onClick={onClick} {...props}>
        {Icon ? (
          <Icon
            color={iconColor}
            height={defaultIconSize}
            width={defaultIconSize}
            {...iconProps}
          />
        ) : null}
        {children}
      </Container>
    </Outer>
  )
})

export const BlueCircleButton = props => {
  const { children } = props
  return (
    <IconButton {...props} background="accent" color="accent">
      {children}
    </IconButton>
  )
}

export const TrashButton = withTheme(props => {
  const { onClick, theme } = props
  return (
    <TrashContainer onClick={onClick} {...props}>
      <Trash color={theme.color.error} />
    </TrashContainer>
  )
})
