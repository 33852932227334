import gql from 'graphql-tag'

export const UserFragment = gql`
  fragment User on UserType {
    firstName
    lastName
    fullName
    email
    phoneNumber
    jobTitle
    id
    projects {
      id
    }

    isActive
    isValidated

    isAdmin
    userProfilePicture {
      id
      url
    }
  }
`
