/** @flow
 *
 * .. component:: Delay
 *
 *    :prop number wait: How long (in ms) to wait before first rendering.
 *    :prop boolean skip: If true, skip wait time immmediatelly.
 *
 *    Delay first render of component. Useful in combination with skeletons
 *    or any other loader indicator, which should be displayed only after
 *    minimum wait time. For example, if data for component are loaded fast
 *    (< 250ms) then the progress indicator isn't required and would cause
 *    unnecessary flickering of loader indicator.
 *
 *    .. code-block:: jsx
 *
 *       <Delay skip={!loading}>
 *         {loading ? <Skeleton> : <Component>}
 *        </Delay>
 */

import * as React from 'react'

type DelayProps = {
  wait: number,
  skip: boolean,
}

type DelayState = {
  waiting: true,
}

export default class Delay extends React.Component<DelayProps, DelayState> {
  static defaultProps = {
    wait: 250,
    skip: false,
  }

  state = {
    waiting: true,
  }

  componentDidMount() {
    this.timer = setTimeout(
      () =>
        this.setState({
          waiting: false,
        }),
      this.props.wait
    )
  }

  componentDidUpdate() {
    if (this.props.skip) {
      clearTimeout(this.timer)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    if (this.state.waiting && !this.props.skip) return null
    return this.props.children
  }
}
