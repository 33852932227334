// @flow
import * as React from 'react'
import { cx } from 'react-emotion'
import { color } from 'bvdash/styles'

type TextProps = {
  color: $Keys<typeof color>,
  className?: string,
  children: any,
}

export default ({
  color: _color,
  className,
  children,
  ...props
}: TextProps) => (
  <span className={cx(className, color[_color])} {...props}>
    {children}
  </span>
)
