import * as React from 'react'
import { compose } from 'react-apollo'
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { colorMap } from 'bvdash/styles'
import { Actions } from 'bvdash/components/styles'
import { IconBox, ConfirmDialogDefault } from 'bvdash/ui/core'
import { Pencil, Trash } from 'bvdash-core/icons'
import { withTable, Table } from 'bvdash/ui/tables'
import { withProgramCodes, withProgramCodeRemove } from 'bvdash/admin/queries'
import { HelpText } from 'bvdash/admin/ui'
import { disableDelete } from 'bvdash/admin/utils'
import { pluralize } from 'bvdash/utils'
import { AddButton } from 'bvdash/components/buttons'
import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'
import { FlexContainer } from 'bvdash/components/standard/containers'

import { fieldsTitle, codesTitle } from 'bvdash/admin/adminLinks'

export const ProjectCodes = props => {
  const { titles } = props

  return (
    <>
      <AdminBreadcrumbs>
        {titles.project} Setup / {titles.project} {codesTitle}s
      </AdminBreadcrumbs>
      <FlexContainer>
        <HelpText>
          A {titles.project} {codesTitle} is a repetitive value that can be used{' '}
          across multiple {pluralize(titles.project)}. (e.g. Area, Phase,{' '}
          Department, State, City, etc.){<br />}
          {<br />}For unique values such as Address, Phone Number, etc. use{' '}
          {titles.project} {fieldsTitle}s.
        </HelpText>
      </FlexContainer>
      <AddButton to={routes.admin.projectAddCode}>
        Add New {titles.project} {codesTitle}
      </AddButton>

      <ProjectCodesTable titles={titles} />
    </>
  )
}

const programCodesConfig = {
  name: 'adminProjectCodes',
  rowKey: ({ key, value }) => `${key}-${value}`,
  sortBy: 'key',
  columns: props => {
    const { titles } = props

    return [
      {
        attr: 'key',
        label: `${titles.project} Group Title`,
      },
      {
        attr: 'value',
        label: `${titles.project} Group Title Value`,
      },
      {
        attr: 'description',
        label: `${titles.project} Group Title Description`,
      },
      {
        shrink: true,
        attr: '_actions',
        render: ({ row, props }) => {
          return (
            <Actions>
              <Link
                to={reverse(routes.admin.projectEditCode, { code: row.key })}
              >
                <IconBox>
                  <Pencil inline />
                </IconBox>
              </Link>
              {disableDelete(row.key, row.value) ? null : (
                <ConfirmDialogDefault
                  title={`Delete ${titles.project} Code Confirmation`}
                  question={
                    <>
                      You are about to remove a {titles.project} {codesTitle}{' '}
                      {row.key} = {row.value}
                      . <br />
                      Are you sure you want to proceed?
                    </>
                  }
                  theme="danger"
                >
                  {confirm => (
                    <Trash
                      color={colorMap.carnation}
                      onClick={confirm(() => props.programCodeRemove(row.id))}
                    />
                  )}
                </ConfirmDialogDefault>
              )}
            </Actions>
          )
        },
      },
    ]
  },
}

const ProjectCodesTable = compose(
  withProgramCodes,
  withProgramCodeRemove,
  withTable(programCodesConfig)
)(props => {
  const { table, isLoading, programCodeRemove } = props

  return (
    <FlexContainer marginBottom={24}>
      <Table
        loading={isLoading}
        table={table}
        renderProps={{ programCodeRemove }}
      />
    </FlexContainer>
  )
})
