import * as React from 'react'
import { cx } from 'react-emotion'

import { Search } from 'bvdash-core/icons'
import styles from './SearchBox.scss'

export default class SearchBox extends React.Component {
  static defaultProps = {
    id: 'search',
    placeholder: 'Search…',
    focusOnMount: false,
  }

  input = React.createRef()

  componentDidMount() {
    this.props.focusOnMount && this.input.current && this.input.current.focus()
  }

  render() {
    const {
      id,
      value,
      onSearch,
      placeholder,
      className,
      disabled = false,
    } = this.props
    return (
      <label htmlFor={id} className={cx(styles.searchbox, className)}>
        <Search width={16} height={16} />

        <input
          id={id}
          type="text"
          className={styles.input}
          placeholder={placeholder}
          value={value}
          onChange={onSearch}
          disabled={disabled}
          ref={this.input}
          autoComplete="off"
        />
      </label>
    )
  }
}
