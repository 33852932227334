import styled from 'styled-components'

export const BaseWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 769px) {
    height: calc(100vh - 300px);
  }
`

export const BaseSidebar = styled.div`
  border: 1px solid ${({ theme }) => theme.color.shadow};
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 296px;
  max-height: 100%;
`

export const BaseContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.shadow};
  flex: 4;
  flex-basis: 472px;

  @media (min-width: 768px) {
    max-height: 100%;
  }
`

export const Container = styled.div``

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.shadow};
  flex: 4;
  flex-basis: 472px;
`

export const Header = styled.header`
  align-items: center;
  display: flex;
  outline: none;
  padding: 16px 24px;
  position: relative;
  width: 100%;
`

export const HeaderTitle = styled.h1`
  flex: 1;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5em;
`

export const ListContainer = styled.ul`
  max-height: calc(100% - 62px);
  overflow-y: scroll;
`

export const ListItemContainer = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.color.shadow};
`

export const ListItem = styled.span`
  background: ${({ isActive, theme: { color } }) =>
    isActive ? color.whiteDark : color.white};
  border-left: 3px solid
    ${({ isActive, theme }) => (isActive ? theme.color.accent : 'transparent')};
  color: ${({ theme }) => theme.color.main};
  cursor: pointer;
  font-weight: ${({ isUnread }) => (isUnread ? '500' : 'normal')};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-decoration: none;

  &:hover {
    border-left: 3px solid ${({ theme }) => theme.color.accent};
  }
`

export const MainContainer = styled.div`
  max-height: calc(100vh - 300px - 64px);
  height: 100%;
  overflow-y: scroll;

  @media (min-width: 769px) {
    min-height: calc(100vh - 300px - 64px);
  }
`
