import { reverse } from 'named-urls'
import * as React from 'react'
import { compose } from 'react-apollo'
import { withTheme } from 'styled-components'

import routes from 'bvdash/routes'

import * as Card from 'bvdash/ui/core/Card'
import { Number } from 'bvdash/ui/core'
import { withBudgetFields } from 'bvdash/admin/queries'

import { pluralize } from 'bvdash/utils'
import { setColor } from 'bvdash/css'

import { PieChart } from 'bvdash/components/charts'
import {
  LegendWrapper,
  LegendBox,
  LegendContainer,
  LegendItem,
  LegendItemText,
} from 'bvdash/components/charts/Legend'

const DashboardBudgetSummary = props => {
  const { budget, budgetFields, budgetStatus, objectKey, theme, titles } = props

  if (budgetFields.isLoading) return null

  const budgetStatusData = [
    {
      description: `${pluralize(titles.project)} on ${titles.budget}`,
      label: `On ${titles.budget}`,
      value: budgetStatus.onBudget,
      color: setColor('onBudget', theme),
    },
    {
      description: `${pluralize(titles.project)} over ${titles.budget}`,
      label: `Over ${titles.budget}`,
      value: budgetStatus.overBudget,
      color: setColor('overBudget', theme),
    },
  ]

  const Legend = budgetStatusData.map(({ label, value, color }) => {
    return (
      <LegendItem key={label}>
        <LegendBox color={color} />
        <LegendItemText>{label}</LegendItemText>
        {value}
      </LegendItem>
    )
  })

  const budgetData = [
    {
      label: `Total ${titles.program} ${titles.budget}`,
      value: <Number value={budget.original} numberScale="dashboard" />,
    },
    {
      label: budgetFields.all.find(x => x.internal === 'EAC').label,
      value: <Number value={budget.eac} numberScale="dashboard" />,
    },
    {
      label: budgetFields.all.find(x => x.internal === 'ACTUAL').label,
      value: <Number value={budget.actual} numberScale="dashboard" />,
    },
    {
      label: 'Variance',
      value: (
        <Number value={budget.original - budget.eac} numberScale="dashboard" />
      ),
    },
  ]

  return (
    <Card.Card
      title={`${titles.program} ${titles.budget}`}
      detailsLink={reverse(routes.program.budget, { programKey: objectKey })}
    >
      <PieChart data={budgetStatusData} Legend={Legend} />
      <LegendWrapper>
        <LegendContainer>
          {budgetData.map(({ label, value, color }) => (
            <LegendItem key={label}>
              <LegendItemText>{label}</LegendItemText>
              {value}
            </LegendItem>
          ))}
        </LegendContainer>
      </LegendWrapper>
    </Card.Card>
  )
}

export default compose(
  withBudgetFields,
  withTheme
)(DashboardBudgetSummary)
