import * as React from 'react'
import { Print } from 'bvdash-core/icons'

import HeaderButton from './HeaderButton'

const handlePrint = () => window.print()

export default () => (
  <HeaderButton label="Print" icon={Print} onClick={handlePrint} />
)
