import * as React from 'react'
import styled from 'react-emotion'

import { color, selectable } from 'bvdash/styles'
import { DatePicker } from 'bvdash/ui/core'
import { FieldGroup, FieldLabel } from './Field'

const DatePickerFieldGroup = styled(FieldGroup)`
  input {
    ${selectable};
    ${color.sanJuan};
    border: none;
    font-size: 14px;
    font-weight: 500;

    &::placeholder {
      font-weight: normal;
    }
  }
`

export default function DatePickerField({ setValue, value, dayPickerProps }) {
  return (
    <DatePickerFieldGroup>
      <FieldLabel>Deadline:</FieldLabel>
      <DatePicker
        value={value}
        onChange={setValue}
        dayPickerProps={dayPickerProps}
      />
    </DatePickerFieldGroup>
  )
}
