import * as React from 'react'
import PropTypes from 'prop-types'

export default class Toggle extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    initialOpen: PropTypes.bool,
  }

  static defaultProps = {
    initialOpen: false,
  }

  state = {
    isOpen: this.props.initialOpen,
  }

  setIsOpen = isOpen =>
    this.setState(state => ({
      isOpen: isOpen !== undefined ? isOpen : !state.isOpen,
    }))

  open = e => this.setIsOpen(true)
  close = e => this.setIsOpen(false)
  toggle = e => this.setIsOpen()

  render() {
    return this.props.children({
      isOpen: this.state.isOpen,
      open: this.open,
      close: this.close,
      toggle: this.toggle,
    })
  }
}
