import * as React from 'react'
import PropTypes from 'prop-types'

import Toggle from '../Toggle'
import styles from './Tooltip.scss'

const Tooltip = ({ text, children }) => (
  <Toggle>
    {({ isOpen, open, close }) => (
      <span className={styles.root}>
        {isOpen && <div className={styles.tooltip}>{text}</div>}
        {
          <span
            onMouseEnter={open}
            onMouseLeave={close}
            className={styles.wrapper}
          >
            {children}
          </span>
        }
      </span>
    )}
  </Toggle>
)

Tooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  toggledOn: PropTypes.bool,
  show: PropTypes.func,
  hide: PropTypes.func,
}

export default Tooltip
