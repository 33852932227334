/**
 * Open/Close status field with confirmation dialog on change.
 *
 * NOTE: Check the supported `type` of `ConfirmStatusChange` dialog and
 * add copy if missing.
 */

import * as React from 'react'

import { openCloseStatusChoices } from 'bvdash/components/tables/filters'
import ConfirmStatusChange from 'bvdash/actions/ui/ConfirmStatusChange'
import SelectField from './SelectField'

const StatusField = props => {
  const { field, form, label, type, choices = openCloseStatusChoices } = props
  return (
    <ConfirmStatusChange type={type} isOpen={field.value}>
      {confirm => (
        <SelectField
          label={label}
          value={field.value}
          onChange={confirm(value => form.setFieldValue(field.name, value))}
          choices={choices}
        />
      )}
    </ConfirmStatusChange>
  )
}

export default StatusField
