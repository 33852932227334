import * as React from 'react'
import PropTypes from 'prop-types'

import { AsyncImage } from 'bvdash/ui/core'
import { truncate } from 'bvdash/utils'
import styles from './Summary.scss'

const Summary = ({ image, imageAlt, text, footer }) => {
  const { value, truncated } = truncate(text, 150)

  return (
    <div className={styles.body}>
      {image && (
        <AsyncImage src={image} alt={imageAlt} className={styles.image} />
      )}
      <p className={styles.text}>
        {value}
        {truncated && '…'}
        {footer && <span className={styles.footer}>{footer}</span>}
      </p>
    </div>
  )
}

Summary.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.string,
}

export default Summary
