import { Link } from 'react-router-dom'
import styled, { css } from 'react-emotion'

import { color, colorMap, opacityMap } from 'bvdash/styles'

export const setColor = type => {
  switch (type) {
    case 'error':
      return colorMap.carnation
    case 'standard':
      return colorMap.dodgerBlue
    case 'success':
      return colorMap.wasabi
    case 'warning':
      return colorMap.neonCarrot
    default:
      return colorMap.solidWhite
  }
}

const dropdownFontSize = '12px'

export const alertRowColor = type => css`
  border-left: 5px solid ${setColor(type)};
`

const marginLeft = left => css`
  margin-left: ${left && '12px'};
`

const marginTop = top => css`
  margin-top: ${top && '4px'};
`

const Text = styled.p`
  font-size: ${dropdownFontSize};
  ${({ top }) => marginTop(top)};
`

export const AlertLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
`

export const AlertText = styled(Text)`
  ${color.dodgerBlue};
  ${({ left }) => marginLeft(left)};
`

export const Content = styled.div`
  ${({ left }) => marginLeft(left)};
  align-items: center;
  display: flex;
  flex: 1;
`

export const DateText = styled(Text)`
  ${color.waterloo}
`

export const Description = styled(Text)`
  ${color.waterloo};
`

export const Header = styled(Text)`
  ${color.sanJuan}
`

export const IconContainer = styled.div`
  align-items: center;
  background: ${({ type }) => `${setColor(type)}${opacityMap.thirty}`};
  border-radius: 100%;
  cursor: ${({ cursor }) => cursor && 'pointer'};
  display: flex;
  justify-content: center;
  height: 25px;
  width: 25px;
`

export const ProjectText = styled(Text)`
  ${color.black}
  ${({ left }) => marginLeft(left)};
`

export const RowLink = styled(Link)`
  ${color.sanJuan}
  text-decoration: none;
`
