import gql from 'graphql-tag'
import { object, string } from 'yup'

import { QuestionMark } from 'bvdash-core/icons'
import {
  projectIdExampleHelpText,
  projectNameHelpText,
  GenerateIdField,
  InputField,
  SelectField,
} from 'bvdash/components/inputs'
import { StandardInputContainer } from 'bvdash/admin/components/containers'

const generateProjectIdQuery = gql`
  query generateProjectId($programId: ID!) {
    nextId: projectNextKey(programId: $programId)
  }
`

const components = {
  components: {
    InputContainer: StandardInputContainer,
  },
}

export const inputs = props => {
  const {
    managerChoices,
    numProjects,
    projectFields,
    programId,
    programCodesGroup,
    titles,
  } = props

  const idFieldInfo = {
    ...components,
    label: `${titles.project} ID*`,
    name: 'key',
    placeholder: `Enter a ${titles.project} ID`,
    type: 'text',
    icon: {
      Icon: QuestionMark,
      text: projectIdExampleHelpText(titles),
    },
  }

  const IdField =
    numProjects > 2
      ? {
          ...idFieldInfo,
          InputComponent: GenerateIdField,
          disabled: false,
          disableGenerateId: false,
          generateIdQuery: generateProjectIdQuery,
          generateIdVariables: { programId: programId },
        }
      : {
          ...idFieldInfo,
          InputComponent: InputField,
        }

  return [
    IdField,
    {
      ...components,
      InputComponent: InputField,
      label: `${titles.project} Name*`,
      name: 'name',
      placeholder: `Enter your ${titles.project} Name`,
      type: 'text',
      icon: {
        Icon: QuestionMark,
        text: projectNameHelpText(titles),
      },
    },
    {
      ...components,
      InputComponent: SelectField,
      label: `${titles.project} Manager*`,
      name: 'managerId',
      options: managerChoices,
      placeholder: `Select ${titles.project} Manager*`,
    },
    ...projectFields.all.map(field => ({
      ...components,
      InputComponent: InputField,
      label: field.label || field.key,
      name: `fields.${field.key}`,
      placeholder: `Enter your ${field.key}`,
    })),
    ...programCodesGroup.codes.map(code => ({
      ...components,
      InputComponent: SelectField,
      label: code.key,
      name: `codes.${code.key}`,
      options: code.values,
      placeholder: '',
    })),
  ]
}

export const validationSchema = props => {
  const { titles } = props

  return object({
    key: string().required(`${titles.project} ID is required`),
    name: string().required(`${titles.project} Name is required`),
    managerId: string().required(`${titles.project} Manager is required`),
    codes: object({
      Status: string().required(`${titles.project} Status is required`),
    }),
  })
}
