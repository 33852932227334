import * as React from 'react'

import { useToggle } from 'bvdash/hooks/useToggle'

export const withToggle = WrappedComponent => props => {
  const { initialOpen } = props

  const { isOpen, open, close, toggle } = useToggle(initialOpen || false)

  return (
    <WrappedComponent
      {...props}
      toggle={{
        isOpen,
        open,
        close,
        toggle,
      }}
    />
  )
}
