import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { programsListQuery } from 'bvdash/queries/withPrograms'

const customerQuery = gql`
  query customer {
    customer {
      theme {
        navTitlePortfolio
        navTitleProgram
        navTitleProjects
      }
    }
  }
`

export const mapCustomerTheme = theme => {
  return {
    navTitleProgram: theme.navTitleProgram || 'program',
    navTitleProjects: theme.navTitleProjects || 'project',
    navTitlePortfolio: theme.navTitlePortfolio || 'Portfolio',
  }
}

// using only cache
export const withCustomerTheme = graphql(customerQuery, {
  props: ({ data }) => {
    const { loading, customer = {} } = data

    return {
      theme: {
        isLoading: loading,
        theme: mapCustomerTheme(customer),
      },
    }
  },
})

// using only cache
export const withPrograms = graphql(programsListQuery, {
  props: ({ data }) => {
    const { loading, programs = [] } = data
    return {
      programs: {
        isLoading: loading,
        programs: programs.filter(program => !program.deleted),
      },
    }
  },
})
