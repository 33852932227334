import gql from 'graphql-tag'
import { path } from 'ramda'
import { graphql } from 'react-apollo'

import { projectIssueQuery } from './withProjectIssue'

import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { IssueFragment } from './issueFragment'

const projectIssueEditMutation = gql`
  mutation IssueEdit(
    $id: ID!
    $attachments: [Upload]
    $urgency: Int
    $importance: Int
    $assigneeId: ID
    $content: String
    $status: String
  ) {
    issueEdit(
      id: $id
      attachments: $attachments
      urgency: $urgency
      importance: $importance
      assigneeId: $assigneeId
      content: $content
      status: $status
    ) {
      ok
      error
      issue {
        ...Issue

        attachments {
          ...Attachment
        }

        updates {
          id
          canEdit
          content
          dateCreated
          author {
            id
            fullName
          }
        }
      }
    }
  }
  ${AttachmentFragment}
  ${IssueFragment}
`

export const withProjectIssueEdit = graphql(projectIssueEditMutation, {
  props: ({ mutate }) => ({
    projectIssueEdit(id, issue, { issueId, project }) {
      return mutate({
        variables: { ...issue, id },
        awaitRefetchQueries: true,
        refetchQueries: ({ data }) => {
          return [
            {
              query: projectIssueQuery,
              variables: { issueId, project },
            },
          ]
        },
      }).then(path(['data', 'issueEdit']))
    },
  }),
})
