import * as React from 'react'
import styled from 'react-emotion'

import { reverse } from 'named-urls'

import { sum } from 'ramda'
import {
  VictoryAxis,
  VictoryBar,
  VictoryStack,
  VictoryChart,
  VictoryLabel,
} from 'victory'

import { settings } from 'bvdash/queries/settings'
import { withCustomer } from 'bvdash/auth/queries'
import { Box, Number, Card } from 'bvdash/ui/core'
import { withTable, Table } from 'bvdash/ui/tables'

import routes from 'bvdash/routes'
import { colorMap } from 'bvdash/styles'
import { compose } from 'react-apollo'

const config = {
  name: 'projectBudget',
  rowKey: row => row.description,
  columns: props => {
    const { titles } = props
    return [
      {
        attr: 'description',
        label: 'Phase',
        summary: 'Total',
      },
      {
        attr: 'original',
        label: titles.budget,
        render: ({ value }) => <Number value={value} />,
        summary: sum,
        right: true,
        shrink: true,
      },
      {
        attr: 'variance',
        label: 'Variance',
        render: ({ value }) => <Number value={value} />,
        summary: sum,
        right: true,
        shrink: true,
      },
    ]
  },
}

const ContainerBox = styled(Box)`
  margin-top: -1px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  justify-content: space-around;
  align-items: center;
  overflow: auto;
  width: 100%;
`

const ChartArea = styled.div`
  flex-grow: 0;
  margin: 12px;
`

const TableArea = styled.div`
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 48px;
  overflow: auto;
`

const defaultFont = {
  fontFamily: colorMap.primaryFont,
  fontSize: 14,
  fontWeight: 500,
}

const DashboardBudgetSummaryStories = props => {
  const {
    projectKey,
    table,
    versionsDropdown,
    settings,
    customer,
    titles,
  } = props

  const [eac, original, actual, variance] = [
    'eac',
    'original',
    'actual',
    'variance',
  ].map(key => sum(table.data.map(item => item[key])))

  const [budgetVariance, eacVariance] =
    variance > 0 ? [0, variance] : [-variance, 0]

  const {
    settings: { dashboardNumberPrecision, dashboardNumberScale },
  } = settings

  let unit = dashboardNumberScale
  const formatOptions = {
    minimumFractionDigits: dashboardNumberPrecision,
    maximumFractionDigits: dashboardNumberPrecision,
  }

  if (unit === 'A_' || unit === 'NONE' || unit === null) unit = ''

  let scale
  unit = unit.toUpperCase()
  switch (unit) {
    case 'M':
      scale = '1000000'
      break
    case 'K':
      scale = '1000'
      break
    default:
      scale = 1
  }

  let locale = 'en-US'
  if (customer.numberFormat === 'AMERICAN') {
    locale = 'en-US'
  } else if (customer.numberFormat === 'EUROPEAN') {
    locale = 'sv-SE'
  }

  return (
    <Card.Card
      title={`${titles.project} ${titles.budget}`}
      detailsLink={reverse(routes.project.budget, { projectKey })}
      versions={versionsDropdown}
    >
      <ContainerBox horizontal>
        <ChartArea>
          <VictoryChart
            padding={{ top: 20, right: 0, bottom: 20, left: 50 }}
            domainPadding={[30, 30]}
            width={200}
            height={220}
          >
            <VictoryStack categories={{ x: [titles.budget, 'eac', 'actual'] }}>
              <VictoryBar
                style={{ data: { width: 20, fill: colorMap.cadetBlue } }}
                data={[
                  { x: titles.budget, y: original },
                  { x: 'eac', y: 0 },
                  { x: 'actual', y: 0 },
                ]}
              />
              <VictoryBar
                style={{ data: { width: 20, fill: colorMap.dodgerBlue } }}
                data={[
                  { x: titles.budget, y: 0 },
                  { x: 'eac', y: eac },
                  { x: 'actual', y: 0 },
                ]}
              />
              <VictoryBar
                style={{ data: { width: 20, fill: colorMap.wasabi } }}
                data={[
                  { x: titles.budget, y: 0 },
                  { x: 'eac', y: 0 },
                  { x: 'actual', y: actual },
                ]}
              />
              <VictoryBar
                style={{
                  data: {
                    width: 20,
                    fill: budgetVariance
                      ? colorMap.carnation
                      : colorMap.sanJuan,
                  },
                }}
                data={[
                  { x: titles.budget, y: budgetVariance },
                  { x: 'eac', y: eacVariance },
                ]}
              />
            </VictoryStack>
            <VictoryAxis
              dependentAxis
              tickLabelComponent={
                <VictoryLabel
                  renderInPortal
                  text={value =>
                    parseFloat(value / scale).toLocaleString(
                      locale,
                      formatOptions
                    ) +
                    ' ' +
                    unit
                  }
                />
              }
              style={{
                axis: { stroke: colorMap.dropShadow },
                ticks: {
                  size: 5,
                  stroke: colorMap.dropShadow,
                  strokeWidth: 1,
                },
                tickLabels: {
                  ...defaultFont,
                  fontSize: 10,
                  letterSpacing: 0.8,
                  textTransform: 'uppercase',
                  fill: 'rgba(124, 133, 157, 0.9)',
                },
              }}
            />
            <VictoryAxis
              style={{
                axis: { stroke: colorMap.dropShadow },
                tickLabels: {
                  ...defaultFont,
                  fontSize: 10,
                  letterSpacing: 0.8,
                  textTransform: 'uppercase',
                  fill: 'rgba(124, 133, 157, 0.9)',
                },
              }}
            />
          </VictoryChart>
        </ChartArea>
        <TableArea>
          <Table table={table} />
        </TableArea>
      </ContainerBox>
    </Card.Card>
  )
}

export default compose(
  settings,
  withCustomer
)(withTable(config)(DashboardBudgetSummaryStories))
