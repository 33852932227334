import {
  unless,
  isNil,
  evolve,
  constructN,
  pipe,
  defaultTo,
  map,
  prop,
  curry,
  keys,
  reduce,
  assoc,
} from 'ramda'

export function truncate(value = '', maxLength) {
  if (value.length <= maxLength) {
    return {
      value,
      truncated: false,
    }
  }

  return {
    value: value.substr(0, value.indexOf(' ', maxLength)),
    truncated: true,
  }
}

export const sliceText = (text, len) =>
  text.length > len ? text.slice(0, len - 3).concat('...') : text

export function title(value) {
  // match either char at the beginning of word (^\w)
  // or space followed by a character (\s\w)
  return value.replace(/(^|\s)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase())
}

export function capitalize(value) {
  if (value == null) return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export function maybeInt(value) {
  try {
    return parseInt(value, 10)
  } catch (e) {
    return null
  }
}

export const maybeProp = key => unless(isNil, prop(key))

export const maybeDate = unless(isNil, constructN(1, Date))

export const maybeEvolve = schema => unless(isNil, evolve(schema))

export const normalizeList = schema =>
  pipe(
    defaultTo([]),
    map(evolve(schema))
  )

/**
 * Creates a new object with the own properties of the provided object, but the
 * keys renamed according to the keysMap object as `{oldKey: newKey}`.
 * When some key is not found in the keysMap, then it's passed as-is.
 *
 * Keep in mind that in the case of keys conflict is behaviour undefined and
 * the result may vary between various JS engines!
 *
 * @sig {a: b} -> {a: *} -> {b: *}
 */
export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export const arrayToObject = array => {
  let obj = {}
  for (let i = 0; i < array.length; i++) {
    Object.assign(obj, array[i])
  }
  return obj
}

export const pluralize = string => {
  const lastChar = string.slice(-1)
  const lastTwoChar = string.slice(-2)
  if (lastChar === 's' || lastTwoChar === 'sh') {
    return `${string}es`
  }
  if (lastChar === 'y' && lastTwoChar !== 'ey') {
    return `${string.substring(0, string.length - 1)}ies`
  }
  return `${string}s`
}

export const initials = name => {
  return name
    .split(' ')
    .slice(0, 2)
    .map(part => part[0])
    .join('')
}
