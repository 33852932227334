import * as React from 'react'
import Skeleton from 'react-loading-skeleton'

import { FetchMoreButton, TableContainer } from './styles'
import { Delay, Toggle } from 'bvdash/ui/core'
import { ArrowDown } from 'bvdash-core/icons'
import { clickable } from 'bvdash/utils/a11y'
import { Row, Cell, HeaderRow } from '.'
import styles from './Table.scss'

function RowList(allProps) {
  const {
    rows,
    rowKey,
    rowExpand,
    columns,
    columnSizes,
    props,
    isGrouped = false,
    isPrimary = false,
    isExpandable,
    group = {},
    groupRender: Group,
    summary,
    select,
    selectKey,
    selectRow = null,
    rowClassName = null,
  } = allProps

  if (isGrouped) {
    return Object.keys(rows).map(groupLabel => {
      return (
        <Toggle key={groupLabel} initialOpen>
          {group => (
            <>
              {groupLabel && (
                <tr className={styles.groupRow}>
                  <td colSpan={columns.length}>
                    {Group ? (
                      <Group toggle={group.toggle} expanded={group.isOpen}>
                        {groupLabel}
                      </Group>
                    ) : (
                      <div className={styles.group}>{groupLabel}</div>
                    )}
                  </td>
                </tr>
              )}

              {group.isOpen && (
                <>
                  <RowList
                    {...allProps}
                    rows={rows[groupLabel]}
                    isGrouped={false}
                  />
                  {summary.data && summary.data[groupLabel] != null && (
                    <Summary
                      summary={{ data: summary.data[groupLabel] }}
                      visibleColumns={columns}
                      renderProps={allProps.renderProps}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Toggle>
      )
    })
  }

  return rows.map(row => {
    const key = rowKey(row)
    const hasData = isExpandable && isPrimary && !!group.data(row).length
    const expand = rowExpand ? rowExpand(key) : null

    const { hoverStyle, onSelect } = selectRow != null && selectRow(props)
    const selectedRow = row[selectKey]

    return (
      <React.Fragment key={key}>
        <Row
          test={row.id || key}
          onClick={onSelect != null ? () => onSelect(selectedRow) : null}
          hoverStyle={hoverStyle != null ? hoverStyle : {}}
          rowClassName={rowClassName != null ? () => rowClassName(row) : null}
        >
          {isExpandable && (
            <td className={styles.expandCell}>
              {hasData && (
                <a
                  className={styles.expand}
                  {...clickable(group.onExpand(key))}
                >
                  <ArrowDown />
                </a>
              )}
            </td>
          )}
          {columns.map((col, index) => {
            const {
              attr,
              value: getValue,
              render,
              className,
              label,
              stretch = false,
              shrink = false,
              style,
              right = false,
            } = col
            const value =
              typeof getValue === 'function' ? getValue(row) : row[attr]

            const rendered =
              typeof render === 'function'
                ? render({
                    value,
                    row,
                    attr,
                    label,
                    props,
                    expand,
                    key,
                    select,
                    right,
                  })
                : value

            return (
              <Cell
                key={`${attr}${index}`}
                className={className}
                stretch={stretch}
                shrink={shrink}
                size={columnSizes[index]}
                style={style}
                right={right}
              >
                {rendered}
              </Cell>
            )
          })}
        </Row>
        {expand && expand.isExpanded && (
          <tr>
            <td colSpan={columns.length}>{expand.render(row, props)}</td>
          </tr>
        )}
        {group.isExpandable && isPrimary && group.expanded === key && (
          <>
            <HeaderRow
              columns={group.visibleColumns}
              columnSizes={group.columnSizes}
              isExpandable
              props={props}
            />
            <RowList
              rows={group.data(row)}
              rowKey={rowKey}
              isExpandable
              columns={group.visibleColumns}
              columnSizes={group.columnSizes}
              props={props}
            />
          </>
        )}
      </React.Fragment>
    )
  })
}

function LoaderRows({ columns }) {
  return (
    <tbody>
      {Array(10)
        .fill()
        .map((_, rowIndex) => (
          <Row key={rowIndex}>
            {columns.map((column, i) => (
              <Cell key={i}>
                {column.attr.startsWith('_') ? null : <Skeleton />}
              </Cell>
            ))}
          </Row>
        ))}
    </tbody>
  )
}

const Table = props => {
  const {
    table,
    renderProps,
    loading = false,
    hideHeader = false,
    groupRender,
    selectKey,
    rowClassName,
    fetchMore = null,
  } = props

  const {
    data,
    isGrouped,
    summary,
    visibleColumns,
    columnSizes,
    rowKey,
    rowExpand,
    sort,
    group,
    selectRow,
  } = table

  return (
    <Delay skip={!loading}>
      <TableContainer>
        <table className={styles.table} data-testid={`table-${table.name}`}>
          {!hideHeader && (
            <thead>
              <HeaderRow
                columns={visibleColumns}
                sortBy={sort.sortBy}
                onSort={sort.onSort}
                select={table.select}
                props={renderProps}
                isExpandable={group.isExpandable}
                columnSizes={columnSizes}
              />
            </thead>
          )}
          {loading && !table.data.length ? (
            <LoaderRows columns={visibleColumns} />
          ) : (
            <>
              <tbody>
                <RowList
                  rows={data}
                  rowKey={rowKey}
                  rowExpand={rowExpand}
                  columns={visibleColumns}
                  groupRender={groupRender}
                  isGrouped={isGrouped}
                  isPrimary
                  group={group}
                  columnSizes={columnSizes}
                  isExpandable={group.isExpandable}
                  props={renderProps}
                  summary={summary}
                  select={table.select}
                  selectRow={selectRow}
                  selectKey={selectKey}
                  rowClassName={rowClassName}
                />
              </tbody>
              {summary.has && (
                <tfoot>
                  <Summary
                    summary={summary}
                    visibleColumns={visibleColumns}
                    renderProps={renderProps}
                  />
                </tfoot>
              )}
            </>
          )}
        </table>
        {loading
          ? null
          : fetchMore != null && (
              <FetchMoreButton onClick={fetchMore} type="button">
                Load More
              </FetchMoreButton>
            )}
      </TableContainer>
    </Delay>
  )
}

export default Table

const Summary = ({ visibleColumns, renderProps, summary }) => (
  <Row footer>
    {visibleColumns.map(({ attr, row, render, summary: getSummary }, i) => {
      const key = i

      if (getSummary === undefined) {
        return <Cell key={key} footer />
      }

      const value =
        typeof getSummary === 'function'
          ? getSummary(summary.data[attr])
          : getSummary

      const rendered =
        typeof render === 'function'
          ? render({
              value,
              row,
              props: renderProps,
              summary: true,
            })
          : value

      const right =
        rendered != null && rendered.props
          ? rendered.props.value
            ? typeof rendered.props.value === 'number'
            : rendered.value
            ? rendered.value === 'number'
            : false
          : typeof rendered === 'number'
          ? true
          : false

      return (
        <Cell key={key} footer right={right}>
          {rendered}
        </Cell>
      )
    })}
  </Row>
)
