import * as React from 'react'
import { omit, propEq, path, allPass } from 'ramda'
import invariant from 'tiny-invariant'

export default function withFilter(options, Component) {
  if (Component == null) {
    return Component => withFilter(options, Component)
  }

  invariant(
    typeof options.data === 'function',
    'Required `data` option in withFilter is missing. ' +
      'It must be a function which takes a prop and returns a list to be filtered.'
  )

  return class extends React.Component {
    constructor(props) {
      super(props)

      const { initialFilters } = options
      let filters
      if (typeof initialFilters === 'function') {
        filters = initialFilters(props)
      } else if (typeof initialFilters === 'object') {
        filters = initialFilters
      } else {
        filters = {}
      }

      this.state = {
        filters,
      }
    }

    setFilter = (key, value) => {
      if (value !== undefined) {
        // enable filter
        this.setState(state => ({
          filters: {
            ...state.filters,
            [key]: value,
          },
        }))
      } else {
        // disable filter
        this.setState(state => ({
          filters: omit([key], state.filters),
        }))
      }
    }

    onFilterChange = (key, value) => e => this.setFilter(key, value)

    filterData = data => {
      if (data == null) return []

      const filters = Object.keys(this.state.filters)
      if (!filters.length) return data

      const predicates = filters.map(key => {
        const value = this.state.filters[key]

        // allow override defautl equality check with custom condition
        // defined in options.filters[key]
        const condition = path(['filters', key], options) || propEq(key)
        return condition(value)
      })

      return data.filter(allPass(predicates))
    }

    render() {
      const originalData = options.data(this.props) || []
      const data = this.filterData(originalData)

      return (
        <Component
          {...this.props}
          data={data}
          isFiltered={data.length !== originalData.length}
          filters={this.state.filters}
          setFilter={this.setFilter}
          onFilterChange={this.onFilterChange}
        />
      )
    }
  }
}
