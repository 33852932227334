export const disableDelete = (code, value) => {
  if (code.toLowerCase() === 'status') {
    switch (value.toLowerCase()) {
      case 'active':
      case 'on hold':
      case 'onhold':
      case 'cancelled':
      case 'completed':
        return true
      default:
        return false
    }
  }
  return false
}

export const disableStatusField = code => code.toLowerCase() === 'status'
