import * as React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'

import Root from 'bvdash/Root'
import 'bvdash-core/styles.web'

if (process.env.NODE_ENV === 'production') {
  const subdomain = window.location.hostname.split('.')[0]
  let environment
  if (subdomain === 'my') {
    environment = 'production'
  } else if (process.env.CI) {
    environment = 'ci'
  } else {
    environment = subdomain
  }
  Raven.config('https://48f91e38cbda477ea4e6ac1cb08ce348@sentry.io/256700', {
    environment,
    tags: {
      site: window.location.hostname,
    },
    release: process.env.BVDASH_RELEASE,
  }).install()
}

const render = Component => {
  ReactDOM.render(<Component />, document.getElementById('root'))
}

window.onload = function() {
  if (process.env.NODE_ENV !== 'production') {
    if (window.location.hostname === 'localhost') {
      render(() => (
        <div
          style={{
            textAlign: 'center',
            fontSize: '2em',
            maxWidth: '30em',
            margin: '5em auto',
          }}
        >
          <h1>Wrong hostname</h1>

          <p style={{ marginBottom: '2em', marginTop: '3em' }}>
            Please add <strong>127.0.0.1 lan.bvdash.com</strong> record to your{' '}
            <strong>/etc/hosts</strong> and open development server at{' '}
            <a href="http://lan.bvdash.com:3000">lan.bvdash.com:3000</a>.
          </p>

          <p>
            This is required for cookie sharing with <code>.bvdash.com</code>{' '}
            servers.
          </p>
        </div>
      ))
      return
    }
  }

  if (process.env.NODE_ENV === 'production') {
    Raven.context(() => render(Root))
  } else {
    render(Root)
  }
}

// Webpack Hot Module Replacement API
if (module.hot) module.hot.accept('bvdash/Root', () => render(Root))
