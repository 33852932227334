import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { evolve, filter, map } from 'ramda'

import { maybeEvolve } from 'bvdash/utils'
import { choices, enumChoices, enumValue } from 'bvdash/utils/graphql'
import { ActionFragment } from 'bvdash/queries/actions/actionFragment'
import { TimelineEventFragment } from 'bvdash/queries/actions/timelineEventFragment'
import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { LinkedIssueFragment } from 'bvdash/queries/linkedIssueFragment'

function assignedToChoices(list) {
  return choices(list, ({ key, fullName }) => [key, fullName])
}

const normalizeAction = maybeEvolve({
  attachments: filter(attachment => !attachment.deleted),
  urgency: enumValue,
  importance: enumValue,
  issues: map(
    evolve({
      urgency: enumValue,
      importance: enumValue,
    })
  ),
})

export const withAction = graphql(
  gql`
    query action($actionId: Int!) {
      action(actionId: $actionId) {
        ...Action
        attachments {
          ...Attachment
        }
        issues {
          ...LinkedIssue
        }

        events {
          ...TimelineEvent
        }

        updates {
          id
          canEdit
          author {
            id
            fullName
          }
          content
          dateCreated
        }
      }
    }
    ${ActionFragment}
    ${AttachmentFragment}
    ${LinkedIssueFragment}
    ${TimelineEventFragment}
  `,
  {
    options: props => ({
      variables: {
        actionId: props.match.params.actionId,
      },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => ({
      action: {
        isLoading: data.loading,
        hasData: !data.loading && data.action !== null,
        // make `actionId` available before `action` is loaded
        actionId: data.variables.actionId,
        action: normalizeAction(data.action),
      },
    }),
  }
)

export const withActions = graphql(
  gql`
    query actionsList {
      actions {
        ...Action
        hasIssue
        my
      }
    }
    ${ActionFragment}
  `,
  {
    options: {
      fetchPolicy: 'cache-and-network',
    },
    props: ({ data }) => {
      const { actions = [] } = data
      const myActions = actions.filter(action => action.my)
      const teamActions = actions.filter(action => !action.my)

      return {
        actionsList: {
          loading: data.loading,
          teamActions,
          myActions,
          hasData: actions.length,
        },
      }
    },
  }
)

export const withActionFormFields = graphql(
  gql`
    query actionForm {
      actionAssignTo {
        id
        key
        fullName
      }

      urgency: __type(name: "ActionUrgency") {
        enumValues {
          name
          description
        }
      }

      importance: __type(name: "ActionImportance") {
        enumValues {
          name
          description
        }
      }

      status: __type(name: "ActionStatus") {
        enumValues {
          name
          description
        }
      }
    }
  `,
  {
    props: ({ data }) => ({
      actionForm: {
        loading: data.loading,
        urgency: enumChoices(data.urgency),
        importance: enumChoices(data.importance),
        assignedTo: assignedToChoices(data.actionAssignTo || []),
        status: enumChoices(data.status),
      },
    }),
  }
)

export const withActionSetOpen = graphql(
  gql`
    mutation actionSetOpen($id: ID!, $open: Boolean!) {
      actionSetOpen(id: $id, open: $open) {
        action {
          id
          isOpen
          status
        }
        error
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      actionSetOpen: (id, open) => {
        return mutate({
          variables: {
            id,
            open,
          },
        })
      },
    }),
  }
)

export const withActionAddUpdate = graphql(
  gql`
    mutation actionAddUpdate($id: ID!, $content: String!) {
      actionAddUpdate(id: $id, content: $content) {
        ok
        action {
          id
          events {
            ...TimelineEvent
          }
          updates {
            id
            canEdit
            author {
              id
              fullName
            }
            content
            dateCreated
          }
        }
      }
    }
    ${TimelineEventFragment}
  `,
  {
    props: ({ mutate }) => ({
      actionAddUpdate: (id, variables) =>
        mutate({
          variables: {
            ...variables,
            id,
          },
        }),
    }),
  }
)

export const withAttachmentDelete = graphql(
  gql`
    mutation attachmentDelete($id: ID!) {
      attachmentDelete(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      attachmentDelete(id) {
        return mutate({
          variables: { id },
          optimisticResponse: {
            attachmentDelete: {
              ok: true,
              __typename: 'AttachmentDelete',
            },
          },

          update(store, response) {
            const {
              data: { attachmentDelete },
            } = response
            if (!attachmentDelete || !attachmentDelete.ok) {
              return
            }

            // Mark file as deleted in cache.
            // Dunno why but it's necessary to pass typename to id. There seems
            // to be closed unresolved issue in apollo-client.
            store.writeFragment({
              id: `AttachmentType:${id}`,
              fragment: gql`
                fragment DeletedAttachment on AttachmentType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'AttachmentType',
              },
            })
          },
        })
      },
    }),
  }
)
