import * as React from 'react'
import { sum, map, prop } from 'ramda'

import { reverse } from 'named-urls'
import { withTheme } from 'styled-components'

import routes from 'bvdash/routes'

import { PieChart } from 'bvdash/components/charts'
import {
  LegendWrapper,
  LegendBox,
  LegendContainer,
  LegendHeader,
  LegendItem,
  LegendItemText,
} from 'bvdash/components/charts/Legend'

import { Card } from 'bvdash/ui/core'
import { pluralize } from 'bvdash/utils'
import { setColor } from 'bvdash/css'
import { FlexContainer } from 'bvdash/components/standard/containers'

const sumValues = list => sum(map(prop('value'), list))

const ProgramProjects = props => {
  const { projects, theme, titles } = props

  const total = sumValues(projects)

  const projectPieChartData = projects.map(project => {
    const label = project.label.toLowerCase()
    const color = setColor(label, theme)
    return {
      ...project,
      name: label,
      color,
    }
  })

  return (
    <Card.Card
      title={`${titles.program} ${pluralize(titles.project)}`}
      detailsLink={reverse(routes.projects.all)}
    >
      <PieChart data={projectPieChartData} />
      <LegendWrapper>
        <LegendContainer>
          <LegendHeader>
            {total} {pluralize(titles.project)} in Total
          </LegendHeader>

          {projectPieChartData.map(({ label, value, color }) => (
            <LegendItem key={label}>
              <FlexContainer>
                <LegendBox color={color} />
                <LegendItemText>{label}</LegendItemText>
              </FlexContainer>
              {value}
            </LegendItem>
          ))}
        </LegendContainer>
      </LegendWrapper>
    </Card.Card>
  )
}

export default withTheme(ProgramProjects)
