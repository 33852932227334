import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { StripeCustomerFragment } from './stripeCustomerFragment'

const stripeCreateCustomerMutation = gql`
  mutation stripeCreateCustomer($id: ID!) {
    stripeCreateCustomer(id: $id) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripeCustomerCreate = graphql(stripeCreateCustomerMutation, {
  props: ({ mutate }) => ({
    stripeCreateCustomer: async ({ id }) => {
      const res = await mutate({
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerQuery }],
      })

      return res.data.stripeCreateCustomer
    },
  }),
})
