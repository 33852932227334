import { graphql } from 'react-apollo'

import query from './projectDetail.graphql'

export default graphql(query, {
  options: ({ projectKey, match }) => ({
    variables: { project: projectKey || match.params.projectKey },
  }),
  // on ProjectList, we don't have projectKey, but we still render Project Breadcrumbs
  skip: ({ projectKey, match }) => !projectKey && !match.params.projectKey,
  props: ({ data }) => {
    return {
      project: data.loading ? {} : data.project,
      projectIsLoading: data.loading,
      projectKey: data.variables.project,
    }
  },
})
