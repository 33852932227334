import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'

import { UserFragment } from './userFragment'
import { usersQuery } from './withUsers'

const userCreateMutation = gql`
  mutation userCreate($user: UserInput!) {
    userCreate(user: $user) {
      ok
      error
      user {
        ...User
      }
    }
  }
  ${UserFragment}
`

export const withUserCreate = graphql(userCreateMutation, {
  props: ({ mutate }) => ({
    userCreate: async user => {
      const { data } = await mutate({
        variables: { user },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: usersQuery }, { query: customerQuery }],
      })

      return data.userCreate
    },
  }),
})
