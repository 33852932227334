import * as React from 'react'
import { isEmpty } from 'ramda'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import styled, { css } from 'react-emotion'

import { color, textBold, background, shadow, selectable } from 'bvdash/styles'

import { Button } from 'bvdash/ui/core'
import { Conversation, Email, Phone } from 'bvdash-core/icons'

import { authUser } from 'bvdash/auth/queries'
import { startConversation } from 'bvdash/conversations/queries'
import { projectDetail } from 'bvdash/projects/queries'

import colors from 'bvdash/styles/variables.scss'
import styles from './ProjectManager.scss'

export const ProjectManager = props => {
  const { authUser, project, startConversation, titles } = props

  if (!project || isEmpty(project)) return <Content />

  const { manager } = project
  const notSameUser = authUser.user && authUser.user.key !== manager.key

  const projectLabel = suffix => `${titles.project} ${suffix}`
  return (
    <Content>
      <KeyValue label={projectLabel('ID')}>{project.key}</KeyValue>

      <KeyValue label={projectLabel('Name')} stretch>
        {project.name}
      </KeyValue>

      <KeyValue label={projectLabel('Manager')}>{manager.fullName}</KeyValue>

      <KeyValue label="Contact Details">
        <KeyValueWrapper>
          <Button
            className={contactLink}
            onClick={
              notSameUser ? () => startConversation([manager.key]) : undefined
            }
            theme="link"
          >
            <Conversation {...iconProps} /> <span className={styles.online} />{' '}
            Chat
          </Button>

          {manager.email && (
            <ContactButton href={`mailto:${manager.email}`}>
              <Email {...iconProps} />
              {manager.email}
            </ContactButton>
          )}

          {manager.phoneNumber && (
            <ContactButton href={`tel:${manager.phoneNumber}`}>
              <Phone {...iconProps} />
              {manager.phoneNumber}
            </ContactButton>
          )}
        </KeyValueWrapper>
      </KeyValue>
    </Content>
  )
}

function KeyValue({ label, stretch = false, onClick, children }) {
  return (
    <Section stretch={stretch} onClick={onClick}>
      <Label>{label}</Label>
      <p style={{ lineHeight: 1.4 }} className={textBold}>
        {children}
      </p>
    </Section>
  )
}

const iconProps = {
  color: colors.cadetBlue,
  width: 14,
  height: 14,
  className: css`
    margin-right: 5px;
  `,
}

const KeyValueWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
`

const gutterWidth = '10px'

const Content = styled.div`
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  border-radius: 5px;
  ${background.solidWhite};
  ${shadow};

  > * {
    margin-right: ${gutterWidth};
    margin-left: ${gutterWidth};
  }
`

const contactLink = css`
  display: inline;
  margin-right: ${gutterWidth};
`

const ContactButton = styled(Button)`
  ${contactLink};
`

const Section = styled.div`
  ${props => props.stretch && `flex-grow: 1`};
  ${props => props.onClick && selectable};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: wrap;
  overflow: auto;
  margin: 4px;
`

const Label = styled.span`
  ${color.waterloo};
  margin-bottom: 6px;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
`

export default compose(
  authUser,
  withRouter,
  projectDetail,
  startConversation
)(ProjectManager)
