import styled, { css } from 'react-emotion'

import { color, colorMap, opacityMap } from 'bvdash/styles'

export const Header = styled.p`
  ${color.dodgerBlue};
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 1.25;
  margin-bottom: 24px;
  text-transform: capitalize;
`

export const HeaderContainer = styled.div``

export const ProgramProjectName = styled(Header)`
  ${color.sanJuan};
`

export const ProjectTitle = styled.span`
  ${color.dodgerBlue};
`

export const BoldTitle = styled(ProgramProjectName.withComponent('span'))`
  font-weight: bold;
  text-transform: uppercase;
`

export const memberStyle = row => css`
  background: ${row.isManager
    ? `${colorMap.wasabi}${opacityMap.fifteen}`
    : !row.isMember
    ? `${colorMap.waterloo}${opacityMap.ten}`
    : 'transparent'};
`
