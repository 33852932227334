import * as React from 'react'
import PropTypes from 'prop-types'

import styles from './Layout.scss'

const Layout = ({ children, type }) => {
  const rows = React.Children.toArray(children)

  return (
    <div className={`${styles[type]}`}>
      {rows.map((row, index) => (
        <div key={index} className={styles.row}>
          {row}
        </div>
      ))}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['two', 'four']),
}

export default Layout
