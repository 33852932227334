import styled from 'react-emotion'

import { navBarWidth } from 'bvdash/styles'

export const Nav = styled.nav`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 16px;

  @media screen and (max-width: ${navBarWidth}px) {
    display: none;
  }
`

export const FlexContainer = styled.div`
  display: flex;
`
