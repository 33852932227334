import gql from 'graphql-tag'
import { sortBy, prop } from 'ramda'
import { graphql } from 'react-apollo'

const stripePlansQuery = gql`
  query stripePlans {
    stripePlans {
      amount
      name
      numProjects
      numUsers
      order
      planId
      trialPeriodDays
    }
  }
`

export const withStripePlans = graphql(stripePlansQuery, {
  props: ({ data }) => {
    const { stripePlans = [], loading } = data

    return {
      stripePlans: {
        isLoading: loading,
        stripePlans: sortBy(prop('order'))(stripePlans),
      },
    }
  },
})
