// @flow
import * as React from 'react'
import { Dialog } from '@reach/dialog'

type ConfirmDialogProps = {
  children: Function,
  dialog: Function,
}

type ConfirmDialogState = {
  open: boolean,
  callback: ?Function,
}

class ConfirmDialog extends React.Component<
  ConfirmDialogProps,
  ConfirmDialogState
> {
  state = {
    open: false,
    callback: null,
  }

  show = (callback: Function) => (event: Object, ...args: Array<any>) => {
    // callback is called with event-like object on form initialization
    if (event.initial) return callback(event)

    this.setState({
      open: true,
      callback: () => callback(event, ...args),
    })

    // this handler should support also event-like objects, which aren't
    // subclasses of Event
    if (event.preventDefault) event.preventDefault()
  }

  hide = () => this.setState({ open: false, callback: null })

  confirm = () => {
    this.state.callback && this.state.callback()
    this.hide()
  }

  render() {
    return (
      <>
        {this.props.children(this.show)}

        {this.state.open && (
          <Dialog>
            {this.props.dialog({ hide: this.hide, confirm: this.confirm })}
          </Dialog>
        )}
      </>
    )
  }
}

export default ConfirmDialog
