export const ENTERPRISE = 'ENTERPRISE'
export const FREE = 'FREE' // TASKS
export const FREE_PLAN_A = 'FREE_PLAN_A' // TASKS
export const FREE_PLAN_B = 'FREE_PLAN_B' // SCHEDULE
export const PROFESSIONAL = 'PROFESSIONAL'
export const BUSINESS = 'BUSINESS'

export const TRIAL = 'TRIAL'
export const PAYING = 'PAYING'
export const CANCELLED = 'CANCELLED'
export const MARK_FOR_DELETION = 'MARK_FOR_DELETION'

export const isFreePlanA = plan => plan === (FREE_PLAN_A || FREE)
export const isFreePlanB = plan => plan === (FREE_PLAN_B || FREE)

const checkPlan = (plan, compare) =>
  plan != null && plan.toLowerCase() === compare.toLowerCase()

export const isProfessionalPlan = plan => checkPlan(plan, PROFESSIONAL)
export const isBusinessPlan = plan => checkPlan(plan, BUSINESS)
export const isEnterprisePlan = plan => checkPlan(plan, ENTERPRISE)
export const isFreePlan = plan => checkPlan(plan, FREE)

export const isValidPlan = plan =>
  plan.toLowerCase() === FREE.toLowerCase() ||
  plan.toLowerCase() === PROFESSIONAL.toLowerCase() ||
  plan.toLowerCase() === BUSINESS.toLowerCase() ||
  plan.toLowerCase() === ENTERPRISE.toLowerCase()

export const isUnlimited = maxCount => maxCount === 'Unlimited'
