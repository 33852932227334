import * as React from 'react'
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'

import { BlueCircleButton } from 'bvdash/components/buttons'
import routes from 'bvdash/routes'
import { DateComponent } from 'bvdash/components/DateComponent'
import { Button, DeadlineText } from 'bvdash/ui/core'
import { Pencil } from 'bvdash-core/icons'
import { ActionStatus, AttachmentIcon } from 'bvdash/actions/ui'
import { descriptionColumn, descriptionLink } from 'bvdash/ui/tables/styles'

import { CellStatusUpdate } from 'bvdash/components/tables/CellStatusUpdate'

export const actionId = () => ({
  attr: 'actionId',
  label: 'ID',
  render: ({ value: actionId }) => (
    <Button to={reverse(routes.tasks.detail, { actionId })} size="small">
      #{actionId}
    </Button>
  ),
  shrink: true,
})

export const actionAttachments = () => ({
  attr: '_attachments',
  value: item => !!item.attachments.length,
  shrink: true,
  render: ({ value }) => value && <AttachmentIcon />,
})

export const actionContent = () => ({
  attr: 'content',
  label: 'Description',
  className: descriptionColumn,
  render: ({ value, row: { actionId }, props: { projectKey } }) => (
    <Button
      className={descriptionLink}
      to={reverse(routes.tasks.detail, { actionId })}
    >
      {value}
    </Button>
  ),
  stretch: true,
})

export const actionPriority = () => ({
  attr: 'priority',
  label: 'Priority',
})

export const actionUrgency = () => ({
  attr: 'urgency',
  label: 'Urgency',
  value: ({ urgency }) => urgency.slice(2),
  render: ({ value, props }) => props.urgency.values[value],
})

export const actionImportance = () => ({
  attr: 'importance',
  label: 'Importance',
  value: ({ importance }) => importance.slice(2),
  render: ({ value, props }) => props.importance.values[value],
})

export const actionAssignedBy = () => ({
  attr: 'author',
  label: 'Assigned By',
  value: action => action.author && action.author.fullName,
})

export const actionAssignedTo = () => ({
  attr: 'assignedTo',
  label: 'Assigned To',
  value: action => action.assignedTo && action.assignedTo.key,
  render: ({ value, props }) => props.assignedTo.values[value],
})

export const actionDeadline = () => ({
  attr: 'deadline',
  label: 'Deadline',
  render: ({ value, row: { isOpen } }) => {
    const date = new Date(value)
    return (
      <DeadlineText over={isOpen && date < new Date()}>
        {<DateComponent date={date} />}
      </DeadlineText>
    )
  },
})

export const actionStatus = () => ({
  attr: 'status',
  label: 'Status',
  render: ({ value, props, row }) => {
    return <ActionStatus value={row.status ? row.status : row.isOpen} />
  },
  shrink: true,
})

export const issueStatusWithUpdate = p => {
  if (p == null) return {}

  return {
    attr: 'status',
    label: 'Status',
    render: ({ value, props, row }) => {
      const issue = {
        assigneeId: row.assignedTo.id,
      }
      const changeStatus = status => {
        return p.handleClick(
          row.id,
          { ...issue, status: status.label },
          { issueId: row.issueId, project: props.projectKey }
        )
      }
      return (
        <CellStatusUpdate
          handleClick={changeStatus}
          value={row.status != null ? row.status : row.isOpen}
          status={p.status}
        />
      )
    },
    shrink: true,
  }
}
export const actionStatusWithUpdate = p => {
  if (p == null) return {}

  return {
    attr: 'status',
    label: 'Status',
    render: ({ value, props, row }) => {
      const { importance, urgency } = row
      const changeStatus = status => {
        const action = {
          assignedTo: row.assignedTo.key,
          attachments: row.attachments,
          content: row.content,
          deadline: row.deadline,
          importance: importance[importance.length - 1],
          issues: row.issues,
          project: row.project,
          urgency: urgency[urgency.length - 1],
          status: status.label,
        }
        return p.handleClick(row.id, { ...action })
      }
      return (
        <CellStatusUpdate
          handleClick={changeStatus}
          value={row.status != null ? row.status : row.isOpen}
          status={p.status}
        />
      )
    },
    shrink: true,
  }
}

export const actionEdit = () => ({
  attr: '_edit',
  render: ({ row: { actionId } }) => (
    <Link
      to={reverse(routes.tasks.edit, {
        actionId,
      })}
    >
      <BlueCircleButton Icon={Pencil} />
    </Link>
  ),
  shrink: true,
})
