import * as React from 'react'
import styled from 'react-emotion'

import ConfirmDialog from 'bvdash/ui/core/ConfirmDialog'
import Headline from 'bvdash/ui/core/Headline/Headline'

import { Button } from 'bvdash/components/buttons'

const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  > * {
    margin: 5px;
  }
`

const Question = styled.p`
  margin: 40px 0;
`

function getContent(props) {
  const { type, isOpen, titles } = props
  if (type === 'action') {
    return isOpen
      ? {
          title: `Close ${titles.task} Confirmation`,
          question: `You are about to close an open ${titles.task}`,
        }
      : {
          title: `Reopen ${titles.task} Confirmation`,
          question: `You are about to reopen a closed ${titles.task}`,
        }
  } else if (type === 'document') {
    return isOpen
      ? {
          title: `Close ${titles.document} Confirmation`,
          question: `You are about to close an open ${titles.document}`,
        }
      : {
          title: `Reopen ${titles.document} Confirmation`,
          question: `You are about to reopen a closed ${titles.document}`,
        }
  } else if (type === 'ncr') {
    return isOpen
      ? {
          title: `Close Quality Confirmation`,
          question: `You are about to close an open Quality`,
        }
      : {
          title: `Reopen Quality Confirmation`,
          question: `You are about to reopen a closed Quality`,
        }
    // ? {
    //     title: `Close ${titles.quality} Confirmation`,
    //     question: `You are about to close an open ${titles.quality}`,
    //   }
    // : {
    //     title: `Reopen ${titles.quality} Confirmation`,
    //     question: `You are about to reopen a closed ${titles.quality}`,
    //   }
  } else if (type === 'risk') {
    return isOpen
      ? {
          title: `Close Risk Confirmation`,
          question: `You are about to close an open Risk`,
        }
      : {
          title: `Reopen Risk Confirmation`,
          question: `You are about to reopen a closed Risk`,
        }
    // return isOpen
    //   ? {
    //       title: `Close ${titles.risk} Confirmation`,
    //       question: `You are about to close an open ${titles.risk}`,
    //     }
    //   : {
    //       title: `Reopen ${titles.risk} Confirmation`,
    //       question: `You are about to reopen a closed ${titles.risk}`,
    //     }
  } else if (type === 'issue') {
    return isOpen
      ? {
          title: `Close ${titles.issue} Confirmation`,
          question: `You are about to close an open ${titles.issue}`,
        }
      : {
          title: `Reopen ${titles.issue} Confirmation`,
          question: `You are about to reopen a closed ${titles.issue}`,
        }
  } else {
    throw new Error(`Unknown type: ${type}`)
  }
}

const ConfirmStatusChange = props => {
  const { isOpen } = props
  const { question, title } = getContent(props)

  const buttonText = isOpen ? 'Close' : 'ReOpen'
  const buttonColor = isOpen ? 'caution' : 'add'
  return (
    <ConfirmDialog
      dialog={({ hide, confirm }) => (
        <>
          <Headline size={3}>{title}</Headline>

          <Question>
            {question}
            <br />
            Are you sure you want to proceed?
          </Question>

          <Buttons>
            <Button onClick={hide}>Cancel</Button>
            <Button color={buttonColor} onClick={confirm}>
              {buttonText}
            </Button>
          </Buttons>
        </>
      )}
    >
      {confirm => props.children(confirm)}
    </ConfirmDialog>
  )
}

export default ConfirmStatusChange
