import * as React from 'react'
import { Dialog } from '@reach/dialog'
import { Formik, Form, Field } from 'formik'
import styled from 'react-emotion'
import { sortBy, prop } from 'ramda'

import { space } from 'bvdash/styles'
import { Headline, Button } from 'bvdash/ui/core'
import { withProgramCode } from 'bvdash/admin/queries'
import { projectSetStatus } from 'bvdash/projects/queries'

class ChangeProjectStatusDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      initialValues: this.props.status || {},
    }
  }

  show = initialValues => () => {
    this.setState({
      open: true,
      initialValues,
    })
  }

  hide = () => this.setState({ open: false, initialValues: {} })

  confirm = () => {
    this.state.callback && this.state.callback()
    this.hide()
  }

  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true)
    const response = await this.props.projectSetStatus(
      values.key,
      values.status
    )

    actions.setSubmitting(false)
    this.hide()

    return response
  }

  render() {
    return (
      <>
        {this.props.children(this.show)}

        {this.state.open && (
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.handleSubmit}
          >
            {() => (
              <Dialog>
                <Form>
                  <Headline size={3}>Change Project Status</Headline>
                  <Field
                    name="status"
                    component={CodeRadioInput}
                    codeKey="Status"
                  />

                  <p>
                    You are about to change project status. Are you sure you
                    want to proceed?
                  </p>

                  <Buttons>
                    <Button onClick={this.hide}>Cancel</Button>
                    <Button color="add" type="submit">
                      Change
                    </Button>
                  </Buttons>
                </Form>
              </Dialog>
            )}
          </Formik>
        )}
      </>
    )
  }
}

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: ${space.larger} -${space.smaller} 0;

  > * {
    margin: 0 ${space.smaller};
  }
`

const CodeRadioInput = withProgramCode(
  ({ programCode: { isLoading, values }, field }) => {
    if (isLoading) return null

    return (
      <CodeInputs>
        {sortCodes(values).map(value => (
          <label>
            <input
              {...field}
              type="radio"
              value={value.value}
              checked={field.value === value.value}
            />{' '}
            {value.description}
          </label>
        ))}
      </CodeInputs>
    )
  }
)

const CodeInputs = styled.div`
  display: flex;
  margin: ${space.larger} -${space.normal};

  > * {
    margin: 0 ${space.normal};
  }
`

const sortCodes = sortBy(prop('id'))

export default projectSetStatus(ChangeProjectStatusDialog)
