import * as React from 'react'
import styled from 'react-emotion'

import { color, colorMap, textBold } from 'bvdash/styles'
import { DateComponent } from 'bvdash/components/DateComponent'

import { Box } from 'bvdash/ui/core'
import { FormSelect } from 'bvdash/ui/forms'
import { Transition } from 'react-spring'

const ActionDetail = styled.div`
  border-top: 1px solid ${colorMap.dropShadow};
  padding: 15px 60px;

  font-size: 14px;
`

const HeaderTitle = styled.header`
  ${color.sanJuan};
`

const Content = styled.div`
  ${textBold};
  ${color.cadetBlue};

  padding: 30px 0;
`

const UpdatesHeader = styled.div`
  ${color.sanJuan};
  font-weight: bold;
`

const ActionUpdate = styled.div`
  ${color.cadetBlue};
  margin-top: 10px;
`

const ActionUpdateFields = styled.div`
  ${color.waterloo};
  margin-bottom: 7px;

  span + span {
    margin-left: 20px;
  }
`

export default function IssueActionDetail({ action, urgency, importance }) {
  return (
    <Transition
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {styles => (
        <ActionDetail style={styles}>
          <Box horizontal align="space-between" alignCross="center">
            <HeaderTitle>Action Details</HeaderTitle>

            <Box horizontal shrink={0}>
              <FormSelect
                label="Urgency"
                value={urgency.values[action.urgency]}
              />

              <FormSelect
                label="Importance"
                value={importance.values[action.importance]}
              />
            </Box>
          </Box>

          <Content>{action.content}</Content>

          {!!action.updates.length && (
            <>
              <UpdatesHeader>Action Updates</UpdatesHeader>

              {action.updates.map(update => (
                <ActionUpdate key={update.id}>
                  <ActionUpdateFields>
                    <span>Posted by: {update.author.fullName}</span>
                    <span>
                      Posted on: <DateComponent date={update.dateCreated} />
                    </span>
                  </ActionUpdateFields>
                  {action.content}
                </ActionUpdate>
              ))}
            </>
          )}
        </ActionDetail>
      )}
    </Transition>
  )
}
