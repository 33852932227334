import React from 'react'
import { compose } from 'react-apollo'
import { reverse } from 'named-urls'
import { css } from 'react-emotion'

import routes from 'bvdash/routes'
import { color, selectable, space, menuHoverLeft } from 'bvdash/styles'
import { Dropdown, UserPhoto, Button } from 'bvdash/ui/core'
import { ArrowDown, Logout, Person, Settings } from 'bvdash-core/icons'
import * as a11y from 'bvdash/utils/a11y'
import { authUser, logout } from 'bvdash/auth/queries'
import { has_admin_permissions } from 'bvdash/auth/permissions'

import colors from 'bvdash/styles/variables.scss'
import styles from './UserProfileDropdown.scss'
import { useToggle } from 'bvdash/hooks/useToggle'

export const UserProfileDropdown = compose(
  authUser,
  logout
)(props => {
  const {
    authUser: { user },
    logout,
    onboarding,
    trialExpired,
  } = props

  const outsideClickRef = React.useRef()
  const toggle = useToggle()

  if (!user) return null

  const name = user.fullName.split(' ')[0]
  const role = user.isAdmin ? 'Administrator' : (user.roles[0] || {}).role
  const hasAdmin = has_admin_permissions(user)

  const isSuperUser =
    user.isBvdsuperuser || user.isBvdsuperadmin ? (
      <li className={styles.choice}>
        <Button
          {...a11y.clickable(toggle.close)}
          to={reverse(routes.accounts.list)}
          className={buttonClass}
          theme="link"
        >
          <Settings /> SuperUser
        </Button>
      </li>
    ) : null

  let profilePhoto = ''
  if (user.userProfilePicture && user.userProfilePicture.length > 0) {
    profilePhoto = user.userProfilePicture[0].url
  }

  return (
    <div ref={outsideClickRef} className={styles.root}>
      <div
        {...a11y.clickable(toggle.toggle)}
        className={styles.trigger}
        test="UserProfileDropdown"
      >
        <UserPhoto
          photo={profilePhoto}
          name={user.fullName}
          className={styles.image}
        />
        <div className={styles.user}>
          <span className={styles.userName}>
            {name ? `Hello, ${name}!` : user.email}
          </span>
          <span className={styles.userRole}>{role}</span>
        </div>
        <ArrowDown color={colors.cadetBlue} />
      </div>

      <Dropdown
        position="right"
        isOpen={toggle.isOpen}
        onClose={toggle.close}
        outsideClickRef={outsideClickRef}
      >
        <ul>
          {!trialExpired && onboarding ? (
            <li className={styles.choice}>
              <Button
                {...a11y.clickable(toggle.close)}
                to={routes.profile}
                className={buttonClass}
                theme="link"
              >
                <Person /> Profile
              </Button>
            </li>
          ) : null}
          {hasAdmin && (
            <li className={styles.choice}>
              <Button
                {...a11y.clickable(toggle.close)}
                to={reverse(routes.admin.toString())}
                className={buttonClass}
                theme="link"
              >
                <Settings /> Admin
              </Button>
            </li>
          )}
          {isSuperUser}
          <li className={styles.choice}>
            <Button
              {...a11y.clickable(logout)}
              className={buttonClass}
              test="UserProfileDropdown__Logout"
              theme="link"
            >
              <Logout /> Logout
            </Button>
          </li>
        </ul>
      </Dropdown>
    </div>
  )
})

const buttonClass = css`
  ${selectable};
  ${color.waterloo};

  height: 50px;
  width: 230px;

  line-height: ${50 - 2 * 16}px;

  padding: ${space.normal} ${space.normal};

  &:hover {
    ${menuHoverLeft()};
  }
`
