import gql from 'graphql-tag'

export const AlertFragment = gql`
  fragment AlertFragment on AlertType {
    id
    code
    date
    read
    deleted
    context {
      update {
        author {
          id
          key
          fullName
        }
        description
        id
        project {
          id
          key
        }
      }

      issue {
        author {
          id
          key
          fullName
        }
        content
        id
        issueId
        project {
          id
          key
        }
      }

      action {
        actionId
        author {
          id
          key
          fullName
        }
        content
        id
      }
    }
  }
`
