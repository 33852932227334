import * as React from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'

import { Toggle, Dropdown, Button, Headline } from 'bvdash/ui/core'

import * as a11y from 'bvdash/utils/a11y'
import { callAll } from 'bvdash/utils/handlers'

import ActionLink from './ActionLink'
import styles from './NotificationsDropdown.scss'

export default class NotificationsDropdown extends React.Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    initialOpen: PropTypes.bool,
  }

  static defaultProps = {
    onNotificationClick: () => {},
  }

  render() {
    const {
      children,
      emptyLabel,
      footerLabel,
      footerLink,
      icon,
      initialOpen,
      label,
      labelClassName,
      notifications,
      onNotificationClick,
      titleActions,
      titleLabel,
    } = this.props
    const hasNotifications = notifications.length > 0

    return (
      <Toggle initialOpen={initialOpen}>
        {toggle => (
          <div className={cx(styles.root, this.props.className)}>
            <div
              {...a11y.clickable(toggle.toggle)}
              className={cx(labelClassName, styles.trigger)}
            >
              <div className={styles.icon}>
                {icon}
                {hasNotifications && (
                  <span className={styles.indicator}>
                    {notifications.length}
                  </span>
                )}
              </div>
              {label}
            </div>

            <Dropdown
              className={styles.dropdown}
              position="center"
              isOpen={toggle.isOpen}
              onClose={toggle.close}
            >
              <header className={styles.header}>
                <Headline level={4}>{titleLabel}</Headline>
                <div>
                  {titleActions.map((action, index) => (
                    <ActionLink
                      key={index}
                      action={action}
                      onClick={toggle.close}
                    />
                  ))}
                </div>
              </header>

              {hasNotifications ? (
                notifications.slice(0, 3).map(notification => (
                  <div
                    key={notification.id}
                    className={styles.notification}
                    {...a11y.clickable(
                      callAll(toggle.close, () =>
                        onNotificationClick(notification)
                      )
                    )}
                  >
                    {children({ notification, onClick: toggle.close })}
                  </div>
                ))
              ) : (
                <Headline className={styles.empty} align="center" level={3}>
                  {emptyLabel}
                </Headline>
              )}

              <Button
                onClick={toggle.close}
                to={footerLink}
                fullWidth={true}
                className={styles.footerBtn}
              >
                {footerLabel}
              </Button>
            </Dropdown>
          </div>
        )}
      </Toggle>
    )
  }
}
