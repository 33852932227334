import styled, { css } from 'react-emotion'

import { background, shadow, colorMap, selectable } from 'bvdash/styles'
import { bottomRadius, topRadius } from 'bvdash/components/styles'

export const hoverStyle = css`
  background: ${colorMap.alabasterLight};
  border-left: 5px solid ${colorMap.dodgerBlue};
  cursor: pointer;
`

export const SelectedProject = styled.button`
  ${shadow};
  ${bottomRadius};
  ${selectable};
  ${topRadius};
  ${background.solidWhite};
  border: none;
  color: ${colorMap.sanJuan};
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: space-evenly;
  padding: 12px 24px;
  width: 100%;
  overflow: auto;
  flex: 9;
  flex-basis: 1436px;
  &:disabled {
    ${background.grey};
  }
`

export const TableContainer = styled.div`
  ${shadow};
  ${bottomRadius};
  ${background.solidWhite};
  max-height: 195px;
  overflow-y: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 11px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    ${background.darkBorder};
    border-radius: 8px;
    border: 2px solid ${colorMap.solidWhite};
  }
`

export const Title = styled.span`
  font-weight: 500;
`

export const TextContainer = styled.div`
  @media (max-width: 768px) {
    &:first-child {
      margin-bottom: 16px;
    }
  }
`
