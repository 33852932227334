import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { projectsPreviewQuery } from 'bvdash/admin/queries/projects'
import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { authUserQuery } from 'bvdash/queries/withAuthUser'

const customerOnboardingMutation = gql`
  mutation customerCompleteOnboarding($id: ID!) {
    customerCompleteOnboarding(id: $id) {
      ok
      error
      customer {
        id
        onboarding
      }
    }
  }
`

export const withOnboarding = graphql(customerOnboardingMutation, {
  props: ({ mutate }) => ({
    customerCompleteOnboarding: async ({ customerId, programKey }) => {
      const { data } = await mutate({
        variables: { id: customerId },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: projectsPreviewQuery, variables: { programKey } },
          { query: customerQuery },
          { query: authUserQuery },
        ],
      })
      return data.customerCompleteOnboarding.ok
    },
  }),
})

const customerWelcomeMutation = gql`
  mutation customerCompleteWelcome($id: ID!) {
    customerCompleteWelcome(id: $id) {
      ok
      error
      customer {
        id
        onboarding
      }
    }
  }
`

export const withWelcome = graphql(customerWelcomeMutation, {
  props: ({ mutate }) => ({
    customerCompleteWelcome: async ({ customerId }) => {
      const { data } = await mutate({
        variables: { id: customerId },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerQuery }],
      })
      return data.customerCompleteWelcome.ok
    },
  }),
})
