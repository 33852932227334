import styled from 'styled-components'

import {
  alignItems,
  justifyContent,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  paddingBottom,
  paddingTop,
  paddingLeft,
  paddingRight,
  textAlign,
} from 'bvdash/css'

export const Container = styled.div`
  ${marginBottom};
  ${marginTop};
  ${marginLeft};
  ${marginRight};
  ${paddingBottom};
  ${paddingTop};
  ${paddingLeft};
  ${paddingRight};
  ${textAlign};
  ${({ flex }) => (flex > 0 ? `flex: ${flex}` : undefined)};
  ${({ height }) => (height ? `height: ${height}` : undefined)};
  ${({ width }) => (width ? `width: ${width}` : undefined)};
  ${({ color, theme }) => (color ? `color: ${theme.color[color]}` : undefined)};
`

const basePadding = 16
export const Card = styled(Container)`
  ${textAlign};
  ${({ theme }) => theme.shadow};
  background: ${({ background, theme }) =>
    background != null ? theme.color[background] : theme.color.white};
  border-radius: 5px;
  padding: ${({ small, medium, large, extraLarge, padding }) =>
    padding != null
      ? padding
      : small
      ? basePadding / 4
      : medium
      ? basePadding / 2
      : large
      ? basePadding
      : extraLarge
      ? basePadding * 2
      : 12}px;
  ${({ overflow }) => (overflow != null ? `overflow: ${overflow}` : undefined)};
`

export const FlexCard = styled(Card)`
  ${alignItems};
  ${justifyContent};
  ${({ flex }) => (flex > 0 ? `flex: ${flex}` : undefined)};
  ${({ flexDirection }) =>
    flexDirection != null ? `flex-direction: ${flexDirection}` : undefined};
  ${({ flexWrap }) => (flexWrap ? 'flex-wrap: wrap' : undefined)};
  display: flex;
`

export const FlexContainer = styled(Container)`
  ${alignItems};
  ${justifyContent};
  ${({ flex }) => (flex > 0 ? `flex: ${flex}` : undefined)};
  ${({ flexDirection }) =>
    flexDirection != null ? `flex-direction: ${flexDirection}` : undefined};
  ${({ flexWrap }) => (flexWrap ? 'flex-wrap: wrap' : undefined)};
  ${({ overflow }) => (overflow ? `overflow: ${overflow}` : undefined)};
  display: flex;
  max-width: 100%;
`
export const CenterCenterContainer = styled(FlexContainer).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))``
export const TopCenterContainer = styled(FlexContainer).attrs(() => ({
  alignItems: 'flex-start',
  justifyContent: 'center',
}))``
export const BottomCenterContainer = styled(FlexContainer).attrs(() => ({
  alignItems: 'flex-end',
  justifyContent: 'center',
}))``
export const CenterSpaceBetweenContainer = styled(FlexContainer).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
}))``
export const FullWidthContainer = styled(Container).attrs(() => ({
  width: '100%',
}))``
export const RelativeContainer = styled(Container)`
  position: relative;
`

export const FullScreenContainer = styled(FlexContainer)`
  height: 100vh;
  width: 100vw;
`
