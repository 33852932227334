export const setMenuTitle = key => settingsMenu => {
  if (settingsMenu != null) {
    return settingsMenu[key].label
  }
  return key
}

export const alertTitle = setMenuTitle('alert')
export const budgetTitle = setMenuTitle('budget')
export const conversationTitle = setMenuTitle('conversation')
export const documentTitle = setMenuTitle('document')
export const issueTitle = setMenuTitle('issue')
export const programTitle = setMenuTitle('program')
export const projectTitle = setMenuTitle('project')
export const portfolioTitle = setMenuTitle('portfolio')
export const qualityTitle = setMenuTitle('quality')
export const riskTitle = setMenuTitle('risk')
export const scheduleTitle = setMenuTitle('schedule')
export const scopeTitle = setMenuTitle('scope')
export const surveyTitle = setMenuTitle('survey')
export const taskTitle = setMenuTitle('task')
export const teamTitle = setMenuTitle('team')
export const timesheetTitle = setMenuTitle('timesheet')
export const updateTitle = setMenuTitle('update')
