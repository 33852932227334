import styled from 'react-emotion'

import { background } from 'bvdash/styles'
import { bottomRadius } from 'bvdash/components/styles'

export const Container = styled.div`
  ${background.solidWhite};
  ${bottomRadius};
  overflow: auto;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`
