import * as React from 'react'
import { range } from 'ramda'

import styles from './ScheduleHeader.scss'

export default function ScheduleHeader({ row, props }) {
  const [dateMin, dateMax] = props.dateRange
  const startYear = dateMin.getFullYear()
  const endYear = dateMax.getFullYear()

  const years = range(startYear, endYear + 1)

  return (
    <div className={styles.root}>
      {years.map(year => (
        <div className={styles.years} key={year}>
          <div className={styles.yearLabel}>
            <span className={styles.label}>{year}</span>
          </div>
          <div className={styles.quarters}>
            {range(1, 5).map(index => (
              <div className={styles.quarterLabel} key={index}>
                <span>Q{index}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
