import styled from 'styled-components'

export const LegendWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-basis: 300px;
  justify-content: center;
  padding: 32px;
`

export const LegendBox = styled.div`
  background: ${({ color }) => color};
  display: inline-block;
  height: 16px;
  margin-right: 10px;
  width: 16px;
`

export const LegendContainer = styled.div`
  width: 100%;
`

export const LegendHeader = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  line-height: 1.25em;
`

export const LegendItem = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`

export const LegendItemText = styled.div`
  display: flex;
  font-weight: 500;
  margin-right: 16px;
  text-transform: capitalize;

  &:first-child {
    flex: 1;
  }
`
