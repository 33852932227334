import * as React from 'react'
import { compose } from 'react-apollo'

import { VersionInfo } from './VersionInfo'

import { Button } from 'bvdash/components/buttons'
import { ConfirmDialogDefault } from 'bvdash/ui/core'

import { versionPublish } from 'bvdash/admin/queries'

import { capitalize } from 'bvdash/utils'

const PublishButton = props => {
  const { btnYes, handleClick, publishButtonText, question, title } = props

  return (
    <ConfirmDialogDefault
      title={title}
      question={question}
      btnYes={btnYes}
      theme="danger"
    >
      {confirm => (
        <Button color="add" onClick={confirm(handleClick)}>
          {publishButtonText}
        </Button>
      )}
    </ConfirmDialogDefault>
  )
}

const Version = props => {
  const { publishButtonText = '', type, version, versionPublish } = props

  if (!version) return null

  const handleVersionPublish = () =>
    versionPublish({
      id: version.id,
      published: !version.published,
    })

  const renderPublishButton = version.published ? (
    <PublishButton
      btnYes="Unpublish"
      question={
        <>
          You are about to unpublish a {type} data update.
          <br />
          Are you sure you want to proceed?
        </>
      }
      handleClick={handleVersionPublish}
      publishButtonText={`Unpublish ${publishButtonText}`}
      title={`Unpublish ${capitalize(type)} Update Confirmation`}
    />
  ) : (
    <PublishButton
      btnYes="Publish"
      question={
        <>
          You are about to publish a {type} data update.
          <br />
          Are you sure you want to proceed?
        </>
      }
      handleClick={handleVersionPublish}
      publishButtonText={`Publish ${publishButtonText}`}
      title={`Publish ${capitalize(type)} Update Confirmation`}
    />
  )

  return <VersionInfo version={version} publishButton={renderPublishButton} />
}

export default compose(versionPublish)(Version)
