import * as React from 'react'
import { FaFileUpload } from 'react-icons/fa'
import styled from 'styled-components'

import { Button } from './Button'
import { IconButton } from './IconButton'

const ButtonWrapper = styled(Button)`
  margin-bottom: 16px;
  margin-left: 16px;
  margin-top: 16px;
  min-width: auto;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ImportButton = props => {
  return (
    <ButtonWrapper {...props}>
      <Container>
        <IconButton
          background="accent"
          color="accent"
          Icon={FaFileUpload}
          marginRight={16}
          noShadow
        />
        Import
      </Container>
    </ButtonWrapper>
  )
}
