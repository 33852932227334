import { graphql } from 'react-apollo'

import query from './getConversation.graphql'
import moreQuery from './moreMessagesQuery.graphql'
import subscription from './newMessageSubscription.graphql'

export default graphql(query, {
  options: props => {
    const { conversationId } = props.match.params
    return {
      variables: { conversationId },
      fetchPolicy: 'cache-and-network',
    }
  },
  props: ({ data }) => {
    const { loading } = data
    const { messages = [], ...conversation } = data.conversation || {}

    const firstId = (messages[0] || {}).id

    return {
      loading,
      conversation,
      messages,

      projectKey: data.variables.project,

      loadMore: () =>
        data.fetchMore({
          query: moreQuery,
          variables: {
            conversationId: conversation.id,
            before: firstId,
          },
          updateQuery: ({ conversation = {} }, { fetchMoreResult = {} }) => ({
            conversation: {
              ...conversation,
              messages: [...fetchMoreResult.messages, ...conversation.messages],
            },
          }),
        }),

      subscribe: params =>
        data.subscribeToMore({
          document: subscription,
          variables: {
            conversationId: conversation.id,
          },
          updateQuery: ({ conversation = {} }, { subscriptionData = {} }) => ({
            conversation: {
              ...conversation,
              latestMessage: conversation.messages,
              messages: [
                ...conversation.messages,
                subscriptionData.data.messages,
              ],
            },
          }),
        }),
    }
  },
})
