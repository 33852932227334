import * as React from 'react'
import styled from 'styled-components'

import { File, Doc, Pdf, Txt, Xls } from 'bvdash-core/icons'

import { StandardText } from 'bvdash/components/standard/texts'

const Wrapper = styled.div`
  outline: none;
`

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
`

const typeArray = [
  { type: '.csv', Icon: Xls },
  { type: '.xls', Icon: Xls },
  { type: '.doc', Icon: Doc },
  { type: '.pdf', Icon: Pdf },
  { type: '.txt', Icon: Txt },
  { type: '.json', Icon: Txt },
]

const AsyncImage = props => {
  const { alt, className, onClick, src } = props

  const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif']
  const isImage = allowedExtensions.find(a => src.endsWith(a))

  const IconType = typeArray.find(t => src.includes(t.type))

  return (
    <Wrapper
      role="button"
      tabIndex={0}
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {isImage !== '' ? (
        <Image src={src} alt={alt} />
      ) : IconType != null ? (
        <IconWrapper>
          <IconType.Icon width="100%" height="100%" />
          <StandardText marginTop={8} textAlign="center">
            {alt}
          </StandardText>
        </IconWrapper>
      ) : (
        <IconWrapper>
          <File fill="none" width="100%" height="100%" />
          <StandardText marginTop={8} textAlign="center">
            {alt}
          </StandardText>
        </IconWrapper>
      )}
    </Wrapper>
  )
}

export default AsyncImage
