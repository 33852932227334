import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { StripeCustomerFragment } from './stripeCustomerFragment'

const stripeUpdateSubscriptionQuantityMutation = gql`
  mutation stripeUpdateSubscriptionQuantity(
    $stripeCustomerId: String!
    $stripeSubscriptionId: String!
    $stripeUserQuantity: Int!
  ) {
    stripeUpdateSubscriptionQuantity(
      stripeCustomerId: $stripeCustomerId
      stripeSubscriptionId: $stripeSubscriptionId
      stripeUserQuantity: $stripeUserQuantity
    ) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripeSubscriptionUpdateQuantity = graphql(
  stripeUpdateSubscriptionQuantityMutation,
  {
    props: ({ mutate }) => ({
      stripeUpdateSubscriptionQuantity: async ({
        stripeCustomerId,
        stripeSubscriptionId,
        stripeUserQuantity,
      }) => {
        const res = await mutate({
          variables: {
            stripeCustomerId,
            stripeSubscriptionId,
            stripeUserQuantity,
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: customerQuery }],
        })

        return res.data.stripeUpdateSubscriptionQuantity
      },
    }),
  }
)

const stripeUpdateSubscriptionTypeMutation = gql`
  mutation stripeUpdateSubscriptionType(
    $stripeCustomerId: String!
    $stripeSubscriptionId: String!
    $stripePlanId: String!
    $stripeUserQuantity: Int!
  ) {
    stripeUpdateSubscriptionType(
      stripeCustomerId: $stripeCustomerId
      stripeSubscriptionId: $stripeSubscriptionId
      stripePlanId: $stripePlanId
      stripeUserQuantity: $stripeUserQuantity
    ) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripeSubscriptionUpdateType = graphql(
  stripeUpdateSubscriptionTypeMutation,
  {
    props: ({ mutate }) => ({
      stripeUpdateSubscriptionType: async ({
        stripeCustomerId,
        stripeSubscriptionId,
        stripePlanId,
        stripeUserQuantity,
      }) => {
        const res = await mutate({
          variables: {
            stripeCustomerId,
            stripeSubscriptionId,
            stripePlanId,
            stripeUserQuantity,
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: customerQuery }],
        })

        return res.data.stripeUpdateSubscriptionType
      },
    }),
  }
)
