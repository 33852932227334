import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { BreadcrumbsHeader } from 'bvdash/ui/core'
import * as queries from 'bvdash/projects/queries'

function reverseBreadcrumbRoutes(context, ...breadcrumbs) {
  return breadcrumbs.map(({ to, route, ...props }) => ({
    ...props,
    to: to || reverse(route, context),
  }))
}

const Breadcrumbs = props => {
  const {
    children,
    hideExport,
    hidePrint,
    project,
    projectKey,
    program: { program, programKey },
    projectBreadcrumbs = [],
    programBreadcrumbs = [],
    breadcrumbs: addBreadcrumbs = [],
  } = props

  const breadcrumbs = !programKey
    ? []
    : [
        // NOTE: I'm suspision that this block of code isn't used at all,
        // because program breadcrumbs use ProgramBreadcrumbs component
        ...reverseBreadcrumbRoutes(
          { programKey },
          {
            label: program.name,
            route: routes.program.dashboard,
          },
          ...programBreadcrumbs
        ),
        ...(projectKey
          ? reverseBreadcrumbRoutes(
              { projectKey },

              {
                label: project.name,
                route: routes.project.documents,
                highlight: true,
              },
              ...projectBreadcrumbs
            )
          : []),
        ...addBreadcrumbs,
      ]

  return (
    <BreadcrumbsHeader
      breadcrumbs={breadcrumbs}
      hideExport={hideExport}
      hidePrint={hidePrint}
    >
      {children}
    </BreadcrumbsHeader>
  )
}

Breadcrumbs.propTypes = {
  children: PropTypes.any,
  breadcrumbs: PropTypes.array,
}

export default compose(
  withRouter,
  queries.projectDetail,
  queries.programDetail
)(Breadcrumbs)
