import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { UserFragment } from './userFragment'

export const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...User
    }
  }
  ${UserFragment}
`

export const withUser = graphql(userQuery, {
  options: props => ({
    variables: {
      id: props.match.params.userId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => {
    const { loading, user = {} } = data

    return {
      user: {
        isLoading: loading,
        user,
      },
    }
  },
})
