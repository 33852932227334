import * as React from 'react'
import PropTypes from 'prop-types'

import { makeFilterChoices, FilterSelect } from 'bvdash/ui/tables'

const codeChoices = codes =>
  makeFilterChoices(codes, code => ({
    label: code.description,
    value: code.description,
  }))

export default function CodeFilter({ label, attr, filter, codes, isLoading }) {
  if (isLoading) return null

  return (
    <FilterSelect
      multiple
      label={label}
      attr={attr}
      filter={filter}
      choices={codeChoices(codes[attr])}
    />
  )
}

CodeFilter.propTypes = {
  label: PropTypes.string,
  attr: PropTypes.string,

  isLoading: PropTypes.bool,
}
