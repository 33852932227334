import * as React from 'react'
import { compose } from 'react-apollo'

import { withTable, Filters, FilterSelect, Table } from 'bvdash/ui/tables'
import { Button } from 'bvdash/components/buttons'
import { FormActions } from 'bvdash/ui'
import { Modal } from 'bvdash/components/modals'
import { withUsers } from 'bvdash/admin/users/queries'
import { HelpText } from 'bvdash/admin/ui'
import {
  withProject,
  withProjectAddUsers,
  withProjectRemoveUsers,
} from 'bvdash/admin/projects/queries'
import { projectTeamStatusChoices } from 'bvdash/components/tables/filters'
import { withProjectSelectTable } from 'bvdash/hoc/withProjectSelectTable'

import { FlexContainer } from 'bvdash/components/standard/containers'

import { SEND_VALIDATION } from 'bvdash/admin/setup/constants'

import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'
import routes from 'bvdash/routes'

import { projectTeamListConfig } from './tableConfig'
import { memberStyle } from './styles'

export const submitText = (numSelected, type) => {
  if (numSelected === 0) {
    return `${type} Users`
  }
  if (numSelected === 1) {
    return `${type} 1 User`
  }
  return `${type} ${numSelected} Users`
}

export const TeamTable = withTable(projectTeamListConfig)(props => {
  const { isLoading, table, titles } = props

  return (
    <>
      <Filters
        withSearch
        table={table}
        filters={[
          {
            label: `${titles.team} Member`,
            attr: 'isMember',
            render: <FilterSelect choices={projectTeamStatusChoices} />,
          },
        ]}
      />
      <Table
        loading={isLoading}
        table={table}
        withSearch
        rowClassName={memberStyle}
      />
    </>
  )
})

const ListTable = compose(
  withUsers,
  withProject,
  withProjectAddUsers,
  withProjectRemoveUsers
)(props => {
  const {
    project,
    projectId,
    projectAddUsers,
    projectRemoveUsers,
    titles,
    users,
  } = props

  if ((project != null && project.isLoading) || users.isLoading) return null

  const allUsers = users.users
  const projectTeam = project.project.team
  const projectManager = project.project.manager

  const teamMembers = allUsers.map(u => {
    if (projectTeam.find(t => u.id === t.id)) {
      u.isMember = true
    } else {
      u.isMember = false
    }
    return u
  })

  const teamUsers = teamMembers.map(u => {
    if (u.id === projectManager.id) {
      u.isManager = true
    } else {
      u.isManager = false
    }
    return u
  })

  const [modal, displayModal] = React.useState(false)
  const [userToRemove, setUserToRemove] = React.useState(null)

  const handleOpenModal = userId => {
    displayModal(true)
    setUserToRemove(userId)
  }

  const handleRemoveUser = () => {
    projectRemoveUsers(projectId, [userToRemove])
    displayModal(false)
    setUserToRemove(null)
  }

  const handleAddUser = async userId => {
    await projectAddUsers(projectId, [userId])
  }

  const removeText = [
    `You are about to unassign a ${titles.team} Member from ${project.project.name}.`,
    'Are you sure you want to proceed?',
  ]
  const RemoveModal = modal ? (
    <Modal
      CancelButton={Button}
      headerText="Remove Team Member Confirmation"
      onCancel={() => displayModal(false)}
      onSubmit={handleRemoveUser}
      SubmitButton={() => (
        <Button onClick={handleRemoveUser} color="remove">
          Remove
        </Button>
      )}
      submitText="Remove"
      text={removeText}
      type="caution"
    />
  ) : null

  return (
    <>
      {RemoveModal}
      <TeamTable
        {...props}
        onAddUser={handleAddUser}
        onRemoveUser={handleOpenModal}
        teamUsers={teamUsers}
      />
    </>
  )
})

export const ProjectTeam = compose(withProjectSelectTable)(props => {
  const {
    customer: { customer },
    history,
    hoc: {
      activeProject,
      ActiveProjectContent,
      HelpContent,
      isOpen,
      ProjectTable,
    },
    projects: { projects },
    titles,

    setCurrentModalProps,
    setSetupState,
    handleModalOpen,
  } = props

  const [selected, setSelected] = React.useState(activeProject || null)

  React.useEffect(() => {
    setSelected(activeProject)
  })

  const handleNext = () => {
    setSetupState(SEND_VALIDATION)
    setCurrentModalProps({ setupState: SEND_VALIDATION })
    handleModalOpen()
    history.push(routes.admin.users)
  }

  const currentProjectByKey =
    selected != null ? projects.find(p => p.key === selected) : null

  const projectId = currentProjectByKey != null ? currentProjectByKey.id : null

  return (
    <>
      <AdminBreadcrumbs>
        {titles.project} Setup / Manage {titles.team}
      </AdminBreadcrumbs>
      <FlexContainer flexWrap marginBottom={16}>
        {ActiveProjectContent}
        {ProjectTable}
      </FlexContainer>
      {!selected ? HelpContent : null}
      {selected ? (
        <HelpText>
          To Add or Remove a Team Member, please make sure you have selected the{' '}
          correct Project, and click on the “TEAM MEMBER” column to assign or
          unassign users
        </HelpText>
      ) : null}

      {!isOpen && selected != null ? (
        <FlexContainer flexWrap width="100%">
          <ListTable
            customer={customer}
            projectId={projectId}
            titles={titles}
          />
        </FlexContainer>
      ) : null}
      {!customer.onboarding ? (
        <FormActions>
          <Button color="add" onClick={handleNext}>
            Next
          </Button>
        </FormActions>
      ) : null}
    </>
  )
})
