import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import routes from 'bvdash/routes'

// Components
import { Modal } from 'bvdash/components/modals'
import {
  Highlight,
  RegularText,
  StandardText,
} from 'bvdash/components/standard/texts'

export const TrialExpiredModal = compose(withRouter)(props => {
  const { closeModal, history } = props

  const Content = (
    <>
      <RegularText marginBottom={16} textAlign="center">
        Your <Highlight>Free Trial</Highlight> of BVDash has expired
      </RegularText>
      <StandardText textAlign="center">
        To continue using <Highlight>BVDash</Highlight> click the{' '}
        <Highlight>Account</Highlight> button below
      </StandardText>
    </>
  )

  const handleSubmit = () => {
    history.push(routes.admin.accountSubscriptions)
    closeModal()
  }

  return (
    <Modal
      headerColor="error"
      headerText="Trial Expired"
      onSubmit={handleSubmit}
      submitText="Account"
      type="add"
    >
      {Content}
    </Modal>
  )
})
