import * as React from 'react'
import styled from 'react-emotion'

import {
  VictoryPie,
  VictoryContainer,
  VictoryLegend,
  VictoryTooltip,
  VictoryLabel,
} from 'victory'
import variables from 'bvdash/styles/variables.scss'
import { maybeInt } from 'bvdash/utils'

const defaultFont = {
  fontFamily: variables.primaryFont,
  fontSize: 14,
  fontWeight: 500,
  fill: variables.sanJuan,
}

const Div = styled.div`
  margin: auto;
`

export default function PieChart({ showLegend = false, colorMap, data }) {
  const pieData = data.map((item, index) => ({
    x: item.label,
    y: maybeInt(item.value) || 0,
    name: item.description,
    symbol: { fill: colorMap[index], type: 'square' },
  }))

  const legendData = data.map((item, index) => ({
    x: item.label,
    y: item.value,
    name: item.label,
    symbol: { fill: colorMap[index], type: 'square' },
  }))

  return (
    <Div>
      <VictoryContainer width={275} height={250} responsive={false}>
        {showLegend && (
          <VictoryLegend
            y={200}
            x={24}
            orientation="horizontal"
            gutter={40}
            data={legendData}
            standalone={false}
            style={{
              labels: {
                ...defaultFont,
                fontSize: 10,
                textTransform: 'uppercase',
              },
            }}
          />
        )}
        <VictoryPie
          height={220}
          width={250}
          padding={40}
          colorScale={colorMap}
          data={pieData}
          standalone={false}
          labelRadius={40}
          labelComponent={
            <VictoryTooltip
              width={220}
              orientation="bottom"
              labelComponent={
                <VictoryLabel
                  text={({ name, y, x }) => `${y} ${name}`}
                  style={defaultFont}
                />
              }
              flyoutStyle={{
                stroke: variables.dropShadow,
                strokeWidth: 1,
                fill: variables.solidWhite,
              }}
            />
          }
        />
      </VictoryContainer>
    </Div>
  )
}
