import { graphql } from 'react-apollo'

import { mapCodes } from 'bvdash/projects/utils'
import query from './budgetCodesQuery.graphql'

export default graphql(query, {
  props: ({ data }) => ({
    isLoading: data.isLoading,
    codes: mapCodes(data.budgetCodesGroup, 'values'),
  }),
})
