import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

import {
  ClearFilters,
  TableConfig,
  TableFilter,
  SearchBox,
} from 'bvdash/ui/tables/ui'
import { handleChange } from 'bvdash/utils/handlers'

import styles from './Filters.scss'

export default function Filters({
  table,
  withSearch = false,
  filters = [],
  children,
}) {
  const filterLabels = {}
  filters.forEach(filter => {
    filterLabels[filter.attr] = filter.label
  })

  return (
    <div className={styles.filters}>
      <SearchContainer>
        {withSearch && (
          <SearchBox
            value={table.search.value}
            onSearch={handleChange(table.search.onSearch)}
          />
        )}
      </SearchContainer>

      <FilterContainer>
        {children}
        {filters.map(filter => (
          <TableFilter key={filter.attr} {...filter} table={table} />
        ))}

        {!!filters.length && (
          <FilterIconContainer>
            <ClearFilters filters={table.filter} disabled={!table.isFiltered} />
            <TableConfig {...table.tableConfig} filters={filterLabels} />
          </FilterIconContainer>
        )}
      </FilterContainer>
    </div>
  )
}

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const FilterIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

Filters.propTypes = {
  table: PropTypes.object.isRequired,
  withSearch: PropTypes.bool,
}
