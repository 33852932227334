import * as React from 'react'
import styled from 'react-emotion'

import { Attach } from 'bvdash-core/icons'

const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: -10px;
`

export default function AttachmentIcon() {
  return (
    <Icon>
      <Attach />
    </Icon>
  )
}
