import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { IssueFragment } from './issueFragment'
import { projectIssueQuery } from './withProjectIssue'

const projectIssueRemoveUpdateMutation = gql`
  mutation issueRemoveUpdate($id: Int!) {
    issueRemoveUpdate(id: $id) {
      ok
      error
      issue {
        ...Issue
        updates {
          id
        }
      }
    }
  }
  ${IssueFragment}
`

export const withProjectIssueRemoveUpdate = graphql(
  projectIssueRemoveUpdateMutation,
  {
    props: ({ mutate, ownProps }) => ({
      projectIssueRemoveUpdate: id => {
        return mutate({
          variables: { id },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: projectIssueQuery,
              variables: {
                project:
                  ownProps.projectKey || ownProps.match.params.projectKey,
                issueId: ownProps.issueId || ownProps.match.params.issueId,
              },
            },
          ],
        })
      },
    }),
  }
)
