import styled from 'react-emotion'
import { color } from 'bvdash/styles'

const HelpTextBold = styled.div`
  ${color.waterloo};
  font-weight: 510;
  font-size: 14px;
  line-height: 1.57;

  margin-bottom: 20px;
`

export default HelpTextBold
