import * as React from 'react'
import { useWith, path, split } from 'ramda'
import styled, { css } from 'react-emotion'

import { color, colorMap, fontStyle, space } from 'bvdash/styles'
import { Error } from 'bvdash/ui/forms'

const HeaderBoxField = ({
  field,
  form,
  label,
  widget: Widget = TextWidget,
  ...props
}) => {
  if (field == null) {
    return (
      <Container>
        <Label>{label}</Label>
        <Widget {...props} value={props.value} />
      </Container>
    )
  }

  const { touched, errors, values, setFieldValue } = form

  return (
    <Container>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>

      <Widget
        id={field.name}
        type="text"
        {...field}
        {...props}
        value={dottedPath(field.name, values)}
        setValue={value => setFieldValue(field.name, value)}
      />

      <Error
        show={dottedPath(field.name, touched) && dottedPath(field.name, errors)}
      >
        {dottedPath(field.name, errors)}
      </Error>
    </Container>
  )
}

const dottedPath = useWith(path, [split('.')])

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${space.normal};
`

const Label = styled.span`
  margin-right: ${space.smaller};
  ${color.waterloo};
`

const FormLabel = Label.withComponent('label')

function TextWidget(props) {
  if (!props.onChange) {
    return (
      <Value readOnly>
        {props.value} {props.suffix && <Suffix readOnly>{props.suffix}</Suffix>}
      </Value>
    )
  }

  return (
    <Value>
      <TextInput {...props} />
      {props.suffix && <Suffix>{props.suffix}</Suffix>}
    </Value>
  )
}

const Value = styled.span`
  display: inline-flex;
  flex-direction: horizontal;
  align-items: center;

  ${color.sanJuan};

  ${props =>
    !props.readOnly &&
    css`
      border-bottom: 1px solid ${colorMap.waterloo};
    `}
`

const TextInput = styled.input`
  ${fontStyle.normal};
  ${color.sanJuan};

  outline: none;
  border: none;
`

const Suffix = styled.span`
  ${props =>
    !props.readOnly
      ? css`
          ${fontStyle.smaller};
          ${color.waterloo};
        `
      : css`
          margin-left: ${space.smaller};
        `}
`

export default HeaderBoxField
