import { propEq, lensPath, curry, unless, o, isNil, view, over } from 'ramda'
import { graphql } from 'react-apollo'

import query from './listConversations.graphql'
import subscription from './newConversationSubscription.graphql'

// overIf - apply lense only if value is defined
// https://github.com/ramda/ramda/wiki/Cookbook#overif
const overIf = curry((lens, fn) => unless(o(isNil, view(lens)), over(lens, fn)))

const latestMessageDate = lensPath(['latestMessage', 'date'])

export default graphql(query, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data, ownProps }) => {
    const allConversations = (data.conversations || []).map(
      overIf(latestMessageDate, str => str && new Date(str))
    )
    const channels = allConversations.filter(
      conversation => conversation.project !== null
    )
    const conversations = allConversations.filter(
      conversation => !conversation.project && conversation.users.length === 1
    )

    return {
      isLoading: data.loading,
      channels,
      conversations,

      // all conversations
      data: allConversations,

      subscribe: params =>
        data.subscribeToMore({
          document: subscription,
          updateQuery: ({ conversations = [] }, { subscriptionData }) => {
            // can't use router.location, because that's not updated in Safari
            // when switching between channels/conversations
            const conversationId = window.location.pathname
              .split('/')
              .reverse()[1]
            const notification = subscriptionData.data.conversations
            const newConversations = conversations.slice()

            if (notification.__typename === 'MessageType') {
              // New message notification received, update unread message count
              if (notification.conversation.id === conversationId) {
                return { conversations }
              }

              const index = newConversations.findIndex(
                propEq('id', notification.conversation.id)
              )
              if (
                index !== -1 &&
                ownProps.authUser.user.id !== notification.author.id
              ) {
                const conversation = newConversations[index]
                newConversations[index] = {
                  ...conversation,
                  latestMessage: notification,
                  unreadMessages: (conversation.unreadMessages || 0) + 1,
                }
              }
            } else {
              // New conversation created, add it to the list
              const index = newConversations.findIndex(
                propEq('id', notification.id)
              )

              if (index === -1) {
                newConversations.push(notification)
              } else {
                newConversations.splice(index, 1, notification)
              }
            }

            return {
              conversations: newConversations,
            }
          },
        }),
    }
  },
})
