import { css } from 'styled-components'

export const alignItems = css`
  ${({ alignItems }) =>
    alignItems != null ? `align-items: ${alignItems}` : undefined};};
`
export const justifyContent = css`
  ${({ justifyContent }) =>
    justifyContent != null
      ? `justify-content: ${justifyContent}`
      : undefined};};
`
export const marginBottom = css`
  ${({ marginBottom }) =>
    marginBottom >= 0 ? `margin-bottom: ${marginBottom}px` : undefined};
`
export const marginTop = css`
  ${({ marginTop }) =>
    marginTop >= 0 ? `margin-top: ${marginTop}px` : undefined};
`
export const marginLeft = css`
  ${({ marginLeft }) =>
    marginLeft >= 0 ? `margin-left: ${marginLeft}px` : undefined};
`
export const marginRight = css`
  ${({ marginRight }) =>
    marginRight >= 0 ? `margin-right: ${marginRight}px` : undefined};
`
export const paddingBottom = css`
  ${({ paddingBottom }) =>
    paddingBottom >= 0 ? `padding-bottom: ${paddingBottom}px` : undefined};
`
export const paddingTop = css`
  ${({ paddingTop }) =>
    paddingTop >= 0 ? `padding-top: ${paddingTop}px` : undefined};
`
export const paddingLeft = css`
  ${({ paddingLeft }) =>
    paddingLeft >= 0 ? `padding-left: ${paddingLeft}px` : undefined};
`
export const paddingRight = css`
  ${({ paddingRight }) =>
    paddingRight >= 0 ? `padding-right: ${paddingRight}px` : undefined};
`
export const textAlign = css`
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign}` : undefined)};
`
export const setColor = (type, theme) => {
  const { white } = theme.color
  if (!type) return white

  const lowerType = type.toLowerCase()
  switch (lowerType) {
    case 'warning':
    case 'pending':
    case 'onhold':
    case 'on hold':
    case 'behindschedule':
      return theme.color.warning
    case 'danger':
    case 'error':
    case 'no':
    case 'cancelled':
    case 'over budget':
    case 'overbudget':
      return theme.color.error
    case 'open':
    case 'remove':
    case 'caution':
    case 'email not sent':
      return theme.color.caution
    case 'progress':
    case 'handling':
    case 'info':
    case 'add':
    case 'onschedule':
    case 'project':
    case 'accent':
      return theme.color.accent
    case 'resolved':
    case 'veryLow':
    case 'low':
    case 'success':
    case 'validated':
    case 'active':
    case 'assigned':
    case 'yes':
    case 'on budget':
    case 'onbudget':
      return theme.color.success
    case 'closed':
    case 'completed':
    case 'muted':
    case 'inactive':
    case 'unassigned':
    case 'n/a':
      return theme.color.muted
    case 'main':
      return theme.color.main
    case 'neutral':
    default:
      return white
  }
}
