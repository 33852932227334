import * as React from 'react'
import styled, { css } from 'react-emotion'
import { Link } from 'react-router-dom'

import { background, color, colorMap } from 'bvdash/styles'
import Button from './Button'
import DropdownMenu from './DropdownMenu'

import { FlexContainer } from 'bvdash/components/standard/containers'
import { VideoCamera } from 'bvdash-core/icons'
import { IconWithHelp } from 'bvdash/components/IconWithHelp'

// TODO(Erik): Rework this so it's more customizeable and readable.
const SubNavigation = props => {
  const { admin, links, sourcePageRoute, onboarding, welcome } = props

  const helpLink = 'https://www.youtube.com/watch?v=-oYCdi-P8Gw&t=14s'
  const helpIcon = {
    Icon: VideoCamera,
    color: 'white',
    text:
      'If you need help while using BVDash, click here for a quick video tutorial.',
  }
  const toHelpLink = () => window.open(helpLink, '_blank')

  return (
    <Nav admin={admin}>
      <FlexContainer alignItems="center" flexWrap>
        {admin && <AdminTitle to="/admin">Admin Menu:</AdminTitle>}
        {links
          .filter(link => !link.hide)
          .map((link, i) => {
            if (link.links != null) {
              return (
                <DropdownMenu
                  key={i}
                  items={link.links.filter(link => !link.hide)}
                  activePath={link.key}
                  className={admin && adminLink}
                  activeClassName={admin && adminActiveClassName}
                >
                  {link.title}
                </DropdownMenu>
              )
            }
            return (
              <LinkItem
                admin={admin}
                activeaccounts={
                  (sourcePageRoute === 'customer' && link.key === 'accounts') ||
                  null
                }
                activesuperusers={
                  (sourcePageRoute === 'superuser' &&
                    link.key === 'superusers') ||
                  null
                }
                activeClassName={
                  admin ? adminLink && adminActiveClassName : activeClassName
                }
                className={admin && adminLink}
                exact={i === 0}
                key={i}
                label={link.title}
                to={link.url}
              />
            )
          })}
      </FlexContainer>
      {admin && welcome ? (
        !onboarding ? (
          <IconWithHelp
            handleClick={toHelpLink}
            icon={helpIcon}
            initialState={{ delay: 1000, open: true }}
            width={200}
          />
        ) : (
          <IconWithHelp handleClick={toHelpLink} icon={helpIcon} width={200} />
        )
      ) : null}
    </Nav>
  )
}

export default SubNavigation

const Nav = styled.nav`
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(49, 61, 90, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 6px 32px;
  width: 100%;

  ${props =>
    props.admin
      ? css`
          ${background.sanJuan};
          ${color.solidWhite};
        `
      : css`
          ${background.solidWhite};
        `}
`

const LinkItem = styled(Button)`
  ${({ admin }) => (admin ? adminLink : background.solidWhite)};
  height: 35px;
  margin: 0 12.5px;
  text-transform: capitalize;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  ${({ activeaccounts }) => (activeaccounts ? activeClassName : null)};
  ${({ activesuperusers }) => (activesuperusers ? activeClassName : null)};

  &:hover {
    ${({ admin }) => !admin && color.dodgerBlue};
  }
`

const activeClassName = css`
  ${color.dodgerBlue};
`

const AdminTitle = styled(Link)`
  ${color.solidWhite};
  font-weight: bold;
  margin-right: 32px;
  text-decoration: none;
  text-transform: uppercase;
`

const adminLink = css`
  ${background.sanJuan};
  ${color.solidWhite};
  border: none;
  border-bottom: 2.3px solid transparent;
  height: 35px;
  margin: 0;
  margin-right: 36px;
  padding: 0;
`

const adminActiveClassName = css`
  ${color.solidWhite};
  font-weight: 500;
  border-bottom-color: ${colorMap.solidWhite} !important;
`
