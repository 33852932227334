import { colorMap } from 'bvdash/styles'

export const theme = {
  color: {
    black: '#000000',
    blackLight: '#333333',
    white: '#FFFFFF',
    whiteDark: '#F3F3F3',
    main: '#344775',
    accent: '#23A2F5',

    greyUltraLight: '#E6E6E6',
    grey: '#979797',
    greyDark: '#757575',
    greyBlueLight: '#B0B6C1',

    success: '#77C76F',
    error: '#FF585B',
    warning: '#FFC944',
    caution: '#FE9338',
    muted: '#7C859D',
    shadow: '#DCDFE2',
    dodgerBlue: '#23A2F5',
  },
  opacity: {
    90: 'E6',
    80: 'CC',
    70: 'B3',
    60: '99',
    50: '80',
    40: '66',
    30: '4D',
    25: '40',
    20: '33',
    15: '26',
    10: '1A',
  },

  shadow: 'box-shadow: 0 2px 3px 0 #75757580;',
  shadowActive: 'box-shadow: 0 1px 2px 0 #33333380;',

  table: {
    header: {
      background: '#E6E6E6',
      color: colorMap.waterloo,
      shadow: `inset 0 -1px 0 0 ${colorMap.dropShadow}`,
    },
  },
}
