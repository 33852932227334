import * as React from 'react'
import styled from 'react-emotion'

import { color, colorMap } from 'bvdash/styles'
import { Number } from 'bvdash/ui/core'

export default function RiskValueField({ field, form, value }) {
  return (
    <div>
      <FormLabel>Value:</FormLabel>
      <Value>
        {form == null ? (
          <Number value={value} numberScale="dashboard" />
        ) : (
          <ValueInput {...field} value={field.value || ''} />
        )}
      </Value>
    </div>
  )
}

const FormLabel = styled.span`
  margin-right: 10px;
  ${color.waterloo};
`

const Value = styled.span`
  display: inline-flex;
  flex-direction: horizontal;
  align-items: center;

  ${color.sanJuan};
  border-bottom: 1px solid ${colorMap.waterloo};
`

const ValueInput = styled.input`
  font-size: 14px;
  ${color.sanJuan};

  outline: none;
  border: none;
`
