import { sort, toPairs, fromPairs } from 'ramda'
import { compose } from 'react-apollo'

export function makeFilterChoices(
  choices = [],
  transformer = ([value, label]) => ({ value, label })
) {
  return choices.map(transformer)
}

const NO_PROJECT = 'No project'
export const sortByGroup = compose(
  fromPairs,
  sort(([a], [b]) => {
    if (a === b) return 0
    if (a === NO_PROJECT || (b !== NO_PROJECT && a < b)) return -1
    return 1
  }),
  toPairs
)
