import * as React from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup } from 'bvdash/ui/core'
import styled from 'react-emotion'

import PrintButton from './PrintButton'
import ExportButton from './ExportButton'

export default function HeaderActions({
  children,
  hidePrint = false,
  hideExport = true,
}) {
  if (!children && hidePrint && hideExport) return null

  return (
    <ButtonGroup>
      {children}
      <PrintButtonDiv>{!hidePrint && <PrintButton />}</PrintButtonDiv>
      {!hideExport && <ExportButton />}
    </ButtonGroup>
  )
}

const PrintButtonDiv = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

HeaderActions.propTypes = {
  children: PropTypes.any,
  hidePrint: PropTypes.bool,
  hideExport: PropTypes.bool,
}
