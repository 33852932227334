import * as React from 'react'
import styled from 'react-emotion'

import { selectable } from 'bvdash/styles'
import { DatePicker } from 'bvdash/ui/core'
import { Calendar } from 'bvdash-core/icons'

import TextInputWidget from './TextInputWidget'

function DateWidget({ setValue, value, width, ...props }) {
  return (
    <Container width={width}>
      <DatePicker
        {...props}
        onChange={setValue}
        placeholder="YYYY-MM-DD"
        component={DateInput}
        value={value}
      />
      <CalendarIcon />
    </Container>
  )
}

const CalendarIcon = styled(Calendar)`
  position: absolute;
  right: 30px;
`

const Container = styled(TextInputWidget.withComponent('div'))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: initial;
  position: relative;
  width: ${({ width }) => width || 420}px;
`

const DateInput = styled.input`
  ${selectable};
  border: none;
  font-size: 16px;
  line-height: 59px;
  width: 100%;
`

export default DateWidget
