import { graphql } from 'react-apollo'

import query from './login.graphql'
import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import authUserQuery from './authUser.graphql'

const login = graphql(query, {
  props: ({ mutate }) => ({
    login: async (email, password, keep = false) => {
      const {
        data: { authLogin },
      } = await mutate({
        variables: { email, password, keep },
        refetchQueries: [{ query: authUserQuery }, { query: customerQuery }],
      })

      return authLogin
    },
  }),
})

export default login
