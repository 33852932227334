import { Field } from 'formik'
import * as React from 'react'

import {
  ErrorText,
  ErrorContainer,
  InputContainer as StandardContainer,
  Input as StandardInput,
  Label as StandardLabel,
  ParallelContainer,
} from './styles'
import { errorHandler } from './errorHandler'
import { hasIcon, HelpIcon } from './HelpIcon'

import { PasswordMeter } from 'bvdash/components'

export const InputField = props => {
  const {
    components: {
      InputContainer = StandardContainer,
      Input = StandardInput,
      Label = StandardLabel,
    },
    containerStyle,
    inputStyle,
    disabled,
    displayErrorText,
    label,
    max,
    name,
    onClick,
    passwordMeter,
    placeholder,
    type,
    value,

    icon,
  } = props
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { errors, touched } = form

        const error = errorHandler(name, touched, errors)

        const TextInput = (
          <Input
            {...field}
            disabled={disabled}
            error={error}
            max={type === 'number' ? max : undefined}
            min={type === 'number' ? 1 : undefined}
            placeholder={placeholder}
            type={type || 'string'}
            value={value || field.value}
            style={inputStyle}
          />
        )

        return (
          <InputContainer {...containerStyle} onClick={onClick}>
            {label && (
              <Label name={name} error={error}>
                {label}
              </Label>
            )}
            {hasIcon(icon) ? (
              <ParallelContainer>
                {TextInput}
                <HelpIcon icon={icon} width={300} />
              </ParallelContainer>
            ) : (
              TextInput
            )}
            {passwordMeter ? <PasswordMeter password={field.value} /> : null}
            {displayErrorText && (
              <ErrorContainer>
                {error ? <ErrorText>{errors[name]}</ErrorText> : null}
              </ErrorContainer>
            )}
          </InputContainer>
        )
      }}
    />
  )
}

InputField.defaultProps = {
  components: {
    InputContainer: StandardContainer,
    Input: StandardInput,
    Label: StandardLabel,
  },
  displayErrorText: true,
  onClick: null,
}
