import URLSearchParams from 'url-search-params'

export function getVersionDate(location) {
  if (!location) return null

  const params = new URLSearchParams(location.search)

  // TODO: use YYYY-MM-DD date instead of ID and fetch ID from cache
  return params.get('date')
}
