import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { StripeCustomerFragment } from './stripeCustomerFragment'

const stripeSetDefaultPaymentMutation = gql`
  mutation stripeSetDefaultPaymentSource(
    $stripeCustomerId: String!
    $stripeCardId: String!
  ) {
    stripeSetDefaultPaymentSource(
      stripeCustomerId: $stripeCustomerId
      stripeCardId: $stripeCardId
    ) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripePaymentSetDefault = graphql(
  stripeSetDefaultPaymentMutation,
  {
    props: ({ mutate, ownProps }) => ({
      stripeSetDefaultPaymentSource: async ({
        stripeCustomerId,
        stripeCardId,
      }) => {
        const res = await mutate({
          variables: { stripeCustomerId, stripeCardId },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: customerQuery,
            },
          ],
        })

        return res.data.stripeSetDefaultPaymentSource
      },
    }),
  }
)
