import * as React from 'react'
import { css } from 'react-emotion'
import { color } from 'bvdash/styles'

const errorClass = css`
  ${color.carnation};
  line-height: 1.5em;
`

export default function Error({ children, show, test }) {
  return (
    show && (
      <div className={errorClass} test={test}>
        {children}
      </div>
    )
  )
}

Error.defaultProps = {
  show: false,
}
