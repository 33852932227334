import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { filter, pick } from 'ramda'

import { enumChoices, enumValue } from 'bvdash/utils/graphql'
import { maybeEvolve } from 'bvdash/utils'
import { assignedToChoices } from 'bvdash/projects/dataTransform'
import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { EnumValueFragment } from 'bvdash/core/queries'

const NCRFragment = gql`
  fragment NCR on NCRType {
    id
    ncrId
    classification
    content
    isOpen
    canEdit
    assignedTo {
      id
      key
      fullName
    }
    reportedBy {
      id
      key
      fullName
    }
  }
`

const TimelineNCREventFragment = gql`
  fragment TimelineNCREvent on NCREventType {
    id
    type
    classification
    author {
      id
      fullName
    }
    assignee {
      id
      fullName
    }
    reporter {
      id
      fullName
    }
    date
  }
`

const NCRUpdateFragment = gql`
  fragment NCRUpdate on NCRUpdateType {
    id
    canEdit
    content
    dateCreated
    author {
      id
      fullName
    }
  }
`

const pickNCRInputFields = pick([
  'content',
  'classification',
  'attachments',
  'assignedTo',
  'reportedBy',
  'assignedToId',
  'reportedById',
])

const normalizeNCR = maybeEvolve({
  attachments: filter(attachment => !attachment.deleted),
  probability: enumValue,
  impact: enumValue,
})

const ncrQuery = gql`
  query ncr($project: String!, $ncrId: Int!) {
    ncr(project: $project, ncrId: $ncrId) {
      ...NCR
      attachments {
        ...Attachment
      }
      events {
        ...TimelineNCREvent
      }
      updates {
        ...NCRUpdate
      }
    }
  }

  ${NCRFragment}
  ${NCRUpdateFragment}
  ${TimelineNCREventFragment}
  ${AttachmentFragment}
`

export const withNCR = graphql(ncrQuery, {
  options: props => ({
    variables: {
      project: props.projectKey,
      ncrId: props.ncrId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => ({
    ncr: {
      isLoading: data.loading,
      ncr: normalizeNCR(data.ncr),
      projectKey: data.variables.project,
      ncrId: data.variables.ncrId,
    },
  }),
})

export const withNCRs = graphql(
  gql`
    query ncrs($project: String!) {
      ncrs(project: $project) {
        ...NCR
        attachments {
          id
        }
      }
    }

    ${NCRFragment}
  `,
  {
    options: props => ({
      variables: { project: props.match.params.projectKey },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => {
      const { ncrs = [] } = data

      return {
        ncrs: {
          isLoading: data.loading,
          projectKey: data.variables.project,
          ncrs,
        },
      }
    },
  }
)

export const withNCRFieldsChoices = graphql(
  gql`
    query projectIssueForm($project: String!) {
      users(project: $project) {
        id
        key
        fullName
      }

      classification: __type(name: "NCRClassification") {
        ...EnumValues
      }
    }

    ${EnumValueFragment}
  `,
  {
    options: props => ({
      variables: { project: props.projectKey || props.match.params.projectKey },
    }),
    props: ({ data }) => ({
      ncrFieldsChoices: {
        isLoading: data.loading,
        classification: enumChoices(data.classification),
        assignedTo: assignedToChoices(data.users),
        reportedBy: assignedToChoices(data.users),
      },
    }),
  }
)

export const withNCRCreate = graphql(
  gql`
    mutation ncrCreate($project: String!, $ncr: NCRCreateInput!) {
      ncrCreate(project: $project, ncr: $ncr) {
        ok
        error
        ncr {
          id
          ncrId
        }
      }
    }
  `,
  {
    props: ({ mutate, ownProps }) => ({
      ncrCreate: (project, ncrInput) => {
        return mutate({
          variables: {
            ncr: pickNCRInputFields(ncrInput),
            project,
          },
        })
      },
    }),
  }
)

export const withNCREdit = graphql(
  gql`
    mutation ncrEdit($id: ID!, $ncr: NCREditInput!) {
      ncrEdit(id: $id, ncr: $ncr) {
        ok
        error
        ncr {
          ...NCR
          attachments {
            ...Attachment
          }
        }
      }
    }

    ${NCRFragment}
    ${AttachmentFragment}
  `,
  {
    props: ({ mutate }) => ({
      ncrEdit(id, ncrInput, queryVariables) {
        return mutate({
          variables: {
            ncr: pickNCRInputFields(ncrInput),
            id,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: ncrQuery,
              variables: queryVariables,
            },
          ],
        })
      },
    }),
  }
)

export const withNCRSetOpen = graphql(
  gql`
    mutation ncrClose($id: ID!, $open: Boolean!) {
      ncrSetOpen(id: $id, open: $open) {
        ok
        error
        ncr {
          id
          isOpen
          events {
            ...TimelineNCREvent
          }
        }
      }
    }

    ${TimelineNCREventFragment}
  `,
  {
    props: ({ mutate }) => ({
      ncrSetOpen: (id, open) => {
        return mutate({
          variables: {
            id,
            open,
          },
        })
      },
    }),
  }
)

export const withNCRAddUpdate = graphql(
  gql`
    mutation ncrAddUpdate($id: ID!, $update: NCRUpdateInput!) {
      ncrAddUpdate(id: $id, update: $update) {
        ok
        ncr {
          id
          events {
            ...TimelineNCREvent
          }
          updates {
            ...NCRUpdate
          }
        }
      }
    }

    ${TimelineNCREventFragment}
    ${NCRUpdateFragment}
  `,
  {
    props: ({ mutate }) => ({
      ncrAddUpdate: (id, update) => {
        return mutate({
          variables: {
            id,
            update,
          },
        })
      },
    }),
  }
)

export const withNCREditUpdate = graphql(
  gql`
    mutation ncrEditUpdate($id: ID!, $update: NCRUpdateInput!) {
      ncrEditUpdate(id: $id, update: $update) {
        ok
        ncr {
          id
          events {
            ...TimelineNCREvent
          }
          updates {
            ...NCRUpdate
          }
        }
      }
    }

    ${TimelineNCREventFragment}
    ${NCRUpdateFragment}
  `,
  {
    props: ({ mutate }) => ({
      ncrEditUpdate: (id, update) => {
        return mutate({
          variables: {
            id,
            update,
          },
        })
      },
    }),
  }
)

export const withNCRRemoveUpdate = graphql(
  gql`
    mutation ncrRemoveUpdate($id: ID!) {
      ncrRemoveUpdate(id: $id) {
        ok
        ncr {
          id
          events {
            ...TimelineNCREvent
          }
          updates {
            id
          }
        }
      }
    }

    ${TimelineNCREventFragment}
  `,
  {
    props: ({ mutate }) => ({
      ncrRemoveUpdate: id => {
        return mutate({
          variables: {
            id,
          },
        })
      },
    }),
  }
)
