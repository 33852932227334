import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import authUser from 'bvdash/auth/queries/authUser.graphql'
import customerUpdate from './customerUpdate.graphql'
import { CustomerProfileFragment } from './customerProfileFragment'

const customerUpdateMutation = gql`
  mutation customerUpdate($customer: CustomerUpdateInput!, $id: ID!) {
    customerUpdate(customer: $customer, id: $id) {
      ok
      error
      customer {
        ...CustomerProfile
      }
    }
  }
  ${CustomerProfileFragment}
`

export const withCustomerProfileUpdate = graphql(customerUpdateMutation, {
  props: ({ mutate }) => ({
    customerUpdate: (customer, id) => {
      return mutate({
        variables: { customer, id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerUpdate }, { query: authUser }],
      })
    },
  }),
})

const customerLogoUpdateMutation = gql`
  mutation customerProfilePictureUrlUpdate(
    $attachments: [Upload]
    $customerId: ID!
  ) {
    customerProfilePictureUrlUpdate(
      attachments: $attachments
      customerId: $customerId
    ) {
      ok
      error
      customer {
        ...CustomerProfile
      }
    }
  }
  ${CustomerProfileFragment}
`

export const withCustomerLogoUpdate = graphql(customerLogoUpdateMutation, {
  props: ({ mutate }) => ({
    customerLogoUpdate: (attachments, customerId) => {
      return mutate({
        variables: { attachments, customerId },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerUpdate }, { query: authUser }],
      })
    },
  }),
})

const customerRemoveMutation = gql`
  mutation customerRemove {
    customerRemove {
      ok
      error
    }
  }
`

const customerRemoveCancelMutation = gql`
  mutation customerRemoveCancel {
    customerRemoveCancel {
      ok
      error
    }
  }
`

export const withCustomerRemove = graphql(customerRemoveMutation, {
  props: ({ mutate }) => ({
    customerRemove: () => {
      return mutate({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerUpdate }, { query: authUser }],
      })
    },
  }),
})

export const withCustomerRemoveCancel = graphql(customerRemoveCancelMutation, {
  props: ({ mutate }) => ({
    customerRemoveCancel: () => {
      return mutate({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerUpdate }, { query: authUser }],
      })
    },
  }),
})
