import { graphql } from 'react-apollo'
import query from './projectDashboardQuery.graphql'

export default graphql(query, {
  options: props => ({
    variables: { project: props.match.params.projectKey },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => {
    const { loading, projectSummary, variables } = data
    return {
      summary: {
        isLoading: loading,
        projectKey: variables.project,
        summary: projectSummary,
      },
    }
  },
})
