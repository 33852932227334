import { withFormik } from 'formik'
import * as React from 'react'
import { compose } from 'react-apollo'

import { AddButton, Button } from 'bvdash/components/buttons'
import { FormActions } from 'bvdash/ui'
import {
  withProject,
  withProjectFormData,
  withProjectFields,
  withProjectCreate,
  withProjectUpdate,
  withProgramCodesGroup,
} from 'bvdash/admin/queries'
import { withProjectAddUsers } from 'bvdash/admin/projects/queries'
import { renderInputArray } from 'bvdash/utils/rendering'
import { FormContainer } from 'bvdash/admin/components/containers'

import {
  initializeFields,
  serializeProject,
  deserializeProject,
} from 'bvdash/projects/serializers'

import routes from 'bvdash/routes'

import { inputs, validationSchema } from './inputs'

import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'
import { ASSIGN_USERS, INDIVIDUAL_SETUP } from 'bvdash/admin/setup/constants'

export const ProjectAddEdit = props => {
  const {
    canAddMoreProjects,
    customer: { customer },
    history,
    match: {
      params: { id, versionId },
    },
    program,
    titles,
    setNotificationContent,
    notificationClose,
    notificationIsOpen,
    notificationOpen,
    setCurrentModalProps,
    setSetupState,
    setCurrentModal,
    handleModalOpen,
    setupOption,
  } = props

  const [nextStep, setNextStep] = React.useState(false)

  if (!canAddMoreProjects && !id) {
    setCurrentModal('noMoreProjects')
    handleModalOpen()
    return null
  }

  const redirectRoute = routes.admin.projectData

  const onSubmitRedirect = () => {
    history.push(redirectRoute, { notification: true })
  }

  const numProjects = program.projects.length

  const title = id
    ? `Edit ${titles.project} Details`
    : `Add New ${titles.project}`

  const handleCancel = () => history.goBack()

  const handleNextStep = () => {
    setSetupState(ASSIGN_USERS)
    setCurrentModalProps({ setupState: ASSIGN_USERS })
    handleModalOpen()
    history.push(routes.admin.projectTeam)
  }

  const handleFinishSetup = () => {
    setCurrentModal('finishSetup')
    handleModalOpen()
  }

  const handleSuccess = () => {
    notificationOpen()
    setNotificationContent({
      type: 'success',
      text: 'Project successfully added',
    })
  }

  return (
    <>
      <AdminBreadcrumbs>{title}</AdminBreadcrumbs>
      <ProjectForm
        handleCancel={handleCancel}
        handleNextStep={handleNextStep}
        numProjects={numProjects}
        onSubmitRedirect={onSubmitRedirect}
        onboarding={customer.onboarding}
        program={program}
        projectId={id}
        setupOption={setupOption}
        titles={titles}
        versionId={versionId}
        handleFinishSetup={handleFinishSetup}
        nextStep={nextStep}
        setNextStep={setNextStep}
        setNotificationContent={setNotificationContent}
        notificationClose={notificationClose}
        notificationIsOpen={notificationIsOpen}
        notificationOpen={notificationOpen}
        handleSuccess={handleSuccess}
      />
    </>
  )
}

const withProjectForm = withFormik({
  enableReinitialize: true,

  isInitialValid: ({ project }) => project != null,

  mapPropsToValues: props => {
    const { programCodesGroup, projectFields, project } = props

    return project != null && !project.isLoading
      ? deserializeProject(project.project)
      : {
          key: '',
          name: '',
          managerId: '',
          fields: initializeFields(projectFields.all.map(field => field.key)),
          codes: initializeFields(
            programCodesGroup.codes.map(code => code.key),
            true
          ),
        }
  },

  handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
    const {
      onSubmitRedirect,
      projectId,
      program,
      projectAddUsers,
      projectCreate,
      projectUpdate,
      versionId,
      onboarding,
      setupOption,
      handleFinishSetup,
      handleNextStep,
      nextStep,
      handleSuccess,
    } = props

    const project = serializeProject(values)

    const managerId = values.managerId

    if (projectId != null) {
      const { data } = await projectUpdate({ projectId, versionId, project })
      await projectAddUsers(projectId, [managerId])
      if (data.projectUpdate.ok) {
        onSubmitRedirect()
      }
    } else {
      const { data } = await projectCreate({
        programId: program.id,
        versionId,
        project,
      })
      if (data.projectCreate.ok) {
        const ok = await projectAddUsers(data.projectCreate.project.id, [
          managerId,
        ])
        if (ok) {
          localStorage.setItem('activeProject', data.projectCreate.project.key)
          handleSuccess()
          if (!onboarding) {
            resetForm()
            if (nextStep) {
              if (setupOption === INDIVIDUAL_SETUP) {
                return handleFinishSetup()
              }
              return handleNextStep()
            }
          } else {
            onSubmitRedirect()
          }
        }
      }
    }
  },

  validationSchema,
})

const ProjectForm = compose(
  // queries
  withProject,
  withProjectFormData,
  withProgramCodesGroup,
  withProjectFields,
  // mutations
  withProjectAddUsers,
  withProjectCreate,
  withProjectUpdate,
  // form
  withProjectForm
)(props => {
  const {
    handleCancel,
    numProjects,
    program,
    programCodesGroup,
    projectFields,
    projectFormData: { managerChoices },
    titles,

    onboarding,
    submitForm,
    setNextStep,
  } = props

  const renderInputs = renderInputArray(
    inputs({
      managerChoices,
      numProjects,
      projectFields,
      programId: program.id,
      programCodesGroup,
      titles,
    })
  )

  const handleSaveAndNext = () => {
    setNextStep(true)
    submitForm()
  }

  const verticalMargin = 8
  const OnboardingButtons = (
    <>
      <div>
        <AddButton
          marginBottom={verticalMargin}
          marginTop={verticalMargin}
          onClick={submitForm}
        >
          Save and Add Another {titles.project}
        </AddButton>
      </div>
      <Button color="add" onClick={handleSaveAndNext}>
        Save and Next
      </Button>
    </>
  )

  const StandardButtons = (
    <>
      <Button onClick={handleCancel}>Cancel</Button>
      <Button type="submit" color="add">
        Save
      </Button>
    </>
  )

  return (
    <FormContainer>
      {renderInputs}
      <FormActions>
        {!onboarding ? OnboardingButtons : StandardButtons}
      </FormActions>
    </FormContainer>
  )
})
