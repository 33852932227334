import * as React from 'react'

import { Button } from 'bvdash/components'
import { Empty, EmptyContainer } from 'bvdash/ui/core'

const EmptyPage = props => {
  const { buttonText, buttonUrl, dashboard, description, image, title } = props

  return (
    <EmptyContainer>
      <Empty
        dashboard={dashboard}
        image={image}
        title={title}
        description={description}
      >
        {buttonText ? (
          <Button color="add" to={buttonUrl}>
            {buttonText}
          </Button>
        ) : null}
      </Empty>
    </EmptyContainer>
  )
}

EmptyPage.defaultProps = {
  buttonText: '',
  dashboard: false,
}

export default EmptyPage
