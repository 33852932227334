import * as React from 'react'
import { css } from 'react-emotion'
import { background, shadow, shadowLight, space, radius } from 'bvdash/styles'
import { Box } from 'bvdash/ui/core'

export default function HeaderBox({ children, actions, standalone }) {
  return (
    <Box horizontal className={headerBoxClass({ standalone })}>
      <Box
        horizontal
        grow={2}
        shrink={0}
        basis={0}
        align="flex-start"
        alignCross="center"
      >
        {children}
      </Box>
      {actions && (
        <Box
          horizontal
          grow={1}
          shrink={0}
          basis={0}
          align="flex-end"
          alignCross="center"
        >
          {actions}
        </Box>
      )}
    </Box>
  )
}

const headerBoxClass = props => css`
  ${background.solidWhite};
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  margin-bottom: 8px;
  width: 100%;

  ${props.standalone
    ? css`
        ${radius()}
        ${shadow};
        margin-bottom: ${space.normal};
      `
    : css`
        ${radius({ br: false, bl: false })}
        ${shadowLight};
        // Make shadow visible above divs underneath
        z-index: 1;
      `}
`
