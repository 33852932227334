import * as React from 'react'

import { CenterContainer, CenterFullPage } from 'bvdash/components/styles'

const CenterPage = props => {
  const { children } = props
  return (
    <CenterFullPage>
      <CenterContainer>{children}</CenterContainer>
    </CenterFullPage>
  )
}

export default CenterPage
