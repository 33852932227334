import styled from 'react-emotion'

import { colorMap } from 'bvdash/styles'

const Row = styled.tr`
  border-top: 1px solid ${colorMap.dropShadow};
  border-left: 5px solid transparent;
  height: 34px;
  &:first-child {
    border-top: ${({ footer }) => !footer && 'none'};
  }
  &:hover {
    ${({ hoverStyle }) => hoverStyle || undefined};
  }
  ${({ rowClassName }) => rowClassName};
`

export default Row
