import styled from 'styled-components'

const border = 5

export const Container = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${border}px;
  box-shadow: 0 5px 10px 0 rgba(49, 61, 90, 0.2), inset 0 -1px 0 0 #eaecee;
  position: absolute;
  right: 25%;
  top: calc(100% + 2px);
  ${({ width }) => (width >= 0 ? `width: ${width}px` : undefined)};
  z-index: 100;

  &::before {
    border: 10px solid transparent;
    border-bottom-color: ${({ theme }) => theme.color.white};
    content: '';
    display: block;
    height: 0;
    margin-left: -10px;
    right: 8px;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    width: 0;
    z-index: 1;
  }
`

export const Item = styled.li`
  border-left: 3px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyUltraLight};
  cursor: pointer;
  line-height: 1.5em;
  outline: none;
  padding: 4px 16px 4px 13px;
  user-select: none;
  text-align: center;
  white-space: ${({ wordWrap }) => (wordWrap ? 'normal' : 'nowrap')};

  &:hover {
    background: ${({ theme }) => theme.color.whiteDark};
    border-left: 3px solid ${({ theme }) => theme.color.accent};
  }

  &:first-child {
    border-top-left-radius: ${border}px;
    border-top-right-radius: ${border}px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: ${border}px;
    border-bottom-right-radius: ${border}px;
  }
`
