import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { IssueFragment } from './issueFragment'

const projectIssueEditUpdateMutation = gql`
  mutation issueEditUpdate($id: Int!, $content: String!) {
    issueEditUpdate(id: $id, content: $content) {
      ok
      error
      issue {
        ...Issue
        updates {
          id
          content
        }
      }
    }
  }
  ${IssueFragment}
`

export const withProjectIssueEditUpdate = graphql(
  projectIssueEditUpdateMutation,
  {
    props: ({ mutate }) => ({
      projectIssueEditUpdate: (id, content) => {
        return mutate({
          variables: { id, content },
        })
      },
    }),
  }
)
