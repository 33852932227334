import gql from 'graphql-tag'

export const StripeCustomerFragment = gql`
  fragment StripeCustomer on CustomerType {
    id
    name
    stripeCustomerId
    stripeSubscriptionId
    stripePlanId
    stripePlanName
    stripeUserQuantity
    stripeTrialStart
    stripeTrialEnd
  }
`
