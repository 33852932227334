import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { TimelineEventFragment } from './timelineEventFragment'

const projectIssueCloseMutation = gql`
  mutation projectIssueClose($id: ID!) {
    issueClose(id: $id) {
      issue {
        id
        isOpen
        status
        events {
          ...TimelineEvent
        }
      }
      error
      ok
    }
  }
  ${TimelineEventFragment}
`

export const withProjectIssueClose = graphql(projectIssueCloseMutation, {
  props: ({ mutate }) => ({
    projectIssueClose: id => {
      return mutate({
        variables: { id },
      })
    },
  }),
})
