import { graphql } from 'react-apollo'

import query from './getVersions.graphql'

export default graphql(query, {
  options: ({ kind }) => ({
    variables: {
      kind: kind ? kind.toUpperCase() : null,
    },
  }),
  props: ({ data }) => {
    return {
      isLoading: data.loading,
      versions: data.versions || [],
    }
  },
})
