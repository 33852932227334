import * as React from 'react'
import useOutsideClick from 'use-onclickoutside'

import { Container } from './styles'

export const Dropdown = props => {
  const { children, isOpen, onClose, outsideClickRef, width } = props
  const ref = React.useRef(null)
  useOutsideClick(outsideClickRef || ref, onClose)

  if (!isOpen) return null

  return (
    <Container ref={ref} width={width}>
      {children}
    </Container>
  )
}
