import * as React from 'react'
import { withRouter } from 'react-router-dom'

// Components
import { Modal } from 'bvdash/components/modals'
import { Highlight, StandardText } from 'bvdash/components/standard/texts'

export const ConfirmDeleteModal = withRouter(props => {
  const {
    closeModal,
    currentModalProps: {
      color,
      handleSubmit = null,
      removeStatus,
      titles,
      type,
    },
  } = props

  const stepOneTitle = 'Mark for Deletion'
  const stepOneText = (
    <StandardText marginBottom={16}>
      You are about to mark this {titles[type]} for{' '}
      <Highlight color={color}>Deletion</Highlight>. Your {titles[type]} will
      remain operational until you confirm the deletion on the second step.
    </StandardText>
  )
  const stepOneProceed = (
    <StandardText>
      Are you sure you want to{' '}
      <Highlight color={color}>Mark for Deletion?</Highlight>
    </StandardText>
  )

  const stepTwoTitle = `Delete ${titles[type]}`
  const stepTwoText = (
    <StandardText marginBottom={16}>
      <Highlight color={color}>CAUTION:</Highlight>{' '}
      <Highlight color={color}>Deleting</Highlight> your {titles[type]} will{' '}
      <Highlight color={color}>permanently erase</Highlight> all information
      associated with this {titles[type]}
    </StandardText>
  )
  const stepTwoProceed = (
    <StandardText>
      Are you sure you want to{' '}
      <Highlight color={color}>Delete {titles[type]}?</Highlight>
    </StandardText>
  )

  const onCancel = () => closeModal()
  const onSubmit = () => {
    handleSubmit()
    closeModal()
  }

  const submitText = 'Confirm'

  const headerText = removeStatus ? stepTwoTitle : stepOneTitle

  const Content = removeStatus ? (
    <>
      {stepTwoText}
      {stepTwoProceed}
    </>
  ) : (
    <>
      {stepOneText}
      {stepOneProceed}
    </>
  )

  return (
    <Modal
      headerText={headerText}
      headerColor={color}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitText={submitText}
      type={color}
    >
      {Content}
    </Modal>
  )
})
