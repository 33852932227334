import { css } from 'react-emotion'

export * from './core'
export FormField from './FormField'

// padding-bottom depends on size of `FormActions`, which has fixed position
export const formClass = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  padding-bottom: 65px;
`
