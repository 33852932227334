import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'
import { NavLink } from 'react-router-dom'

import styles from './Button.scss'

class Button extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    containerElement: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.node,
    labelPosition: PropTypes.oneOf(['before', 'after']),
    name: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['default', 'small', 'large', 'resize']),
    to: PropTypes.string,
    theme: PropTypes.oneOf([
      'default',
      'primary',
      'danger',
      'link',
      'link-muted',
      'header',
      'warning',
    ]),
  }

  static defaultProps = {
    containerElement: 'button',
    disabled: false,
    fullWidth: false,
    labelPosition: 'after',
    size: 'default',
    type: 'button',
  }

  render() {
    const {
      children,
      className,
      containerElement,
      disabled,
      fullWidth,
      href,
      icon,
      label,
      labelPosition,
      name,
      onClick,
      size,
      theme: themeRaw,
      to,
      type,
      ...other
    } = this.props

    const isLink = href || to
    const theme = themeRaw || (isLink ? 'link' : 'default')

    if (disabled && isLink) {
      const disabledLink = cx(className, {
        [styles.root]: true,
        [styles.themelink]: true,
        [styles.disabled]: true,
      })
      return (
        <span {...other} className={disabledLink}>
          {children}
        </span>
      )
    }

    const buttonClass = cx(
      {
        [styles.root]: true,
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
        [styles.iconRight]: icon && label && labelPosition === 'before',
        [styles.iconLeft]: icon && label && labelPosition === 'after',
      },
      styles[`size${size}`],
      styles[`theme${theme}`],
      className
    )

    const buttonProps = {
      ...other,
      disabled,
      href,
      to,
      onClick,
      className: buttonClass,
      name,
    }

    if (!isLink && containerElement === 'button') {
      buttonProps.type = type
    }

    const labelElement = label ? (
      <span key="labelElement">{label}</span>
    ) : (
      undefined
    )

    const [first, last] =
      labelPosition === 'before'
        ? [labelElement, children]
        : [children, labelElement]

    if (to) {
      return (
        <NavLink {...buttonProps}>
          {first}
          {icon}
          {last}
        </NavLink>
      )
    }

    const Element = href ? 'a' : containerElement
    return (
      <Element {...buttonProps}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {first}
        {last}
      </Element>
    )
  }
}

export default Button
