import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { TimelineEventFragment } from 'bvdash/projects/scenes/ProjectIssues/queries/timelineEventFragment'

export default graphql(
  gql`
    mutation IssueAddUpdate($id: ID!, $content: String!) {
      issueAddUpdate(id: $id, content: $content) {
        ok
        issue {
          id
          events {
            ...TimelineEvent
          }
          updates {
            id
            canEdit
            author {
              id
              fullName
            }
            content
            dateCreated
          }
        }
      }
    }
    ${TimelineEventFragment}
  `,
  {
    props: ({ mutate }) => ({
      projectIssueAddUpdate: (id, variables) => {
        return mutate({
          variables: {
            ...variables,
            id,
          },
        })
      },
    }),
  }
)
