import { graphql } from 'react-apollo'

import query from './passwordResetRequest.graphql'

export default graphql(query, {
  props: ({ mutate }) => ({
    passwordResetRequest: async email => {
      const { data } = await mutate({ variables: { email } })
      return data.authPasswordResetRequest
    },
  }),
})
