import { graphql } from 'react-apollo'

import { mapCodes, scheduleDateRange } from 'bvdash/projects/utils'
import { getVersionDate } from 'bvdash/urls'
import query from './projectScheduleQuery.graphql'

const dates = ['earlyStart', 'earlyFinish', 'earlyStartBl', 'earlyFinishBl']

function getScheduleItemStatus(
  { earlyFinish, earlyStart, status },
  versionDate
) {
  if ((earlyFinish || earlyStart) <= versionDate) return 'completed'
  if (
    earlyStart &&
    earlyStart <= versionDate &&
    earlyFinish &&
    earlyFinish > versionDate
  )
    return 'inProgress'

  return status ? 'onSchedule' : 'behindSchedule'
}

/**
 * - Convert date strings in schedule item to Date objects.
 * - Convert list of codes to map
 */
export const normalize = versionDate => item => {
  const dateAttrs = {}
  dates.forEach(
    field => (dateAttrs[field] = item[field] ? new Date(item[field]) : null)
  )

  const codes = item.codes ? mapCodes(item.codes) : undefined

  item = {
    ...item,
    codes,
    ...dateAttrs,
  }
  // we need to call getScheduleItemStatus on item with Date objects
  item.statusVerbose = getScheduleItemStatus(item, versionDate)

  return item
}

export default graphql(query, {
  options: props => {
    const variables = {
      project: props.match.params.projectKey,
      programId: props.project.program.id,
    }

    const versionDate = getVersionDate(props.location)
    if (versionDate) {
      variables.version = versionDate
    }

    return {
      variables,
    }
  },
  props: ({ data }) => {
    const {
      items: scheduleRaw = [],
      milestones: scheduleMilestonesRaw = [],
      version = {},
    } = data.schedule || {}

    const versionDate = version != null ? new Date(version.date) : null

    const schedule = scheduleRaw.map(normalize(versionDate))
    const scheduleMilestones = scheduleMilestonesRaw.map(normalize(versionDate))
    const dateRange = scheduleDateRange(
      [...schedule, ...scheduleMilestones],
      versionDate
    )

    return {
      isLoading: data.loading,
      schedule,
      scheduleMilestones,
      versionDate,
      dateRange,
      codes: mapCodes(data.scheduleCodesGroup, 'values'),
    }
  },
})
