import * as React from 'react'
import { compose } from 'react-apollo'
import PropTypes from 'prop-types'

// WARNING: DO NOT REMOVE OR COMMENT OUT!!!
import { renderLabelText } from 'bvdash/components'
import routes from 'bvdash/routes'

import { withCustomer } from 'bvdash/queries/settings'

import { AlertsDropdown } from 'bvdash/components/navigation/AlertsDropdown'
import { ConversationsDropdown } from 'bvdash/components/navigation/ConversationsDropdown'
import { UserProfileDropdown } from 'bvdash/components/navigation/UserProfileDropdown'
import Button from 'bvdash/ui/core/Button'

import { displayLink, linkClasses } from 'bvdash/components/navigation/links'

import { FlexContainer, Nav } from './styles'

export const DesktopNav = compose(withCustomer)(props => {
  const {
    // WARNING: DO NOT REMOVE OR COMMENT OUT!!
    customer: { theme },
    links,
    onboarding,
    settingsMenu,
    titles,
    trialExpired,
  } = props

  const AlertLink =
    !trialExpired && displayLink('alert', settingsMenu) ? (
      <AlertsDropdown
        {...linkClasses}
        titles={titles}
        to={routes.alerts.toString()}
      />
    ) : null

  const ConversationLink =
    !trialExpired && displayLink('conversation', settingsMenu) ? (
      <ConversationsDropdown
        {...linkClasses}
        titles={titles}
        to={routes.conversations.toString()}
      />
    ) : null

  return (
    <Nav>
      <FlexContainer>
        {links.map(props => (
          <Button key={props.to} {...props} />
        ))}
      </FlexContainer>
      <FlexContainer>
        {AlertLink}
        {ConversationLink}
        <UserProfileDropdown
          onboarding={onboarding}
          trialExpired={trialExpired}
        />
      </FlexContainer>
    </Nav>
  )
})

DesktopNav.propTypes = {
  location: PropTypes.object,
}
