import { CannotChangePlanModal } from './CannotChangePlanModal'
import { ConfirmDeleteModal } from './ConfirmDeleteModal'
import { ImportModal } from './ImportModal'
import { NoMoreProjectsModal } from './NoMoreProjectsModal'
import { NoMoreUsersModal } from './NoMoreUsersModal'
import { NoSendValidationEmailsModal } from './NoSendValidationEmailsModal'
import { TrialExpiredModal } from './TrialExpiredModal'
import { FinishSetupModal, SetupModal, WelcomeModal } from './SetupModals'

export const modalOptions = {
  cannotChangePlan: CannotChangePlanModal,
  confirmDelete: ConfirmDeleteModal,
  finishSetup: FinishSetupModal,
  import: ImportModal,
  noMoreUsers: NoMoreUsersModal,
  noMoreProjects: NoMoreProjectsModal,
  noSendValidationEmails: NoSendValidationEmailsModal,
  setup: SetupModal,
  trialExpired: TrialExpiredModal,
  welcome: WelcomeModal,
}
