import * as React from 'react'
import styled from 'react-emotion'
import { color } from 'bvdash/styles'

const Empty = props => {
  const { image, Icon, title, description, dashboard = false, children } = props
  const Container = dashboard ? DashboardContent : Content

  return (
    <Container>
      <ImageContainer>
        {image ? (
          Array.isArray(image) ? (
            <Image
              src={image[0]}
              srcSet={`${image[1]} 2x, ${image[2]} 3x`}
              alt={title}
            />
          ) : (
            <Image src={image} alt={title} />
          )
        ) : null}
        {Icon ? <Icon.Component size={Icon.size} color={Icon.color} /> : null}
      </ImageContainer>
      <Heading dashboard={dashboard}>{title}</Heading>
      <Description dashboard={dashboard}>{description}</Description>
      {children}
    </Container>
  )
}

const ImageContainer = styled.div`
  margin: auto;
  max-width: 200px;
`
const Image = styled.img`
  max-height: 200px;
  max-width: 200px;
`

const Content = styled.div`
  padding-top: 8px;
  padding-bottom: 36px;
  text-align: center;
`

const DashboardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;

  img {
    height: 100px;
    width: auto;
  }
`

const Heading = styled.h1`
  display: block;
  font-size: 16px;
  font-weight: 500;

  ${props =>
    props.dashboard
      ? ` margin-top: 20px;
  `
      : `
  margin: 36px auto 0;
  max-width: 370px;
  `};
`

const Description = styled.p`
  ${color.waterloo};
  display: block;
  font-size: 14px;
  line-height: 1.57;

  ${props =>
    props.dashboard
      ? ` margin-top: 5px;
  `
      : `
  margin: 36px auto 26px;
  max-width: 370px;
  `};
`

export default Empty
