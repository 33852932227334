import * as React from 'react'

import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import './DatePicker.scss'

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
}

function formatDate(date, format) {
  return dateFnsFormat(date || new Date(), format)
}

class DatePicker extends React.Component {
  dayPickerInput = React.createRef()

  componentDidMount() {
    this.updateInputSize(this.props.value)
  }

  updateInputSize = value => {
    // Setting input.size to value.length isn't pixel perfect.
    // Substract 1 character to make it look better.
    this.dayPickerInput.current.getInput().size = formatDate(value).length - 1
  }

  handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
    this.props.onChange(selectedDay)
    this.updateInputSize(selectedDay)
  }

  render() {
    return (
      <DayPickerInput
        ref={this.dayPickerInput}
        value={this.props.value}
        onDayChange={this.handleDayChange}
        formatDate={formatDate}
        parseDate={parseDate}
        inputProps={{ name: this.props.name }}
        {...this.props}
      />
    )
  }
}

DatePicker.defaultProps = {
  placeholder: 'Select Date',
  format: 'yyyy-MM-dd',
}

export default DatePicker
