import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { StripeCustomerFragment } from './stripeCustomerFragment'

const stripeCreateSubscriptionMutation = gql`
  mutation stripeCreateSubscription(
    $stripeCustomerId: String!
    $stripePlanId: String!
  ) {
    stripeCreateSubscription(
      stripeCustomerId: $stripeCustomerId
      stripePlanId: $stripePlanId
    ) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripeSubscriptionCreate = graphql(
  stripeCreateSubscriptionMutation,
  {
    props: ({ mutate }) => ({
      stripeCreateSubscription: async ({ stripeCustomerId, stripePlanId }) => {
        const res = await mutate({
          variables: { stripeCustomerId, stripePlanId },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: customerQuery }],
        })

        return res.data.stripeCreateSubscription
      },
    }),
  }
)
