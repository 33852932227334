import { reverse } from 'named-urls'
import * as React from 'react'
import { css } from 'react-emotion'

import routes from 'bvdash/routes'
import { color, colorMap } from 'bvdash/styles'
import {
  Calendar,
  Portfolio,
  Program,
  Projects,
  Timesheets,
  Tasks,
} from 'bvdash-core/icons'
import { pluralize } from 'bvdash/utils'

const mainLinkClass = css`
  align-items: center;
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 16px;

  &:hover {
    ${color.dodgerBlue};
  }
`

const activeLinkClass = css`
  ${color.dodgerBlue};
  border-bottom-color: ${colorMap.dodgerBlue};
`

export const linkClasses = {
  className: mainLinkClass,
  activeClassName: activeLinkClass,
}

const isAction = (match, location) =>
  match || location.pathname.match(/^\/tasks?/i)
const isTimesheet = (match, location) =>
  match || location.pathname.match(/^\/timesheets?/i)
const isCalendar = (match, location) =>
  match || location.pathname.match(/^\/calendar?/i)
const isPortfolio = (match, location) =>
  match || location.pathname.match(/^\/portfolio?/i)
const isProgram = (match, location) =>
  match || location.pathname.match(/^\/programs?/i)
const isProject = (match, location) =>
  match || location.pathname.match(/^\/projects?/i)

export const displayLink = (key, settingsMenu) => {
  if (settingsMenu != null) {
    return settingsMenu[key].on
  }
  return true
}

export const renderLinks = args => {
  const {
    titles,
    defaultProgram,
    permissions,
    plans,
    settingsMenu,
    trialExpired,
  } = args

  if (trialExpired) {
    return []
  }

  const { hasPrograms } = permissions
  const {
    isBusinessPlan,
    isEnterprisePlan,
    isFreePlan,
    isProfessionalPlan,
  } = plans

  const programRouteLink = isFreePlan
    ? routes.program.team
    : isEnterprisePlan || isBusinessPlan || isProfessionalPlan
    ? routes.program.dashboard
    : routes.program.team

  let linkArray = []

  const portfolioLink = {
    to: routes.portfolio,
    label: titles.portfolio,
    isActive: isPortfolio,
    icon: <Portfolio />,
    ...linkClasses,
  }
  const programLink = hasPrograms &&
    !isFreePlan && {
      to: reverse(programRouteLink, {
        programKey: defaultProgram != null && defaultProgram.key,
      }),
      isActive: isProgram,
      label: titles.program,
      icon: <Program />,
      ...linkClasses,
    }
  const projectLink = {
    to: routes.projects.all,
    isActive: isProject,
    label: pluralize(titles.project),
    icon: <Projects />,
    ...linkClasses,
  }
  const timesheetLink = displayLink('timesheet', settingsMenu) && {
    to: routes.timesheets.toString(),
    isActive: isTimesheet,
    label: pluralize(titles.timesheet),
    icon: <Timesheets />,
    ...linkClasses,
  }
  const actionLink = displayLink('task', settingsMenu) && {
    ...linkClasses,
    to: routes.tasks.toString(),
    isActive: isAction,
    label: pluralize(titles.task),
    icon: <Tasks />,
  }
  const calendarLink = {
    ...linkClasses,
    to: routes.calendar,
    isActive: isCalendar,
    label: 'Calendar',
    icon: <Calendar />,
  }

  if (isFreePlan) {
    linkArray = [projectLink, actionLink].filter(Boolean)
  }
  if (isBusinessPlan || isProfessionalPlan) {
    linkArray = [programLink, projectLink, actionLink].filter(Boolean)
  }
  if (isEnterprisePlan) {
    linkArray = [
      portfolioLink,
      programLink,
      projectLink,
      timesheetLink,
      actionLink,
      calendarLink,
    ].filter(Boolean)
  }

  return linkArray
}
