import { graphql } from 'react-apollo'

import mutation from './muteConversationMutation.graphql'
import listConversations from './listConversations.graphql'

export default graphql(mutation, {
  props: ({ mutate, ownProps }) => ({
    muteConversation: (conversationId, muted) =>
      mutate({
        variables: { conversationId, muted },
        update: (
          store,
          {
            data: {
              conversationsMute: { ok },
            },
          }
        ) => {
          if (!ok) return

          let { conversations } = store.readQuery({
            query: listConversations,
          })
          conversations = conversations.map(conversation =>
            conversation.id === conversationId
              ? {
                  ...conversation,
                  muted,
                }
              : conversation
          )
          store.writeQuery({
            query: listConversations,
            data: { conversations },
          })
        },
      }),
  }),
})
