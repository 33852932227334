import * as React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { search, withTable, Table } from 'bvdash/ui/tables'
import { actionStatus } from 'bvdash/actions/columns'

import SearchBoxSelect from './SearchBoxSelect'

const config = {
  name: 'issueSearchBoxTable',
  rowKey: row => row.id,
  sortBy: 'issueId',
  columns: [
    {
      attr: '_checkbox',
      render: ({ row, props }) => {
        return (
          <input
            type="checkbox"
            onChange={props.onChange(row.issueId)}
            checked={props.value.includes(row.issueId)}
          />
        )
      },
    },
    {
      attr: 'issueId',
      label: 'Issue ID',
    },
    {
      attr: 'content',
      label: 'Issue Description',
    },
    actionStatus(),
  ],
}

const IssueTable = withTable(config)(props => {
  const { onChange, table, value } = props

  if (!table.data.length && !table.isFilted) {
    return null
  }

  return (
    <Table paddingBottom={1} table={table} renderProps={{ onChange, value }} />
  )
})

export class IssueSearchBox extends React.Component {
  handleChange = id => event => {
    const {
      target: { checked },
    } = event
    const { onChange, value } = this.props
    const filteredValue = value.filter(value => value !== id)

    if (checked) filteredValue.push(id)
    return onChange(filteredValue)
  }

  render() {
    const { issues = [], value, project } = this.props
    return (
      <SearchBoxSelect
        id="issueSearch"
        placeholder='Search issue ID, description, or enter a blank space to show "All Issues"'
        disabled={!project}
      >
        {needle => (
          <IssueTable
            onChange={this.handleChange}
            value={value}
            data={issues.filter(
              issue =>
                value.includes(issue.issueId) ||
                search(needle, issue.issueId, issue.content)
            )}
          />
        )}
      </SearchBoxSelect>
    )
  }
}

export default graphql(
  gql`
    query issueSearchBox($project: String!) {
      issues(project: $project) {
        id
        issueId
        content
        isOpen
      }
    }
  `,
  {
    skip: props => !props.project,
    options: props => ({ variables: { project: props.project } }),
    props: ({ data }) => ({
      issues: data.issues,
    }),
  }
)(IssueSearchBox)
