import * as React from 'react'
import PropTypes from 'prop-types'

import { Box, Button } from 'bvdash/ui/core'
import { TextInputWidget } from 'bvdash/ui/FormField'
import { withApollo } from 'react-apollo'

class GenerateIdWidget extends React.Component {
  handleGenerateCodeId = async () => {
    const response = await this.props.client.query({
      query: this.props.generateIdQuery,
      variables: this.props.generateIdVariables,
      fetchPolicy: 'no-cache',
    })

    const { nextId } = response.data
    if (nextId != null) this.props.setValue(nextId)
  }

  render() {
    const { readOnly, disabled, ...props } = this.props

    return (
      <Box align="flex-start" horizontal alignCross="center">
        <TextInputWidget {...props} disabled={readOnly} />
        {!readOnly && (
          <Button
            onClick={this.handleGenerateCodeId}
            theme="link"
            disabled={disabled}
            test="GenerateIDBtn"
          >
            Generate ID automatically
          </Button>
        )}
      </Box>
    )
  }
}

GenerateIdWidget.defaultProps = {
  readOnly: false,
  disabled: false,
}

GenerateIdWidget.propTypes = {
  generateIdQuery: PropTypes.object.isRequired,
  generateIdVariables: PropTypes.object,
  // input isn't editable
  readOnly: PropTypes.bool,
  // link for generating code is disabled (but input is editable)
  disabled: PropTypes.bool,
}

export default withApollo(GenerateIdWidget)
