import * as React from 'react'

import {
  BarCode,
  Money,
  List,
  Schedule,
  ProgramCode,
  ScanCode,
  FeatureSettings,
  MenuSettings,
  PreferenceSettings,
  ManageSubscription,
  CompanyProfile,
  CreditCards,
  Team,
} from 'bvdash-core/icons'

import routes from 'bvdash/routes'

import { pluralize } from 'bvdash/utils'

const AdminLink = props => {
  const { Icon, title, url } = props

  return {
    title: (
      <>
        <Icon /> {title}
      </>
    ),
    url,
  }
}

export const fieldsTitle = 'Column Title'
export const codesTitle = 'Group Title'

const accountLinks = {
  key: 'account',
  title: 'Account',
  activePath: routes.admin.account,
  links: [
    AdminLink({
      Icon: CompanyProfile,
      title: 'Company Profile',
      url: routes.admin.accountCompanyProfile,
    }),
    AdminLink({
      Icon: ManageSubscription,
      title: 'Manage Subscription',
      url: routes.admin.accountSubscriptions,
    }),
    AdminLink({
      Icon: CreditCards,
      title: 'Payment Methods',
      url: routes.admin.accountPaymentMethods,
    }),
  ],
}

const settingsLinks = {
  key: 'settings',
  title: 'Settings',
  activePath: routes.admin.settings,
  links: [
    AdminLink({
      Icon: PreferenceSettings,
      title: 'Preference Settings',
      url: routes.admin.settingsUnits,
    }),
    AdminLink({
      Icon: FeatureSettings,
      title: 'Feature Settings',
      url: routes.admin.settingsFeatures,
    }),
    AdminLink({
      Icon: MenuSettings,
      title: 'Menu Settings',
      url: routes.admin.settingsTitles,
    }),
  ],
}

const userLinks = {
  key: 'users',
  title: 'Users',
  url: routes.admin.users,
}

const programLinks = ({ titles }) => ({
  key: 'programs',
  title: `${titles.program} Setup`,
  activePath: routes.admin.programs,
  links: [
    AdminLink({
      Icon: List,
      title: `Manage ${pluralize(titles.program)}`,
      url: routes.admin.programs,
    }),
    AdminLink({
      Icon: ProgramCode,
      title: `${titles.program} Snapshots`,
      url: routes.admin.programSnapshots,
    }),
  ],
})

const projectLinks = ({ titles }) => ({
  key: 'projects',
  title: `${pluralize(titles.project)} Setup`,
  activePath: routes.admin.projectData,
  links: [
    AdminLink({
      Icon: List,
      title: `Manage ${pluralize(titles.project)}`,
      url: routes.admin.projectData,
    }),
    AdminLink({
      Icon: Team,
      title: `Manage ${pluralize(titles.team)}`,
      url: routes.admin.projectTeam,
    }),
    AdminLink({
      Icon: BarCode,
      title: `${titles.project} ${fieldsTitle}s`,
      url: routes.admin.projectFields,
    }),
    AdminLink({
      Icon: ScanCode,
      title: `${titles.project} ${codesTitle}s`,
      url: routes.admin.projectCodes,
    }),
  ],
})

const budgetLinks = ({ titles }) => ({
  key: 'budget',
  title: `${titles.budget} Setup`,
  activePath: routes.admin.budget,
  links: [
    AdminLink({
      Icon: Money,
      title: `Manage ${pluralize(titles.budget)}`,
      url: routes.admin.budgetItems,
    }),
    AdminLink({
      Icon: BarCode,
      title: `${titles.budget} ${fieldsTitle}s`,
      url: routes.admin.budgetFields,
    }),
    AdminLink({
      Icon: ScanCode,
      title: `${titles.budget} ${codesTitle}s`,
      url: routes.admin.budgetCodes,
    }),
    AdminLink({
      Icon: ProgramCode,
      title: `${titles.budget} Snapshots`,
      url: routes.admin.budgetSnapshots,
    }),
  ],
})

const scheduleLinks = ({ titles }) => ({
  key: 'schedule',
  title: `${titles.schedule} Setup`,
  activePath: routes.admin.schedule,
  links: [
    AdminLink({
      Icon: Schedule,
      title: `Manage ${titles.schedule}`,
      url: routes.admin.scheduleItems,
    }),
    AdminLink({
      Icon: ScanCode,
      title: `${titles.schedule} ${codesTitle}s`,
      url: routes.admin.scheduleCodes,
    }),
    AdminLink({
      Icon: ProgramCode,
      title: `${titles.schedule} Snapshots`,
      url: routes.admin.scheduleSnapshots,
    }),
  ],
})

export const adminLinks = props => {
  const {
    isBusinessPlan,
    isEnterprisePlan,
    isFreePlan,
    isProfessionalPlan,
    titles,
    trialExpired,
    onboarding,
  } = props

  const account = accountLinks
  const settings = settingsLinks
  const users = userLinks
  const program = programLinks({ titles })
  const project = projectLinks({ titles })
  const budget = budgetLinks({ titles })
  const schedule = scheduleLinks({ titles })

  const standardLinks = [account, users, project]

  if (trialExpired) {
    return standardLinks
  }

  if (!onboarding) {
    switch (true) {
      case isFreePlan:
      case isProfessionalPlan:
      case isBusinessPlan:
        return standardLinks
      case isEnterprisePlan:
        return [account, users, program, project]
      default:
        return standardLinks
    }
  }

  switch (true) {
    case isFreePlan:
      return standardLinks
    case isProfessionalPlan:
    case isBusinessPlan:
      return [account, users, project, budget, schedule, settings]
    case isEnterprisePlan:
      return [account, users, program, project, budget, schedule, settings]
    default:
      return standardLinks
  }
}
