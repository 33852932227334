// @flow
import * as React from 'react'
import styled, { css } from 'react-emotion'

import Box from 'bvdash/ui/core/Box'
import { search } from 'bvdash/ui/tables'
import { withProjectsList } from 'bvdash/queries/withProjects'
import {
  selectable,
  colorMap,
  color,
  background,
  textBold,
} from 'bvdash/styles'
import { clickable } from 'bvdash/utils/a11y'

import SearchBoxSelect from './SearchBoxSelect'

export class ProjectSearchBox extends React.Component {
  state = {
    isEditing: false,
  }

  handleChange = key => () => {
    this.props.onChange(key)
    this.setState({ isEditing: false })
  }

  render() {
    const {
      projects: { projects = [] },
      value,
      id,
      keySelector,
      disabled,
    } = this.props
    const selectedProject = projects.find(
      project => keySelector(project) === value
    )

    return (
      <SearchBoxSelect
        id={id}
        placeholder="Search project ID, name, Project Manager, or enter a blank space to show “All Projects”"
        hideSearch={selectedProject && !this.state.isEditing}
        disabled={disabled}
      >
        {needle =>
          selectedProject && !this.state.isEditing ? (
            <SelectedProject
              horizontal
              {...clickable(
                () => !disabled && this.setState({ isEditing: true })
              )}
            >
              <span>{selectedProject.key}</span>
              <span>{selectedProject.name}</span>
              <span>{selectedProject.manager.fullName}</span>
            </SelectedProject>
          ) : (
            <table style={{ width: '100%' }}>
              <tbody>
                {projects
                  .filter(project =>
                    search(
                      needle,
                      project.key,
                      project.name,
                      project.manager.fullName
                    )
                  )
                  .slice(0, 5)
                  .map(project => (
                    <Row
                      onClick={this.handleChange(keySelector(project))}
                      key={project.id}
                    >
                      <td>{project.key}</td>
                      <td>{project.name}</td>
                      <td>{project.manager.fullName}</td>
                    </Row>
                  ))}
              </tbody>
            </table>
          )
        }
      </SearchBoxSelect>
    )
  }
}

ProjectSearchBox.defaultProps = {
  keySelector: project => project.key,
  disabled: false,
}

const selectedClass = css`
  border-left-color: ${colorMap.dodgerBlue};
  ${background.alabasterLight};
`

const Row = styled.tr`
  ${selectable};
  width: 100%;

  line-height: 40px;
  font-weight: 500;
  border-top: 1px solid ${colorMap.dropShadow};
  border-left: 3px solid transparent;
  ${props => props.selected && selectedClass};

  :hover {
    ${selectedClass};
  }

  td {
    white-space: nowrap;
    padding: 0 10px;
  }

  td:first-child {
    padding-left: 70px;
  }
`

const SelectedProject = styled(Box)`
  ${selectable};
  ${color.sanJuan};
  ${textBold};
  padding: 17px 20px;

  span {
    margin-right: 10px;
  }
`

export default withProjectsList(ProjectSearchBox)
