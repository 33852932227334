import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { AlertFragment } from './alertFragment'
import { AlertsSubscription } from './alertsSubscription'

export const alertsQuery = gql`
  query alerts {
    alerts {
      ...AlertFragment
    }
  }
  ${AlertFragment}
`

export const withAlerts = graphql(alertsQuery, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => {
    const { alerts, loading } = data
    return {
      alerts: {
        isLoading: loading,
        alerts: (alerts || []).filter(alert => !alert.deleted),
      },
      subscribe: params =>
        data.subscribeToMore({
          document: AlertsSubscription,
          updateQuery: ({ alerts = [] }, { subscriptionData }) => ({
            alerts: [subscriptionData.data.alerts, ...alerts],
          }),
        }),
    }
  },
})
