import { graphql } from 'react-apollo'
import mutation from './settingsUpdate.graphql'
import { settings as settingsQuery } from './settings.graphql'

const updateSettings = graphql(mutation, {
  props: ({ mutate }) => ({
    settingsUpdate: settings => {
      return mutate({
        variables: { settings },
      })
    },
  }),
  options: {
    update: (proxy, { data: { settingsUpdate } }) => {
      const data = proxy.readQuery({ query: settingsQuery })
      Object.assign(data, settingsUpdate)
      proxy.writeQuery({ query: settingsQuery, data })
    },
  },
})

export default updateSettings
