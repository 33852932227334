import * as React from 'react'
import { css } from 'react-emotion'

import { background, color } from 'bvdash/styles'

const badge = css`
  ${background.dodgerBlue};
  ${color.solidWhite};

  display: inline-block;
  flex-shrink: 0;
  min-width: 9px;
  padding: 3px 7px;
  border-radius: 9px;

  font-size: 11px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
`

export default function Badge({ value }) {
  if (value > 99) value = '99+'
  return <span className={badge}>{value}</span>
}
