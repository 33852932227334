import format from 'date-fns/format'
import * as React from 'react'

import { SettingsContext } from 'bvdash/contexts/Settings'
import { formatDate } from 'bvdash/utils/intl'

export const DateComponent = props => {
  const settingsContext = React.useContext(SettingsContext)

  if (!settingsContext || !settingsContext.settings) return null
  if (props === null) return formatDate(new Date())

  const { date } = props
  const datetimeFormat = settingsContext.settings.datetimeFormat.split(' ')[0]

  const modifiedDateFormat = datetimeFormat
    .replace(/D/g, 'd')
    .replace(/Y/g, 'y')

  if (typeof date === 'object' || date.includes('T')) {
    return format(new Date(date), modifiedDateFormat)
  }
  return format(new Date(`${date}T00:00`), modifiedDateFormat)
}

DateComponent.defaultProps = {
  date: null,
}
