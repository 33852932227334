import * as React from 'react'
import styled from 'styled-components'

import { RelativeContainer } from 'bvdash/components/standard/containers'
import { SmallText } from 'bvdash/components/standard/texts'
import {
  Container as DropdownContainer,
  DropdownList,
  withToggle,
} from 'bvdash/components/toggle'

const Container = styled(RelativeContainer)`
  align-items: center;
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
`

export const IconWithHelp = withToggle(props => {
  const {
    initialState,
    handleClick,
    icon: { Icon, color, size, text },
    toggle,
    width,
  } = props

  const choices = [{ key: '', label: text }]

  const handleToggle = () => toggle.toggle()
  const handleOpen = () => toggle.open()
  const handleClose = () => toggle.close()

  React.useEffect(() => {
    if (initialState.open) {
      const timer = setTimeout(() => {
        handleOpen()
      }, initialState.delay)
      return () => clearTimeout(timer)
    }
  }, [])

  return (
    <Container
      color="main"
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <Icon color={color} onClick={handleToggle} size={size} />
      {toggle.isOpen ? (
        <DropdownContainer width={width}>
          <DropdownList
            items={choices.map(item => ({
              key: item.label,
              children: <SmallText>{item.label}</SmallText>,
              onClick: () => {
                handleClick(item)
                toggle.close()
              },
            }))}
            wordWrap
          />
        </DropdownContainer>
      ) : null}
    </Container>
  )
})

IconWithHelp.defaultProps = {
  choices: [{ key: '', label: '' }],
  handleClick: () => {},
  initialState: {
    delay: 0,
    open: false,
  },
  width: 200,
}
