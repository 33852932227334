import * as React from 'react'
import styled from 'react-emotion'

function normalizeSize(size) {
  if (size === true) return 1
  return size
}

const BoxStyle = styled.div(
  ({
    item = false,
    horizontal = false,
    align = 'center',
    alignCross = 'stretch',
    grow,
    shrink,
    basis = 'auto',
    wrap = true,
    marginTop,
    maxWidth,
    marginLeft,
    marginRight,
    marginBottom,
    minWidth,
    flexDirection,
    width,
  }) => ({
    display: !item ? 'flex' : undefined,
    // flexDirection: horizontal ? undefined : 'column', // row is default
    flexWrap: wrap ? 'wrap' : undefined, // no-wrap is default
    flexGrow: normalizeSize(grow),
    flexShrink: normalizeSize(shrink),
    flexBasis: basis,
    justifyContent: align,
    alignItems: alignCross,
    marginTop: marginTop,
    marginLeft: marginLeft,
    marginRight: marginRight,
    maxWidth: maxWidth,
    marginBottom: marginBottom,
    minWidth: minWidth,
    flexDirection: flexDirection,
    width: width,
  })
)

export default function Box({ render = 'div', children, ...props }) {
  const Element = render !== 'div' ? BoxStyle.withComponent(render) : BoxStyle
  return <Element {...props}>{children}</Element>
}
