import gql from 'graphql-tag'

export const ProjectFragment = gql`
  fragment ProjectFragment on ProjectType {
    id
    key
    program {
      id
      name
    }
    manager {
      id
    }
    name
    team {
      id
      key
      email
      phoneNumber
      isActive
      fullName
      photo
      jobTitle
      __typename
    }
  }
`
