import styled from 'react-emotion'
import { color } from 'bvdash/styles'

export function Field({
  name,
  onChange,
  onFocus,
  onBlur,
  value,
  children,
  type: fieldType,
  placeholder,
}) {
  const id = `form_${name}`
  const getLabelProps = () => ({
    htmlFor: id,
  })

  const getInputProps = ({ type: inputType } = {}) => {
    // allow input type overriding on per-input or per-field basis,
    // type="text" is default
    const type = inputType || fieldType || 'text'

    return {
      id,
      name,
      onChange,
      onFocus,
      onBlur,
      placeholder,
      type,
      value: type !== 'checkbox' ? value : undefined,
      checked: type === 'checkbox' ? value : undefined,
    }
  }

  return children({ getLabelProps, getInputProps })
}

export const FieldGroup = styled.div`
  ${color.waterloo};
  position: relative;
  margin-right: 17px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }
`

export const FieldLabel = styled.span`
  padding-right: 10px;
  line-height: 25px;
`

export const FieldValue = styled.span`
  ${color.sanJuan};
  font-weight: 500;
`
