import { graphql } from 'react-apollo'

import query from './programIssues.graphql'
import { mapCodes } from 'bvdash/projects/utils'

export default graphql(query, {
  options: props => ({
    variables: { program: props.match.params.programKey },

    // Cache isn't updated for some reson after mutation. Let's fix it later.
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => {
    return {
      isLoading: data.loading,
      programKey: data.variables.program,
      data: (data.programIssues || []).map(({ project, ...issues }) => ({
        ...issues,
        ...project,
        codes: mapCodes(project.codes),
      })),
    }
  },
})
