import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import routes from 'bvdash/routes'

// Components
import { Modal } from 'bvdash/components/modals'
import { Highlight, StandardText } from 'bvdash/components/standard/texts'

// Queries and Mutations
import { withCustomer } from 'bvdash/queries/settings/withCustomer'
import { withStripePlans } from 'bvdash/admin/account/queries'

export const NoMoreUsersModal = compose(
  withCustomer,
  withStripePlans,
  withRouter
)(props => {
  const {
    customer: { customer },
    closeModal,
    history,
    path,
    stripePlans: { stripePlans },
  } = props

  const currentPlanData = stripePlans.find(
    plan => plan.planId === customer.stripePlanId
  )
  const canAddMoreUsers = customer.numUsers < currentPlanData.numUsers

  const FirstLine = (
    <StandardText>
      You have reached your user limit of{' '}
      <Highlight>{customer.numUsers}</Highlight>
    </StandardText>
  )

  const Content = !canAddMoreUsers ? (
    <>
      {FirstLine}
      <StandardText>
        The <Highlight>{currentPlanData.name} Plan</Highlight> you are currently
        using only supports <Highlight>{currentPlanData.numUsers}</Highlight>{' '}
        users
      </StandardText>
      <StandardText>Click below to upgrade your plan</StandardText>
    </>
  ) : (
    <>
      {FirstLine}
      <StandardText>
        The <Highlight>{currentPlanData.name} Plan</Highlight> you are currently
        using allows up to <Highlight>{currentPlanData.numUsers}</Highlight>{' '}
        users
      </StandardText>
      <StandardText>Click below to purchase more seats</StandardText>
    </>
  )

  const submitText = !canAddMoreUsers ? 'Upgrade' : 'Purchase more seats'
  const onCancel = () => {
    if (path === routes.admin.usersAdd) {
      history.goBack()
    }
    closeModal()
  }
  const onSubmit = () => {
    history.push(routes.admin.accountSubscriptions)
    closeModal()
  }
  return (
    <Modal
      headerText="Unable to Add More Users"
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitText={submitText}
      type="add"
    >
      {Content}
    </Modal>
  )
})
