import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { css } from 'react-emotion'

import { HeaderActions } from 'bvdash/ui/core'
import { colorMap } from 'bvdash/styles'

const breadcrumbCss = css`
  color: ${colorMap.sanJuan};
  text-decoration: none;
`
const highlightCss = css`
  color: ${colorMap.dodgerBlue};
  text-decoration: none;
`
const BreadcrumbLink = styled(Link)`
  font-weight: normal;
  line-height: 1.5em;
  &::before {
    cursor: default;
    content: ' / ';
    color: ${colorMap.sanJuan};
  }
  &:first-child::before {
    cursor: default;
    content: '';
    color: ${colorMap.sanJuan};
  }
  ${({ highlight, label, to }, index) =>
    highlight ? highlightCss : breadcrumbCss}
`
const Container = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  ${props => {
    if (props.conversations) {
      return '@media (max-width: 831px){display: none;}'
    }
  }}
  width: 100%;
`

const Header = styled.h1`
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 16px;
  margin-top: 16px;
`

export const BreadcrumbsHeader = props => {
  const { breadcrumbs, children, hidePrint, hideExport } = props
  return (
    <Container {...props}>
      <Header>
        {breadcrumbs.map(({ highlight, label, to }, index) => (
          <BreadcrumbLink
            key={index}
            to={to}
            highlight={
              breadcrumbs.length === 1 ||
              (breadcrumbs.length === 2 && index === 0) ||
              (breadcrumbs.length >= 3 &&
                (breadcrumbs[1].highlight && index === 1))
                ? highlightCss
                : breadcrumbs.length >= 3 &&
                  (index === 0 &&
                    (breadcrumbs[2].label.includes('Add') ||
                      breadcrumbs[2].label.includes('Edit') ||
                      breadcrumbs[2].label.includes('#')))
                ? highlightCss
                : highlight
            }
          >
            {label}
          </BreadcrumbLink>
        ))}
      </Header>
      <HeaderActions hideExport={hideExport} hidePrint={hidePrint}>
        {children}
      </HeaderActions>
    </Container>
  )
}

BreadcrumbsHeader.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  hidePrint: PropTypes.bool,
  hideExport: PropTypes.bool,
}
export default BreadcrumbsHeader
