import * as React from 'react'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'

import { Card } from 'bvdash/ui/core'
import { EmptyPage } from 'bvdash/projects/ui'

import { pluralize } from 'bvdash/utils'

import EmptyIssues from 'bvdash-core/artwork/EmptyIssuesNew.png'

const DashboardIssues = props => {
  const { forProgram = false, open, total, objectKey, titles } = props

  const body = !total ? (
    <EmptyPage
      dashboard
      description={`There are no ${
        forProgram ? titles.program : titles.project
      } ${pluralize(titles.issue)} yet`}
      image={EmptyIssues}
      title={
        forProgram
          ? `No ${titles.program} ${pluralize(titles.issue)}`
          : `No ${titles.project} ${pluralize(titles.issue)}`
      }
    />
  ) : (
    <Card.Layout type="two">
      <Card.Section description={`Total ${pluralize(titles.issue)}`}>
        {total}
      </Card.Section>
      <Card.Section description={`Open ${pluralize(titles.issue)}`}>
        {open}
      </Card.Section>
    </Card.Layout>
  )

  const detailsLink = !forProgram
    ? reverse(!total ? routes.project.issueAdd : routes.project.issues, {
        projectKey: objectKey,
      })
    : reverse(routes.program.issues, { programKey: objectKey })

  return (
    <Card.Card
      title={
        forProgram
          ? `${titles.program} ${pluralize(titles.issue)}`
          : `${titles.project} ${pluralize(titles.issue)}`
      }
      detailsLink={detailsLink}
      detailsLabel={!forProgram && !total ? 'Add New' : 'See Details'}
    >
      {body}
    </Card.Card>
  )
}

export default DashboardIssues
