import * as React from 'react'

import { ToggleDropdown, withToggle } from 'bvdash/components/toggle'
import { TableButton } from 'bvdash/components/buttons/TableButton'

export const CellStatusUpdate = withToggle(props => {
  const { handleClick, value = false, status, toggle } = props

  if (typeof value === 'boolean') {
    return (
      <TableButton onClick={() => toggle.toggle()} type={value.toLowerCase()}>
        {value ? 'Open' : 'Closed'}
      </TableButton>
    )
  }

  return (
    <>
      <TableButton onClick={() => toggle.toggle()} type={value.toLowerCase()}>
        {value === 'PROGRESS' ? 'IN PROGRESS' : value}
      </TableButton>
      <ToggleDropdown
        choices={status.choices}
        handleClick={handleClick}
        small
        toggle={toggle}
      />
    </>
  )
})
