import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

// Components
import { Modal } from 'bvdash/components/modals'
import { MediumText } from 'bvdash/components/standard/texts'

import { withCustomer } from 'bvdash/queries/settings/withCustomer'
import { withOnboarding } from 'bvdash/admin/setup/withSetup'

import routes from 'bvdash/routes'

export const FinishSetupModal = compose(
  withOnboarding,
  withCustomer,
  withRouter
)(props => {
  const {
    customer: { customer },
    customerCompleteOnboarding,
    closeModal,
    history,
  } = props

  const Content = (
    <>
      <MediumText textAlign="center">Your initial setup is complete</MediumText>
      <MediumText textAlign="center">
        Click Continue to start using BVDash
      </MediumText>
    </>
  )

  const onSubmit = async () => {
    const res = await customerCompleteOnboarding({ customerId: customer.id })
    if (res) {
      localStorage.removeItem('setupOption')
      localStorage.removeItem('setupState')
      closeModal()
      return history.push(routes.projects.all)
    }
  }

  return (
    <Modal
      headerText="Congratulations!"
      onSubmit={onSubmit}
      submitFullWidth
      submitText="Continue"
      type="add"
    >
      {Content}
    </Modal>
  )
})
