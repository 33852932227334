import * as React from 'react'
import styled from 'react-emotion'
import { StyledLinkifyLinks } from 'bvdash/components/StyledLinkifyLinks'

import { color, space, textDefault } from 'bvdash/styles'

function TextareaField({ field, value, ...props }) {
  if (field != null) {
    return <Textarea {...field} {...props} />
  } else {
    return (
      <ReadOnly>
        <StyledLinkifyLinks>{value}</StyledLinkifyLinks>
      </ReadOnly>
    )
  }
}

const ReadOnly = styled.p`
  ${color.sanJuan};
  ${textDefault};

  width: 100%;
  flex-grow: 1;
  padding: ${space.normal};
  white-space: pre-line;
`

const Textarea = styled(ReadOnly.withComponent('textarea'))`
  min-height: 300px;

  border: none;
  resize: none;
  outline: none;
`

export default TextareaField
