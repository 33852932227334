import { makeFilterChoices } from 'bvdash/ui/tables'

export function mapCodes(codes, attr = null) {
  if (!codes || !codes.length) return {}

  return codes.reduce(
    (codes, code) => ({
      ...codes,
      [code.key]: attr ? code[attr] : code,
    }),
    {}
  )
}

export function unique(iterable, mapping = id => [id, id]) {
  const unique = {}
  iterable.forEach(item => {
    const [key, value] = mapping(item)
    unique[key] = value
  })
  return Object.values(unique)
}

const yearStart = year => new Date(year, 0, 1)
const yearEnd = year => new Date(year, 11, 31)

export function scheduleDateRange(items, currentDate = new Date()) {
  let dateMin = currentDate
  let dateMax = currentDate

  items.forEach(item => {
    const { earlyStart, earlyFinish, earlyStartBl, earlyFinishBl } = item
    // Use only defined dates (filter boolean) when calculating min and
    // max date, because some values might be undefined
    dateMin = Math.min.apply(
      this,
      [dateMin, earlyStart, earlyStartBl].filter(Boolean)
    )
    dateMax = Math.max.apply(
      this,
      [dateMax, earlyFinish, earlyFinishBl].filter(Boolean)
    )
  })

  return [
    yearStart(new Date(dateMin).getFullYear()),
    yearEnd(new Date(dateMax).getFullYear()),
  ]
}

// Transform list of users into list of choices
export const authorChoices = authors =>
  makeFilterChoices(authors, author => ({
    label: author.fullName,
    value: author.key,
  }))
