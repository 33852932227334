import * as React from 'react'
import PropTypes from 'prop-types'

import { Dialog } from '@reach/dialog'
import styled from 'react-emotion'

import { Button, Headline } from 'bvdash/ui/core'
import { withDocumentReview } from 'bvdash/projects/queries'

class DocumentReviewDialog extends React.Component {
  state = {
    requestedValue: null,
  }

  handleShowDialog = requestedValue => {
    if (this.props.document.status === requestedValue) return
    this.setState({ requestedValue })
  }

  handleHideDialog = () => this.setState({ requestedValue: null })

  handleReview = approve => async () => {
    await this.props.documentReview(this.props.document.id, approve)
    this.handleHideDialog()
  }

  render() {
    const { requestedValue } = this.state
    const approve = requestedValue === 'APPROVED'
    const dialogProps = approve
      ? {
          title: 'Approve Documents Confirmation',
          question: (
            <>
              You are about to approve all documents included in this batch.
              <br />
              Are you sure you want to proceed?
            </>
          ),
          btnYes: 'Approve',
          theme: 'primary',
        }
      : {
          title: 'Reject Documents',
          question: (
            <>
              You are about to reject all documents included in this batch.{' '}
              <br />
              Are you sure you want to proceed?
            </>
          ),
          btnYes: 'Reject',
          theme: 'danger',
        }

    return (
      <>
        {this.props.children(this.handleShowDialog)}
        {requestedValue != null && (
          <Dialog>
            <Headline size={3}>{dialogProps.title}</Headline>
            <Question>{dialogProps.question}</Question>
            <Buttons>
              <Button theme="default" onClick={this.handleHideDialog}>
                Cancel
              </Button>
              <Button
                theme={dialogProps.theme}
                onClick={this.handleReview(approve)}
              >
                {dialogProps.btnYes}
              </Button>
            </Buttons>
          </Dialog>
        )}
      </>
    )
  }
}

const Buttons = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin: 5px;
  }
`

const Question = styled.p`
  margin: 40px 0;
`

DocumentReviewDialog.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default withDocumentReview(DocumentReviewDialog)
