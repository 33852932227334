import format from 'date-fns/format'

const styles = {
  default: 'MMM d, y',
  compact: 'dd/MM/yy',
}

export function formatDate(value, { style = 'default', format: _format } = {}) {
  if (!value) return
  if (typeof value === 'string') value = new Date(value)

  return format(value, _format || styles[style] || styles.default)
}

export function getDate(date = new Date()) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function relativeDate(date = new Date()) {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const relative = Math.floor((new Date() - date) / 1000)
  let hours = date.getHours()
  const time = `${hours}:${String(date.getMinutes()).padStart(2, '0')}`

  if (isNaN(relative)) {
    return ''
  } else if (relative < 5) {
    return 'Just now'
  } else if (relative < 60) {
    return 'Few seconds ago'
  } else if (relative < 3600 /* hour */) {
    const minute = Math.floor(relative / 60)
    return minute === 1 ? '1 minute ago' : `${minute} minutes ago`
  } else if (relative < 86400 /* day */) {
    return `Today ${time}`
  } else if (relative < 86400 * 2 /* 2 days */) {
    return `Yesterday ${time}`
  } else if (relative < 604800 /* week */) {
    const days = Math.floor(relative / 86400)
    return `${days} days ago`
  } else {
    const week = Math.floor(relative / 604800)
    return week === 1 ? '1 week ago' : `${week} weeks ago`
  }
}

export const settingsDateFormat = dateFormat => value => {
  const modifiedDateFormat = dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y')
  return format(new Date(value), modifiedDateFormat)
}
