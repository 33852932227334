import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { authUser } from 'bvdash/auth/queries'
import { ThreeDotsMenu } from 'bvdash/ui/tables'
import startConversation from 'bvdash/conversations/queries/startConversationMutation'
import projectSetFavorite from 'bvdash/projects/queries/projectSetFavoriteMutation'

export function ProgramActions({
  projectId,
  isFavorite,
  managerKey,
  projectSetFavorite,
  startConversation,

  authUser,
}) {
  return (
    <ThreeDotsMenu
      actions={[
        {
          label: 'Mark as Favorite',
          onClick: () => projectSetFavorite(projectId, true),
          hide: isFavorite,
        },
        {
          label: 'Remove from Favorites',
          onClick: () => projectSetFavorite(projectId, false),
          hide: !isFavorite,
        },
        {
          label: 'Contact PM',
          onClick: () => startConversation([managerKey]),
          hide: authUser.user && authUser.user.key === managerKey,
        },
      ]}
    />
  )
}

export default compose(
  withRouter,
  startConversation,
  projectSetFavorite,
  authUser
)(ProgramActions)
