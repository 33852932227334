import * as React from 'react'
import { Label } from 'bvdash/ui/core'

const statusStyles = {
  true: 'open',
  false: 'closed',
}

export default function ActionStatus({ value = false, inline = false }) {
  if (typeof value === 'boolean') {
    return (
      <Label inline={inline} theme={statusStyles[value]}>
        {value ? 'Open' : 'Closed'}
      </Label>
    )
  }
  return (
    <Label inline={inline} theme={value.toLowerCase()}>
      {value === 'PROGRESS' ? 'IN PROGRESS' : value}
    </Label>
  )
}
