import * as React from 'react'
import { compose } from 'react-apollo'

import {
  hoverStyle,
  SelectedProject,
  TableContainer,
  TextContainer,
  Title,
} from './styles'
import { Filters, Table, withTable, TableWrapper } from 'bvdash/ui/tables'
import { withProjectsList } from 'bvdash/queries/withProjects'
import { config } from './table'

const ProjectSelectTable = withTable(config)(props => {
  const {
    customer: { theme },
    disabled,
    display,
    onClick,
    onSelect,
    projects: { isLoading, onFetchMore },
    selectKey,
    table,
    value,
  } = props

  const selectRow = {
    hoverStyle,
    onSelect,
  }

  if (isLoading) return null

  const keySelector = row => row[selectKey]
  const selectedProject = table.data.find(row => {
    return keySelector(row) === value
  })

  return display || selectedProject == null ? (
    <>
      <Filters table={table} withSearch />
      <TableContainer>
        <TableWrapper>
          <Table
            fetchMore={onFetchMore}
            loading={isLoading}
            paddingBottom={1}
            renderProps={{ selectRow }}
            selectKey={selectKey}
            table={table}
            theme={theme}
          />
        </TableWrapper>
      </TableContainer>
    </>
  ) : (
    <SelectedProject disabled={disabled} onClick={onClick} type="button">
      <TextContainer>
        <Title>Project ID:</Title> {selectedProject.key}
      </TextContainer>
      <TextContainer>
        <Title>Project Name:</Title> {selectedProject.name} <Title>▾</Title>
      </TextContainer>
    </SelectedProject>
  )
})

export default compose(withProjectsList)(ProjectSelectTable)
