import gql from 'graphql-tag'

export const AttachmentFragment = gql`
  fragment Attachment on AttachmentType {
    id
    name
    url
    preview
    deleted
  }
`
