import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { filter, pick } from 'ramda'

import { enumChoices, enumValue } from 'bvdash/utils/graphql'
import { maybeEvolve } from 'bvdash/utils'
import { assignedToChoices } from 'bvdash/projects/dataTransform'
import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { EnumValueFragment } from 'bvdash/core/queries'

const DocumentFragment = gql`
  fragment Document on DocumentType {
    id
    documentId
    description
    category
    status
    canEdit
    dateCreated
    author {
      id
      key
      fullName
    }
    approvedBy {
      id
      key
      fullName
    }
  }
`

const TimelineDocumentEventFragment = gql`
  fragment TimelineDocumentEvent on DocumentEventType {
    id
    type
    category
    author {
      id
      fullName
    }
    approver {
      id
      fullName
    }
    date
  }
`

const pickDocumentInputFields = pick([
  'description',
  'category',
  'attachments',
  'approvedById',
])

const normalizeDocument = maybeEvolve({
  attachments: filter(attachment => !attachment.deleted),
  category: enumValue,
})

const documentQuery = gql`
  query document($project: String!, $documentId: Int!) {
    document(project: $project, documentId: $documentId) {
      ...Document
      attachments {
        ...Attachment
      }
      events {
        ...TimelineDocumentEvent
      }
    }
  }

  ${DocumentFragment}
  ${TimelineDocumentEventFragment}
  ${AttachmentFragment}
`

export const withDocument = graphql(documentQuery, {
  options: props => ({
    variables: {
      project: props.projectKey,
      documentId: props.documentId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => ({
    document: {
      isLoading: data.loading,
      document: normalizeDocument(data.document),
      projectKey: data.variables.project,
      documentId: data.variables.documentId,
    },
  }),
})

export const withDocuments = graphql(
  gql`
    query documents($project: String!) {
      documents(project: $project) {
        ...Document
        attachments {
          id
        }
      }
    }

    ${DocumentFragment}
  `,
  {
    options: props => ({
      variables: { project: props.projectKey || props.match.params.projectKey },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => {
      const { documents = [] } = data

      return {
        documents: {
          isLoading: data.loading,
          projectKey: data.variables.project,
          documents,
        },
      }
    },
  }
)

export const withDocumentFieldsChoices = graphql(
  gql`
    query documentFieldsChoices($project: String!) {
      users(project: $project) {
        id
        key
        fullName
      }

      category: __type(name: "DocumentCategory") {
        ...EnumValues
      }

      status: __type(name: "DocumentStatus") {
        ...EnumValues
      }
    }

    ${EnumValueFragment}
  `,
  {
    options: props => ({
      variables: { project: props.projectKey || props.match.params.projectKey },
    }),
    props: ({ data }) => ({
      documentFieldsChoices: {
        isLoading: data.loading,
        category: enumChoices(data.category),
        status: enumChoices(data.status),
        users: assignedToChoices(data.users),
      },
    }),
  }
)

export const withDocumentCreate = graphql(
  gql`
    mutation documentCreate(
      $project: String!
      $document: DocumentCreateInput!
    ) {
      documentCreate(project: $project, document: $document) {
        ok
        document {
          id
          documentId
        }
      }
    }
  `,
  {
    props: ({ mutate, ownProps }) => ({
      documentCreate: (project, documentInput) => {
        return mutate({
          variables: {
            document: pickDocumentInputFields(documentInput),
            project,
          },
        })
      },
    }),
  }
)

export const withDocumentEdit = graphql(
  gql`
    mutation documentEdit($id: ID!, $document: DocumentEditInput!) {
      documentEdit(id: $id, document: $document) {
        ok
        error
        document {
          ...Document
          attachments {
            ...Attachment
          }
        }
      }
    }

    ${DocumentFragment}
    ${AttachmentFragment}
  `,
  {
    props: ({ mutate }) => ({
      documentEdit(id, documentInput, queryVariables) {
        return mutate({
          variables: {
            document: pickDocumentInputFields(documentInput),
            id,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: documentQuery,
              variables: queryVariables,
            },
          ],
        })
      },
    }),
  }
)

export const withDocumentReview = graphql(
  gql`
    mutation documentReview($id: ID!, $approve: Boolean!) {
      documentReview(id: $id, approve: $approve) {
        ok
        error
        document {
          id
          status
          events {
            ...TimelineDocumentEvent
          }
        }
      }
    }

    ${TimelineDocumentEventFragment}
  `,
  {
    props: ({ mutate }) => ({
      documentReview: (id, approve) => {
        return mutate({
          variables: {
            id,
            approve,
          },
        })
      },
    }),
  }
)
