import * as React from 'react'

import { VersionBox, VersionDate, VersionLabel } from './styles'

import { DateComponent } from 'bvdash/components/DateComponent'

export const VersionInfo = props => {
  const { publishButton, version } = props

  return (
    <VersionBox horizontal align="space-between" alignCross="center">
      <span>
        <VersionLabel>Data Date:</VersionLabel>{' '}
        <VersionDate>
          <DateComponent date={version.date} />
        </VersionDate>
      </span>
      {publishButton}
    </VersionBox>
  )
}
