import * as React from 'react'

import { CenterPage } from 'bvdash/components'
import { DescriptionText, MainText, SceneImage } from './styles'

import Error400Image from 'bvdash-core/artwork/Error404New.png'

const Error400 = () => {
  return (
    <CenterPage>
      <SceneImage src={Error400Image} alt="not found" />
      <MainText>Whoops, something went wrong!</MainText>
      <DescriptionText>
        Please contact your administrator if you see this message in error, or
        report it to support@bvdash.com
      </DescriptionText>
    </CenterPage>
  )
}

export default Error400
