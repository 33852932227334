import { pick } from 'ramda'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { addToCache } from 'bvdash/utils/graphql'
import { mapCodes } from 'bvdash/projects/utils'
import { deserializeBudgetItem } from 'bvdash/projects/serializers'

const budgetCodesQuery = gql`
  query budgetCodes {
    budgetCodes {
      id
      key
      value
      description
      deleted
      __typename
    }
  }
`

export const withBudgetCodes = graphql(budgetCodesQuery, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => ({
    data: (data.budgetCodes || []).filter(budgetCode => !budgetCode.deleted),
    isLoading: data.loading,
  }),
})

export const withBudgetCode = graphql(
  gql`
    query budgetCode($key: String) {
      budgetCodes(key: $key) {
        id
        key
        value
        description
      }
    }
  `,
  {
    options: props => ({
      variables: {
        key: props.codeKey,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => props.codeKey == null,
    props: ({ data }) => {
      const { budgetCodes = [] } = data
      return {
        budgetCode: {
          isLoading: data.loading,
          key: data.variables.key,
          values: budgetCodes.map(pick(['id', 'value', 'description'])),
        },
      }
    },
  }
)

export const withBudgetCodeCreate = graphql(
  gql`
    mutation budgetCodeCreate($budgetCode: BudgetCodeInput!) {
      budgetCodeCreate(budgetCode: $budgetCode) {
        ok
        error
        budgetCode {
          id
          key
          value
          description
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetCodeCreate: budgetCode => {
        return mutate({ variables: { budgetCode } })
      },
    }),
  }
)

export const withBudgetCodeUpdate = graphql(
  gql`
    mutation budgetCodeUpdate($id: ID!, $budgetCode: BudgetCodeInput!) {
      budgetCodeUpdate(id: $id, budgetCode: $budgetCode) {
        ok
        budgetCode {
          id
          key
          value
          description
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetCodeUpdate: (id, budgetCode) => {
        return mutate({ variables: { id, budgetCode } })
      },
    }),
  }
)

export const withBudgetCodeRemove = graphql(
  gql`
    mutation budgetCodeRemove($id: ID!) {
      budgetCodeRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetCodeRemove: id => {
        return mutate({
          variables: { id },
          update: proxy =>
            proxy.writeFragment({
              id: `BudgetCodeType:${id}`,
              fragment: gql`
                fragment DeleteBudgetCode on BudgetCodeType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'BudgetCodeType',
              },
            }),
        })
      },
    }),
  }
)

const budgetFieldsQuery = gql`
  query budgetFields {
    budgetFields {
      id
      key
      label
      internal
      deleted
      __typename
    }
  }
`

export const withBudgetFields = graphql(budgetFieldsQuery, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => {
    const all = (data.budgetFields || []).filter(
      budgetField => !budgetField.deleted
    )

    return {
      budgetFields: {
        all,
        internal: all.filter(field => field.internal != null),
        custom: all.filter(field => field.internal == null),
        isLoading: data.loading,
      },
    }
  },
})

export const withBudgetFieldCreate = graphql(
  gql`
    mutation budgetFieldCreate($budgetField: BudgetFieldInput!) {
      budgetFieldCreate(budgetField: $budgetField) {
        ok
        budgetField {
          id
          key
          label
          internal
          deleted
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetFieldCreate: ({ budgetField }) => {
        return mutate({
          variables: { budgetField },
          update: (proxy, { data: { budgetFieldCreate } }) => {
            if (!budgetFieldCreate.ok) return

            addToCache(
              proxy,
              {
                query: budgetFieldsQuery,
              },
              {
                budgetFields: prevBudgetFields => [
                  budgetFieldCreate.budgetField,
                  ...prevBudgetFields,
                ],
              }
            )
          },
        })
      },
    }),
  }
)

export const withBudgetFieldUpdate = graphql(
  gql`
    mutation budgetFieldUpdate($id: ID!, $budgetField: BudgetFieldInput!) {
      budgetFieldUpdate(id: $id, budgetField: $budgetField) {
        ok
        budgetField {
          id
          key
          label
          internal
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetFieldUpdate: ({ id, budgetField }) => {
        return mutate({ variables: { id, budgetField } })
      },
    }),
  }
)

/**
 * Budget Field - Remove
 */

export const withBudgetFieldRemove = graphql(
  gql`
    mutation budgetFieldRemove($id: ID!) {
      budgetFieldRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetFieldRemove: id => {
        return mutate({
          variables: { id },
          update: proxy =>
            proxy.writeFragment({
              id: `BudgetFieldType:${id}`,
              fragment: gql`
                fragment DeleteBudgetField on BudgetFieldType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'BudgetFieldType',
              },
            }),
        })
      },
    }),
  }
)

/**
 * Budget Codes Group
 */

export const withBudgetCodesGroup = graphql(
  gql`
    query budgetCodesGroup {
      budgetCodesGroup {
        key
        values {
          value
          label: description
        }
      }
    }
  `,
  {
    options: {
      fetchPolicy: 'network-only',
    },
    props: ({ data }) => ({
      budgetCodesGroup: {
        isLoading: data.loading,
        codes: data.budgetCodesGroup || [],
        codeGroups: mapCodes(data.budgetCodesGroup, 'values'),
      },
    }),
  }
)

/**
 * Budget Data - Preview
 */

const BudgetItemFragment = gql`
  fragment BudgetItem on BudgetItemType {
    id
    key
    original
    actual
    eac
    variance
    overBudget
    deleted

    project {
      id
      key
    }

    codes {
      id
      key
      value
      description
    }

    fields {
      key
      value
    }
  }
`

const budgetPreviewQuery = gql`
  query budgetPreview($version: Int, $programId: ID, $project: String) {
    budget(
      programId: $programId
      version: $version
      published: false
      project: $project
    ) {
      ...BudgetItem
    }
  }

  ${BudgetItemFragment}
`

export const withBudgetPreview = graphql(budgetPreviewQuery, {
  options: props => {
    const { program, project, versionId } = props
    return {
      fetchPolicy: 'network-only',
      variables: {
        version: versionId,
        programId: program.id,
        project,
      },
    }
  },
  props: ({ data }) => {
    const budget = (data.budget || [])
      .filter(item => !item.deleted)
      .map(item => deserializeBudgetItem(item, false))

    return {
      budgetPreview: {
        isLoading: data.loading,
        budget,
      },
    }
  },
})

/**
 * Budget Data - Get item by ID
 */

export const withBudgetItem = graphql(
  gql`
    query budgetItem($id: ID!) {
      budgetItem(id: $id) {
        ...BudgetItem
      }
    }

    ${BudgetItemFragment}
  `,
  {
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => props.match.params.id == null,
    props: ({ data }) => ({
      budgetItem: {
        isLoading: data.loading,
        item: data.budgetItem,
      },
    }),
  }
)

/**
 * Budget Data - Create Item
 */

export const withBudgetItemCreate = graphql(
  gql`
    mutation budgetItemCreate(
      $projectId: ID!
      $versionId: ID!
      $key: String!
      $budgetItem: BudgetItemInput!
    ) {
      budgetItemCreate(
        projectId: $projectId
        versionId: $versionId
        key: $key
        budgetItem: $budgetItem
      ) {
        ok
        budgetItem {
          ...BudgetItem
        }
      }
    }

    ${BudgetItemFragment}
  `,
  {
    props: ({ mutate }) => ({
      budgetItemCreate: ({ projectId, versionId, key, budgetItem }) => {
        return mutate({
          variables: { projectId, versionId, key, budgetItem },
          update: (proxy, { data: { budgetItemCreate } }) => {
            if (!budgetItemCreate.ok) return

            addToCache(
              proxy,
              {
                query: withBudgetPreview,
                variables: {
                  versionId,
                },
              },
              {
                budget: prevItems => [
                  budgetItemCreate.budgetItem,
                  ...prevItems,
                ],
              }
            )
          },
        })
      },
    }),
  }
)

/**
 * Budget Data - Update Item
 */

export const withBudgetItemUpdate = graphql(
  gql`
    mutation budgetItemUpdate($id: ID!, $budgetItem: BudgetItemInput!) {
      budgetItemUpdate(id: $id, budgetItem: $budgetItem) {
        ok
        budgetItem {
          ...BudgetItem
        }
      }
    }

    ${BudgetItemFragment}
  `,
  {
    props: ({ mutate }) => ({
      budgetItemUpdate: ({ id, budgetItem }) => {
        return mutate({
          variables: { id, budgetItem },
        })
      },
    }),
  }
)

/**
 * Budget Data - Remove item
 */

export const withBudgetItemRemove = graphql(
  gql`
    mutation budgetDataRemove($id: ID!) {
      budgetItemRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      budgetItemRemove: id =>
        mutate({
          variables: { id },
          optimisticResponse: {
            budgetItemRemove: {
              __typename: 'BudgetItemRemove',
              ok: true,
            },
          },
          update: (proxy, response) => {
            const {
              data: {
                budgetItemRemove: { ok: deleted },
              },
            } = response

            proxy.writeFragment({
              id: `BudgetItemType:${id}`,
              fragment: gql`
                fragment DeleteBudgetType on BudgetItemType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted,
                __typename: 'BudgetItemType',
              },
            })
          },
        }),
    }),
  }
)
