import { graphql } from 'react-apollo'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import query from './startConversationMutation.graphql'
import listConversationsQuery from './listConversations.graphql'

export default graphql(query, {
  props: ({ mutate, ownProps }) => ({
    startConversation(usersKeys) {
      return mutate({
        variables: { users: usersKeys },
        update(store, result) {
          const {
            data: {
              conversationsStart: { conversation },
            },
          } = result

          if (!conversation) return

          const data = store.readQuery({ query: listConversationsQuery })
          data.conversations.push(conversation)
          store.writeQuery({ query: listConversationsQuery, data: data })

          ownProps.history.push(
            reverse(routes.conversations.detail, {
              conversationId: conversation.id,
            })
          )
        },
      })
    },
  }),
})
