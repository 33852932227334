import * as React from 'react'

import { LabelText } from 'bvdash/components'

export const customColumn = (labelProps, columnProps) => {
  const { plural, prefix, suffix, theme, type } = labelProps

  const { attr, render, value } = columnProps

  return {
    attr,
    label: (
      <LabelText
        plural={plural}
        prefix={prefix}
        suffix={suffix}
        type={type}
        theme={theme}
      />
    ),
    render,
    value,
  }
}
