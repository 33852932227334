import gql from 'graphql-tag'
import { union } from 'ramda'
import { graphql } from 'react-apollo'

import { mapCodes, unique } from 'bvdash/projects/utils'
import { getVersionDate } from 'bvdash/urls'
import { deserializeProjectForTable } from 'bvdash/projects/serializers'

const ProjectListFragment = gql`
  fragment ProjectListFragment on ProjectType {
    id
    key
    name
    isFavorite
    overBudget
    behindSchedule

    manager {
      id
      key
      fullName
    }

    codes {
      id
      key
      value
      description
    }

    fields {
      key
      value
    }

    team {
      id
    }
  }
`

// This query is extremely inefficient. It's getting codes and fields multiple times
// but we'd need to remake the table to refactor it.
const projectsQuery = gql`
  query projectsList($version: Int, $programId: ID, $first: Int, $offset: Int) {
    projects(
      version: $version
      programId: $programId
      first: $first
      offset: $offset
    ) {
      ...ProjectListFragment
    }

    codes: programCodesGroup {
      key
      values {
        id
        value
        description
      }
    }

    fields: projectFields {
      id
      key
      label
    }
  }
  ${ProjectListFragment}
`

export const projectsFavoriteQuery = gql`
  query projectsFavoriteList(
    $version: Int
    $programId: ID
    $first: Int
    $offset: Int
  ) {
    projects(
      version: $version
      programId: $programId
      first: $first
      offset: $offset
      favorite: true
    ) {
      ...ProjectListFragment
    }

    codes: programCodesGroup {
      key
      values {
        id
        value
        description
      }
    }

    fields: projectFields {
      id
      key
      label
    }
  }
  ${ProjectListFragment}
`
const FIRST = 100
const OFFSET = 0

const getQueryOptions = props => {
  const { location, programKey } = props

  let variables = {}
  const versionDate = getVersionDate(location)

  if (versionDate) {
    variables.version = versionDate
  }

  return {
    /*
    Temporary fix until we come up with a better solution for loading the tables
    This makes a lot more requests than we want but when we get it from the
      cache after it's loaded it gets them all at once which defeats the purpose
      of pagination.
    Will sort it out after the Cox Media demo.
    */
    fetchPolicy: 'network-only',
    variables: {
      first: FIRST,
      offset: OFFSET,
      program: programKey,
      ...variables,
    },
  }
}

const getManagers = projects =>
  unique(projects, ({ manager }) => [manager.key, manager])

const prepareData = ({ data }) => {
  const { fetchMore, fields = [], loading } = data
  const codes = mapCodes(data.codes, 'values')

  const list = data.projects || []
  const projects = list.map(project => deserializeProjectForTable(project))

  const managers = getManagers(projects)

  const onFetchMore = () =>
    fetchMore({
      variables: { offset: projects.length },
      updateQuery: (prev, args) => {
        const { fetchMoreResult } = args
        if (fetchMoreResult.projects.length === 0) {
          return { size: fetchMoreResult.projects.length, ...prev }
        }

        return {
          ...prev,
          projects: union(prev.projects, fetchMoreResult.projects),
          codes: union(prev.codes, fetchMoreResult.codes),
        }
      },
    })

  const allowFetchMore = projects.length % FIRST !== 0

  return {
    projects: {
      isLoading: loading,
      projects,
      codes,
      fields,
      managers,
      onFetchMore: allowFetchMore ? null : () => onFetchMore(),
    },
  }
}

export const withProjectsList = graphql(projectsQuery, {
  options: getQueryOptions,
  props: prepareData,
})

export const withProjectsFavoriteList = graphql(projectsFavoriteQuery, {
  options: getQueryOptions,
  props: prepareData,
})
