import * as React from 'react'
import { cx } from 'react-emotion'

import * as a11y from 'bvdash/utils/a11y'
import styles from './Placeholder.scss'

export default function makePlaceholder({ imageSet }) {
  return function Placeholder({ children, onClick }) {
    return (
      <div
        className={cx(styles.root, { [styles.clickable]: onClick })}
        {...a11y.clickable(onClick)}
      >
        <img
          src={imageSet[1]}
          srcSet={`${imageSet[2]} 2x, ${imageSet[3]} 3x`}
          className={styles.icon}
          alt=""
        />
        {children}
      </div>
    )
  }
}
