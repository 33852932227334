import { map, values, prop } from 'ramda'

import { customColumn, search } from 'bvdash/ui/tables'
import {
  budgetStatus,
  codes,
  fields,
  scheduleStatus,
} from 'bvdash/projects/columns'

const projectId = onSelect => ({
  attr: 'key',
  label: 'ID',
  render: ({ value }) => value,
  shrink: true,
})

const projectName = (onSelect, theme) =>
  customColumn(
    {
      suffix: 'name',
      theme,
      type: 'project',
    },
    {
      attr: 'name',
      render: ({ value }) => value,
    }
  )

const projectManagerName = (onSelect, theme) =>
  customColumn(
    {
      suffix: 'manager',
      theme,
      type: 'project',
    },
    {
      attr: 'name',
      value: project => project.manager.fullName,
      render: ({ value }) => value,
    }
  )

export const config = {
  name: 'projectListSelectTask',
  data: props => props.projects.projects,
  rowKey: row => row.id,
  selectRow: props => props.selectRow,
  search: value => project =>
    search.apply(this, [
      value,
      project.key,
      project.name,
      project.manager.fullName,
      ...values(map(prop('description'), project.codes)),
      ...Object.values(project.fields),
    ]),
  columns: props => {
    const {
      customer: { theme },
      onSelect,
    } = props

    return [
      projectId(onSelect),
      projectName(onSelect, theme),
      ...fields(props.projects.fields),
      projectManagerName(onSelect, theme),
      ...codes(props.projects.codes),
      budgetStatus({ title: '' }),
      scheduleStatus({ title: '' }),
    ]
  },
}
