import { graphql } from 'react-apollo'
import mutation from './projectSetFavoriteMutation.graphql'

import { projectsFavoriteQuery as query } from 'bvdash/queries/withProjects'

export default graphql(mutation, {
  props: ({ mutate }) => ({
    projectSetFavorite: (projectId, favorite) =>
      mutate({
        variables: { projectId, favorite },

        // mutation updates objects (ProjectType), but we need to add or
        // remove object from a collection (projectsFavorite) manually
        update: (
          proxy,
          {
            data: {
              projectsSetFavorite: { project },
            },
          }
        ) => {
          let data
          try {
            data = proxy.readQuery({ query })
          } catch (e) {
            return
          }

          if (project.isFavorite) {
            data.projectsFavorite.push(project)
          } else {
            data.projectsFavorite = data.projectsFavorite.filter(
              ({ id }) => project.id !== id
            )
          }
          proxy.writeQuery({ query, data })
        },
      }),
  }),
})
