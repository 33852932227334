import { graphql } from 'react-apollo'
import mutation from './projectSetStatus.graphql'

export default graphql(mutation, {
  props: ({ mutate }) => ({
    projectSetStatus: (project, status) =>
      mutate({
        variables: { project, status },
      }),
  }),
})
