import * as React from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'

import { Headline, Tooltip } from 'bvdash/ui/core'
import { Info } from 'bvdash-core/icons'
import styles from './Section.scss'

const Section = ({
  description,
  title,
  align = 'center',
  titleTooltip,
  children,
}) => (
  <div className={styles.section}>
    {title && (
      <div className={styles.header}>
        <Headline level={3}>{title}</Headline>
        {titleTooltip && (
          <Tooltip text={titleTooltip}>
            <Info />
          </Tooltip>
        )}
      </div>
    )}
    <div className={cx(styles.body, `${styles[align]}`)}>
      <div className={styles.number}>{children}</div>
      {description && <p className={styles.description}>{description}</p>}
    </div>
  </div>
)

Section.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  align: PropTypes.oneOf(['center', 'start', 'end']),
  children: PropTypes.node,
}

export default Section
