import styled, { css } from 'react-emotion'
import {
  color,
  colorMap,
  headerHeight,
  opacityMap,
  shadow,
  spaceBetween,
} from 'bvdash/styles'

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ space }) => space && spaceBetween(5)};
`

export const CenterContainer = styled.div`
  text-align: center;
  width: 100%;
`

export const CenterFullPage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(80vh - ${headerHeight}px);
  width: 100%;
`

export const horizontalPadding = css`
  padding-left: 24px;
  padding-right: 24px;
`

export const verticalPadding = css`
  padding-bottom: 24px;
  padding-top: 24px;
`

export const bottomRadius = css`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

export const topRadius = css`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const Card = styled.div`
  ${shadow};
  ${({ bottom }) => (bottom ? bottomRadius : null)};
  ${({ top }) => (top ? topRadius : null)};
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 1};
`

Card.defaultProps = {
  bottom: true,
  top: true,
}

export const TextAreaLabel = styled.p`
  ${color.dodgerBlue};
  ${horizontalPadding};
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  padding-top: 24px;
`
export const setColor = type => {
  switch (type) {
    case 'error':
      return colorMap.carnation
    case 'standard':
      return colorMap.dodgerBlue
    case 'success':
      return colorMap.wasabi
    case 'warning':
      return colorMap.neonCarrot
    default:
      return colorMap.solidWhite
  }
}

export const StatusLabel = styled.div`
  align-items: center;
  background: ${({ mapFunction = setColor, type }) =>
    `${mapFunction(type)}${opacityMap.thirty}`};
  border-radius: 3px;
  color: ${({ mapFunction = setColor, type }) => mapFunction(type)};
  display: flex;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
`
