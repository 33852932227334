import * as React from 'react'

import Form from './Form/Form'

import routes from 'bvdash/routes'
import { withUserCreate } from 'bvdash/admin/users/queries'

import { ADD_PROJECT } from 'bvdash/admin/setup/constants'

export const UserAdd = withUserCreate(props => {
  const {
    canAddMoreUsers,
    history,
    notificationClose,
    notificationOpen,
    setNotificationContent,
    titles,
    userCreate,

    setCurrentModal,
    handleModalOpen,

    customer: {
      customer: { onboarding },
    },

    setCurrentModalProps,
    setSetupState,
  } = props

  if (!canAddMoreUsers) {
    setCurrentModal('noMoreUsers')
    handleModalOpen()
    return null
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    confirmEmail: '',
    jobTitle: '',
    isAdmin: false,
  }

  const handleSubmit = user => userCreate(user)

  const handleNextStep = () => {
    setSetupState(ADD_PROJECT)
    setCurrentModalProps({ setupState: ADD_PROJECT })
    handleModalOpen()
    return history.push(routes.admin.projectAddItem)
  }

  const handleCancel = () => history.goBack()

  return (
    <Form
      handleCancel={handleCancel}
      handleNextStep={handleNextStep}
      initialValues={initialValues}
      onSubmit={canAddMoreUsers ? handleSubmit : null}
      titles={titles}
      title="Users / Add Users"
      notificationClose={notificationClose}
      notificationOpen={notificationOpen}
      onboarding={onboarding}
      setNotificationContent={setNotificationContent}
    />
  )
})
