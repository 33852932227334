import * as React from 'react'
import styled from 'react-emotion'

import ConfirmDialog from 'bvdash/ui/core/ConfirmDialog'
import Headline from 'bvdash/ui/core/Headline/Headline'

import { Button } from 'bvdash/components/buttons'

const ConfirmDialogDefault = props => {
  const { btnNo, btnYes, theme, title } = props
  return (
    <ConfirmDialog
      dialog={({ hide, confirm }) => (
        <>
          <Headline size={3}>{title}</Headline>
          <Question>{props.question}</Question>
          <Buttons>
            <Button onClick={hide}>{btnNo}</Button>
            <Button color={theme} onClick={confirm}>
              {btnYes}
            </Button>
          </Buttons>
        </>
      )}
    >
      {confirm => props.children(confirm)}
    </ConfirmDialog>
  )
}

export default ConfirmDialogDefault

ConfirmDialogDefault.defaultProps = {
  btnYes: 'Confirm',
  btnNo: 'Cancel',
  theme: 'primary',
}

const Buttons = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin: 5px;
  }
`

const Question = styled.p`
  margin: 40px 0;
  line-height: 1.5;
`
