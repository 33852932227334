import gql from 'graphql-tag'
import { union } from 'ramda'
import { graphql } from 'react-apollo'

import { enumChoices } from 'bvdash/utils/graphql'
import { unique } from 'bvdash/projects/utils'
import { IssueFragment } from './issueFragment'

const projectIssuesQuery = gql`
  query projectIssues($project: String!, $first: Int, $offset: Int) {
    issues(first: $first, offset: $offset, project: $project) {
      ...Issue
      actionsCount
      attachments {
        id
      }
    }

    urgencyChoices: __type(name: "IssueUrgency") {
      enumValues {
        name
        description
      }
    }

    importanceChoices: __type(name: "IssueImportance") {
      enumValues {
        name
        description
      }
    }
  }
  ${IssueFragment}
`

const FIRST = 100
const OFFSET = 0

export const withProjectIssues = graphql(projectIssuesQuery, {
  options: props => ({
    variables: {
      offset: OFFSET,
      first: FIRST,
      project: props.match.params.projectKey,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => {
    const { fetchMore, issues = [], urgencyChoices, importanceChoices } = data

    const assignees = unique(issues, ({ assignedTo }) => [
      assignedTo.key,
      assignedTo,
    ])

    const onFetchMore = () =>
      fetchMore({
        variables: { offset: issues.length },
        updateQuery: (prev, args) => {
          const { fetchMoreResult } = args
          if (fetchMoreResult.issues.length === 0) {
            return { size: fetchMoreResult.issues.length, ...prev }
          }

          return {
            ...prev,
            issues: union(prev.issues, fetchMoreResult.issues),
          }
        },
      })

    const allowFetchMore = issues.length % FIRST !== 0

    return {
      issues: {
        assignees,
        importanceChoices: enumChoices(importanceChoices),
        isLoading: data.loading,
        issues,
        onFetchMore: allowFetchMore ? null : () => onFetchMore(),
        urgencyChoices: enumChoices(urgencyChoices),
      },
    }
  },
})
