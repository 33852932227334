import { withApollo, graphql, compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { wsClient } from 'bvdash/apollo'

import query from './logout.graphql'

const logout = graphql(query, {
  props: ({ mutate, ownProps }) => ({
    logout: async () => {
      const {
        data: { authLogout },
      } = await mutate()

      localStorage.removeItem('activeProject')

      if (authLogout.ok) {
        wsClient.close()
        // clearStore is merged in apollo-client, but not released yet
        // ownProps.client.clearStore()
        // ownProps.history.push('/login')
        //
        // for now, refresh the browser instead
        window.location.href = '/login'
      }

      return authLogout
    },
  }),
})

export default compose(
  withRouter,
  withApollo,
  logout
)
