import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { filter, map, evolve } from 'ramda'

import { ActionFragment } from 'bvdash/queries/actions/actionFragment'
import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { IssueFragment } from './issueFragment'
import { TimelineEventFragment } from './timelineEventFragment'

import { enumValue } from 'bvdash/utils/graphql'
import { maybeEvolve } from 'bvdash/utils'

export const projectIssueQuery = gql`
  query projectIssue($project: String!, $issueId: Int!) {
    issue(project: $project, issueId: $issueId) {
      ...Issue

      attachments {
        ...Attachment
      }

      events {
        ...TimelineEvent
      }

      actions {
        ...Action
      }

      updates {
        id
        canEdit
        content
        dateCreated
        author {
          id
          fullName
        }
      }
    }
  }
  ${ActionFragment}
  ${AttachmentFragment}
  ${IssueFragment}
  ${TimelineEventFragment}
`

const normalizeIssue = maybeEvolve({
  attachments: filter(attachment => !attachment.deleted),
  urgency: enumValue,
  importance: enumValue,
  actions: map(
    evolve({
      urgency: enumValue,
      importance: enumValue,
    })
  ),
})

export const withProjectIssue = graphql(projectIssueQuery, {
  options: props => ({
    variables: {
      project: props.projectKey || props.match.params.projectKey,
      issueId: props.issueId || props.match.params.issueId,
    },
  }),
  props: ({ data }) => {
    const { issue = {} } = data

    return {
      issue: {
        isLoading: data.loading,
        issue: normalizeIssue(issue),
        projectKey: data.variables.project,
        issueId: data.variables.issueId,
      },
    }
  },
})
