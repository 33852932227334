import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { StripeCustomerFragment } from 'bvdash/admin/account/queries/stripeCustomerFragment'
import { CustomerProfileFragment } from 'bvdash/admin/account/queries/customerProfileFragment'
import { MenuFragment } from 'bvdash/admin/settings/queries'

export const customerQuery = gql`
  query customer {
    customer {
      ...StripeCustomer
      ...CustomerProfile

      numPrograms
      numProjects
      numUsers

      status
      onboarding
      welcome

      dashboardNumberScale
      tableNumberScale
      budgetUnit
      numberFormat

      settingsMenu {
        ...Menu
      }

      theme {
        id
        siteLogo
        navTitlePortfolio
        navTitleProgram
        navTitleProjects
      }
    }
  }
  ${StripeCustomerFragment}
  ${CustomerProfileFragment}
  ${MenuFragment}
`

export const withCustomer = graphql(customerQuery, {
  props: ({ data }) => {
    const { loading, customer } = data

    return {
      customer: {
        isLoading: loading,
        customer,
        theme: getCustomerTheme(customer),
      },
    }
  },
})

export const getCustomerTheme = customer => {
  if (customer && customer.theme) {
    const {
      theme: { navTitleProgram, navTitleProjects, navTitlePortfolio },
    } = customer
    return {
      navTitleProgram: navTitleProgram || 'program',
      navTitleProjects: navTitleProjects || 'project',
      navTitlePortfolio: navTitlePortfolio || 'Portfolio',
    }
  }
}

export default withCustomer
