import { graphql } from 'react-apollo'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import mutation from './hideConversationMutation.graphql'
import listConversations from './listConversations.graphql'

export default graphql(mutation, {
  props: ({ mutate, ownProps }) => ({
    hideConversation: conversationId =>
      mutate({
        variables: { conversationId },
        update: (
          store,
          {
            data: {
              conversationsHide: { ok },
            },
          }
        ) => {
          if (!ok) return

          let { conversations } = store.readQuery({
            query: listConversations,
          })
          conversations = conversations.filter(
            conversation => conversation.id !== conversationId
          )
          store.writeQuery({
            query: listConversations,
            data: { conversations },
          })

          // redirect to first conversation
          const conversationUrl = reverse(routes.conversations.detail, {
            conversationId,
          })
          if (ownProps.location.pathname === conversationUrl) {
            ownProps.history.push(
              reverse(routes.conversations.detail, {
                conversationId: conversations[0].id,
              })
            )
          }
        },
      }),
  }),
})
