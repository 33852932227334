import * as React from 'react'
import PropTypes from 'prop-types'
import { eqProps } from 'ramda'

import { relativeDate } from 'bvdash/utils/intl'

export default class RelativeTime extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }

  static defaultProps = {
    value: new Date(),
  }

  state = {
    value: null,
    formattedValue: null,
  }

  _timeout = null

  componentDidMount() {
    this.setState(this.normalizeValue(this.props.value), () => this.tick())
  }

  componentDidUpdate(prevProps) {
    if (!eqProps('value', this.props, prevProps)) {
      this.setState(this.normalizeValue(this.props.value), () => {
        clearTimeout(this._timeout)
        this.tick()
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  normalizeValue = value => state => {
    return {
      value: typeof value === 'string' ? new Date(value) : value,
    }
  }

  getNextInterval = value => {
    const relative = Math.floor((new Date() - value) / 1000)
    const limits = [
      5, // few seconds
      60, // one minute
      3600, // one hour
      86400, // one day
      86400 * 2, // two days
      604800, // one week
    ]

    const index = limits.findIndex(limit => limit > relative)

    let next, current
    if (index === -1) {
      next = current = 365 * 86400 // one year
    } else {
      next = limits[index]
      current = limits[index - 1] || 60 // skip updates until first minute
    }

    // Next interval is either remaining seconds to next current unit
    // (e.g one minute -> two minutes)
    // or remaining seconds to next interval (e.g. 59 minutes -> 1 hour).
    // Finally, add an extra second to be safe we don't skip update.
    return Math.min(current - (relative % current), next - relative) + 1
  }

  tick = () => {
    const { value } = this.state
    if (!value) return

    this.setState({
      formattedValue: relativeDate(value),
    })
    const nextInterval = this.getNextInterval(value)
    this._timeout = setTimeout(this.tick, nextInterval)
  }

  render() {
    return this.state.formattedValue
  }
}
