/** @flow
 *
 * .. component: UploadArea
 *
 *    This component conditionally wraps children in react-dropzone based on
 *    `onUpload` props. If undefined, it just renders children, otherwise
 *    provides a dropzone.
 *
 *    Expects a function as children which receives either empty props when
 *    no upload is allowed, or `openFileDialog` prop which opens system file
 *    dialog.
 *
 *    When file is selected or dropped in dropzone, it triggers `onUpload` prop.
 *
 *    .. code-block: jsx
 *
 *       <UploadArea onUpload={onUpload}>
 *         {({ openFileDialog }) => (
 *           <ul>
 *             {files.map(file => <li>{file.name}</li>)}
 *             {openFileDialog && <li onClick={openFileDialog}>Upload</li>}
 *           </ul>
 *         )}
 *       </UploadArea>
 */

import * as React from 'react'
import { css } from 'react-emotion'
import ReactDropzone from 'react-dropzone'

import { background } from 'bvdash/styles'

const dropzoneClass = css`
  border: none;
`

const dropzoneActiveClass = css`
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
    z-index: 1;

    ${background.dodgerBlue};
    opacity: 0.3;
  }
`

type UploadBoxProps = {
  onUpload: ?Function,
  children: Function,
}

export default class UploadArea extends React.Component<UploadBoxProps> {
  dropzone = React.createRef()

  handleOpen = () => this.dropzone.current && this.dropzone.current.open()

  render() {
    if (!this.props.onUpload) {
      return this.props.children({})
    }

    return (
      <ReactDropzone
        ref={this.dropzone}
        onDrop={this.props.onUpload}
        className={dropzoneClass}
        activeClassName={dropzoneActiveClass}
        disableClick
        disablePreview
        multiple
      >
        {this.props.children({ openFileDialog: this.handleOpen })}
      </ReactDropzone>
    )
  }
}
