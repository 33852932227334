import * as React from 'react'
import { Button } from 'bvdash/ui/core'
import colors from 'bvdash/styles/variables.scss'

export default ({ label, icon: Icon, onClick }) => (
  <Button
    size="small"
    theme="header"
    icon={<Icon color={colors.cadetBlue} />}
    onClick={onClick}
  >
    {label}
  </Button>
)
