import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { reverse } from 'named-urls'
import { compose } from 'react-apollo'

import routes from 'bvdash/routes'
import { BreadcrumbsHeader } from 'bvdash/ui/core'
import * as queries from 'bvdash/projects/queries'

function ProgramBreadcrumbs({
  children,
  program: { program, programKey },
  breadcrumbs: addBreadcrumbs = [],
  hidePrint,
}) {
  if (program == null) return null

  const breadcrumbs = [
    {
      label: program.name,
      route: routes.program.dashboard,
      highlight: true,
    },
    ...addBreadcrumbs,
  ].map(({ to, route, ...props }) => ({
    ...props,
    to: to || reverse(route, { programKey }),
  }))

  return (
    <BreadcrumbsHeader hidePrint={hidePrint} breadcrumbs={breadcrumbs}>
      {children}
    </BreadcrumbsHeader>
  )
}

ProgramBreadcrumbs.propTypes = {
  children: PropTypes.any,
  program: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
  hideExport: PropTypes.bool,
}

export default compose(
  withRouter,
  queries.programDetail
)(ProgramBreadcrumbs)
