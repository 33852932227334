import * as React from 'react'

import { Select } from 'bvdash/ui/core'

// Connect table.group API to Select component
export default class GroupBySelect extends React.Component {
  handleChange = value => this.props.group.onGroup(value)

  render() {
    const { label, group, choices, multiple } = this.props

    return (
      <Select
        label={label}
        choices={choices}
        onChange={this.handleChange}
        value={group.groupBy}
        multiple={multiple}
      />
    )
  }
}
