import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { enumChoices } from 'bvdash/utils/graphql'
import { assignedToChoices } from 'bvdash/projects/dataTransform'

const projectIssueFormQuery = gql`
  query projectIssueForm($project: String!) {
    users(project: $project) {
      id
      key: id
      fullName
    }

    urgency: __type(name: "IssueUrgency") {
      enumValues {
        name
        description
      }
    }

    importance: __type(name: "IssueImportance") {
      enumValues {
        name
        description
      }
    }

    status: __type(name: "IssueStatus") {
      enumValues {
        name
        description
      }
    }
  }
`

export const withProjectIssueForm = graphql(projectIssueFormQuery, {
  options: props => ({ variables: { project: props.match.params.projectKey } }),
  props: ({ data }) => {
    const { importance, loading, status, urgency, users } = data
    return {
      issueForm: {
        assignedTo: assignedToChoices(users),
        isLoading: loading,
        importance: enumChoices(importance),
        status: enumChoices(status),
        urgency: enumChoices(urgency),
      },
    }
  },
})
