import * as React from 'react'
import { compose } from 'react-apollo'
import routes from 'bvdash/routes'
import {
  withProgramCode,
  withProgramCodeCreate,
  withProgramCodeUpdate,
  withProgramCodeRemove,
} from 'bvdash/admin/queries'
import { HelpText, CodeForm } from 'bvdash/admin/ui'
import { pluralize } from 'bvdash/utils'
import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'
import { FlexContainer } from 'bvdash/components/standard/containers'

import { codesTitle } from 'bvdash/admin/adminLinks'

export const ProjectCodeForm = props => {
  const { match, titles } = props

  return (
    <>
      <AdminBreadcrumbs>
        {pluralize(titles.project)} Setup / {titles.project} {codesTitle} / Add
        New
      </AdminBreadcrumbs>
      <FlexContainer>
        <HelpText>
          Add a new {codesTitle} to your {titles.project} and click Save when
          you’re done.
        </HelpText>
      </FlexContainer>
      <FlexContainer>
        <ProjectCodeAddEdit titles={titles} codeKey={match.params.code} />
      </FlexContainer>
    </>
  )
}

const ProjectCodeAddEdit = compose(
  withProgramCode,
  withProgramCodeCreate,
  withProgramCodeUpdate,
  withProgramCodeRemove
)(props => {
  const {
    programCode,
    programCodeCreate,
    programCodeUpdate,
    programCodeRemove,
    titles,
  } = props

  return (
    <CodeForm
      type="program"
      code={programCode}
      redirectTo={routes.admin.projectCodes}
      codeCreate={programCodeCreate}
      codeUpdate={programCodeUpdate}
      codeRemove={programCodeRemove}
      titles={titles}
    />
  )
})
