import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { ThreeDotsMenu } from 'bvdash/ui/tables'
import { ConfirmStatusChange } from 'bvdash/actions/ui'

import { authUser } from 'bvdash/auth/queries'
import { startConversation } from 'bvdash/conversations/queries'
import { withRiskSetOpen } from 'bvdash/projects/queries'

function RiskContextMenu(props) {
  const {
    id,
    riskId,
    projectKey,
    managerKey,
    assignedToKey,
    isOpen,
    canEdit,
    history,

    riskSetOpen,
    startConversation,
    authUser,
    titles,
  } = props
  return (
    <ConfirmStatusChange titles={titles} type="risk" isOpen={isOpen}>
      {confirm => (
        <ThreeDotsMenu
          actions={[
            {
              label: `Edit ${titles.risk}`,
              onClick: () =>
                history.push(
                  reverse(routes.project.riskEdit, {
                    projectKey,
                    riskId,
                  })
                ),
            },
            {
              label: `Reopen ${titles.risk}`,
              onClick: confirm(() => riskSetOpen(id, true)),
              hide: !canEdit || !isOpen,
            },
            {
              label: `Close ${titles.risk}`,
              onClick: confirm(() => riskSetOpen(id, false)),
              hide: !canEdit || isOpen,
            },
            {
              label: 'Contact Assignee',
              onClick: () => startConversation([assignedToKey]),
              hide: authUser.user && authUser.user.key === assignedToKey,
            },
            {
              label: 'Contact PM',
              onClick: () => startConversation([managerKey]),
              hide: authUser.user && authUser.user.key === managerKey,
            },
          ]}
        />
      )}
    </ConfirmStatusChange>
  )
}

export default compose(
  withRouter,
  authUser,
  withRiskSetOpen,
  startConversation
)(RiskContextMenu)
