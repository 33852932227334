import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import routes from 'bvdash/routes'

// Components
import { Modal } from 'bvdash/components/modals'
import { Highlight, StandardText } from 'bvdash/components/standard/texts'

// Queries and Mutations
import { withCustomer } from 'bvdash/queries/settings/withCustomer'
import { withStripePlans } from 'bvdash/admin/account/queries'

export const NoMoreProjectsModal = compose(
  withCustomer,
  withStripePlans,
  withRouter
)(props => {
  const {
    customer: { customer },
    closeModal,
    history,
    path,
    stripePlans: { stripePlans },
  } = props

  const currentPlanData = stripePlans.find(
    plan => plan.planId === customer.stripePlanId
  )
  const Content = (
    <>
      <StandardText>
        You have reached your project limit of{' '}
        <Highlight>{customer.numProjects}</Highlight>
      </StandardText>
      <StandardText>
        The <Highlight>{currentPlanData.name} Plan</Highlight> you are currently
        using only supports <Highlight>{currentPlanData.numProjects}</Highlight>{' '}
        projects
      </StandardText>
      <StandardText>Click below to upgrade your plan</StandardText>
    </>
  )

  const onCancel = () => {
    if (path === routes.admin.projectAddItem) {
      history.goBack()
    }
    closeModal()
  }
  const onSubmit = () => {
    history.push(routes.admin.accountSubscriptions)
    closeModal()
  }
  return (
    <Modal
      headerText="Unable to Add More Projects"
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitText="Upgrade"
      type="add"
    >
      {Content}
    </Modal>
  )
})
