import * as React from 'react'
import styled from 'styled-components'

import { passwordStrength } from 'bvdash/utils/password'

const setColor = (strength, theme) => {
  switch (strength) {
    case 4:
      return theme.color.success
    case 3:
      return theme.color.warning
    case 2:
      return theme.color.caution
    case 1:
      return theme.color.error
    default:
      return theme.color.white
  }
}

const displayResponse = strength => {
  switch (strength) {
    case 4:
      return 'strongest'
    case 3:
      return 'strong'
    case 2:
      return 'okay'
    case 1:
      return 'weak'
    default:
      return 'poor'
  }
}

const Container = styled.div`
  ${({ theme }) => theme.shadowActive};
  background: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  height: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  overflow: hidden;
`

const Internal = styled.div`
  background: ${({ strength, theme }) => setColor(strength, theme)};
  height: 100%;
  width: ${({ width }) => width}%;
`

const Label = styled.p`
  color: ${({ theme }) => theme.color.main};
  text-transform: capitalize;
`

export const PasswordMeter = props => {
  const { password } = props

  const score = passwordStrength(password)

  const width = (score * 100) / 4 || 0

  return (
    <>
      <Container>
        <Internal width={width} strength={score} />
      </Container>
      <Label>{displayResponse(score)}</Label>
    </>
  )
}
