import styled, { css } from 'react-emotion'
import { color, backgroundAlpha } from 'bvdash/styles'

export const descriptionColumn = css`
  white-space: pre-line;
  width: 40%;
`

export const descriptionLink = css`
  display: block;
  line-height: 1.5;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const LabelForStatus = styled.span`
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;

  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;

  ${({ value }) => {
    switch (value) {
      case 'false':
        return css`
          ${color.wasabi}
          ${backgroundAlpha.wasabi}
        `
      case 'true':
        return css`
          ${color.neonCarrot}
          ${backgroundAlpha.neonCarrot}
        `
      default:
        return color.solidWhite
    }
  }}
`

export const WarningText = styled.span`
  ${({ value }) => {
    switch (value) {
      case 'false':
        return css`
          ${color.neonCarrot}
        `
      case 'true':
        return css`
          ${color.carnation}
        `
      default:
        return css`
          ${color.sanJuan}
        `
    }
  }}
`
