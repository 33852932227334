import styled from 'react-emotion'

const EmptyContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  background-color: $solid-white;
`

export default EmptyContainer
