import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { cx } from 'react-emotion'

import { callAll } from 'bvdash/utils/handlers'

import styles from './ActionLink.scss'

export default function ActionLink({ action, onClick }) {
  const onClickAll = callAll(onClick, action.onClick)
  const className = cx(styles.btn, {
    [styles.disabled]: action.disabled,
    [styles.primary]: action.primary,
  })

  return action.to && !action.disabled ? (
    <Link
      className={className}
      onClick={onClickAll}
      to={action.to}
      disabled={action.disabled}
    >
      {action.label}
    </Link>
  ) : (
    <button
      type="button"
      className={className}
      onClick={action.disabled ? onClick : onClickAll}
    >
      {action.label}
    </button>
  )
}

ActionLink.propTypes = {
  children: PropTypes.any,
}
