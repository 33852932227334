import styled from 'styled-components'

import {
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  setColor,
  textAlign,
} from 'bvdash/css'

export const Text = styled.p`
  ${marginBottom};
  ${marginTop};
  ${marginLeft};
  ${marginRight};
  ${textAlign};
  color: ${({ color, defaultColor, theme }) =>
    color != null ? setColor(color, theme) : theme.color[defaultColor]};
  font-size: ${({ defaultFontSize, fontSize }) =>
    fontSize >= 0 ? fontSize : defaultFontSize}px;
  font-weight: ${({ fontWeight }) => (fontWeight >= 0 ? fontWeight : 'normal')};
  line-height: ${({ lineHeight }) => lineHeight || 1.5}em;
`
export const Highlight = styled.span`
  color: ${({ color, theme }) =>
    color ? theme.color[color] : theme.color.accent};
  font-weight: 500;
`

export const TinyText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 10,
}))``

export const SmallText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 12,
}))``

export const StandardText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 14,
}))``

export const MediumText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 16,
}))``

export const RegularText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 18,
}))``

export const LargeText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 20,
}))`
  font-weight: 500;
`

export const LargeHeaderText = styled(Text).attrs(() => ({
  defaultColor: 'accent',
  fontSize: 32,
  marginBottom: 32,
  textAlign: 'center',
}))`
  font-weight: 500;
  letter-spacing: 0.2px;
`
export const HeaderText = styled(Text).attrs(() => ({
  defaultColor: 'accent',
  fontSize: 24,
  marginBottom: 16,
  textAlign: 'center',
}))`
  font-weight: 500;
  letter-spacing: 0.2px;
`
export const RegularHeaderText = styled(Text).attrs(() => ({
  defaultColor: 'accent',
  fontSize: 18,
  marginBottom: 8,
  textAlign: 'center',
}))`
  font-weight: 500;
  letter-spacing: 0.1px;
`
export const StandardHeaderText = styled(Text).attrs(() => ({
  defaultColor: 'accent',
  fontSize: 14,
  marginBottom: 8,
  textAlign: 'center',
}))`
  font-weight: 500;
  letter-spacing: 0.1px;
`

export const ExtraLargeText = styled(Text).attrs(() => ({
  defaultColor: 'main',
  fontSize: 36,
}))``

export const GiantText = styled(Text).attrs(() => ({
  defaultColor: 'accent',
  fontSize: 48,
}))``

export const AdminBreadcrumbs = styled(HeaderText).attrs(() => ({
  fontSize: 18,
  textAlign: 'left',
}))`
  align-self: flex-start;
`
export const MutedText = styled(StandardText).attrs(() => ({
  color: 'muted',
}))``
