import * as React from 'react'

import { Label } from 'bvdash/ui/core'

const themeMap = {
  [true]: 'danger',
  [false]: 'success',
}

export default function BooleanLabel({ value, yes, no, unknown }) {
  return (
    <Label theme={themeMap[value]}>
      {value === null ? unknown || no : value ? yes : no}
    </Label>
  )
}
