import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { normalizeList, maybeEvolve } from 'bvdash/utils'
import { addToCache } from 'bvdash/utils/graphql'

import { versionQuery as latestVersionQuery } from 'bvdash/queries/withVersions'

const VersionFragment = gql`
  fragment VersionFragment on VersionType {
    id
    __typename

    date
    published
    deleted

    type

    author {
      id
      fullName
    }
  }
`

const versionsQuery = gql`
  query versions($program: String, $type: VersionEnum) {
    versions(kind: $type, program: $program, includeUnpublished: true) {
      ...VersionFragment
    }
  }

  ${VersionFragment}
`

const normalizeVersions = normalizeList({ date: value => new Date(value) })

export const withVersions = graphql(versionsQuery, {
  options: props => {
    const { program, type } = props
    return {
      fetchPolicy: 'cache-and-network',
      variables: {
        program: program != null ? program.key : undefined,
        type: type.toUpperCase(),
      },
    }
  },
  props: ({ data }) => ({
    versions: {
      versions: normalizeVersions(data.versions)
        .map((version, index) => ({
          ...version,
          isFirst: index === 0,
        }))
        .filter(version => !version.deleted),
      isLoading: data.loading,
    },
  }),
})

/**
 * Version - get by id
 */

const versionQuery = gql`
  query version($programKey: String, $id: ID, $kind: VersionEnum) {
    version(programKey: $programKey, id: $id, kind: $kind) {
      ...VersionFragment
    }
  }

  ${VersionFragment}
`

export const version = graphql(versionQuery, {
  options: props => {
    const { programKey, type, versionId } = props
    return {
      fetchPolicy: 'cache-and-network',
      variables: {
        programKey: programKey,
        kind: type != null && type.toUpperCase(),
        ...(versionId && { id: versionId }),
      },
    }
  },
  props: ({ data }) => {
    return {
      version: {
        version: maybeEvolve({
          date: value => new Date(value),
        })(data.version),

        isLoading: data.loading,
      },
    }
  },
})

/**
 * Version - unpublish/publish
 */

export const versionPublish = graphql(
  gql`
    mutation versionPublish($id: ID!, $published: Boolean) {
      versionsPublish(id: $id, published: $published) {
        ok
        error
        version {
          id
          published
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      versionPublish: ({ id, published }) => {
        mutate({
          variables: { id, published },
        })
      },
    }),
  }
)

/**
 * Version - Create
 */

export const versionCreate = graphql(
  gql`
    mutation versionsCreate($program: String!, $type: VersionEnum!) {
      versionsCreate(program: $program, type: $type) {
        ok
        error
        version {
          ...VersionFragment
        }
      }
    }

    ${VersionFragment}
  `,
  {
    props: ({ mutate, ownProps }) => ({
      versionCreate: ({ program }) => {
        const type = ownProps.type.toUpperCase()
        return mutate({
          variables: { program, type },
          update: (proxy, { data: { versionsCreate } }) => {
            if (!versionsCreate.ok) return

            addToCache(
              proxy,
              {
                query: versionsQuery,
                variables: {
                  program,
                  type,
                },
              },
              {
                versions: prevVersions => [
                  versionsCreate.version,
                  ...prevVersions,
                ],
              }
            )
          },
        })
      },
    }),
  }
)

export const versionRemove = graphql(
  gql`
    mutation versionRemove($id: ID!) {
      versionsRemove(id: $id) {
        ok
        error
      }
    }
  `,
  {
    props: ({ mutate, ownProps }) => ({
      versionRemove: async id => {
        const { data } = mutate({
          variables: { id },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: latestVersionQuery,
              variables: {
                kind: ownProps.type.toUpperCase(),
                programKey: ownProps.programKey,
              },
            },
          ],
          update: proxy =>
            proxy.writeFragment({
              id: `VersionType:${id}`,
              fragment: gql`
                fragment DeleteVersionType on VersionType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'VersionType',
              },
            }),
        })
        return data
      },
    }),
  }
)
