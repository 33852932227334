import * as React from 'react'
import { ConfirmDialog, Headline } from 'bvdash/ui/core'
import styled from 'react-emotion'

import { Button } from 'bvdash/components/buttons'

export default function ConfirmMissingProject({ title, question, children }) {
  return (
    <ConfirmDialog
      dialog={({ hide, confirm }) => (
        <>
          <Headline size={3}>{title}</Headline>

          <Question>{question}</Question>

          <Buttons>
            <Button onClick={hide}>Cancel</Button>
            <Button color="add" onClick={() => confirm()}>
              Save
            </Button>
          </Buttons>
        </>
      )}
    >
      {confirm => children(confirm)}
    </ConfirmDialog>
  )
}

ConfirmMissingProject.defaultProps = {
  title: 'No project selected!',
  question: 'Are you sure you want to proceed?',
}

const Buttons = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin: 5px;
  }
`

const Question = styled.p`
  margin: 40px 0;
`
