import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  button {
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
`

export const InfoContainer = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  box-shadow: 0 10px 50px 0 rgba(49, 61, 90, 0.2);
  margin-top: 10vh;
  max-height: 85vh;
  max-width: 95%;
  padding: 32px;
  width: ${({ width }) => (width >= 0 ? width : 580)}px;

  @media (max-width: 768px) {
    margin-top: 7.5vh;
    overflow: auto;
  }
`

export const Overlay = styled.div`
  align-items: flex-start;
  background: ${({ theme: { color, opacity } }) =>
    `${color.black}${opacity[30]}`};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.color.muted};
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.2px;
`
