import {
  actionBaseRoute,
  adminBaseRoute,
  alertListRoute,
  calendarBaseRoute,
  accountsBaseRoute,
  superUsersBaseRoute,
  conversationBaseRoute,
  loginRedirect,
  loginRoute,
  notFoundRoute,
  onBoardingBaseRoute,
  passwordResetRequestRoute,
  passwordResetRoute,
  programBaseDetailRoute,
  programBaseListRoute,
  projectBaseDetailRoute,
  projectBaseListRoute,
  projectListRedirect,
  timesheetBaseRoute,
  profileBaseRoute,
} from './configSetup'

const standardAuthRouteConfig = [
  // Admin routes.
  adminBaseRoute,
  // Project List
  projectBaseListRoute,
  // Project Detail
  projectBaseDetailRoute,
  // Conversations
  conversationBaseRoute,
  // User Profile
  profileBaseRoute,
  // Alerts
  alertListRoute,
]

export const standardNoAuthRouteConfig = [
  // Login
  loginRoute,
  // Password Reset Request
  passwordResetRequestRoute,
  // Password Reset
  passwordResetRoute,
  // Register
  onBoardingBaseRoute,
]

export const noAuthRouteConfig = [...standardNoAuthRouteConfig, loginRedirect]

export const freePlanRouteConfig = [
  ...standardAuthRouteConfig,
  actionBaseRoute,
  // Redirect to project list
  alertListRoute,
  projectListRedirect,
  notFoundRoute,
]

export const proBusPlanRouteConfig = [
  ...standardAuthRouteConfig,
  actionBaseRoute,
  // Redirect to project list
  programBaseDetailRoute,
  alertListRoute,
  projectListRedirect,
  notFoundRoute,
]

export const enterpriseRouteConfig = [
  ...standardAuthRouteConfig,
  // Program List
  programBaseListRoute,
  // Program Detail
  programBaseDetailRoute,
  alertListRoute,
  actionBaseRoute,
  timesheetBaseRoute,
  calendarBaseRoute,
  superUsersBaseRoute,
  accountsBaseRoute,
  // Redirect to project list
  projectListRedirect,
  notFoundRoute,
]

export const trialExpiredRouteConfig = [adminBaseRoute, notFoundRoute]
