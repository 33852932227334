export default function download(url, fileName) {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'

  //Keeping in case of future need
  //   xhr.onprogress = function(event) {
  //     if (event.lengthComputable) {
  //       var percentComplete = (event.loaded / event.total) * 100
  //       //yourShowProgressFunction(percentComplete);
  //     }
  //   }

  xhr.onload = function(event) {
    saveBlob(this.response, fileName)
  }

  xhr.send()
}

function saveBlob(response, fileName) {
  if (navigator.msSaveBlob) {
    //OK for IE10+
    navigator.msSaveBlob(response, fileName)
  } else {
    html5Saver(response, fileName)
  }
}

function html5Saver(blob, fileName) {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = fileName
  a.click()
  document.body.removeChild(a)
}
