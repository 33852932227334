import { graphql } from 'react-apollo'

import markRead from './markRead.graphql'
import listConversations from './listConversations.graphql'

export default graphql(markRead, {
  props: ({ mutate, ownProps }) => ({
    markRead: conversationId =>
      mutate({
        variables: { conversationId },
        optimisticResponse: {
          __typename: 'Mutation',
          conversationsMarkRead: {
            __typename: 'ConversationMarkAsRead',
            ok: true,
          },
        },
        update: (
          store,
          {
            data: {
              conversationsMarkRead: { ok },
            },
          }
        ) => {
          if (!ok) return

          let { conversations } = store.readQuery({
            query: listConversations,
          })
          conversations = conversations.map(conversation => {
            if (!conversationId || conversation.id === conversationId) {
              return {
                ...conversation,
                unreadMessages: 0,
              }
            } else {
              return conversation
            }
          })
          store.writeQuery({
            query: listConversations,
            data: { conversations },
          })
        },
      }),
  }),
})
