import Linkify from 'linkifyjs/react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Header, HeaderTitle } from 'bvdash/conversations/styles'

export const ListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.color.shadow};
`

export const Label = styled.label``

export const UserPhotoPlaceholder = styled.div`
  align-items: center;
  background: ${({ theme: { color, opacity } }) =>
    `${color.warning}${opacity[40]}`};
  border-radius: 100%;
  color: ${({ theme }) => theme.color.warning};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`

// Sidebar
export const SidebarWrapper = styled.div`
  ${({ expanded }) => (expanded ? 'flex: 1' : undefined)};
  overflow-y: hidden;
  max-height: 100%;
  width: 100%;

  @media (min-width: 769px) {
    max-height: 50%;
  }
`

export const SidebarHeader = styled(Header)`
  ${({ theme }) => theme.shadow};
  background: ${({ theme }) => theme.color.accent};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.main};
  }
`

export const SidebarTitle = styled(HeaderTitle)`
  color: ${({ theme }) => theme.color.white};
`

export const SidebarLink = styled(Link)`
  align-items: center;
  background: ${({ isActive, theme: { color } }) =>
    isActive ? color.whiteDark : color.white};
  border-left: 3px solid
    ${({ isActive, theme }) => (isActive ? theme.color.accent : 'transparent')};
  color: ${({ theme }) => theme.color.main};
  font-weight: ${({ isUnread }) => (isUnread ? '500' : 'normal')};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-decoration: none;

  &:hover {
    border-left: 3px solid ${({ theme }) => theme.color.accent};
  }
`

export const SidebarText = styled.p`
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
`

// Messages
const triangle = css`
  content: ' ';
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 6px;
`
export const MessageContainer = styled.div`
  display: flex;
  flex-direction: ${({ incoming }) => (!incoming ? 'row-reverse' : 'row')};
  padding-bottom: 16px;
`
export const MessageAvatar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
export const MessageAvatarText = styled.span`
  margin-top: 8px;
`
export const MessageItemContainer = styled.div`
  ${({ incoming }) => (incoming ? 'margin-left: 16px' : 'margin-right: 16px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% * 2 / 3);
`
export const MessageItem = styled.div`
  border-radius: 5px;
  display: flex;
  padding: 4px 8px;
  position: relative;

  ${({ incoming, theme }) =>
    incoming
      ? `
    background: ${`${theme.color.muted}${theme.opacity[20]}`}
    color: ${theme.color.muted};
  `
      : `
    background: ${`${theme.color.accent}${theme.opacity[20]}`}
    color: ${theme.color.accent};
  `};

  &::before {
    ${triangle};
    ${({ incoming, theme }) =>
      incoming
        ? `
      border-top-color: ${`${theme.color.muted}${theme.opacity[20]}`};
      left: 12px;
    `
        : `
      border-top-color: ${`${theme.color.accent}${theme.opacity[20]}`};
      right: 12px;
    `}
  }
`
export const MessageDate = styled.span`
  color: ${({ theme }) => theme.color.muted};
  margin-top: 16px;
  ${({ incoming }) => (incoming ? 'text-align: left' : 'text-align: right')};
`
export const MessageText = styled(Linkify)`
  line-height: 1.5em;
  overflow: auto;
`
export const MessageAttachmentsContainer = styled.div``
