import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { cx } from 'react-emotion'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import URLSearchParams from 'url-search-params'

import * as a11y from 'bvdash/utils/a11y'
import { Toggle, Dropdown } from 'bvdash/ui/core'
import { Calendar, ArrowDown, ArrowUp } from 'bvdash-core/icons'
import { color } from 'bvdash/styles'
import { DateComponent } from 'bvdash/components/DateComponent'

import variables from 'bvdash/styles/variables.scss'
import getVersions from './getVersions'
import styles from './VersionsDropdown.scss'
import { callAll } from 'bvdash/utils/handlers'

export class VersionsDropdown extends React.Component {
  static propTypes = {
    kind: PropTypes.oneOf(['project', 'schedule', 'budget']),
    versions: PropTypes.array,
    initialOpen: PropTypes.bool,
  }

  static defaultProps = {
    versions: [],
  }

  handleSelected = version => () => {
    if (this.props.onChange) {
      this.props.onChange(version.id)
      return
    }

    const { versions, location, history } = this.props

    const search = new URLSearchParams(location.search)
    // search.set('date', utcDate(version.date))
    if (version.id === versions[0].id) {
      search.delete('date')
    } else {
      search.set('date', version.id)
    }

    const searchString = search.toString()

    return history.push({ search: searchString ? `?${searchString}` : '' })
  }

  render() {
    const { initialOpen, versions, location } = this.props

    let versionId
    if (this.props.value !== undefined) {
      versionId = this.props.value
    } else {
      const search = new URLSearchParams(location.search)
      versionId = search.get('date')
    }
    const selected =
      versions.find(version => version.id === versionId) || versions[0]

    return (
      <div className={styles.root}>
        <SpanText>Status as of</SpanText>
        <Toggle initialOpen={initialOpen}>
          {toggle => (
            <div
              className={cx(styles.btn, this.props.className, {
                [styles.open]: toggle.isOpen,
              })}
            >
              <div
                {...a11y.clickable(toggle.toggle)}
                className={cx(styles.trigger)}
              >
                <Calendar color={variables.waterloo} />
                <span className={styles.value}>
                  {selected && <DateComponent date={selected.date} />}
                </span>
                <ArrowContainer>
                  {toggle.isOpen ? <ArrowUp /> : <ArrowDown />}
                </ArrowContainer>
              </div>
              <Dropdown
                className={styles.dropdown}
                withoutArrow
                isOpen={toggle.isOpen}
                onClose={toggle.close}
              >
                <ul>
                  {versions.map(version => (
                    <li
                      key={version.id}
                      className={cx(styles.item, {
                        [styles.selected]:
                          selected && version.date === selected.date,
                      })}
                      {...a11y.clickable(
                        callAll(this.handleSelected(version), toggle.toggle)
                      )}
                    >
                      <DateComponent date={version.date} />
                    </li>
                  ))}
                </ul>
              </Dropdown>
            </div>
          )}
        </Toggle>
      </div>
    )
  }
}

const ArrowContainer = styled.div`
  ${color.cadetBlue};
  padding-left: 32px;
  padding-right: 8px;
`

const SpanText = styled.span`
  ${color.darkBorder};
  display: inline-flex;
  align-items: center;
`

export default compose(
  getVersions,
  withRouter
)(VersionsDropdown)
