import * as React from 'react'
import { cx } from 'react-emotion'

import { CheckboxField, FieldGroup, FieldLabel } from 'bvdash/ui/forms'
import { ArrowDown } from 'bvdash-core/icons'

import * as a11y from 'bvdash/utils/a11y'
import { callAll } from 'bvdash/utils/handlers'

import colors from 'bvdash/styles/variables.scss'
import styles from './Select.scss'
import Toggle from '../Toggle/Toggle'
import Dropdown from '../Dropdown/Dropdown'

export default class Select extends React.Component {
  static defaultProps = {
    multiple: false,
  }

  handleChangeMultiple = e => {
    const { name: selectedValue, checked } = e.target

    if (selectedValue === '_all') {
      this.props.onChange()(e)
      return
    }

    const value = this.props.value || []

    if (checked) {
      this.props.onChange([...value, selectedValue])(e)
    } else {
      this.props.onChange(value.filter(value => value !== selectedValue))(e)
    }
  }

  render() {
    const {
      label,
      labelClass = styles.active,
      activeLabelClass = styles.inactive,
      choices,
      onChange,
      value,
      multiple,
      emptyLabel,
      emptyValue,
    } = this.props
    const isActive = value !== undefined
    const defaultClassName = cx(styles.dropdownItem, {
      [styles.dropdownItemActive]: value === undefined,
    })

    let activeLabel
    if (!onChange) {
      activeLabel = value
    } else {
      if (multiple) {
        const activeItems = Array.isArray(value)
          ? choices.filter(choice => value.includes(choice.value))
          : []
        activeLabel = activeItems.length
          ? `${activeItems[0].label}${activeItems.length > 1 ? ' +' : ''}`
          : emptyLabel
      } else {
        const activeItem = choices.find(choice => value === choice.value) || {}
        activeLabel = activeItem.label || emptyLabel
      }
    }

    const labelWithValue = (
      <>
        <FieldLabel>{label}:</FieldLabel>
        <span
          className={cx(isActive ? labelClass : activeLabelClass, {
            [styles.emptyLabel]: !activeLabel,
          })}
        >
          {activeLabel}
        </span>{' '}
      </>
    )

    // Read-only Select
    if (!onChange) {
      return <FieldGroup>{labelWithValue}</FieldGroup>
    }

    return (
      <Toggle>
        {toggle => (
          <FieldGroup>
            <a {...a11y.clickable(toggle.toggle)} className={cx(styles.btn)}>
              {labelWithValue}
              <ArrowDown color={colors.cadetBlue} />
            </a>

            <Dropdown
              position="right"
              isOpen={toggle.isOpen}
              onClose={toggle.close}
            >
              <ul className={styles.dropdown}>
                {emptyLabel && (
                  <li
                    className={defaultClassName}
                    {...a11y.clickable(
                      callAll(toggle.close, onChange(emptyValue))
                    )}
                  >
                    {multiple ? (
                      <CheckboxField
                        fieldProps={{
                          name: '_all',
                          value: !isActive,
                          onChange: this.handleChangeMultiple,
                        }}
                      >
                        All
                      </CheckboxField>
                    ) : (
                      emptyLabel
                    )}
                  </li>
                )}

                {choices.map((choice, index) => {
                  const itemClassName = cx(styles.dropdownItem, {
                    [styles.dropdownItemActive]: value === choice.value,
                  })

                  return (
                    <li
                      key={choice.value || index}
                      className={itemClassName}
                      {
                        /*
                        in multiple select no item click handlers are required,
                        because:
                        - clicking on checkbox doesn't close dropdown and
                        - value change is handled by checkbox
                      */
                        ...(multiple
                          ? {}
                          : a11y.clickable(
                              callAll(toggle.close, onChange(choice.value))
                            ))
                      }
                    >
                      {multiple ? (
                        <CheckboxField
                          fieldProps={{
                            name: choice.value,
                            value:
                              (Array.isArray(value) &&
                                value.find(item => item === choice.value)) ||
                              false,
                            onChange: this.handleChangeMultiple,
                          }}
                        >
                          {choice.label}
                        </CheckboxField>
                      ) : (
                        choice.label
                      )}
                    </li>
                  )
                })}
              </ul>
            </Dropdown>
          </FieldGroup>
        )}
      </Toggle>
    )
  }
}
