// @flow
import { evolve } from 'ramda'

type EnumType = {
  name: string,
  description: string,
}

type GraphQLEnumValues = {
  enumValues: Array<EnumType>,
}

export function choices<T>(
  list: Array<T> = [],
  mapping: T => [string, any] = id => [id, id]
) {
  const values = {}
  const choices = []

  list.map(mapping).forEach(([value, label]) => {
    values[value] = label
    choices.push({ value, label })
  })

  return {
    choices,
    values,
  }
}

/**
 * Get value of a first choice. Useful for form initialization.
 *
 * `attr` is object returned from `choices` function.
 */
export const firstChoiceValue = attr => attr.choices[0].value

export function enumChoices({ enumValues = [] }: GraphQLEnumValues = {}) {
  return choices(enumValues, ({ name, description }) => [
    enumValue(name),
    description,
  ])
}

export function enumValue(value: string): string {
  if (!value) return value

  return value.replace(/A_(\d+)/, '$1')
}

export function addToCache(proxy: any, query: Object, schema: Object) {
  try {
    const prev = proxy.readQuery(query)
    proxy.writeQuery({
      ...query,
      data: evolve(schema, prev),
    })
  } catch (e) {}
}
