import styled from 'react-emotion'
import { color, background, shadow } from 'bvdash/styles'

const Input = styled.input`
  ${color.waterloo};
  ${background.solidWhite};
  ${shadow};

  margin-right: 30px;
  margin: 10px 30px 10px 0;
  padding: 0 25px;
  font-size: 16px;
  line-height: 59px;
  border: none;
  border-radius: 5px;
  outline: none;
`

export default Input
