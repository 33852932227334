import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { alertsQuery } from './withAlerts'

export const alertMarkAsReadMutation = gql`
  mutation AlertMarkAsRead($alertId: ID) {
    alertsMarkAsRead(alertId: $alertId) {
      ok
      error
    }
  }
`

export const withAlertsMarkAsRead = graphql(alertMarkAsReadMutation, {
  props: ({ mutate, ownProps }) => ({
    markAsRead: alertId => {
      return mutate({
        variables: { alertId },
        update: (
          proxy,
          {
            data: {
              alertsMarkAsRead: { ok },
            },
          }
        ) => {
          if (!ok) return

          let data
          try {
            data = proxy.readQuery({
              query: alertsQuery,
            })
          } catch (e) {
            return
          }

          data.alerts = data.alerts.map(alert => ({
            ...alert,
            read: !alertId || alert.id === alertId ? true : alert.read,
          }))
          proxy.writeQuery({ query: alertsQuery, data })
        },
      })
    },
  }),
})
