import styled, { css } from 'styled-components'

export const inputStyle = css`
  ${({ theme }) => theme.shadowActive};
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.main};
  border-radius: 5px;
  display: block;
  font-size: 14px;
  height: 59px;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.color.muted};
  }

  &:disabled {
    background: ${({ theme }) => theme.color.greyUltraLight};
  }
`

export const Checkbox = styled.input`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.accent};
  appearance: none;
  align-items: center;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-weight: normal;
  height: ${({ size }) => size}px;
  justify-content: center;
  margin: 8px;
  position: relative;
  width: ${({ size }) => size}px;

  &:checked::after {
    border: solid ${({ theme }) => theme.color.accent};
    content: ' ';
    position: absolute;
    transform: rotate(45deg);
    top: 0;
    ${({ size }) => `
      border-width: 0 ${size * 0.075}px ${size * 0.075}px 0;
      height: ${size * 0.75}px;
      width: ${size * 0.35}px;
    `}
  }

  &:focus {
    outline: none;
  }
  ${({ style }) => style};
`

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 8px;
  min-height: 28px;
  justify-content: center;
  max-width: 100%;
`

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.color.error};
  line-height: 1.5em;
`

export const GenerateIdButton = styled.button`
  color: ${({ theme }) => theme.color.main};
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  width: 50%;

  &:disabled {
    cursor: not-allowed;
  }
`

/*
  Temporary addition because the date form is being used in other locations
  with different styling
*/
export const IconContainer = styled.div`
  ${({ iconStyle }) =>
    iconStyle &&
    `
    align-items: center;
    bottom: 0;
    display: flex;
    margin: auto;
    position: absolute;
    right: 16px;
    top: 0;
  `};
`

export const Input = styled.input`
  ${inputStyle};
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.error : 'transparent')};
`

export const InputContainer = styled.div`
  display: block;
  max-width: 100%;
  min-width: calc(50% - 12px);
  position: relative;
  width: ${({ width }) => width || '330px'};
  ${({ style }) => style};

  .react-datepicker-wrapper {
    display: block;
  }
`

export const Label = styled.p`
  color: ${({ theme }) => theme.color.main};
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.57;
  margin-bottom: 8px;
  ${({ style }) => style};
`

export const ParallelContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const Select = styled.select`
  ${inputStyle};
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.error : 'transparent')};
`
