import * as React from 'react'
import { compose } from 'react-apollo'
import Helmet from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import AuthRoute from 'bvdash/auth/ui/AuthRoute'
import { Header } from 'bvdash/components/navigation/Header'
import { EmptyScreen, LoadingScreen } from 'bvdash/components/emptyScreens'

import { SettingsProvider } from 'bvdash/contexts/Settings'
import {
  enterpriseRouteConfig,
  proBusPlanRouteConfig,
  freePlanRouteConfig,
  noAuthRouteConfig,
  trialExpiredRouteConfig,
} from 'bvdash/routeConfigs'
import {
  BUSINESS,
  isFreePlan,
  isProfessionalPlan,
  isBusinessPlan,
  isEnterprisePlan,
} from 'bvdash/plans'
import {
  alertTitle,
  budgetTitle,
  conversationTitle,
  documentTitle,
  issueTitle,
  programTitle,
  projectTitle,
  portfolioTitle,
  qualityTitle,
  riskTitle,
  scheduleTitle,
  scopeTitle,
  surveyTitle,
  taskTitle,
  teamTitle,
  timesheetTitle,
  updateTitle,
} from 'bvdash/admin/settings/utils'
import { AccountSetup } from 'bvdash/admin/setup/AccountSetup'

import { settings, withCustomer } from 'bvdash/queries/settings'
import { withAuthUser } from 'bvdash/queries/withAuthUser'
import { withPrograms } from 'bvdash/queries/withPrograms'
import {
  withSettingsMenu,
  withSettingsMenuCreate,
  withSettingsMenuElementCreate,
} from 'bvdash/admin/settings/queries'
import {
  withStripeCustomerCreate,
  withStripePlans,
  withStripeSubscriptionCreate,
} from 'bvdash/admin/account/queries'

import { timeRemaining } from 'bvdash/utils/time'
import { initialSettingsMenu } from './initialSettingsMenu'

import { modalOptions } from 'bvdash/components/modals'
import { Notification } from 'bvdash/components/notifications'

import { useToggle } from 'bvdash/hooks/useToggle'

import { isUnlimited } from 'bvdash/plans'
import { theme } from 'bvdash/theme'

const determineRouteConfig = props => {
  const {
    isFreePlan,
    isProfessionalPlan,
    isBusinessPlan,
    isEnterprisePlan,
    trialExpired,
  } = props
  if (trialExpired) {
    return trialExpiredRouteConfig
  }
  switch (true) {
    case isFreePlan:
      return freePlanRouteConfig
    case isProfessionalPlan:
    case isBusinessPlan:
      return proBusPlanRouteConfig
    case isEnterprisePlan:
      return enterpriseRouteConfig
    default:
      return enterpriseRouteConfig
  }
}

const RenderRoutes = props => {
  const {
    authUser,
    isFreePlan,
    isProfessionalPlan,
    isBusinessPlan,
    isEnterprisePlan,
    titles,
    trialExpired,
  } = props

  const routeConfig = authUser.isAuthenticated
    ? determineRouteConfig({
        isFreePlan,
        isProfessionalPlan,
        isBusinessPlan,
        isEnterprisePlan,
        trialExpired,
      })
    : noAuthRouteConfig

  // Notification
  const {
    close: notificationClose,
    isOpen: notificationIsOpen,
    open: notificationOpen,
  } = useToggle(false)

  const [notificationContent, setNotificationContent] = React.useState({})
  const [previousPathName, setPreviousPathName] = React.useState(null)

  // Modal
  const {
    close: handleModalClose,
    isOpen: modalIsOpen,
    open: handleModalOpen,
  } = useToggle(false)
  const [currentModal, setCurrentModal] = React.useState(null)
  const [currentModalProps, setCurrentModalProps] = React.useState({})

  React.useEffect(() => {
    if (trialExpired) {
      setCurrentModal('trialExpired')
      handleModalOpen()
    }
  }, [])

  return (
    <Switch>
      {routeConfig.map((route, i) => {
        const { auth, Component, path, exact } = route

        const Render = auth ? AuthRoute : Route
        const CurrentModal =
          currentModal != null ? modalOptions[currentModal] : null

        return (
          <Render
            authUser={authUser}
            render={routeProps => {
              const { pathname } = routeProps.location

              if (
                previousPathName &&
                notificationIsOpen &&
                previousPathName !== pathname
              )
                notificationClose()
              setPreviousPathName(pathname)

              return (
                <>
                  {modalIsOpen ? (
                    <CurrentModal
                      closeModal={handleModalClose}
                      currentModalProps={currentModalProps}
                      path={pathname}
                      titles={titles}
                    />
                  ) : null}
                  {notificationIsOpen ? (
                    <Notification
                      display={notificationIsOpen}
                      handleClose={notificationClose}
                      text={notificationContent.text}
                      top={112}
                      type={notificationContent.type}
                    />
                  ) : null}
                  <Component
                    {...props}
                    {...routeProps}
                    notificationClose={notificationClose}
                    notificationIsOpen={notificationIsOpen}
                    notificationOpen={notificationOpen}
                    handleModalOpen={handleModalOpen}
                    modalIsOpen={modalIsOpen}
                    handleModalClose={handleModalClose}
                    setNotificationContent={setNotificationContent}
                    setCurrentModal={setCurrentModal}
                    setCurrentModalProps={setCurrentModalProps}
                  />
                </>
              )
            }}
            key={`${path}${i}`}
            path={path}
            exact={exact}
          />
        )
      })}
    </Switch>
  )
}

const AuthAppContent = compose(
  withSettingsMenuCreate,
  withSettingsMenuElementCreate,
  withSettingsMenu
)(props => {
  const {
    authUser,
    customer,
    programs,
    settings,
    settingsMenu,
    settingsMenuCreate,
    settingsMenuElementCreate,
    stripePlans,
  } = props

  const [menu, setMenu] = React.useState(customer.customer.settingsMenu)

  const {
    close: trialNotificationClose,
    isOpen: trialNotificationIsOpen,
  } = useToggle(true)

  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    if (!settingsMenu.isLoading) {
      async function fetchMenu() {
        const menu = await settingsMenuCreate({
          customerId: customer.customer.id,
          menu: initialSettingsMenu,
        })
        setMenu(menu)
      }

      async function generateSurveyMenu() {
        const res = await settingsMenuElementCreate({
          menu_element: {
            label: 'Survey',
            on: true,
            menuId: settingsMenu.settingsMenu.id,
          },
        })
        if (res.ok) {
          setReady(true)
        }
      }
      if (!menu) {
        const menu = setTimeout(() => fetchMenu(), 1000)
        return () => clearTimeout(menu)
      } else if (settingsMenu.settingsMenu.survey == null) {
        generateSurveyMenu()
      } else {
        setReady(true)
      }
    }
  })

  if (!ready || customer.customer.stripePlanId == null) return <LoadingScreen />

  const initialRemaining = customer.customer.stripeTrialEnd * 1000 - Date.now()
  const { days } = timeRemaining(initialRemaining)

  const isTrial =
    customer.customer.status.toLowerCase() === 'trial' && initialRemaining > 0

  const trialExpired =
    customer.customer.status.toLowerCase() === 'trial' && initialRemaining < 0

  const defaultProgram = programs.programs.find(p => p.isDefault) || null

  const freePlan = isFreePlan(customer.customer.stripePlanName)
  const professionalPlan = isProfessionalPlan(customer.customer.stripePlanName)
  const businessPlan = isBusinessPlan(customer.customer.stripePlanName)
  const enterprisePlan = isEnterprisePlan(customer.customer.stripePlanName)

  const currentPlanData = stripePlans.stripePlans.find(
    plan => plan.planId === customer.customer.stripePlanId
  )

  const canAddMoreUsers =
    !trialExpired ||
    customer.customer.numUsers < customer.customer.stripeUserQuantity
  const canAddMoreProjects = isUnlimited(currentPlanData.numProjects)
    ? true
    : !trialExpired ||
      customer.customer.numProjects < currentPlanData.numProjects

  const titleObject = {
    alert: alertTitle(settingsMenu.settingsMenu),
    budget: budgetTitle(settingsMenu.settingsMenu),
    conversation: conversationTitle(settingsMenu.settingsMenu),
    document: documentTitle(settingsMenu.settingsMenu),
    issue: issueTitle(settingsMenu.settingsMenu),
    program: programTitle(settingsMenu.settingsMenu),
    project: projectTitle(settingsMenu.settingsMenu),
    portfolio: portfolioTitle(settingsMenu.settingsMenu),
    quality: qualityTitle(settingsMenu.settingsMenu),
    risk: riskTitle(settingsMenu.settingsMenu),
    schedule: scheduleTitle(settingsMenu.settingsMenu),
    scope: scopeTitle(settingsMenu.settingsMenu),
    survey: surveyTitle(settingsMenu.settingsMenu),
    task: taskTitle(settingsMenu.settingsMenu),
    team: teamTitle(settingsMenu.settingsMenu),
    timesheet: timesheetTitle(settingsMenu.settingsMenu),
    update: updateTitle(settingsMenu.settingsMenu),
  }

  let MainRoutes
  if (authUser.isAuthenticated) {
    if (!customer.customer.onboarding) {
      if (authUser.user.isAdmin) {
        MainRoutes = (
          <AccountSetup
            {...props}
            defaultProgram={defaultProgram}
            program={defaultProgram}
            titles={titleObject}
            // plans (ordered from least to most expensive)
            currentPlanData={currentPlanData}
            isFreePlan={freePlan}
            isProfessionalPlan={professionalPlan}
            isBusinessPlan={businessPlan}
            isEnterprisePlan={enterprisePlan}
            canAddMoreUsers={canAddMoreUsers}
            canAddMoreProjects={canAddMoreProjects}
            trialExpired={trialExpired}
            isTrial={isTrial}
          />
        )
      } else {
        MainRoutes = (
          <EmptyScreen
            project
            theme={customer.theme}
            type="project"
            titles={titleObject}
          />
        )
      }
    } else {
      MainRoutes = (
        <RenderRoutes
          {...props}
          defaultProgram={defaultProgram}
          program={defaultProgram}
          titles={titleObject}
          // plans (ordered from least to most expensive)
          currentPlanData={currentPlanData}
          isFreePlan={freePlan}
          isProfessionalPlan={professionalPlan}
          isBusinessPlan={businessPlan}
          isEnterprisePlan={enterprisePlan}
          canAddMoreUsers={canAddMoreUsers}
          canAddMoreProjects={canAddMoreProjects}
          trialExpired={trialExpired}
          isTrial={isTrial}
        />
      )
    }
  }

  return (
    <SettingsProvider value={settings}>
      <>
        <Header
          authUser={authUser}
          defaultProgram={defaultProgram}
          onboarding={customer.customer.onboarding}
          settingsMenu={settingsMenu.settingsMenu}
          titles={titleObject}
          // plans (ordered from least to most expensive)
          currentPlanData={currentPlanData}
          isFreePlan={freePlan}
          isProfessionalPlan={professionalPlan}
          isBusinessPlan={businessPlan}
          isEnterprisePlan={enterprisePlan}
          trialExpired={trialExpired}
          isTrial={isTrial}
        />
        {!freePlan && isTrial && !trialExpired ? (
          <Notification
            alwaysDisplay
            display={trialNotificationIsOpen}
            type="neutral"
            text={`Trial Remaining: ${days} days`}
            handleClose={trialNotificationClose}
          />
        ) : null}
        {MainRoutes}
      </>
    </SettingsProvider>
  )
})

const AuthenticatedApp = compose(
  withCustomer,
  settings,
  withPrograms,
  withStripePlans,
  withStripeCustomerCreate,
  withStripeSubscriptionCreate
)(props => {
  const {
    stripeCreateCustomer,
    stripeCreateSubscription,
    customer,
    programs,
    settings,
    stripePlans,
  } = props

  if (
    customer.isLoading ||
    programs.isLoading ||
    settings.isLoading ||
    stripePlans.isLoading
  )
    return null

  const getBusinessPlan = stripePlans.stripePlans.find(
    p => p.name.toLowerCase() === BUSINESS.toLowerCase()
  )

  const {
    customer: { id, stripeCustomerId, stripeSubscriptionId },
  } = customer

  React.useEffect(() => {
    async function setupStripeCustomer() {
      if (stripeCustomerId == null) {
        const res = await stripeCreateCustomer({ id })
        if (res.ok) {
          const createSub = setTimeout(
            async () =>
              await stripeCreateSubscription({
                stripeCustomerId: res.customer.stripeCustomerId,
                stripePlanId: getBusinessPlan.planId,
              }),
            2000
          )
          return () => clearTimeout(createSub)
        }
      } else if (stripeSubscriptionId == null) {
        await stripeCreateSubscription({
          stripeCustomerId: stripeCustomerId,
          stripePlanId: getBusinessPlan.planId,
        })
      }
    }
    setupStripeCustomer()
  }, [])

  return (
    <SettingsProvider value={settings}>
      <ThemeProvider theme={theme}>
        <AuthAppContent {...props} />
      </ThemeProvider>
    </SettingsProvider>
  )
})

const HelmetWrapper = WrappedComponent => props => {
  return (
    <>
      <Helmet titleTemplate={`%s | BVDash`}>
        {process.env.NODE_ENV === 'production' ? (
          <script src="//rum-static.pingdom.net/pa-5cb47d545c1f440017000018.js" />
        ) : null}
      </Helmet>
      <WrappedComponent {...props} />
    </>
  )
}

const UnauthenticatedApp = props => {
  const { authUser } = props

  return (
    <ThemeProvider theme={theme}>
      <>
        <Header authUser={authUser} />
        <RenderRoutes {...props} />
      </>
    </ThemeProvider>
  )
}

const App = compose(withAuthUser)(props => {
  const { authUser } = props

  if (authUser.isLoading) return null

  if (authUser.isAuthenticated) {
    return <AuthenticatedApp {...props} />
  }

  return <UnauthenticatedApp {...props} />
})

export default HelmetWrapper(App)
