import * as React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import styled, { css } from 'react-emotion'

import { ArrowDown, ArrowUp } from 'bvdash-core/icons'
import { Toggle, Dropdown } from 'bvdash/ui/core'
import {
  color,
  colorMap,
  selectable,
  menuHoverLeft,
  space,
} from 'bvdash/styles'
import * as a11y from 'bvdash/utils/a11y'

const defaultActiveClassName = css`
  ${color.dodgerBlue};
  font-weight: 500;
`

export const DropdownMenu = props => {
  const {
    children,
    location,
    activePath,
    activeClassName = defaultActiveClassName,
    className,
    items,
  } = props
  return (
    <Toggle>
      {toggle => (
        <Container>
          <Trigger
            {...a11y.clickable(toggle.toggle)}
            test="DropdownMenu"
            isActive={location.pathname.includes(activePath)}
            className={className}
            activeClassName={activeClassName}
          >
            {children}
            {toggle.isOpen ? <ArrowUp /> : <ArrowDown />}
          </Trigger>

          <Dropdown
            position="center"
            isOpen={toggle.isOpen}
            onClose={toggle.close}
          >
            <ul>
              {items.map(item => (
                <MenuItem key={item.url}>
                  <MenuLink
                    to={item.url}
                    onClick={toggle.close}
                    activeClassName={activeClass}
                  >
                    {item.title}
                  </MenuLink>
                </MenuItem>
              ))}
            </ul>
          </Dropdown>
        </Container>
      )}
    </Toggle>
  )
}

const Container = styled.div`
  position: relative;
`

const Trigger = styled.div`
  ${selectable};
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 16px;

  // push dropdown below navigation bar
  line-height: 4em;

  svg {
    margin-left: 6px;
  }

  ${props => (props.isActive ? props.activeClassName : '')}
`

const menuItemHeight = 50

const MenuItem = styled.li`
  & + & {
    border-top: 1px solid ${colorMap.dropShadow};
  }

  &:first-child a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child a {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

// just a class name, so we can apply the same style for :hover and manual active state
const activeClass = css``

const MenuLink = styled(NavLink)`
  ${selectable};
  ${color.waterloo};
  display: block;
  height: ${menuItemHeight}px;
  width: 250px;
  padding: ${space.normal};

  text-decoration: none;
  line-height: ${menuItemHeight - 2 * 16}px; // height - 2*padding

  &.${activeClass}, &:hover {
    ${color.sanJuan};
    ${menuHoverLeft()};

    svg {
      color: ${colorMap.dodgerBlue};
    }
  }

  svg {
    margin-right: 12px;
  }
`

export default withRouter(DropdownMenu)
