import * as React from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'

import { Toggle, Dropdown, DropdownList } from 'bvdash/ui/core'
import { More } from 'bvdash-core/icons'

import { callAll } from 'bvdash/utils/handlers'
import * as a11y from 'bvdash/utils/a11y'

import styles from './ThreeDotsMenu.scss'

export default class ThreeDotsMenu extends React.Component {
  static propTypes = {
    actions: PropTypes.array.isRequired,
  }

  render() {
    return (
      <Toggle>
        {toggle => (
          <div className={styles.root}>
            <div
              {...a11y.clickable(toggle.toggle)}
              className={cx(styles.trigger)}
            >
              <More />
            </div>
            <Dropdown
              position="right"
              isOpen={toggle.isOpen}
              onClose={toggle.close}
            >
              <DropdownList
                items={this.props.actions
                  .filter(({ hide }) => !hide)
                  .map(item => ({
                    key: item.label,
                    children: item.label,
                    onClick: callAll(item.onClick, toggle.close),
                  }))}
              />
            </Dropdown>
          </div>
        )}
      </Toggle>
    )
  }
}
