import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import { cx } from 'react-emotion'

import {
  AlertLink,
  AlertText,
  Content,
  Description,
  Header,
  IconContainer,
  ProjectText,
  setColor,
  DateText,
} from './styles'
import { NotificationsDropdown } from 'bvdash/ui/core'
import { Alert } from 'bvdash-core/icons'
import * as intl from 'bvdash/utils/intl'

import { withAlerts } from 'bvdash/queries/withAlerts'
import { withAlertsMarkAsRead } from 'bvdash/queries/withAlertsMarkAsRead'
import {
  actionProps,
  getAlertLink,
  getAlertType,
  getUserAlert,
} from 'bvdash/alerts/utils'
import { pluralize, sliceText } from 'bvdash/utils'

export const AlertsDropdown = compose(
  withAlerts,
  withAlertsMarkAsRead,
  withRouter
)(props => {
  const { subscribe, titles } = props
  React.useEffect(() => subscribe())

  const {
    activeClassName,
    className,
    alerts: { alerts, isLoading },
    initialOpen,
    location,
    markAsRead,
    to,
  } = props

  if (isLoading) {
    return null
  }

  const filteredAlerts = alerts.filter(alert => !alert.read)
  const hasAlerts = alerts.length !== 0
  const labelClassName = cx(className, {
    [activeClassName]: location.pathname.startsWith(to),
  })

  return (
    <NotificationsDropdown
      emptyLabel={`No ${pluralize(titles.alert)}`}
      footerLabel={`See all ${pluralize(titles.alert)}`}
      footerLink={to}
      icon={<Alert />}
      initialOpen={initialOpen}
      // label={`${pluralize(titles.alert)}`}
      labelClassName={labelClassName}
      notifications={filteredAlerts}
      onNotificationClick={alert => markAsRead(alert.id)}
      titleActions={[
        {
          label: 'Clear All',
          onClick: () => markAsRead(),
          disabled: !hasAlerts,
        },
      ]}
      titleLabel={`Recent ${titles.alert}`}
    >
      {({ notification }) => {
        const link = getAlertLink(notification)
        const { code, Icon, project, text, type } = getAlertType(notification)

        const isActionProps = actionProps(code)
        const { label } = getUserAlert(code)

        return (
          <AlertLink to={link}>
            <IconContainer type={type}>
              {Icon != null && (
                <Icon color={setColor(type)} {...isActionProps} />
              )}
            </IconContainer>
            <Content left>
              <div>
                <Content>
                  {project != null && (
                    <>
                      <Header>{titles.project}</Header>
                      <ProjectText left>{project.key}</ProjectText>
                    </>
                  )}
                  <AlertText left>{label}</AlertText>
                </Content>
                {project != null && (
                  <ProjectText top>{project.name}</ProjectText>
                )}
                <Description top>{sliceText(text, 20)}</Description>
              </div>
            </Content>
            <DateText>{intl.relativeDate(notification.date)}</DateText>
          </AlertLink>
        )
      }}
    </NotificationsDropdown>
  )
})
