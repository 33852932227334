import styled, { css } from 'react-emotion'

import { color } from 'bvdash/styles'

const centerMargin = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
`

export const DescriptionText = styled.p`
  ${centerMargin};
  ${color.waterloo};
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.57;
  margin-bottom: 40px;
  width: 368px;
`

export const MainText = styled.p`
  ${centerMargin};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-top: 16px;
  margin-bottom: 24px;
  width: 400px;
`

export const SceneImage = styled.img`
  ${centerMargin}
  display: block;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
`
