// @flow
import * as React from 'react'
import styled, { css } from 'react-emotion'
import { background, colorMap, color } from 'bvdash/styles'
import { displayTime } from 'bvdash/utils/dateFilters'
import { DateComponent } from 'bvdash/components/DateComponent'

const typeToField = {
  assign: 'assignee',
  classify: 'classification',
}

const timelineColor = '#dbdbdb'

const EventList = styled.ul`
  padding: 20px 25px;
`

const Event = styled.li(
  `
    display: flex;
    flex-direction: row;

  `,
  props => {
    const color = eventTypeMap[props.type]
    const borderColor =
      ['action_link', 'issue_link'].indexOf(props.type) > -1
        ? timelineColor
        : colorMap[color]
    return css`
      ${EventIndicator}::before {
        display: block;
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin: -5px -5px;
        ${background[color]}
        border: solid 2px ${borderColor};
      }
      &:last-child ${EventIndicator} {
        border: transparent;
        background-color: transparent;
      }
    `
  }
)

const EventDate = styled.div`
  flex-basis: 100%;
  overflow: hidden;
  text-align: right;
  ${color.cadetBlue};
  margin-bottom: 22px;
`

const EventIndicator = styled.div`
  margin: 0 25px;
  border: solid 2px ${timelineColor};
  margin-top: 10px;
  margin-bottom: -10px;
`

const EventDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  overflow: hidden;
  text-align: left;
  margin-bottom: 22px;
`

const EventText = styled.span``

const EventSubtext = styled.span`
  ${color.cadetBlue}
`

const eventTypeMap = {
  group_created: 'wasabi',
  open: 'wasabi',
  created: 'wasabi',

  approved: 'dodgerBlue',
  approver: 'dodgerBlue',
  assign: 'dodgerBlue',
  reporter: 'dodgerBlue',

  category: 'carnation',
  classify: 'carnation',
  deadline: 'carnation',
  group_edits: 'carnation',
  group_reopen: 'carnation',
  priority: 'carnation',
  rejected: 'carnation',
  reopen: 'carnation',
  status: 'carnation',
  strategy: 'carnation',
  update: 'carnation',

  action_close: 'cadetBlue',
  close: 'cadetBlue',
  group_close: 'cadetBlue',

  action_link: 'solidWhite',
  issue_link: 'solidWhite',

  //deprecated
  issue_assign: 'dodgerBlue',
  action_assign: 'dodgerBlue',
  action_ass: 'dodgerBlue',
  action: 'solidWhite',
}

function capitalize(text) {
  if (!text) return text
  let words = text.toLowerCase().replace('_', ' ')
  return words.charAt(0).toUpperCase() + words.slice(1)
}

function getEventText({
  __typename,
  type,
  author,
  assignee,
  priorityBefore,
  priorityAfter,
  strategy,
  category,
  approver,
  reporter,
  status,
  classification,
  deadline,
}) {
  category = capitalize(category)
  classification = capitalize(classification)
  strategy = capitalize(strategy ? strategy.split('_')[1] : null)
  type = type.toLowerCase()

  let item = __typename
    .split('E')[0]
    .replace('Action', 'Task')
    .replace('NCR', 'Quality')

  switch (type) {
    //Group events
    case 'group_created':
      switch (__typename) {
        case 'DocumentEventType':
          return `${item} uploaded by ${author.fullName}`
        case 'ActionEventType':
        case 'NCREventType':
          return `${item} created by ${author.fullName}`
        default:
          return `${item} reported by ${author.fullName}`
      }
    case 'group_close':
      return `${item} edited and closed by ${author.fullName}`
    case 'group_edits':
      return `${item} edited by ${author.fullName}`
    case 'group_reopen':
      return `${item} edited and reopened by ${author.fullName}`
    //Documents only
    case 'approved':
      return `${item} approved by ${author.fullName}`
    case 'rejected':
      return `${item} rejected by ${author.fullName}`
    //NCR only
    case 'reporter':
      return `${item} reporter set to ${reporter.fullName} by ${author.fullName}`
    case 'classify':
      return `Classification set to ${classification}`
    //Risks only
    case 'strategy':
      return `Strategy set to ${strategy}`
    //Actions only
    case 'deadline':
      return `Deadline set to ${DateComponent({ date: deadline })} by ${
        author.fullName
      }`
    //Everything else
    case 'category':
      return `Category set to ${category}`
    case 'created':
      switch (__typename) {
        case `DocumentEventType`:
          return `${item} uploaded by ${author.fullName}`
        default:
          return `${item} created by ${author.fullName}`
      }
    case 'update':
      return `Update created by ${author.fullName}`
    case 'approver':
      return `${item} approver set to ${approver.fullName} by ${author.fullName}`
    case 'assign':
      return `${item} assigned to ${assignee.fullName} by ${author.fullName}`
    case 'close':
      return `${item} resolved by ${author.fullName}`
    case 'reopen':
      return `${item} reopened by ${author.fullName}`
    case 'issue_link':
      return `Issue linked to task by ${author.fullName}`
    case 'action_link':
      return `Task linked to issue by ${author.fullName}`
    case 'action_close':
      return `Related action resolved by ${author.fullName}`
    case 'open':
      switch (__typename) {
        case `NCREventType`:
          //deprecated
          return `${item} created by ${author.fullName}`
        default:
          return `${item} reported by ${author.fullName}`
      }
    case 'priority':
      return `Priority ${
        priorityBefore > priorityAfter ? 'raised' : 'lowered'
      } to ${priorityAfter} by ${author.fullName}`
    case 'status':
      return `Status set to ${status} by ${author.fullName}`
    //deprecated
    case 'action_ass':
    case 'action_assign':
      return `Action assigned to ${assignee.fullName}`
    case 'issue_assign':
      return `${item} assigned to ${assignee.fullName}`
    default:
      return type
  }
}

function getEventEdits({ type, __typename, subEvents }) {
  let values = {}
  if (type.startsWith('group')) {
    for (let i = 0; i < subEvents.length; i++) {
      Object.keys(subEvents[i]).forEach(key => {
        if (subEvents[i][key] === null) {
          delete subEvents[i][key]
        }
      })
      if (!['created', 'open', 'close', 'reopen'].includes(subEvents[i].type)) {
        const attribute =
          typeToField[subEvents[i].type] === undefined
            ? subEvents[i].type
            : typeToField[subEvents[i].type]
        switch (attribute) {
          case 'strategy':
            values[attribute] = subEvents[i][attribute].split('_')[1]
            break
          case 'priority':
            values[attribute] = subEvents[i]['priorityAfter']
            break
          case 'deadline':
            values[attribute] = DateComponent({ date: subEvents[i][attribute] })
            break
          case 'assignee':
          case 'approver':
          case 'reporter':
            values[attribute] = subEvents[i][attribute].fullName
            break
          default:
            values[attribute] = subEvents[i][attribute]
            break
        }
      }
    }
  }
  return values
}

type IssueEventType = {
  id: number,
  date: string,
  type: string,
  author: {
    fullName: string,
  },
}

type TimelineProps = {
  events: Array<IssueEventType>,
}

export default function Timeline({ events }: TimelineProps) {
  let simplerEvents = []

  for (let i = events.length - 1; i > -1; i--) {
    let parent = simplerEvents.findIndex(
      event =>
        Math.abs(
          new Date(event.date).getTime() - new Date(events[i].date).getTime()
        ) < 1000 && event.author.id === events[i].author.id
    )
    if (parent === -1) simplerEvents.push(events[i])
    else {
      if (!simplerEvents[parent].type.startsWith('group')) {
        const first = simplerEvents[parent]
        simplerEvents[parent] = {
          ...simplerEvents[parent],
          type: 'group_edits',
          subEvents: [first],
        }
        if (['created', 'open'].includes(first.type))
          simplerEvents[parent].type = 'group_created'
        else if ('close' === first.type)
          simplerEvents[parent].type = 'group_close'
        else if ('reopen' === first.type)
          simplerEvents[parent].type = 'group_reopen'
      }
      if (['created', 'open'].includes(events[i].type))
        simplerEvents[parent].type = 'group_created'
      else if ('close' === events[i].type)
        simplerEvents[parent].type = 'group_close'
      else if ('reopen' === events[i].type)
        simplerEvents[parent].type = 'group_reopen'
      simplerEvents[parent].subEvents.push(events[i])
    }
  }

  return (
    <EventList>
      {simplerEvents.reverse().map(event => {
        const changes = getEventEdits(event)
        return (
          <Event type={event.type.toLowerCase()} key={event.id}>
            <EventDate>
              <DateComponent date={event.date} /> {displayTime(event.date)}
            </EventDate>
            <EventIndicator />
            <EventDescription>
              <EventText>{getEventText(event)}</EventText>
              {Object.keys(changes).map((key, index) => (
                <EventSubtext>
                  {changes[key] === undefined
                    ? `${capitalize(key)}`
                    : `${capitalize(key)}: ${changes[key]}`}
                </EventSubtext>
              ))}
            </EventDescription>
          </Event>
        )
      })}
    </EventList>
  )
}

Timeline.defaultProps = {
  events: [],
}
