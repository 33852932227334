import styled from 'react-emotion'

import { background, color, colorMap, shadow } from 'bvdash/styles'
import {
  bottomRadius,
  horizontalPadding,
  topRadius,
  verticalPadding,
} from 'bvdash/components/styles'

import { theme } from 'bvdash/theme'

// General
export const Card = styled.div`
  ${shadow};
  ${({ bottom }) => (bottom ? bottomRadius : null)};
  ${({ top }) => (top ? topRadius : null)};
  overflow: hidden;
`

Card.defaultProps = {
  bottom: true,
  top: true,
}

export const TopBar = styled.div`
  ${background.solidWhite};
  ${horizontalPadding}
  ${shadow};
  ${topRadius};
  align-items: center;
  display: flex;
  position: relative;
  z-index: 5;
  flex-wrap: wrap;
`

export const SlotsContainer = styled.div`
  display: flex;
  flex-direction: ${({ left }) => (left ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  margin-bottom: 48px;
  margin-top: 16px;
`

export const MainSlotContainer = styled.div`
  ${bottomRadius};
  ${topRadius};
  flex: ${({ flex }) => flex || 2};
  max-width: 100%;
`

export const SideSlotContainer = styled.div`
  ${({ left }) => (left ? 'margin-right: 20px' : 'margin-left: 8px')};
  flex: ${({ flex }) => flex || 1};

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
    min-width: 100%;
    margin-left: 0px;
  }
`

export const SectionHeading = styled.header`
  ${color.dodgerBlue};
  padding: 18px 0 25px;
`

// Inputs
// String
export const InputContainer = styled.div`
  margin-right: 16px;
`
export const Input = styled.input`
  border: none;
  border-bottom: 1px solid
    ${({ error }) => (error ? colorMap.carnation : colorMap.sanJuan)};
  font-size: 14px;
  text-align: center;
  width: 2em;
`

// Date
export const DateInput = styled.input`
  ${color.sanJuan};
  border: none;
  font-size: 14px;
  margin-left: 8px;
  width: ${({ value }) => (value != null ? (value.length + 1) * 8 : 100)}px;
  max-width: 100%;
`

export const DateContainer = styled.div`
  display: flex;
  margin: 16px;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
`

// Select
export const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 12px;
  max-width: 100%;
`

export const Label = styled.span`
  color: ${({ error }) => (error ? colorMap.carnation : colorMap.waterloo)};
  margin-right: 8px;
`

export const Select = styled.select`
  ${theme.shadow};
  ${color.sanJuan};
  background: ${colorMap.alabasterLight};
  border: 1px solid
    ${({ error }) => (error ? colorMap.carnation : 'transparent')};
  font-size: 14px;
  max-width: 100%;
`

// TextArea
export const TextAreaContainer = styled.div`
  ${background.solidWhite};
  ${bottomRadius};
  ${topRadius};
  ${shadow};
  display: flex;
  flex-direction: column;
  position: relative;
  height: 300px;
  z-index: 2;
`

export const TextArea = styled.textarea`
  ${color.sanJuan};
  ${horizontalPadding};
  ${verticalPadding};
  background: transparent;
  border: 1px solid
    ${({ error }) => (error ? colorMap.carnation : 'transparent')};
  font-size: 14px;
  height: 100%;
  letter-spacing: 1.1px;
  outline: none;
  resize: none;
  width: 100%;
`

export const FormWrapper = styled.div`
  width: 100%;
`
