import React, { useState } from 'react'
import { compose } from 'react-apollo'
import { Formik, Form } from 'formik'
import { colorMap } from 'bvdash/styles'
import { Actions } from 'bvdash/components/styles'
import { EditableCell } from 'bvdash/admin/components'
import { IconBox, ButtonGroup } from 'bvdash/ui/core'
import { Pencil, Trash } from 'bvdash-core/icons'
import { withTable, Table } from 'bvdash/ui/tables'
import {
  withProjectFields,
  withProjectFieldCreate,
  withProjectFieldUpdate,
  withProjectFieldRemove,
} from 'bvdash/admin/queries'
import ConfirmDialogDefault from 'bvdash/ui/core/ConfirmDialogDefault'
import { HelpText } from 'bvdash/admin/ui'
import { AddButton, Button } from 'bvdash/components/buttons'
import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'
import { FullWidthContainer } from 'bvdash/components/standard/containers'

import { fieldsTitle, codesTitle } from 'bvdash/admin/adminLinks'

export const ProjectFields = props => {
  const { titles } = props

  return (
    <>
      <AdminBreadcrumbs>
        {titles.project} / {titles.project} {fieldsTitle}
      </AdminBreadcrumbs>
      <FullWidthContainer>
        <HelpText>
          A {titles.project} {fieldsTitle} is a unique piece of information for{' '}
          each individual {titles.project}. (e.g. Address, Phone Number, etc.)
          {<br />} {<br />}For repetitive values such as Phase, Floor, Area,{' '}
          etc. use {titles.project} {codesTitle}s.
        </HelpText>
      </FullWidthContainer>
      <ProjectFieldsForm titles={titles} />
    </>
  )
}

const ProjectFieldsForm = compose(
  withProjectFields,
  withProjectFieldCreate,
  withProjectFieldUpdate
)(props => {
  const { titles } = props
  const {
    data,
    initialValues,
    submit,
    reset,
    isEditing,
    setEditing,
  } = useProjectFieldForm(props)

  return (
    <FullWidthContainer>
      <AddButton onClick={() => setEditing(true)}>
        Add New {titles.project} {fieldsTitle}
      </AddButton>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submit}
        render={() => (
          <Form>
            <ProjectFieldsTable
              isEditing={isEditing}
              setEditing={setEditing}
              reset={reset}
              data={data}
              titles={titles}
            />
          </Form>
        )}
      />
    </FullWidthContainer>
  )
})

const useProjectFieldForm = props => {
  const [isEditing, setIsEditing] = useState(null)
  const [initialValues, setInitialValues] = useState({
    key: '',
    label: '',
  })

  const handleSetEditing = row => {
    if (row === true) {
      // add new row
      setIsEditing(true)
      setInitialValues({
        key: '',
        label: '',
      })
    } else {
      // edit row
      const { id, key, label } = row
      setIsEditing(id)
      setInitialValues({ key, label })
    }
  }

  const handleReset = () => setIsEditing(null)

  const handleSubmit = async values => {
    if (isEditing === true) {
      await props.projectFieldCreate({
        projectField: values,
      })
    } else {
      await props.projectFieldUpdate({ id: isEditing, projectField: values })
    }

    setIsEditing(null)
  }

  const data =
    isEditing === true
      ? [{ id: true }, ...props.projectFields.all]
      : props.projectFields.all

  return {
    data,
    isEditing,
    initialValues,
    submit: handleSubmit,
    reset: handleReset,
    setEditing: handleSetEditing,
  }
}

const projectFieldsConfig = {
  name: 'adminProjectFields',
  rowKey: row => row.id,
  sortBy: 'key',
  columns: props => {
    const { titles } = props

    return [
      {
        attr: 'key',
        label: `${titles.project} ${fieldsTitle}`,
        render: props => (
          <EditableCell
            placeholder={`Enter ${titles.project} ${fieldsTitle}`}
            {...props}
          />
        ),
      },
      {
        attr: 'key',
        label: `${titles.project} ${fieldsTitle} name`,
        render: props => (
          <EditableCell
            placeholder={`Enter ${titles.project} ${fieldsTitle} Name`}
            {...props}
          />
        ),
      },
      {
        shrink: true,
        attr: '_actions',
        render: ({ row, props }) =>
          props.isEditing === row.id ? (
            <ButtonGroup flexWrap={false}>
              <Button small onClick={props.formReset}>
                Cancel
              </Button>
              <Button small type="submit" color="add">
                Save
              </Button>
            </ButtonGroup>
          ) : (
            <Actions>
              <IconBox onClick={() => props.setEditing(row)}>
                <Pencil inline />
              </IconBox>
              <ConfirmDialogDefault
                title={`Delete ${titles.project} ${fieldsTitle} Confirmation`}
                question={
                  <>
                    You are about to remove a {titles.project} {fieldsTitle}{' '}
                    {row.key}.<br />
                    Are you sure you want to proceed?
                  </>
                }
                theme="danger"
              >
                {confirm => (
                  <Trash
                    onClick={confirm(() => props.projectFieldRemove(row.id))}
                    color={colorMap.carnation}
                  />
                )}
              </ConfirmDialogDefault>
            </Actions>
          ),
      },
    ]
  },
}

const ProjectFieldsTable = compose(
  withProjectFieldRemove,
  withTable(projectFieldsConfig)
)(props => {
  const {
    table,
    isLoading,
    projectFieldRemove,
    isEditing,
    setEditing,
    reset,
  } = props

  return (
    <Table
      loading={isLoading}
      table={table}
      renderProps={{
        projectFieldRemove,
        isEditing,
        setEditing,
        formReset: reset,
      }}
    />
  )
})
