import { reverse } from 'named-urls'
import * as React from 'react'
import { compose } from 'react-apollo'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import styled, { css } from 'react-emotion'

import { Version } from 'bvdash/admin/ui'
import { colorMap, color } from 'bvdash/styles'
import { Eye, Pencil, Trash } from 'bvdash-core/icons'
import { DateComponent } from 'bvdash/components/DateComponent'
import { Actions } from 'bvdash/components/styles'
import { capitalize } from 'bvdash/utils'

import { Button } from 'bvdash/components/buttons'
import { EditableCell } from 'bvdash/admin/components'
import { IconBox, ButtonGroup, ConfirmDialogDefault } from 'bvdash/ui/core'
import { withTable, Table } from 'bvdash/ui/tables'
import {
  withVersions,
  versionCreate,
  versionRemove,
} from 'bvdash/admin/queries'

import { AddButton } from 'bvdash/components/buttons'

const findLatestPublish = versionArray => {
  if (!versionArray.length) {
    return {
      id: null,
      published: false,
    }
  }
  let latest = versionArray[versionArray.length - 1]

  for (let i = 0; i < versionArray.length; i++) {
    let v = versionArray[i]
    const versionId = Number(v.id)
    const latestId = Number(latest.id)
    latest = versionId > latestId ? v : latest
  }
  return latest
}

const VersionsTable = props => {
  const {
    addBtnLabel,
    table,
    versions: { isLoading, versions },
    versionRemove,
    versionCreate,
    useVersionForm = {},

    program,
    publishButtonText,

    routePreview,
    routePublic,
    type,
  } = props

  if (isLoading) return null

  const latestPublish = findLatestPublish(versions)

  const handleClick = () => versionCreate({ program: program.key })

  // TODO: Implement form!!!
  const { initialValues, submit, reset, isEditing, setEditing } = useVersionForm

  const lastRow = table.data[table.data.length - 1]

  const canCreate = latestPublish.published || !versions.length

  return (
    <>
      <Version
        type={type}
        publishButtonText={publishButtonText}
        programKey={program.key}
        version={latestPublish}
      />

      {canCreate && <AddButton onClick={handleClick}>{addBtnLabel}</AddButton>}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submit}
        render={({ values }) => (
          <Form>
            <Table
              loading={isLoading}
              table={table}
              renderProps={{
                lastRow,
                versionRemove,
                isEditing,
                setEditing,
                formReset: reset,
                programKey: program.key,
                dataType: type,
                routePreview,
                routePublic,
              }}
            />
          </Form>
        )}
      />
    </>
  )
}

const versionsConfig = {
  name: 'adminVersions',
  data: props => props.versions.versions,
  rowKey: row => row.id,
  sortBy: 'key',
  columns: [
    {
      attr: 'date',
      label: 'Data',
      render: ({ value, ...props }) => (
        <EditableCell value={<DateComponent date={value} />} {...props} />
      ),
    },
    {
      attr: 'author',
      label: 'Created by',
      value: row => (row.author !== null ? row.author.fullName : 'System'),
    },
    {
      attr: 'published',
      label: 'Published',
      render: ({ value }) => (
        <Published published={value}>{value ? 'Yes' : 'No'}</Published>
      ),
    },
    {
      shrink: true,
      attr: '_actions',
      render: rowProps => {
        const {
          row,
          props: {
            dataType,
            formReset,
            isEditing,
            lastRow,
            programKey,
            routePublic,
            routePreview,
            versionRemove,
          },
        } = rowProps

        const publishedLink = reverse(`${routePublic}?date=${row.id}`, {
          programKey,
        })

        const disableDelete = lastRow.id === row.id

        return isEditing === row.id ? (
          <ButtonGroup flexWrap={false}>
            <Button size="small" onClick={formReset}>
              Cancel
            </Button>
            <Button color="add" type="submit">
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <Actions space>
            {row.isFirst && (
              <Link to={routePreview}>
                <IconBox>
                  <Pencil inline />
                </IconBox>
              </Link>
            )}

            {!row.published ? (
              <Eye color={colorMap.waterloo} />
            ) : (
              <Link to={publishedLink}>
                <Eye color={colorMap.dodgerBlue} />
              </Link>
            )}
            {disableDelete ? null : (
              <ConfirmDialogDefault
                title={`Delete ${capitalize(dataType)} Snapshot Confirmation`}
                question={
                  <>
                    You are about to delete a {dataType} data Snapshot from{' '}
                    <DateComponent date={row.date} />.<br /> Are you sure you
                    want to proceed?
                  </>
                }
                theme="danger"
              >
                {confirm => (
                  <Trash
                    color={colorMap.carnation}
                    onClick={confirm(() => versionRemove(row.id))}
                  />
                )}
              </ConfirmDialogDefault>
            )}
          </Actions>
        )
      },
    },
  ],
}

const Published = styled.span`
  text-transform: uppercase;
  ${props =>
    !props.published &&
    css`
      ${color.carnation};
      font-weight: bold;
    `};
`

export default compose(
  withVersions,
  versionCreate,
  versionRemove,
  withTable(versionsConfig)
)(VersionsTable)
