import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { IssueFragment } from './issueFragment'
import { TimelineEventFragment } from './timelineEventFragment'

const projectIssueReopenMutation = gql`
  mutation projectIssueReopen($id: ID!) {
    issueReopen(id: $id) {
      ok
      error
      issue {
        ...Issue
        events {
          ...TimelineEvent
        }
      }
    }
  }
  ${IssueFragment}
  ${TimelineEventFragment}
`

export const withProjectIssueReopen = graphql(projectIssueReopenMutation, {
  props: ({ mutate }) => ({
    projectIssueReopen: id => {
      return mutate({
        variables: { id },
      })
    },
  }),
})
