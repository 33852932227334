import styled from 'react-emotion'
import { textBold, colorMap, color } from 'bvdash/styles'

export default styled.div`
  ${textBold};
  ${color.waterloo};
  height: 44px;
  padding: 10px 25px;

  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${colorMap.dropShadow};
  line-height: 26px;
`
