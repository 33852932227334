import * as React from 'react'
import { compose } from 'react-apollo'

import { Button } from 'bvdash/components'
import { Program } from 'bvdash-core/icons'
import { withProgramSetDefault } from 'bvdash/queries/withProgramSetDefault'
import { compare } from 'bvdash/utils/sort'

import { Container, Label } from './styles'
import { withPrograms } from './queries'

import { Dropdown, DropdownList, Toggle } from 'bvdash/ui/core'

const ProgramSwitcher = props => {
  const {
    program,
    programs: { programs },
    programTitle,
    programSetDefault,
  } = props

  return (
    <Container>
      <Program />
      <Label>{programTitle}:</Label>
      <div style={{ position: 'relative' }}>
        <Toggle>
          {toggle => {
            return (
              <>
                <Button color="add" onClick={() => toggle.toggle()} thin>
                  {program.key} {program.name} ▾
                </Button>
                <Dropdown
                  position="right"
                  isOpen={toggle.isOpen}
                  onClose={toggle.close}
                >
                  <DropdownList
                    items={programs.sort(compare('key')).map(p => {
                      return {
                        key: p.key,
                        children: `${p.key} ${p.name}`,
                        onClick: () => {
                          programSetDefault(p.key)
                          toggle.close()
                        },
                      }
                    })}
                  />
                </Dropdown>
              </>
            )
          }}
        </Toggle>
      </div>
    </Container>
  )
}

export default compose(
  withPrograms,
  withProgramSetDefault
)(ProgramSwitcher)
