import * as React from 'react'
import { reverse } from 'named-urls'
import styled, { css, cx } from 'react-emotion'

import routes from 'bvdash/routes'
import { LabelText } from 'bvdash/components'
import { AsyncImage, Box, Card, Text } from 'bvdash/ui/core'
import { EmptyPage } from 'bvdash/projects/ui'

import { pluralize } from 'bvdash/utils'

import EmptyScopeUpdate from 'bvdash-core/artwork/EmptyUpdatesNew.png'

import { DateComponent } from 'bvdash/components/DateComponent'

const gutter = css`
  margin: 0 20px;
`

const imageSize = css`
  object-fit: cover;
  width: 260px;
  height: 173px;
  flex-shrink: 0;
`

const Content = styled(Box)`
  margin-bottom: 66px;
  margin-top: 42px;
  white-space: pre-line;
`

const DashboardLatestUpdate = props => {
  const { forProgram = false, objectKey, theme, update, titles } = props

  const Body = !update ? (
    <EmptyPage
      dashboard
      description={
        <LabelText
          prefix="There are no"
          type={forProgram ? titles.program : titles.project}
          suffix={`${pluralize(titles.update)} yet`}
          theme={theme}
        />
      }
      image={EmptyScopeUpdate}
      title={
        forProgram
          ? `No ${titles.program} ${pluralize(titles.update)}`
          : `No ${titles.project} ${pluralize(titles.update)}`
      }
    />
  ) : (
    <Content horizontal>
      {update.attachments && update.attachments[0] && (
        <AsyncImage
          src={update.attachments[0].url}
          alt={update.description}
          className={cx(gutter, imageSize)}
        />
      )}
      <Box className={gutter}>
        <Box grow align="center" width="100%">
          <p>{update.description}</p>
        </Box>
        <Box item render="p">
          <Text color="dodgerBlue">Last {titles.update}:</Text>{' '}
          <DateComponent date={update.dateCreated} />
        </Box>
      </Box>
    </Content>
  )

  return (
    <Card.Card
      title={
        forProgram
          ? `${titles.program} ${pluralize(titles.update)}`
          : `${titles.project} ${pluralize(titles.update)}`
      }
      detailsLink={
        !forProgram &&
        reverse(routes.project.updateAdd, { projectKey: objectKey })
      }
      detailsLabel="Add New"
    >
      {Body}
    </Card.Card>
  )
}

export default DashboardLatestUpdate
