// @flow
import * as React from 'react'

import Empty from '../Empty'

const DefaultEmpty = props => {
  const { Icon, image, title, description } = props
  return (
    <Empty image={image} Icon={Icon} title={title} description={description} />
  )
}

export default DefaultEmpty
