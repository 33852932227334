import { graphql } from 'react-apollo'

import query from './settings.graphql'

export default graphql(query, {
  props: ({ data }) => {
    const { loading, settings } = data

    return {
      settings: {
        isLoading: loading,
        settings,
      },
    }
  },
})
