import zxcvbn from 'zxcvbn'

export const passwordStrength = password => {
  if (!password) {
    return 0
  }
  const { score } = zxcvbn(password)

  return score
}
