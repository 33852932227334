import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { ThreeDotsMenu } from 'bvdash/ui/tables'
import { authUser } from 'bvdash/auth/queries'
import { startConversation } from 'bvdash/conversations/queries'
import {
  projectSetFavoriteMutation as projectSetFavorite,
  projectSetStatus,
} from 'bvdash/projects/queries'
import ConfirmProjectStatusChange from './ConfirmProjectStatusChange'

export function _ProjectActions({
  projectId,
  projectKey,
  managerKey,
  isFavorite,
  codes,
  permissions,

  authUser,
  projectSetFavorite,
  projectSetStatus,
  startConversation,
}) {
  return (
    <ConfirmProjectStatusChange>
      {confirm => (
        <ThreeDotsMenu
          actions={[
            {
              label: 'Mark as Favorite',
              onClick: () => projectSetFavorite(projectId, true),
              hide: isFavorite,
            },
            {
              label: 'Remove from Favorites',
              onClick: () => projectSetFavorite(projectId, false),
              hide: !isFavorite,
            },
            ...(permissions && permissions.canSetStatus && codes && codes.status
              ? projectStatusActions(
                  projectKey,
                  codes.status.value,
                  confirm(projectSetStatus)
                )
              : []),
            {
              label: 'Contact PM',
              onClick: () => startConversation([managerKey]),
              hide: authUser.user && authUser.user.key === managerKey,
            },
          ]}
        />
      )}
    </ConfirmProjectStatusChange>
  )
}

const projectStatusActions = (projectKey, status, projectSetStatus) =>
  [
    ['Active', 'Active'],
    ['On Hold', 'OnHold'],
    ['Cancelled', 'Cancelled'],
    ['Completed', 'Completed'],
  ].map(([label, key]) => ({
    label,
    onClick: () => projectSetStatus(projectKey, key),
    hide: status === key,
  }))

const ProjectActions = compose(
  withRouter,
  authUser,
  startConversation,
  projectSetFavorite,
  projectSetStatus
)(_ProjectActions)

export default ProjectActions
