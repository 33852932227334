import * as React from 'react'
import { Label } from 'bvdash/ui/core'

const statusStyles = {
  approved: 'success',
  rejected: 'danger',
  pending: 'open',
  info: 'info',
}

function DocumentStatusLabel({ value, inline = false }) {
  // This might happen in rare cases, but it's better to handle the error
  // than occasionally blow up.
  if (typeof value !== 'string') return null

  return (
    <Label inline={inline} theme={statusStyles[value.toLowerCase()]}>
      {value === 'Info' ? 'Info only' : value}
    </Label>
  )
}

export default DocumentStatusLabel
