import styled, { css } from 'react-emotion'
import { selectable } from 'bvdash/styles'

const SvgIcon = styled.svg(
  // default styles
  `
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
`,

  // map props to styles
  props => ({
    strokeWidth: props.strokeWidth,
    width:
      props.width === 'auto'
        ? null
        : typeof props.width === 'number'
        ? props.width * props.scale
        : props.width,
    height:
      typeof props.height === 'number'
        ? props.height * props.scale
        : props.height,
  }),

  // icons with onClick handler are selectable
  props =>
    props.onClick &&
    css`
      ${selectable}
      user-select: none;
    `,

  // inline icons has width/height fixed to font size
  props =>
    props.inline &&
    `
  height: 1em;
  width: 1em;
  `,

  // color is based on theme
  props => css`
    fill: ${props.fill || props.color};
    ${props.strokeWidth && `stroke: ${props.color};`};
  `
)

SvgIcon.defaultProps = {
  color: 'currentColor',
  scale: 1,
  strokeWidth: 0,
  width: 'auto',
  height: '100%',
}

export default SvgIcon
