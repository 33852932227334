import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import routes from 'bvdash/routes'

// Components
import { ModalMultiSubmit } from 'bvdash/components/modals'
import { Highlight, StandardText } from 'bvdash/components/standard/texts'

// Queries and Mutations
import { withCustomer } from 'bvdash/queries/settings/withCustomer'
import { withStripePlans } from 'bvdash/admin/account/queries'

export const CannotChangePlanModal = compose(
  withCustomer,
  withStripePlans,
  withRouter
)(props => {
  const {
    customer: { customer },
    closeModal,
    currentModalProps,
    history,
    path,
  } = props

  const tooManyUsers = customer.numUsers > currentModalProps.plan.numUsers
  const tooManyProjects =
    customer.numProjects > currentModalProps.plan.numProjects
  const tooManyBoth = tooManyUsers && tooManyProjects

  const PlanText = (
    <>
      The <Highlight>{currentModalProps.plan.name} Plan</Highlight> only allows
      for{' '}
    </>
  )

  const currentText = 'You currently have '
  const responseText = 'You will need to'
  const downgradeText = ' to downgrade'
  const inBetweenTextPlan = tooManyBoth ? ' and ' : PlanText
  const inBetweenTextCurrent = tooManyBoth ? ' and ' : currentText
  const inBetweenTextResponse = tooManyBoth ? ' and ' : responseText

  const Content = (
    <>
      <StandardText>
        {tooManyUsers ? (
          <>
            {PlanText}
            <Highlight>{currentModalProps.plan.numUsers}</Highlight> users
          </>
        ) : null}
        {tooManyProjects ? (
          <>
            {inBetweenTextPlan}
            <Highlight>{currentModalProps.plan.numUsers}</Highlight> projects
          </>
        ) : null}
      </StandardText>
      <StandardText marginBottom={16}>
        {tooManyUsers ? (
          <>
            {currentText}
            <Highlight>{customer.numUsers}</Highlight> users
          </>
        ) : null}
        {tooManyProjects ? (
          <>
            {inBetweenTextCurrent}
            <Highlight>{customer.numProjects}</Highlight> projects
          </>
        ) : null}
      </StandardText>
      <StandardText>
        {tooManyUsers ? <>{responseText} deactivate users</> : null}
        {tooManyProjects ? <>{inBetweenTextResponse} remove projects</> : null}
        {downgradeText}
      </StandardText>
    </>
  )

  const onCancel = () => {
    if (path === routes.admin.projectAddItem) {
      history.goBack()
    }
    closeModal()
  }

  const toRemoveProjects = () => {
    history.push(routes.admin.projectData)
    closeModal()
  }
  const toRemoveUsers = () => {
    history.push(routes.admin.users)
    closeModal()
  }

  const removeUsersButtonData = {
    onSubmit: toRemoveUsers,
    type: 'caution',
    submitText: 'Users',
  }
  const removeProjectsButtonData = {
    onSubmit: toRemoveProjects,
    type: 'caution',
    submitText: 'Projects',
  }

  const submitButtons = []
  if (tooManyUsers) {
    submitButtons.push(removeUsersButtonData)
  }
  if (tooManyProjects) {
    submitButtons.push(removeProjectsButtonData)
  }

  return (
    <ModalMultiSubmit
      headerText="Unable to Change Plan"
      onCancel={onCancel}
      submitButtons={submitButtons}
      type="add"
    >
      {Content}
    </ModalMultiSubmit>
  )
})
