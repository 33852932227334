import * as React from 'react'
import { compose } from 'react-apollo'
import { withBudgetFields } from 'bvdash/admin/queries'

import { Card, Number } from 'bvdash/ui/core'

const BudgetTotal = props => {
  const {
    actual,
    budgetFields,
    eac,
    forProgram = false,
    original,
    titles,
  } = props

  if (budgetFields.isLoading) return null

  return (
    <Card.Card>
      <Card.Layout type="four">
        <Card.Section
          title={
            forProgram
              ? `Total ${titles.program} ${titles.budget}`
              : `Total ${titles.project} ${titles.budget}`
          }
          align="end"
        >
          <Number value={original} numberScale="dashboard" />
        </Card.Section>
        <Card.Section
          title={budgetFields.all.find(x => x.internal === 'EAC').label}
          align="end"
        >
          <Number value={eac} numberScale="dashboard" />
        </Card.Section>
        <Card.Section title={`${titles.budget} Variance`} align="end">
          <Number value={original - eac} numberScale="dashboard" />
        </Card.Section>
        <Card.Section
          title={budgetFields.all.find(x => x.internal === 'ACTUAL').label}
          align="end"
        >
          <Number value={actual} numberScale="dashboard" />
        </Card.Section>
      </Card.Layout>
    </Card.Card>
  )
}

export default compose(withBudgetFields)(BudgetTotal)
