import * as React from 'react'

import { listActionsColumn } from 'bvdash/admin/users/List/ListActions'
import { Validated } from 'bvdash/admin/users/List/Status'
import { search } from 'bvdash/ui/tables'

import { pluralize } from 'bvdash/utils'

import { ProjectCount } from './styles'
import { UserPhoto } from 'bvdash/ui/core'
import styles from 'bvdash/programs/scenes/ProgramTeam/ProgramTeam.scss'

export const tableConfig = {
  name: 'adminUsers',
  data: props => props.users,
  rowKey: row => row.id,
  sortBy: 'name',
  search: value => user =>
    search(value, user.fullName, user.email, user.jobTitle, user.phoneNumber),
  columns: props => {
    const {
      authUser,
      titles,
      userUpdate,
      userSendValidationEmail,
      noProjectsNotActive,
    } = props

    return [
      {
        attr: 'fullName',
        label: 'Name',
        render: ({ value, row: user }) => {
          return (
            <>
              <UserPhoto
                name={user.fullName}
                photo={
                  user.userProfilePicture && user.userProfilePicture.length > 0
                    ? user.userProfilePicture[0].url
                    : null
                }
                className={styles.photo}
              />{' '}
              {value}
            </>
          )
        },
      },
      {
        attr: 'jobTitle',
        label: 'Job Title',
      },
      {
        attr: 'projects',
        label: `Assigned ${pluralize(titles.project)}`,
        render: ({ value }) => (
          <ProjectCount value={value.length}>{value.length}</ProjectCount>
        ),
      },
      {
        attr: 'email',
        label: 'Email',
      },
      {
        attr: 'phoneNumber',
        label: 'Phone',
      },
      {
        attr: 'isValidated',
        label: 'Status',
        render: ({ row: user, value }) => {
          const sendValidationEmail = () =>
            userSendValidationEmail({ email: user.email })

          const isAuthUser = authUser.user.id === user.id
          const deactivateUser = () =>
            userUpdate({
              id: user.id,
              user: { isActive: false, isValidated: 'DISABLED' },
            })

          const userState = {
            hasProjects: user.projects.length > 0,
            isAuthUser,
            isActive: user.isActive,
            isValidated: user.isValidated,
          }

          const displayDropdown = !userState.isAuthUser

          const handleClick = () => {
            if (userState.isAuthUser) {
              return
            }
            if (userState.isActive) {
              return deactivateUser()
            }
            if (!userState.hasProjects) {
              return noProjectsNotActive()
            }
            if (!userState.isActive && userState.hasProjects) {
              return sendValidationEmail()
            }
          }

          return (
            <Validated
              displayDropdown={displayDropdown}
              userState={userState}
              handleClick={handleClick}
              titles={titles}
              width={200}
            />
          )
        },
        shrink: true,
      },
      listActionsColumn(),
    ]
  },
}
