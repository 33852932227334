import PropTypes from 'prop-types'
import * as React from 'react'

import { Item } from './styles'

import * as a11y from 'bvdash/utils/a11y'

export const DropdownList = props => {
  const { items, wordWrap } = props
  return (
    <ul>
      {items.map(({ key, children, onClick, className, ...props }, index) => (
        <Item
          key={key || index}
          {...a11y.clickable(onClick)}
          {...props}
          wordWrap={wordWrap}
        >
          {children}
        </Item>
      ))}
    </ul>
  )
}

DropdownList.propTypes = {
  items: PropTypes.array.isRequired,
}
