import gql from 'graphql-tag'
import { reverse } from 'named-urls'
import { graphql } from 'react-apollo'

import { programsListQuery } from 'bvdash/queries/withPrograms'

import { has_program_permissions } from 'bvdash/auth/permissions'
import routes from 'bvdash/routes'

const programSetDefaultMutation = gql`
  mutation programSetDefault($program: String!) {
    programSetDefault(program: $program) {
      ok
      error
      program {
        id
        key
        name
        teamCount
        isDefault

        manager {
          id
          fullName
        }
      }
    }
  }
`

export const withProgramSetDefault = graphql(programSetDefaultMutation, {
  props: ({ mutate, ownProps }) => ({
    programSetDefault: async (programKey, redirect = false) => {
      const { data } = await mutate({
        variables: { program: programKey },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: programsListQuery }],
      })
      const { programSetDefault } = data

      const {
        isBusinessPlan,
        isEnterprisePlan,
        isFreePlan,
        isProfessionalPlan,
      } = ownProps

      const onClickRoute = isFreePlan
        ? routes.program.team
        : isProfessionalPlan || isBusinessPlan || isEnterprisePlan
        ? routes.program.dashboard
        : routes.program.team

      if (programSetDefault.ok) {
        if (redirect) {
          const { authUser, history } = ownProps

          const hasPrograms = has_program_permissions(
            authUser.user,
            'projects.view_program',
            programKey
          )

          if (hasPrograms) {
            return history.push(
              reverse(onClickRoute, {
                programKey,
              })
            )
          }

          return history.push(routes.projects.all)
        }
      } else {
        return programSetDefault.error
      }
    },
  }),
})
