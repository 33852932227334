import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { ProjectFragment } from './projectFragment'

export const projectQuery = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`

export const withProject = graphql(projectQuery, {
  options: ({ projectId }) => ({
    variables: { id: projectId },
    fetchPolicy: 'network-only',
  }),
  skip: ({ projectId }) => projectId == null,
  props: ({ data }) => {
    const { loading, project = {} } = data

    return {
      project: {
        isLoading: loading,
        project,
      },
    }
  },
})
