import * as React from 'react'
import styled from 'react-emotion'

import { Calendar } from 'bvdash-core/icons'
import { selectable, color, space, fontStyle } from 'bvdash/styles'

import { DatePicker } from 'bvdash/ui/core'

export default function DateWidget({ setValue, value }) {
  return (
    <Container>
      <Calendar />
      {setValue != null ? (
        <DatePicker
          value={value}
          onChange={setValue}
          placeholder="YYYY-MM-DD"
          component={DateInput}
        />
      ) : (
        <DateValue>{value}</DateValue>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const DateValue = styled.span`
  ${fontStyle.bold};
  ${color.sanJuan};

  border: none;
  margin-left: ${space.smaller};
`

const DateInput = styled(DateValue.withComponent('input'))`
  ${selectable};
  width: 100px;
`
