import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { StripeCustomerFragment } from './stripeCustomerFragment'
import { stripePaymentSourcesQuery } from './withStripePaymentSources'

const stripeRemovePaymentMutation = gql`
  mutation stripeRemovePaymentSource(
    $stripeCustomerId: String!
    $stripeCardId: String!
  ) {
    stripeRemovePaymentSource(
      stripeCustomerId: $stripeCustomerId
      stripeCardId: $stripeCardId
    ) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripePaymentRemove = graphql(stripeRemovePaymentMutation, {
  props: ({ mutate, ownProps }) => ({
    stripeRemovePaymentSource: async ({ stripeCustomerId, stripeCardId }) => {
      const res = await mutate({
        variables: { stripeCustomerId, stripeCardId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: customerQuery,
          },
          {
            variables: { stripeCustomerId },
            query: stripePaymentSourcesQuery,
          },
        ],
      })

      return res.data.stripeRemovePaymentSource
    },
  }),
})
