/* eslint-disable jsx-a11y/label-has-for */
import * as React from 'react'

import styled from 'react-emotion'
import { validators, Form, Field } from 'bvdash/ui/forms'
import { ButtonGroup } from 'bvdash/ui/core'
import Box from 'bvdash/ui/core/Box'
import { colorMap } from 'bvdash/styles'

import { AddButton, Button } from 'bvdash/components/buttons'

import ActionSectionTitle from './ActionSectionTitle'

class ActionUpdateTitle extends React.Component {
  state = {
    showForm: false,
  }

  showForm = () => this.setState({ showForm: true })

  hideForm = () => this.setState({ showForm: false })

  handleAddUpdate = form =>
    this.props.onAddUpdate(form).then(() => this.hideForm())

  render() {
    const { title, btnAdd } = this.props
    const { showForm } = this.state

    // only show add button if `onAddUpdate` action is defined
    const showAddBtn = this.props.onAddUpdate && !showForm

    return (
      <Content>
        <Box horizontal align="space-between" alignCross="center">
          <ActionSectionTitle inline>{title}</ActionSectionTitle>
          {showAddBtn && (
            <AddButton
              onClick={this.showForm}
              fullWidth={false}
              marginBottom={0}
              marginTop={0}
            >
              {btnAdd}
            </AddButton>
          )}
        </Box>

        {showForm && (
          <Form validation={updateValidation} onSubmit={this.handleAddUpdate}>
            {({ getFieldProps, getSubmitProps, getFormProps }) => (
              <form {...getFormProps()}>
                <Field
                  {...getFieldProps('content', {
                    placeholder: 'Enter update here',
                  })}
                >
                  {({ getLabelProps, getInputProps }) => (
                    <InputBox horizontal alignCross="center">
                      <Input {...getInputProps()} />
                      <ButtonGroup>
                        <Button onClick={this.hideForm}>Cancel</Button>
                        <Button color="add" {...getSubmitProps()}>
                          Add
                        </Button>
                      </ButtonGroup>
                    </InputBox>
                  )}
                </Field>
              </form>
            )}
          </Form>
        )}
      </Content>
    )
  }
}

ActionUpdateTitle.defaultProps = {
  btnAdd: 'Add New Update',
}

const Content = styled.div`
  padding: 20px;
`

const InputBox = styled(Box)`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
`

const Input = styled.textarea`
  min-height: 44px;
  width: 100%;
  padding: 12px 24px;
  border: 1px solid ${colorMap.sanJuan};
  font-size: 14px;
  margin-bottom: 16px;
`

const updateValidation = {
  content: validators.isRequired,
}

export default ActionUpdateTitle
