import { graphql } from 'react-apollo'

import { mapCodes } from 'bvdash/projects/utils'
import query from './projectCodes.graphql'

export default graphql(query, {
  props: ({ data }) => {
    const codes = mapCodes(data.codes, 'values')
    return { codes }
  },
})
