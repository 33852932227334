import styled from 'react-emotion'
import { color } from 'bvdash/styles'

const ActionSectionTitle = styled.header`
  ${color.dodgerBlue};
  ${props =>
    !props.inline &&
    `
    margin: 20px;
    margin-bottom: 0;
  `};
  font-size: 16px;
  line-height: 25px;
`

export default ActionSectionTitle
