import * as React from 'react'
import styled from 'react-emotion'

import Customer from './Customer'
import ProgramSwitcher from './ProgramSwitcher/ProgramSwitcher'

import { VersionInfo } from 'bvdash/admin/ui/Version/VersionInfo'
import { Layout } from 'bvdash/ui/core'

const MaxWidthContainer = styled.div`
  ${({ maxWidth }) =>
    maxWidth &&
    `
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: ${maxWidth}px;
  `}
`

const AdminLayout = props => {
  const {
    background,
    children,
    maxWidth,
    noCustomer,
    noMinHeight,
    program,
    programTitle,
    version,
  } = props

  const Internals = (
    <>
      {noCustomer ? null : <Customer />}
      {program && (
        <ProgramSwitcher program={program} programTitle={programTitle} />
      )}
      {version && <VersionInfo version={version} />}
      {children}
    </>
  )

  return (
    <Layout
      admin
      withSubHeader
      background={background}
      noMinHeight={noMinHeight}
    >
      {maxWidth > 0 ? (
        <MaxWidthContainer maxWidth={maxWidth}>{Internals}</MaxWidthContainer>
      ) : (
        Internals
      )}
    </Layout>
  )
}

export default AdminLayout
