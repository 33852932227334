/*
wihtCodeList is a wrapper around Formik's FieldArray which does
soft delete for new rows (i.e. rows not saved in database) and triggers
GraphQL mutation for existing rows (i.e. removes row from the database)

## Props

### codeList.addRow(formikAddRow)

- `formikAddRow` - reference to `arrayHelpers.push`

Example:

```js
addRow(value => arrayHelpers.push(value))
```

### codeList.removeRow(row, removeMutation, formikRemoveRow)

- `row` - object data. Should have `id` if the object comes from database

- `removeRow` - GraphQL mutation with object's ID as first argument

- `formikRemoveRow` - reference to `arrayHelpers.remove`

Example:

```js
removeRow(value, removeBudgetCode, () => arrayHelpers.remove(index))
```
*/

import * as React from 'react'

export const initialCode = {
  value: '',
  description: '',
}

export const withCodeList = Component => props => {
  const codeList = {
    addRow: formikAddRow => () => formikAddRow(initialCode),

    removeRow: (row, removeRow, formikRemoveRow) => () => {
      if (row.id) {
        removeRow(row.id).then(() => formikRemoveRow())
      } else {
        formikRemoveRow()
      }
    },
  }
  return <Component {...props} codeList={codeList} />
}
