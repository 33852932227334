import * as React from 'react'
import { cx } from 'react-emotion'

import styles from './Cell.scss'

export default function Cell({
  children,
  header,
  footer,
  stretch,
  shrink,
  style,
  className: baseClassName,
  size = 1,
  right,
}) {
  const className = cx(baseClassName, {
    [styles.header]: header,
    [styles.footer]: footer,
    [styles.cell]: !header && !footer,
    [styles.stretch]: stretch,
    [styles.shrink]: shrink,
    [styles.center]: style === 'center',
    [styles.right]: right,
  })
  return (
    <td className={className} colSpan={size}>
      {children}
    </td>
  )
}
