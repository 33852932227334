import * as React from 'react'
import { compose } from 'react-apollo'

import { withUserUpdate, withUser } from 'bvdash/admin/users/queries'

import Form from './Form/Form'

const Edit = props => {
  const {
    customer: {
      customer: { onboarding },
    },
    history,
    notificationClose,
    notificationOpen,
    setNotificationContent,
    titles,
    user: { isLoading, user },
    userUpdate,
  } = props

  if (isLoading) return null

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    mobileNumber: user.mobileNumber,
    email: user.email,
    confirmEmail: user.email,
    jobTitle: user.jobTitle || '',
    isAdmin: user.isAdmin,
  }

  const handleSubmit = updatedUser =>
    userUpdate({ id: user.id, user: updatedUser })

  return (
    <Form
      initialValues={initialValues}
      onboarding={onboarding}
      onSubmit={handleSubmit}
      handleCancel={history.goBack}
      titles={titles}
      title="Users / Edit User"
      userId={user.id}
      notificationClose={notificationClose}
      notificationOpen={notificationOpen}
      setNotificationContent={setNotificationContent}
    />
  )
}

export default compose(
  withUser,
  withUserUpdate
)(Edit)
