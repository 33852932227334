import * as React from 'react'
import { Field } from 'formik'

import styled from 'react-emotion'

import { color, background, shadow } from 'bvdash/styles'

const InlineInput = styled.input`
  ${color.sanJuan};
  ${background.solidWhite};
  ${shadow};

  padding: 9px;
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;

  &::placeholder {
    ${color.cadetBlue};
  }
`

const InlineFieldComponent = ({ field, ...props }) => (
  <InlineInput id={field.name} type="text" {...field} {...props} />
)

export const EditableCell = field => {
  const { value, row, placeholder, props, attr } = field
  return props.isEditing === row.id ? (
    <Field
      name={attr}
      placeholder={placeholder}
      component={InlineFieldComponent}
    />
  ) : (
    value
  )
}
