import { css } from 'react-emotion'
import '@reach/menu-button/styles.css'

import ColorLib from 'color'
import { map } from 'ramda'

export const headerHeight = 66
export const subHeaderHeight = 46

export const primaryFont = 'Sailec'

export const colorMap = {
  solidWhite: '#ffffff',
  alabasterLight: '#fafafa',
  alabaster: '#f3f3f3',
  cadetBlue: '#b0b6c1',
  waterloo: '#7c859d',
  sanJuan: '#344775',

  dodgerBlue: '#23a2f5',
  wasabi: '#77c76f',
  carnation: '#ff585b',
  neonCarrot: '#fe9338',
  brightSun: '#ffc944',
  moodyPurple: '#8c7eca',
  hotPink: '#ff71a8',

  black: '#000000',
  dropShadow: '#dcdfe2',
  darkBorder: '#979797',
  grey: '#e6e6e6',

  adminBlue: '#4a90e2',
}

export const opacityMap = {
  ninety: 'E6',
  eight: 'CC',
  seventy: 'B3',
  sixty: '99',
  fifty: '80',
  forty: '66',
  thirty: '4D',
  twentyFive: '40',
  twenty: '33',
  fifteen: '26',
  ten: '1A',
}

export const navBarWidth = 1270

export const color = map(
  color =>
    css`
      color: ${color};
    `,
  colorMap
)

export const background = map(
  color =>
    css`
      background-color: ${color};
      max-width: 100%;
    `,
  colorMap
)

export const backgroundAlpha = map(
  color =>
    css`
      background-color: ${ColorLib(color)
        .alpha(0.25)
        .hsl()
        .toString()};
    `,
  colorMap
)

/**
 * Space sizes
 */
export const space = {
  tiny: '4px', // something tiny...
  smaller: '8px', // half
  normal: '16px', // about one empty line
  larger: '32px', // two
  jumbo: '64px', // four
}

export const fontSize = {
  smaller: '12px',
  normal: '14px',
}

export const fontStyle = {
  smaller: css`
    font-size: ${fontSize.smaller};
  `,
  normal: css`
    font-size: ${fontSize.normal};
  `,
  bold: css`
    font-size: ${fontSize.normal};
    font-weight: 500;
  `,
}

// Obsolete: Replace with classes form `fontStyle`
export const textDefault = fontStyle.normal
export const textBold = fontStyle.bold

export const selectable = css`
  cursor: pointer;
  user-select: none;
  outline: none;
`

export const centerContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const shadow = css`
  box-shadow: 0 2px 3px 0 rgba(49, 61, 90, 0.14);
`

export const shadowLight = css`
  box-shadow: 0 2px 3px 0 rgba(49, 61, 90, 0.06);
`

export const defaultRadius = 5

/**
 * Set border radius. Values might be either boolean (disabled/enabled),
 * number (custom size) or string (e.g. 5%). Default is enabled (default size).
 *
 * @param size Border radius size, default value is 5px
 * @param tl Top-left
 * @param tr Top-right
 * @param br Bottom-right
 * @param bl Bottom-left
 */
export const radius = ({
  size = defaultRadius,
  tl = true, // top-left
  tr = true, // top-right
  br = true, // bottom-right
  bl = true, // bottom-left
} = {}) =>
  css`
    border-radius: ${[tl, tr, br, bl]
      .map(value => {
        // false disables default radius
        if (typeof value === 'boolean') {
          return value ? `${size}px` : 0
        }

        // number overrides default radius
        if (typeof value === 'number') {
          return `${value}px`
        }

        // anything else is returned as it is (e.g. `5%`)
        return value
      })
      .join(' ')};
  `

export const spaceBetween = (size: number) => css`
  margin-left: -${size}px;
  margin-right: -${size}px;

  > * {
    margin-left: ${size}px;
    margin-right: ${size}px;
  }
`

export const circle = (size: number) => css`
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
`

export const menuHoverLeft = (
  leftPadding: number = stripUnit(space.normal),
  width: number = 3
) => css`
  border-left: ${width}px solid ${colorMap.dodgerBlue};
  padding-left: ${leftPadding - width}px;
  background-color: ${colorMap.alabasterLight};
`

function stripUnit(value) {
  return value.replace(/^(\d+).*/g, '$1')
}

export const inputShadow = css`
  box-shadow: 0 4px 10px 0 rgba(52, 71, 117, 0.15);
`
export const buttonShadow = css`
  box-shadow: 0 4px 10px 0 rgba(52, 71, 117, 0.2);
`

export const activeButtonShadow = css`
  box-shadow: 0 2px 5px 0 rgba(52, 71, 117, 0.2);
`

export const cardShadow = css`
  box-shadow: 0 0px 5px 0 rgba(49, 61, 90, 0.24);
`
