import * as React from 'react'
import PropTypes from 'prop-types'

import variables from 'bvdash/styles/variables.scss'

import { VictoryChart, VictoryBar, VictoryAxis } from 'victory'

const defaultFont = {
  fontFamily: variables.primaryFont,
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 1,
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const MonthBarChart = ({ data }) => {
  return (
    <div style={{ display: 'flex', flexGrow: 1, padding: '20px 0px' }}>
      <VictoryChart
        // chart is responsive, width/height is used for scale & aspect ratio
        width={700}
        height={200}
        // reduce extra space, must be enough to fit tickLabels
        padding={20}
        // axis before/after bars
        domainPadding={[40, 40]}
        events={[
          {
            childName: 'all',
            target: 'data',
            eventHandlers: {
              onMouseOver: () => [
                {
                  childName: 'data',
                  target: 'data',
                  mutation: ({ style }) => ({
                    style: {
                      ...style,
                      fill: 'rgba(25, 162, 245, 1)',
                    },
                  }),
                },
                {
                  childName: 'axis',
                  target: 'tickLabels',
                  mutation: ({ style }) => ({
                    style: {
                      ...style,
                      fontWeight: 'bold',
                      fill: variables.sanJuan,
                    },
                  }),
                },
              ],
              onMouseOut: () => [
                {
                  childName: 'data',
                  target: 'data',
                  mutation: () => null,
                },
                {
                  childName: 'axis',
                  target: 'tickLabels',
                  mutation: () => null,
                },
              ],
            },
          },
        ]}
      >
        <VictoryAxis
          name="axis"
          style={{
            axis: { stroke: '#dcdfe2' },
            tickLabels: {
              ...defaultFont,
              fontSize: 10,
              letterSpacing: 0.8,
              textTransform: 'uppercase',
              fill: 'rgba(124, 133, 157, 0.9)',
            },
          }}
        />
        <VictoryBar
          name="data"
          labels={({ y }) => y}
          style={{
            data: {
              width: 30,
              padding: 0,
              stroke: 'none',
              fill: 'rgba(35, 162, 245, 0.25)',
            },
            labels: {
              ...defaultFont,
              fill: variables.sanJuan,
            },
          }}
          categories={{
            x: months,
          }}
          data={data.map((y, index) => ({ x: months[index], y }))}
        />
      </VictoryChart>
    </div>
  )
}

MonthBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
}

export default MonthBarChart
