import * as React from 'react'
import PropTypes from 'prop-types'
import { defaultTo, equals } from 'ramda'

// Let's keep it for new future ;)
// function handleAll(...handlers) {
//   const validHandlers = handlers.filter(
//     handler => typeof handler === 'function'
//   )
//
//   return e => validHandlers.forEach(handler => handler(e))
// }

const defaultValue = defaultTo('')

export default class Form extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    validation: PropTypes.object,
    children: PropTypes.func,
  }

  static defaultProps = {
    initialValues: {},
    validation: {},
  }

  state = {
    data: {},
    files: {},
    errors: {},
    isSubmitted: false,
    isSubmitting: false,
  }

  _mounted = false

  componentDidMount() {
    this._mounted = true

    if (this.props.initialValues) {
      this.resetForm()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.initialValues &&
      prevProps.initialValues &&
      !equals(this.props.initialValues, prevProps.initialValues)
    ) {
      this.resetForm()
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  resetForm = () => {
    this.setState({ data: this.props.initialValues })
  }

  handleChange = e => {
    const { name, type, value: _value, checked } = e.target

    const value = type === 'checkbox' ? checked : _value

    let file = null
    if (e.target.type === 'file') {
      file = e.target.multiple ? e.target.files : e.target.files[0]
    }

    this.setState(({ data, files }) => ({
      data: { ...data, [name]: value },
      files: file ? { ...files, [name]: file } : files,
    }))
    this.setIsSubmitted(false)
  }

  setValue = name => value =>
    this.setState(({ data }) => ({ data: { ...data, [name]: value } }))

  setFile = name => file =>
    this.setState(({ files, data }) => ({
      data: { ...data, [name]: file },
      files: { ...files, [name]: file },
    }))

  setIsSubmitted = (isSubmitted = true) => this.setState({ isSubmitted })
  setIsSubmitting = (isSubmitting = true) => this.setState({ isSubmitting })

  validate = data => {
    const { validation } = this.props

    const errors = {}
    Object.keys(validation).forEach(field => {
      errors[field] = !this.isFieldValid(
        field,
        field === '_' ? data || {} : data[field]
      )
    })

    this.setState({ errors })
    return !Object.keys(errors).some(field => errors[field])
  }

  isFieldValid = (name, value) => {
    const { validation } = this.props
    const validators = validation[name]

    if (!validators) {
      return true
    } else if (typeof validators === 'function') {
      return validators(value)
    }

    return validators.some(validator => validator(value))
  }

  handleSubmit = event => {
    event.preventDefault()

    const { onSubmit } = this.props
    const { data, files } = this.state

    this.setIsSubmitted()

    const isValid = this.validate(data)
    if (!isValid) return

    this.setIsSubmitting()
    const promise = onSubmit({ data, files, event })

    if (!promise || promise.then === undefined) {
      this.setIsSubmitting(false)
      this.resetForm()
    } else {
      // setState must be guarded with this._mounted, because there could
      // be a redirect to different page in promise chain. This would case
      // unmount of form and calling setState after form is already unmounted.
      promise
        .then(() => this._mounted && this.setIsSubmitting(false))
        .then(() => this._mounted && this.resetForm())
        .catch(() => this._mounted && this.setIsSubmitting(false))
    }
  }

  handleFocus = e => {
    const { name } = e.target
    this.setState({ errors: { ...this.state.errors, [name]: false } })
  }

  handleBlur = e => {
    const { name, value } = e.target
    const hasError = !this.isFieldValid(name, value)
    this.setState({ errors: { ...this.state.errors, [name]: hasError } })
  }

  render() {
    const { children, initialValues } = this.props
    const { isSubmitted, isSubmitting, data, errors } = this.state

    const getFieldProps = (name, { type, placeholder } = {}) => {
      const value = defaultValue(data[name])
      const initialValue = defaultValue(initialValues[name])

      return {
        setValue: this.setValue(name),
        setFile: this.setFile(name),
        onChange: this.handleChange,
        onFocus: this.handleFocus,
        onBlur: this.handleBlur,
        value,
        name,
        type,
        placeholder,
        isValid: !errors[name],
        isDirty: value !== initialValue,
      }
    }

    const getSubmitProps = ({ onClick } = {}) => ({
      disabled: isSubmitting,
      onClick,
      role: 'submit',
      type: 'submit',
    })

    const getFormProps = () => ({
      onSubmit: this.handleSubmit,
    })

    const isDirty = Object.keys(data).some(
      key => data[key] && (initialValues[key] || '') !== this.state.data[key]
    )

    const isValid = !Object.keys(this.props.validation).some(
      key => !this.isFieldValid(key, key === '_' ? data : data[key])
    )

    const props = {
      getFieldProps,
      getSubmitProps,
      getFormProps,
      isDirty,
      isValid,
      isSubmitted,
      isSubmitting,
    }

    return children(props)
  }
}
