import { Field, Form } from 'formik'
import PropTypes from 'prop-types'
import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import styled from 'react-emotion'

import {
  MainSlotContainer,
  SideSlotContainer,
  SlotsContainer,
  TextArea,
  TextAreaContainer,
  TopBar,
  FormWrapper,
} from './styles'

import { FormActions } from 'bvdash/ui/core'
import { Button } from 'bvdash/components'
import { InputField } from 'bvdash/components/inputs'
import { DropzoneField } from 'bvdash/projects/ui'
import { Attachments } from 'bvdash/actions/ui'

import { withAttachmentDelete } from 'bvdash/actions/queries'

export const ProjectComponentForm = props => {
  const {
    cancelOnClick = null,
    description,
    isSubmitting,
    left,
    mainWidth,
    sideWidth,
    MainSlotContent,
    topBarInputArray,

    attachmentDelete,
    edit = false,
    formKey,
    handleUpdate,
    history,
  } = props

  const renderInputs = topBarInputArray.map(input => {
    const {
      components,
      disabled,
      displayErrorText,
      disablePastDates = false,
      InputComponent,
      label,
      name,
      placeholder,
      type,
    } = input

    return (
      <InputComponent
        components={components}
        disabled={disabled}
        displayErrorText={displayErrorText}
        disablePastDates={disablePastDates}
        label={label}
        key={name}
        name={name}
        placeholder={placeholder}
        options={input.options}
        type={type}
      />
    )
  })

  const descriptionInput = {
    components: {
      InputContainer: TextAreaContainer,
      Label: null,
      Input: TextArea,
    },
    name: description.name,
    placeholder: description.placeholder,
  }

  const handleAttachmentDelete = file => () => attachmentDelete(file.id)

  const handleAttachmentUpload = attachments => handleUpdate(attachments)

  const AttachmentsField = () => (
    <Attachments
      attachments={props[formKey][formKey].attachments}
      onDelete={handleAttachmentDelete}
      onUpload={handleAttachmentUpload}
    />
  )

  const cancelText = 'Cancel'

  const CancelButton = !cancelOnClick ? (
    <Button
      marginTop={'2px'}
      marginBottom={'2px'}
      marginLeft={4}
      marginRight={4}
      onClick={() => history.goBack()}
      type="button"
    >
      {cancelText}
    </Button>
  ) : (
    <Button
      marginTop={'2px'}
      marginBottom={'2px'}
      marginLeft={4}
      marginRight={4}
      onClick={cancelOnClick}
      type="button"
    >
      {cancelText}
    </Button>
  )

  return (
    <FormWrapper>
      <Form autoComplete="off">
        <TopBar>{renderInputs}</TopBar>
        <SlotsContainer left={left}>
          <MainSlotContainer flex={mainWidth}>
            <InputField
              components={descriptionInput.components}
              name={descriptionInput.name}
              placeholder={descriptionInput.placeholder}
              type="text"
            />
            {MainSlotContent}
          </MainSlotContainer>
          <SideSlotContainer left={left} flex={sideWidth}>
            {edit ? (
              <Field name="attachments" component={AttachmentsField} />
            ) : (
              <Field name="attachments" component={DropzoneField} />
            )}
          </SideSlotContainer>
        </SlotsContainer>
        <FormActions>
          <ButtonContainer>
            {CancelButton}
            <Button
              marginTop={'2px'}
              marginBottom={'2px'}
              marginLeft={4}
              marginRight={4}
              color="add"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormActions>
      </Form>
    </FormWrapper>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  flex-wrap: wrap;
`

export default compose(
  withAttachmentDelete,
  withRouter
)(ProjectComponentForm)

ProjectComponentForm.propTypes = {
  cancelOnClick: PropTypes.func,
  left: PropTypes.bool,
  mainWidth: PropTypes.number,
  sideWidth: PropTypes.number,
}

ProjectComponentForm.defaultProps = {
  left: false,
  mainWidth: 2,
  sideWidth: 1,
}
