import React, { useRef } from 'react'

import { Dropdown } from 'bvdash/ui/core'
import { CheckboxField, Form } from 'bvdash/ui/forms'
import { Settings } from 'bvdash-core/icons'
import { useToggle } from 'bvdash/hooks/useToggle'

import { Button, BlueCircleButton } from 'bvdash/components/buttons'

import * as a11y from 'bvdash/utils/a11y'

import styles from './TableConfig.scss'

export default function TableConfig(props) {
  const toggle = useToggle()
  const containerRef = useRef()

  const handleSubmit = ({ data }) => {
    const config = {
      filters: {},
      columns: {},
    }

    Object.keys(data).forEach(key => {
      const [type, name] = key.split('-')
      if (type !== 'filters' && type !== 'columns') return
      config[type][name] = data[key]
    })

    props.onSave(config)
    toggle.close()
  }

  const { filters, columns, config } = props
  const initialValues = {}

  Object.keys(config.filters).map(
    filter => (initialValues[`filters-${filter}`] = config.filters[filter])
  )
  Object.keys(config.columns).map(
    column => (initialValues[`columns-${column}`] = config.columns[column])
  )

  return (
    <div className={styles.root} ref={containerRef}>
      {/* Trigger */}
      <div {...a11y.clickable(toggle.toggle)} className={styles.trigger}>
        <BlueCircleButton Icon={Settings} />
      </div>

      {/* Dropdown content */}
      <Dropdown
        position="tableConfig"
        isOpen={toggle.isOpen}
        onClose={toggle.close}
        outsideClickRef={containerRef}
      >
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ getFormProps, getFieldProps, getSubmitProps }) => (
            <form {...getFormProps()} className={styles.content}>
              <header className={styles.columns}>
                <span className={styles.title}>Filters</span>
                <span className={styles.title}>Columns</span>
              </header>
              <div className={styles.options}>
                <ul className={styles.column}>
                  {Object.keys(filters).map(filter => (
                    <li key={`filters-${filter}`} className={styles.checkbox}>
                      <CheckboxField
                        fieldProps={getFieldProps(`filters-${filter}`)}
                      >
                        {filters[filter]}
                      </CheckboxField>
                    </li>
                  ))}
                </ul>
                <ul className={styles.column}>
                  {Object.keys(columns).map(column => (
                    <li key={`columns-${column}`} className={styles.checkbox}>
                      <CheckboxField
                        fieldProps={getFieldProps(`columns-${column}`)}
                      >
                        {columns[column]}
                      </CheckboxField>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.buttons}>
                <Button fullWidth onClick={toggle.close} role="button">
                  Cancel
                </Button>
                <Button {...getSubmitProps()} color="add" fullWidth>
                  Apply
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Dropdown>
    </div>
  )
}
