import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { mapCodes, unique } from 'bvdash/projects/utils'

export const ProjectFragment = gql`
  fragment Project on ProjectType {
    id
    key
    name
    isFavorite
    overBudget
    behindSchedule

    manager {
      id
      key
      fullName
    }

    codes {
      id
      key
      value
      description
    }
  }
`

export const withCriticalProjects = graphql(
  gql`
    query projectsCritical {
      projects(critical: true) {
        ...Project
      }

      codes: programCodesGroup {
        key
        values {
          id
          value
          description
        }
      }
    }

    ${ProjectFragment}
  `,
  {
    props: ({ data }) => {
      const list = data.projects || []
      const codes = mapCodes(data.codes, 'values')

      const projects = list.map(project => ({
        ...project,
        codes: mapCodes(project.codes),
      }))

      const managers = unique(projects, ({ manager }) => [manager.key, manager])

      return {
        criticalProjects: {
          isLoading: data.loading,
          projects,
          codes,
          managers,
        },
      }
    },
  }
)
