import * as React from 'react'
import styled, { css } from 'react-emotion'

import { Attach } from 'bvdash-core/icons'
import uploadPlaceholder from 'bvdash-core/artwork/UploadSmilingFolder.svg'

import { background, shadow, space, radius } from 'bvdash/styles'
import { CardHeader, IconBox } from 'bvdash/ui/core'
import { Dropzone, Card } from 'bvdash/projects/ui'

function DropzoneWithPlaceholder({ field }) {
  return (
    <Dropzone field={field} className={dropzoneClass} multiple>
      {({ files, openFileDialog }) =>
        files ? (
          <Card noBorder>
            <CardHeader>
              Attachments{' '}
              <IconBox onClick={openFileDialog}>
                <Attach inline />
              </IconBox>
            </CardHeader>

            {files}
          </Card>
        ) : (
          <Placeholder onClick={openFileDialog}>
            <ImgResize src={uploadPlaceholder} alt="" />
            Upload File
          </Placeholder>
        )
      }
    </Dropzone>
  )
}

const ImgResize = styled.img`
  max-width: 50%;
  max-height: 50%;
`

const dropzoneClass = css`
  flex-grow: 1;
  display: flex;
  height: 100%;
`

const Placeholder = styled.div`
  ${background.solidWhite};
  ${shadow};
  ${radius()};

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  img {
    margin-bottom: ${space.normal};
  }
`

export default DropzoneWithPlaceholder
