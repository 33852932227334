import * as React from 'react'
import { Field } from 'formik'

import {
  Checkbox as StandardInput,
  ErrorContainer,
  InputContainer as StandardContainer,
  Label as StandardLabel,
} from './styles'
import { errorHandler } from './errorHandler'
import { Error as ErrorText } from 'bvdash/ui/forms'

export const CheckboxField = props => {
  const {
    components: {
      InputContainer = StandardContainer,
      Input = StandardInput,
      Label = StandardLabel,
    },
    disabled,
    displayError,
    label,
    name,
    size,
    styles,
  } = props

  const { checkboxStyle, containerStyle, labelStyle } = styles

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched } }) => {
        const error = errorHandler(name, touched, errors)
        return (
          <InputContainer style={containerStyle}>
            <Label style={labelStyle}>{label}</Label>
            <Input
              {...field}
              checked={field.value}
              disabled={disabled}
              error={error}
              size={size}
              style={checkboxStyle}
              type="checkbox"
            />
            {displayError ? (
              <ErrorContainer>
                <ErrorText show={error}>{errors[field.name]}</ErrorText>
              </ErrorContainer>
            ) : null}
          </InputContainer>
        )
      }}
    />
  )
}

CheckboxField.defaultProps = {
  components: {
    InputContainer: StandardContainer,
    Input: StandardInput,
    Label: StandardLabel,
  },
  displayError: false,
  styles: {
    checkboxStyle: {},
    containerStyle: {},
    labelStyle: {},
  },
}
