import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { reverse } from 'named-urls'

import { ThreeDotsMenu } from 'bvdash/ui/tables'

import routes from 'bvdash/routes'
import { withUserUpdate } from 'bvdash/admin/users/queries'
import { authUser } from 'bvdash/auth/queries'

const Actions = props => {
  const {
    history,
    user: { id },
  } = props

  const actions = [
    {
      label: 'Edit User',
      onClick: () => {
        return history.push(reverse(routes.admin.usersEdit, { userId: id }))
      },
    },
  ]

  return <ThreeDotsMenu actions={actions} />
}

const ListActions = compose(
  authUser,
  withUserUpdate,
  withRouter
)(Actions)

export const listActionsColumn = () => ({
  attr: '_actions',
  render: ({ row }) => <ListActions user={row} />,
  shrink: true,
})
