import PropTypes from 'prop-types'
import { capitalize } from 'bvdash/utils'

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function() {
  return this.replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase()
  })
}

// TODO(Erik): Switch to user customer theme context.
export const renderLabelText = (type, theme) => {
  if (!theme) {
    return capitalize(type)
  }
  switch (type) {
    case 'portfolio':
      return theme.navTitlePortfolio
    case 'program':
      return capitalize(theme.navTitleProgram)
    case 'project':
      return capitalize(theme.navTitleProjects)
    default:
      return capitalize(type)
  }
}

export const LabelText = props => {
  const { plural, prefix, suffix, type, theme } = props

  const setText = renderLabelText(type, theme)

  const setPlural = plural ? `${setText}s` : setText

  const setPrefix = prefix ? `${prefix} ${setPlural}` : setPlural

  const setSuffix = suffix ? `${setPrefix} ${suffix}` : setPrefix

  return setSuffix.capitalize()
}

LabelText.propTypes = {
  low: PropTypes.bool,
  plural: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  theme: PropTypes.object,
  type: PropTypes.string.isRequired,
  upp: PropTypes.bool,
}

LabelText.defaultProps = {
  plural: false,
  prefix: '',
  suffix: '',
  upp: false,
}
