import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { propEq } from 'ramda'
import styled, { css } from 'react-emotion'
import useOnClickOutside from 'use-onclickoutside'
import download from 'bvdash/utils/download'

import {
  AsyncImage,
  Box,
  Button,
  CardHeader,
  Plural,
  UploadArea,
} from 'bvdash/ui/core'
import { TableContainer } from 'bvdash/components/projectSelectTable/styles'
import { ClosePreview, Download, Trash, PlusO } from 'bvdash-core/icons'
import { Attachment } from '.'
import {
  background,
  color,
  colorMap,
  selectable,
  centerContent,
  shadow,
} from 'bvdash/styles'
import { clickable } from 'bvdash/utils/a11y'
import { Dialog } from '@reach/dialog'

export default function Attachments({
  attachments = [],
  top,
  onDelete,
  onUpload,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setHovered] = useState(false)
  const [previewImage, setPreviewImage] = useState({})
  const previewDialogRef = useRef(null)

  const handleTogglePreview = () => setIsOpen(!isOpen)
  const handlePreviewChange = previewImage => () =>
    setPreviewImage(previewImage)

  useOnClickOutside(previewDialogRef, handleTogglePreview)

  const images = attachments.filter(file => imagesRe.test(file.name))
  const files = attachments.filter(file => !imagesRe.test(file.name))
  const preview = images.find(propEq('id', previewImage.id)) || images[0]

  return (
    <Content top={top}>
      <CardAttachments>
        <CardHeader>Images</CardHeader>

        <UploadArea onUpload={onUpload}>
          {({ openFileDialog }) =>
            images.length ? (
              <Box alignCross="center">
                <PreviewMain>
                  <AsyncImage
                    className={previewMainClass}
                    preview={preview.preview}
                    src={preview.url}
                    onClick={handleTogglePreview}
                    alt={preview.name}
                  />

                  <PreviewDialog
                    className="PreviewDialog"
                    isOpen={isOpen}
                    onClick={handleTogglePreview}
                  >
                    <div ref={previewDialogRef}>
                      <div
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                      >
                        <AsyncImage
                          preview={preview.preview}
                          src={preview.url}
                          onClick={handleTogglePreview}
                          alt={preview.name}
                        />
                        {isHovered && (
                          <>
                            <ClosePreviewContainer>
                              Close
                              <ClosePreview className={closeButtonClass} />
                            </ClosePreviewContainer>
                            <Download
                              className={downloadButtonClass}
                              onClick={() =>
                                download(preview.url, preview.name)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </PreviewDialog>

                  {onDelete && (
                    <Trash
                      className={deleteIconClass}
                      {...clickable(onDelete(preview))}
                    />
                  )}
                </PreviewMain>
                <PreviewNavigation>
                  <Plural
                    value={images.length}
                    one="# Photo"
                    other="# Photos"
                  />
                </PreviewNavigation>

                <PreviewList>
                  {images.map(image => (
                    <PreviewItem
                      key={image.url}
                      {...clickable(!onDelete && handlePreviewChange(image))}
                    >
                      <AsyncImage
                        className={previewImageClass}
                        preview={image.preview}
                        src={image.url}
                        alt={image.name}
                      />
                      {onDelete && (
                        <Trash
                          className={deleteIconClass}
                          {...clickable(onDelete(image))}
                        />
                      )}
                    </PreviewItem>
                  ))}
                  {openFileDialog && (
                    <AddImage key="add-image" {...clickable(openFileDialog)}>
                      <PlusO />
                    </AddImage>
                  )}
                </PreviewList>
              </Box>
            ) : (
              <NoFiles>
                No files available.{' '}
                {openFileDialog && (
                  <Button theme="link" {...clickable(openFileDialog)}>
                    Add new.
                  </Button>
                )}
              </NoFiles>
            )
          }
        </UploadArea>
      </CardAttachments>

      <CardAttachments>
        <CardHeader>Attachments</CardHeader>

        <UploadArea onUpload={onUpload}>
          {({ openFileDialog }) =>
            files.length ? (
              <TableContainer>
                <Files>
                  {files.map(file => (
                    <li key={file.id}>
                      <Attachment file={file} onDelete={onDelete} />
                    </li>
                  ))}
                  {openFileDialog && (
                    <AddFile key="add-new" {...clickable(openFileDialog)}>
                      <PlusO />
                    </AddFile>
                  )}
                </Files>
              </TableContainer>
            ) : (
              <NoFiles>
                No files available.{' '}
                {openFileDialog && (
                  <Button theme="link" {...clickable(openFileDialog)}>
                    Add new.
                  </Button>
                )}
              </NoFiles>
            )
          }
        </UploadArea>
      </CardAttachments>
    </Content>
  )
}

Attachments.propTypes = {
  attachments: PropTypes.array,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onUpload: PropTypes.func,
  top: PropTypes.number,
}

const PreviewMain = styled.div`
  position: relative;
  margin: 16px 0 16px;
  border-radius: 5px;
  focus {
    outline: 0;
  }
  max-width: 100%;
`

const previewMainClass = css`
  width: 248px;
  height: 170px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  max-width: 100%;
`

const PreviewList = styled.ul`
  width: 228px;
  margin: -10px -13px 0 -13px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > li {
    margin: 10px 13px;
  }
`

const PreviewItem = styled.li(
  `
  position: relative;
  text-align: center;
`,
  props => props.onClick && selectable
)

const previewImageClass = css`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`
const ClosePreviewContainer = styled.ul(
  `
  align-items: center;
  background: ${colorMap.alabasterLight};
  border-radius: 16px;
  display: flex;
  height: 48px;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 96px;
  `
)

const closeButtonClass = css`
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding-left: 5px;
  top: 0;
`
const downloadButtonClass = css`
  background: ${colorMap.alabasterLight};
  border-radius: 16px;
  bottom: 40px;
  cursor: pointer;
  height: 64px;
  position: absolute;
  right: 40px;
  opacity: 0.9;
  padding-top: 8px;
  padding-left: 14px;
  width: 64px;
`

const AddImage = styled.li`
  ${centerContent};
  ${selectable};
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid ${colorMap.darkBorder};
`

const PreviewNavigation = styled.div`
  width: 202px;
  margin-bottom: 7px;
`

const deleteIconClass = css`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);

  background: #fff;
  border-radius: 50%;
`

const Content = styled.div(
  `
    flex-grow: 1;
    max-width: 100%;
  `,
  props =>
    props.top !== undefined && {
      position: 'relative',
      top: props.top,
      transition: 'top 0.2s ease-out',
    }
)

const CardAttachments = styled.div`
  ${shadow};
  ${background.solidWhite};
  width: 100%;
  margin-bottom: 16px;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 16px;
  }
`

const Files = styled.ul`
  padding: 24px;
  list-style: none;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 11px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    ${background.darkBorder};
    border-radius: 8px;
    border: 2px solid ${colorMap.solidWhite};
  }
`

const AddFile = styled.li`
  ${selectable};
  text-align: center;
`

const NoFiles = styled.p`
  padding: 24px;
`
const PreviewDialog = styled(Dialog)`
  position: relative;
  box-shadow: 0 6px -6px black;
  width: fit-content;
  max-width: 1024px;
  max-height: 800px;

  span {
    cursor: default;
    ${color.dodgerBlue};
    position: absolute;
    top: 1%;
    right: 6%;
  }
`

const imagesRe = new RegExp(/\.(jpe?g|png|gif)$/, 'i')
