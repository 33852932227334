const HOUColumns = [
  'key',
  '_isFavorite',
  'name',
  'field.First Name',
  'field.Last Name',
  'field.Temporary Address',
  'field.Permanent Address',
  'field.Main Phone',
  'Unit Size',
  'Temp-Perm Unit',
  'field.GIN Notice',
  'field.Assessment',
  'field.30 Days Notice',
  'field.Packing',
  'field.Move-Out',
  'field.Constr Start',
  'field.Constr End',
  'field.Move In/Back',
  'field.Main Phone',
  'managerName',
  'Status',
  'overBudget',
  'behindSchedule',
  '_actions',
]

export function HOUReorderProjectColumns(defaultColumns) {
  const columnByAttr = {}
  defaultColumns.forEach(column => {
    columnByAttr[column.attr] = column
  })
  return HOUColumns.map(attr => {
    const column = columnByAttr[attr]
    if (attr === 'managerName') {
      return {
        ...column,
        label: 'Project Lead',
      }
    } else if (attr === 'name') {
      return {
        ...column,
        label: 'Unit',
      }
    } else {
      return column
    }
  }).filter(Boolean)
}

export const isHou = customer =>
  !customer.isLoading &&
  customer.customer &&
  customer.customer.name === 'Housing Opportunities Unlimited'

export const scopeName = customer => (isHou(customer) ? 'Note' : 'Scope')
