import * as React from 'react'

import { Dropdown, DropdownList } from 'bvdash/components/toggle'

export const ToggleDropdown = props => {
  const { choices, handleClick, toggle, width, wordWrap } = props
  return (
    <Dropdown isOpen={toggle.isOpen} onClose={toggle.close} width={width}>
      <DropdownList
        items={choices.map(item => ({
          key: item.label,
          children: item.label,
          onClick: () => {
            handleClick(item)
            toggle.close()
          },
        }))}
        wordWrap={wordWrap}
      />
    </Dropdown>
  )
}
