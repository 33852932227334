import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

const VersionFragment = gql`
  fragment VersionFragment on VersionType {
    id
    date
    deleted
    published
    type

    author {
      id
      fullName
    }
    __typename
  }
`

export const versionQuery = gql`
  query version($programKey: String, $kind: VersionEnum) {
    version(programKey: $programKey, kind: $kind) {
      ...VersionFragment
    }
  }
  ${VersionFragment}
`

export const withLatestVersions = type =>
  graphql(versionQuery, {
    options: props => {
      const { defaultProgram } = props
      if (defaultProgram == null) {
        return {
          variables: {
            kind: type.toUpperCase(),
          },
          fetchPolicy: 'network-only',
        }
      }
      return {
        variables: {
          programKey: defaultProgram.key,
          kind: type.toUpperCase(),
        },
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data }) => {
      const { loading, version } = data

      return {
        [`${type}Version`]: {
          isLoading: loading,
          version,
        },
      }
    },
  })
