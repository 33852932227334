import styled from 'react-emotion'

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`

export const Label = styled.p`
  font-size: 18px;
  line-height: 1.25;
  margin-left: 8px;
  margin-right: 8px;
`
