import React from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'

import styles from './Headline.scss'

class Headline extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    level: PropTypes.number,
    className: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
  }

  static defaultProps = {
    level: 1,
  }

  render() {
    const { children, level, align, className: customClass } = this.props

    const className = cx(customClass, styles['headline' + level], {
      [styles.left]: align === 'left',
      [styles.center]: align === 'center',
      [styles.right]: align === 'right',
    })
    const headlineProps = { className }

    return React.createElement(`h${level}`, headlineProps, children)
  }
}

export default Headline
