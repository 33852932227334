/*
Apollo client configuration
===========================

Browser environment
-------------------

Different domain is used for production and staging,
"localhost" domain is used for development.

Subscription queries are routed over WebSockets.
All other queries are routed over HTTP using `split` link.

Testing environment
-------------------

WebSockets are disabled and requests are resolved using mocked schema.
 */

import { createUploadLink } from 'apollo-upload-client'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloClient } from 'apollo-client'
import { SchemaLink } from 'apollo-link-schema'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { makeExecutableSchema, addMockFunctionsToSchema } from 'graphql-tools'

import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

let wsClient, link

if (process.env.NODE_ENV !== 'test') {
  // Regular environemnt

  let domainApi = `https://${process.env.DOMAIN_API}`
  let domainWs = `wss://${process.env.DOMAIN_API}`
  if (process.env.NODE_ENV === 'development') {
    if (process.env.DOMAIN_API.startsWith('lan-api.bvdash.com')) {
      // In local env, use non-secure protocols
      domainApi = `http://${process.env.DOMAIN_API}`
      domainWs = `ws://${process.env.DOMAIN_API}`
    }
  }

  const headers = {}
  if (process.env.CIRCLECI_TOKEN) {
    headers['circleci-token'] = process.env.CIRCLECI_TOKEN
  }

  // development: /graphql
  // staging:     https://staging-api.bvdash.com
  // production:  https://api.bvdash.com
  const httpLink = createUploadLink({
    uri: `${domainApi}/graphql/`,
    // send cookie with every request (we use session-based authentication)
    credentials: 'include',
    headers,
  })

  // WebSocket client/link for Subscription queries
  wsClient = new SubscriptionClient(domainWs, {
    // connect only when required, otherwise we might connect before
    // we're authorized (e.g. on login page)
    lazy: true,

    // automatically reconnect when server connection is lost
    reconnect: true,
  })
  const wsLink = new WebSocketLink(wsClient)

  // handle subscriptions in websoket link and the rest in http link
  link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return (
        window !== undefined &&
        kind === 'OperationDefinition' &&
        operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )
} else {
  // Testing environemnt

  // Make a GraphQL schema with no resolvers
  const schema = makeExecutableSchema({
    typeDefs: require('./apollo.graphqls'),
  })

  // Add mocks, modifies schema in place
  addMockFunctionsToSchema({
    schema,
    mocks: {
      DateTime: () => {
        return '2018-09-20'
      },
    },
  })

  wsClient = {}
  link = new SchemaLink({ schema })
}

const cache = new InMemoryCache({ fragmentMatcher })

// wsClient is used in `logout` action to disconnect existing WS connection
export { wsClient }

export default new ApolloClient({
  link,
  cache,
})
