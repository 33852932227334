import * as React from 'react'

import { Logo } from 'bvdash-core/icons'
import { FullScreenContainer } from 'bvdash/components/standard/containers'

export const LoadingScreen = () => {
  return (
    <FullScreenContainer alignItems="center" justifyContent="center">
      <Logo />
    </FullScreenContainer>
  )
}
