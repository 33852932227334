import styled from 'styled-components'

export const Table = styled.table`
  margin: auto;
`

export const TableCell = styled.td`
  padding: 4px;
  text-align: center;
  vertical-align: middle;
`
