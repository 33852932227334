import styled, { css } from 'styled-components'

import { StandardText } from 'bvdash/components/standard/texts'

const fullSize = css`
  height: 100%;
  width: 100%;
`
const borderRadius = css`
  border-radius: 5px;
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex || 1};
  flex-basis: 333px;
  justify-content: space-between;
  max-width: 100%;
  padding: 16px 8px;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    &:first-child {
      border-bottom: 1px solid ${({ theme }) => theme.color.main};
    }
  }
`

export const InnerSection = styled.div`
  ${fullSize};
  height: ${({ height }) => height || '40vh'};
  min-height: 50%;
  margin-bottom: 16px;
`

// Upload
export const UploadInputContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  ${fullSize};
  ${borderRadius};
  border: 1px solid ${({ theme }) => theme.color.shadow};
  position: relative;
`

export const dropzoneStyle = {
  height: '100%',
  position: 'absolute',
  width: '100%',
}

// Info
export const DescriptionItem = styled(StandardText)`
  flex: 1;
  margin-bottom: 8px;
  margin-left: 4px;
`
export const LinkWrapper = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.color.main};
  display: flex;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.accent};
  }
`

export const Spacer = styled.div`
  background: ${({ theme }) => theme.color.muted};
  height: 1px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
`
