import * as React from 'react'

import { Table, TableCell } from './styles'
import { Card } from 'bvdash/components/standard/containers'
import {
  LargeText,
  RegularText,
  StandardText,
  RegularHeaderText,
} from 'bvdash/components/standard/texts'

import { isUnlimited } from 'bvdash/plans'

export const NumCountCard = props => {
  const { items, isTrial } = props

  const TableHeader = [
    {
      color: '',
      Component: StandardText,
      value: '',
    },
    {
      color: '',
      Component: StandardText,
      value: 'Active',
    },
    {
      color: '',
      Component: StandardText,
      value: 'Paid',
    },
    {
      color: '',
      Component: StandardText,
      value: 'Available',
    },
  ].map(t => {
    const { Component, value } = t
    return (
      <TableCell key={value}>
        <Component>{value}</Component>
      </TableCell>
    )
  })

  const CountItems = items.map(item => {
    const { currentCount, maxCount, title } = item
    const unlimited = isUnlimited(maxCount)

    const available = maxCount - currentCount

    const TableRow = [
      {
        color: 'main',
        Component: StandardText,
        value: title,
      },
      {
        color: currentCount > maxCount ? 'error' : 'success',
        Component: LargeText,
        value: currentCount,
      },
      {
        color: 'accent',
        Component: isTrial || unlimited ? RegularText : LargeText,
        value: isTrial || unlimited ? 'Unlimited' : maxCount,
      },
      {
        color: 'caution',
        Component: isTrial || unlimited ? RegularText : LargeText,
        value: isTrial || unlimited ? 'Unlimited' : available,
      },
    ].map((t, i) => {
      const { color, Component, value } = t
      return (
        <TableCell key={i}>
          <Component color={color}>{value}</Component>
        </TableCell>
      )
    })
    return <tr key={title}>{TableRow}</tr>
  })

  return (
    <Card {...props} marginLeft={16} overflow="auto">
      {isTrial ? <RegularHeaderText>FREE TRIAL</RegularHeaderText> : null}
      <Table>
        <tbody>
          <tr>{TableHeader}</tr>
          {CountItems}
        </tbody>
      </Table>
    </Card>
  )
}
