import * as React from 'react'

import { ProjectTeamDropdown } from 'bvdash/admin/users/List/Status'
import { search } from 'bvdash/ui/tables'
import { UserPhoto } from 'bvdash/ui/core'
import styles from 'bvdash/programs/scenes/ProgramTeam/ProgramTeam.scss'

const baseConfig = {
  rowKey: row => row.id,
  sortBy: 'name',
  search: value => user => search(value, user.fullName, user.jobTitle),
}

export const projectTeamListConfig = {
  ...baseConfig,
  name: 'adminProjectTeam',
  data: props => props.teamUsers || [],
  columns: props => {
    const { onAddUser, onRemoveUser, titles } = props
    return [
      {
        attr: 'fullName',
        label: 'Name',
        render: ({ value, row: user }) => {
          return (
            <>
              <UserPhoto
                name={user.fullName}
                photo={
                  user.userProfilePicture && user.userProfilePicture.length > 0
                    ? user.userProfilePicture[0].url
                    : null
                }
                className={styles.photo}
              />{' '}
              {value}
            </>
          )
        },
      },
      {
        attr: 'jobTitle',
        label: 'Job Title',
      },
      {
        attr: 'email',
        label: 'Email',
      },
      {
        attr: 'phoneNumber',
        label: 'Phone',
      },
      {
        attr: 'isMember',
        label: `${titles.team} Member`,
        render: ({ value, row: user }) => {
          const handleClick = () =>
            user.isMember ? onRemoveUser(user.id) : onAddUser(user.id)

          const displayDropdown = !user.isManager

          return (
            <ProjectTeamDropdown
              displayDropdown={displayDropdown}
              handleClick={handleClick}
              user={user}
              value={value}
            />
          )
        },
        shrink: true,
      },
    ]
  },
}
