import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { pick } from 'ramda'

import { addToCache } from 'bvdash/utils/graphql'
import { unique, mapCodes } from 'bvdash/projects/utils'
import { deserializeProjectForTable } from 'bvdash/projects/serializers'

import { programsListQuery } from 'bvdash/queries/withPrograms'
import { customerQuery } from 'bvdash/queries/settings/withCustomer'

const programCodesQuery = gql`
  query programCodes {
    programCodes {
      id
      key
      value
      description
      deleted

      __typename
    }
  }
`

export const withProgramCodes = graphql(programCodesQuery, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => ({
    data: (data.programCodes || []).filter(programCode => !programCode.deleted),
    isLoading: data.loading,
  }),
})

export const withProgramCode = graphql(
  gql`
    query programCode($key: String) {
      programCodes(key: $key) {
        id
        key
        value
        description
      }
    }
  `,
  {
    options: props => ({
      variables: {
        key: props.codeKey,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => !props.codeKey,
    props: ({ data }) => {
      const { programCodes = [] } = data
      return {
        programCode: {
          isLoading: data.isLoading,
          key: data.variables.key,
          values: programCodes.map(pick(['id', 'value', 'description'])),
        },
      }
    },
  }
)

export const withProgramCodeCreate = graphql(
  gql`
    mutation programCodeCreate($programCode: ProgramCodeInput!) {
      programCodeCreate(programCode: $programCode) {
        ok
        programCode {
          id
          key
          value
          description
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      programCodeCreate: programCode => {
        return mutate({ variables: { programCode } })
      },
    }),
  }
)

export const withProgramCodeUpdate = graphql(
  gql`
    mutation programCodeUpdate($id: ID!, $programCode: ProgramCodeInput!) {
      programCodeUpdate(id: $id, programCode: $programCode) {
        ok
        programCode {
          id
          key
          value
          description
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      programCodeUpdate: (id, programCode) => {
        return mutate({ variables: { id, programCode } })
      },
    }),
  }
)

export const withProgramCodeRemove = graphql(
  gql`
    mutation programCodeRemove($id: ID!) {
      programCodeRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      programCodeRemove: id => {
        return mutate({
          variables: { id },
          update: proxy =>
            proxy.writeFragment({
              id: `ProgramCodeType:${id}`,
              fragment: gql`
                fragment DeleteProgramCode on ProgramCodeType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'ProgramCodeType',
              },
            }),
        })
      },
    }),
  }
)

/**
 * Program Codes Group
 */

export const withProgramCodesGroup = graphql(
  gql`
    query programCodesGroup {
      programCodesGroup {
        key
        values {
          value
          label: description
        }
      }
    }
  `,
  {
    options: {
      fetchPolicy: 'network-only',
    },
    props: ({ data }) => ({
      programCodesGroup: {
        isLoading: data.loading,
        codes: data.programCodesGroup || [],
      },
    }),
  }
)

/**
 * Project Data - Preview
 */

const ProjectPreviewFragment = gql`
  fragment ProjectPreview on ProjectType {
    id
    key
    name

    deleted
    removeStatus
    manager {
      id
      key
      fullName
    }

    codes {
      id
      key
      value
      description
    }

    fields {
      key
      value
    }

    __typename
  }
`

export const projectsPreviewQuery = gql`
  query projectsPreview($versionId: ID, $programKey: String) {
    projects(
      program: $programKey
      versionId: $versionId
      includeUnpublished: true
    ) {
      ...ProjectPreview
    }

    codes: programCodesGroup {
      key
      values {
        id
        value
        description
      }
    }
  }

  ${ProjectPreviewFragment}
`

export const withProjectsPreview = graphql(projectsPreviewQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      programKey: props.programKey,
      versionId: props.versionId,
    },
  }),
  props: ({ data }) => {
    const codes = mapCodes(data.codes, 'values')

    const list = data.projects || []
    const projects = list
      .filter(project => !project.deleted)
      .map(project => deserializeProjectForTable(project))

    const managers = unique(projects, ({ manager }) => [manager.key, manager])

    const versionDate = new Date((data.version || {}).date)

    return {
      projectsPreview: {
        isLoading: data.loading,
        projects,
        codes,
        managers,
        versionDate,
      },
    }
  },
})

/**
 * Project Data - Get item by ID
 */

const projectQuery = gql`
  query project($id: ID!) {
    project(id: $id, includeUnpublished: true) {
      ...ProjectPreview
    }
  }

  ${ProjectPreviewFragment}
`

export const withProject = graphql(projectQuery, {
  options: props => ({
    variables: {
      id: props.projectId,
    },
    fetchPolicy: 'network-only',
  }),
  skip: props => props.projectId == null,
  props: ({ data }) => ({
    project: {
      isLoading: data.loading,
      project: data.project,
    },
  }),
})

/**
 * Project Data - Create Item
 */

export const withProjectFormData = graphql(
  gql`
    query projectFormData($programId: ID!) {
      managerChoices: programUsers(programId: $programId) {
        value: id
        label: fullName
      }
    }
  `,
  {
    options: ({ program }) => ({
      variables: {
        programId: program.id,
      },
      fetchPolicy: 'network-only',
    }),
    props: ({ data }) => ({
      projectFormData: {
        isLoading: data.loading,
        managerChoices: data.managerChoices || [],
      },
    }),
  }
)

const projectCreateMutation = gql`
  mutation projectCreate(
    $programId: ID!
    $versionId: ID
    # $key: String!
    $project: ProjectInput!
  ) {
    projectCreate(
      programId: $programId
      versionId: $versionId
      # key: $key
      project: $project
    ) {
      ok
      project {
        ...ProjectPreview
      }
    }
  }

  ${ProjectPreviewFragment}
`

export const withProjectCreate = graphql(projectCreateMutation, {
  props: ({ mutate }) => ({
    projectCreate: ({ programId, versionId, project }) => {
      return mutate({
        variables: { programId, versionId, project },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: programsListQuery },
          { query: customerQuery },
        ],
      })
    },
  }),
})

/**
 * Project Data - Update Item
 */

const projectUpdateMutation = gql`
  mutation projectUpdate(
    $projectId: ID!
    $versionId: ID
    $project: ProjectInput!
  ) {
    projectUpdate(
      projectId: $projectId
      versionId: $versionId
      project: $project
    ) {
      ok
      error
      project {
        ...ProjectPreview
      }
    }
  }

  ${ProjectPreviewFragment}
`

export const withProjectUpdate = graphql(projectUpdateMutation, {
  props: ({ mutate }) => ({
    projectUpdate: ({ projectId, versionId, project }) => {
      return mutate({
        variables: { projectId, versionId, project },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: programsListQuery }],
      })
    },
  }),
})

/**
 * Project Data - Remove item
 */
const projectRemoveMutation = gql`
  mutation projectRemove($id: ID!, $versionId: ID!) {
    projectRemove(id: $id, versionId: $versionId) {
      ok
      error
    }
  }
`

export const withProjectRemove = graphql(projectRemoveMutation, {
  props: ({ mutate, ownProps }) => ({
    projectRemove: (id, versionId) =>
      mutate({
        variables: { id, versionId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: projectsPreviewQuery,
            variables: {
              programKey: ownProps.programKey,
              versionId: versionId,
            },
          },
          { query: customerQuery },
        ],
      }),
  }),
})

const projectRemoveCancelMutation = gql`
  mutation projectRemoveCancel($id: ID!) {
    projectRemoveCancel(id: $id) {
      ok
      error
    }
  }
`

export const withProjectRemoveCancel = graphql(projectRemoveCancelMutation, {
  props: ({ mutate }) => ({
    projectRemoveCancel: id =>
      mutate({
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: projectsPreviewQuery }],
      }),
  }),
})

/**
 * Project Fields
 */

const projectFieldsQuery = gql`
  query projectFields {
    projectFields {
      id
      key
      label
      deleted
    }
  }
`

export const withProjectFields = graphql(projectFieldsQuery, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => {
    const all = (data.projectFields || []).filter(
      projectField => !projectField.deleted
    )

    return {
      projectFields: {
        all,
        isLoading: data.loading,
      },
    }
  },
})

export const withProjectFieldCreate = graphql(
  gql`
    mutation projectFieldCreate($projectField: ProjectFieldInput!) {
      projectFieldCreate(projectField: $projectField) {
        ok
        projectField {
          id
          key
          label
          deleted
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      projectFieldCreate: ({ projectField }) => {
        return mutate({
          variables: { projectField },
          update: (proxy, { data: { projectFieldCreate } }) => {
            if (!projectFieldCreate.ok) return

            addToCache(
              proxy,
              {
                query: projectFieldsQuery,
              },
              {
                projectFields: prevProjectFields => [
                  projectFieldCreate.projectField,
                  ...prevProjectFields,
                ],
              }
            )
          },
        })
      },
    }),
  }
)

export const withProjectFieldUpdate = graphql(
  gql`
    mutation projectFieldUpdate($id: ID!, $projectField: ProjectFieldInput!) {
      projectFieldUpdate(id: $id, projectField: $projectField) {
        ok
        projectField {
          id
          key
          label
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      projectFieldUpdate: ({ id, projectField }) => {
        return mutate({ variables: { id, projectField } })
      },
    }),
  }
)

/**
 * Budget Field - Remove
 */

export const withProjectFieldRemove = graphql(
  gql`
    mutation projectFieldRemove($id: ID!) {
      projectFieldRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      projectFieldRemove: id => {
        return mutate({
          variables: { id },
          update: proxy =>
            proxy.writeFragment({
              id: `ProjectFieldType:${id}`,
              fragment: gql`
                fragment DeleteProjectField on ProjectFieldType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'ProjectFieldType',
              },
            }),
        })
      },
    }),
  }
)
