import * as React from 'react'

import { DateComponent } from 'bvdash/components/DateComponent'

export const renderInputArray = inputArray =>
  inputArray.map(input => {
    const {
      components,
      disabled,
      displayErrorText,
      id,
      InputComponent,
      label,
      name,
      placeholder,
      type,
    } = input

    return (
      <InputComponent
        components={components}
        disabled={disabled}
        displayErrorText={displayErrorText}
        label={label}
        key={id || name}
        name={name}
        placeholder={placeholder}
        options={input.options}
        type={type}
        {...input}
      />
    )
  })

export const shortDateRender = ({ value }) => {
  if (!value) {
    return
  }
  return <DateComponent date={value} />
}
