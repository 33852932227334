import { pick, union } from 'ramda'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { addToCache } from 'bvdash/utils/graphql'
import { scheduleDateRange } from 'bvdash/projects/utils'
import { deserializeScheduleItem } from 'bvdash/projects/serializers'

import { normalize } from 'bvdash/projects/queries/projectScheduleQuery'

const ScheduleCodeFragment = gql`
  fragment ScheduleCode on ScheduleCodeType {
    id
    key
    value
    description
  }
`

const scheduleCodesQuery = gql`
  query scheduleCodes($program: String) {
    scheduleCodes(program: $program) {
      ...ScheduleCode
      deleted
      __typename
    }
  }

  ${ScheduleCodeFragment}
`

export const withScheduleCodes = graphql(scheduleCodesQuery, {
  options: props => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      program: props.program !== null ? props.program.key : null,
    },
  }),
  props: ({ data }) => {
    const { loading, scheduleCodes = [] } = data
    return {
      data: (scheduleCodes || []).filter(scheduleCode => !scheduleCode.deleted),
      isLoading: loading,
    }
  },
})

export const withScheduleCode = graphql(
  gql`
    query scheduleCode($programKey: String, $key: String) {
      scheduleCodes(program: $programKey, key: $key) {
        ...ScheduleCode
      }
    }

    ${ScheduleCodeFragment}
  `,
  {
    options: props => ({
      variables: {
        programKey: props.programKey,
        key: props.codeKey,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => props.programKey == null || props.codeKey == null,
    props: ({ data }) => {
      const { scheduleCodes = [] } = data
      return {
        scheduleCode: {
          isLoading: data.loading,
          key: data.variables.key,
          values: scheduleCodes.map(pick(['id', 'value', 'description'])),
        },
      }
    },
  }
)

export const withScheduleCodeCreate = graphql(
  gql`
    mutation scheduleCodeCreate(
      $program: String!
      $scheduleCode: ScheduleCodeInput!
    ) {
      scheduleCodeCreate(program: $program, scheduleCode: $scheduleCode) {
        ok
        scheduleCode {
          ...ScheduleCode
        }
      }
    }
    ${ScheduleCodeFragment}
  `,
  {
    props: ({ mutate }) => ({
      scheduleCodeCreate: (program, scheduleCode) => {
        return mutate({ variables: { program, scheduleCode } })
      },
    }),
  }
)

export const withScheduleCodeUpdate = graphql(
  gql`
    mutation scheduleCodeUpdate($id: ID!, $scheduleCode: ScheduleCodeInput!) {
      scheduleCodeUpdate(id: $id, scheduleCode: $scheduleCode) {
        ok
        scheduleCode {
          ...ScheduleCode
        }
      }
    }
    ${ScheduleCodeFragment}
  `,
  {
    props: ({ mutate }) => ({
      scheduleCodeUpdate: (id, scheduleCode) => {
        return mutate({ variables: { id, scheduleCode } })
      },
    }),
  }
)

export const withScheduleCodeRemove = graphql(
  gql`
    mutation scheduleCodeRemove($id: ID!) {
      scheduleCodeRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      scheduleCodeRemove: id => {
        return mutate({
          variables: { id },
          update: proxy =>
            proxy.writeFragment({
              id: `ScheduleCodeType:${id}`,
              fragment: gql`
                fragment DeleteScheduleCode on ScheduleCodeType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted: true,
                __typename: 'ScheduleCodeType',
              },
            }),
        })
      },
    }),
  }
)

/**
 * Schedule Codes Group
 */

export const withScheduleCodesGroup = graphql(
  gql`
    query scheduleCodesGroup($program: String!) {
      scheduleCodesGroup(program: $program) {
        key
        values {
          value
          label: description
        }
      }
    }
  `,
  {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        program: props.program.key,
      },
    }),
    props: ({ data }) => ({
      scheduleCodesGroup: {
        isLoading: data.loading,
        codes: data.scheduleCodesGroup || [],
      },
    }),
  }
)

/**
 * Schedule Data - Preview
 */

const ScheduleItemFragment = gql`
  fragment ScheduleItem on ScheduleItemType {
    id
    key
    description
    earlyStart
    earlyStartBl
    earlyFinish
    earlyFinishBl
    deleted

    project {
      id
      key
    }
    codes {
      ...ScheduleCode
    }
  }

  ${ScheduleCodeFragment}
`

const schedulePreviewQuery = gql`
  query schedulePreview(
    $version: Int
    $programId: ID!
    $project: String
    $first: Int
    $offset: Int
  ) {
    schedule(
      first: $first
      offset: $offset
      programId: $programId
      project: $project
      published: false
      version: $version
    ) {
      version {
        id
        date
      }

      items {
        ...ScheduleItem
      }

      milestones {
        ...ScheduleItem
      }
    }
  }

  ${ScheduleItemFragment}
`

const makeMilestone = milestoneArray => {
  return milestoneArray.map(milestone => ({
    ...milestone,
    isMilestone: true,
  }))
}

const FIRST = 100
const OFFSET = 0

export const withSchedulePreview = graphql(schedulePreviewQuery, {
  options: props => {
    const { program, project, versionId } = props
    return {
      fetchPolicy: 'network-only',
      variables: {
        version: versionId,
        programId: program.id,
        project,

        offset: OFFSET,
        first: FIRST,
      },
    }
  },
  props: ({ data }) => {
    const { fetchMore, loading } = data

    const { items = [], milestones = [], version = {} } = data.schedule || {}

    const onFetchMore = () =>
      fetchMore({
        variables: { offset: items.length },
        updateQuery: (prev, args) => {
          const { fetchMoreResult } = args
          if (fetchMoreResult.schedule.items.length === 0) {
            return { size: fetchMoreResult.schedule.items.length, ...prev }
          }

          return {
            ...prev,
            schedule: {
              ...prev.schedule,
              items: union(prev.schedule.items, fetchMoreResult.schedule.items),
            },
          }
        },
      })

    const allowFetchMore = items.length % FIRST !== 0

    const versionDate = version != null ? new Date(version.date) : new Date()

    const scheduleItems = items
      .filter(item => !item.deleted)
      .map(item => deserializeScheduleItem(item, false))

    const mappedMilestones = milestones.map(normalize(versionDate))
    const scheduleMilestones = makeMilestone(mappedMilestones)
    const schedule = [...scheduleMilestones, ...scheduleItems]

    const dateRange = scheduleDateRange(schedule, versionDate)

    return {
      schedulePreview: {
        dateRange,
        isLoading: loading,
        milestones: scheduleMilestones,
        onFetchMore: allowFetchMore ? null : () => onFetchMore(),
        schedule,
        versionDate,
      },
    }
  },
})

/**
 * Schedule Data - Get item by ID
 */

export const withScheduleItem = graphql(
  gql`
    query scheduleItem($id: ID!) {
      scheduleItem(id: $id) {
        ...ScheduleItem
      }
    }

    ${ScheduleItemFragment}
  `,
  {
    options: props => ({
      variables: {
        id: props.itemId,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => props.itemId == null,
    props: ({ data }) => ({
      scheduleItem: {
        isLoading: data.loading,
        item: data.scheduleItem,
      },
    }),
  }
)

/**
 * Schedule Data - Create Item
 */

export const withScheduleItemCreate = graphql(
  gql`
    mutation scheduleItemCreate(
      $projectId: ID!
      $versionId: ID!
      $key: String!
      $scheduleItem: ScheduleItemInput!
    ) {
      scheduleItemCreate(
        projectId: $projectId
        versionId: $versionId
        key: $key
        scheduleItem: $scheduleItem
      ) {
        ok
        scheduleItem {
          ...ScheduleItem
        }
      }
    }

    ${ScheduleItemFragment}
  `,
  {
    props: ({ mutate }) => ({
      scheduleItemCreate: ({ projectId, versionId, key, scheduleItem }) => {
        return mutate({
          variables: { projectId, versionId, key, scheduleItem },
          update: (proxy, { data: { scheduleItemCreate } }) => {
            if (!scheduleItemCreate.ok) return

            addToCache(
              proxy,
              {
                query: schedulePreviewQuery,
                variables: {
                  versionId,
                },
              },
              {
                schedule: {
                  items: prevItems => [
                    scheduleItemCreate.scheduleItem,
                    ...prevItems,
                  ],
                },
              }
            )
          },
        })
      },
    }),
  }
)

/**
 * Schedule Data - Update Item
 */

export const withScheduleItemUpdate = graphql(
  gql`
    mutation scheduleItemUpdate($id: ID!, $scheduleItem: ScheduleItemInput!) {
      scheduleItemUpdate(id: $id, scheduleItem: $scheduleItem) {
        ok
        scheduleItem {
          ...ScheduleItem
        }
      }
    }

    ${ScheduleItemFragment}
  `,
  {
    props: ({ mutate }) => ({
      scheduleItemUpdate: ({ id, scheduleItem }) => {
        return mutate({
          variables: { id, scheduleItem },
        })
      },
    }),
  }
)

/**
 * Schedule Data - Remove item
 */

export const withScheduleItemRemove = graphql(
  gql`
    mutation scheduleDataRemove($id: ID!) {
      scheduleItemRemove(id: $id) {
        ok
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      scheduleItemRemove: id =>
        mutate({
          variables: { id },
          optimisticResponse: {
            scheduleItemRemove: {
              __typename: 'ScheduleItemRemove',
              ok: true,
            },
          },
          update: (proxy, response) => {
            const {
              data: {
                scheduleItemRemove: { ok: deleted },
              },
            } = response

            proxy.writeFragment({
              id: `ScheduleItemType:${id}`,
              fragment: gql`
                fragment DeleteScheduleType on ScheduleItemType {
                  deleted
                  __typename
                }
              `,
              data: {
                deleted,
                __typename: 'ScheduleItemType',
              },
            })
          },
        }),
    }),
  }
)
