// @flow
import * as React from 'react'
import styled from 'react-emotion'

import ConfirmDialog from 'bvdash/ui/core/ConfirmDialog'
import Headline from 'bvdash/ui/core/Headline/Headline'
import Button from 'bvdash/ui/core/Button/Button'

const Buttons = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin: 5px;
  }
`

const Question = styled.p`
  margin: 40px 0;
`

type ConfirmStatusChangeProps = {
  children: Function,
}

export default function ConfirmStatusChange(props: ConfirmStatusChangeProps) {
  return (
    <ConfirmDialog
      dialog={({ hide, confirm }) => (
        <>
          <Headline size={3}>Change of project status</Headline>

          <Question>
            You're about to change the project status.
            <br />
            Are you sure you want to proceed?
          </Question>

          <Buttons>
            <Button onClick={hide}>Cancel</Button>
            <Button theme="primary" onClick={confirm}>
              Confirm
            </Button>
          </Buttons>
        </>
      )}
    >
      {confirm => props.children(confirm)}
    </ConfirmDialog>
  )
}
