import { withFormik } from 'formik'
import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { object, string } from 'yup'

import { AddButton, Button } from 'bvdash/components/buttons'
import { FormActions } from 'bvdash/ui'
import { CheckboxField, InputField } from 'bvdash/components/inputs'
import routes from 'bvdash/routes'

import { FormContainer, FormWrapper, CheckboxContainer } from './styles'
import { inputData } from './inputData'

import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'

const UserPage = props => {
  const {
    handleCancel,
    history,
    initialValues,
    onSubmit,
    title,
    titles,
    userId,
    notificationOpen,
    onboarding,
    setNotificationContent,
    handleNextStep,
  } = props

  const onSubmitRedirect = () => history.push(routes.admin.users)

  const [nextStep, setNextStep] = React.useState(false)

  const handleSuccess = () => {
    notificationOpen()
    setNotificationContent({ type: 'success', text: 'User successfully added' })
    if (onboarding) {
      onSubmitRedirect()
    }
  }

  const handleError = err => {
    const dummyErr = 'There was an error submitting the form. Try again.'
    notificationOpen()
    setNotificationContent({
      type: 'error',
      text: err == null ? dummyErr : err,
    })
  }

  return (
    <>
      <AdminBreadcrumbs>{title}</AdminBreadcrumbs>
      <UserForm
        handleCancel={handleCancel}
        handleError={handleError}
        handleNextStep={handleNextStep}
        handleSuccess={handleSuccess}
        initialValues={initialValues}
        nextStep={nextStep}
        setNextStep={setNextStep}
        onboarding={onboarding}
        onSubmit={onSubmit}
        titles={titles}
        userId={userId}
      />
    </>
  )
}

// TODO(Erik): Need to update validation with correct messages.
const validationSchema = object().shape({
  firstName: string().required('Please enter a first name'),
  lastName: string().required('Please enter a last name'),
  email: string().required('Please enter a valid email address'),
  confirmEmail: string()
    .required('Please re-enter the email address')
    .test('confirmEmail', 'Emails do not match.', function(value) {
      const { email } = this.parent
      return value === email
    }),
})

const checkboxInputStyle = {
  containerStyle: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  labelStyle: {
    flex: 1,
  },
}

const withUserForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: ({ initialValues }) => initialValues,

  handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
    const {
      handleError,
      handleNextStep,
      handleSuccess,
      onSubmit,
      nextStep,
    } = props

    setSubmitting(true)

    try {
      const user = {
        email: values.email || '',
        firstName: values.firstName || '',
        isAdmin: values.isAdmin || false,
        jobTitle: values.jobTitle || '',
        lastName: values.lastName || '',
        phoneNumber: values.phoneNumber || '',
        mobileNumber: values.mobileNumber || '',
      }
      const res = await onSubmit(user)
      if (!res.ok) {
        handleError(res.error)
      } else {
        handleSuccess()
        resetForm()
        if (nextStep) {
          handleNextStep()
        }
      }
    } catch (err) {
      handleError(err.message)
    }

    setSubmitting(false)
  },

  validationSchema,
})

const UserForm = compose(withUserForm)(props => {
  const {
    disabled,
    handleCancel,
    handleSubmit,
    onboarding,
    submitForm,
    setNextStep,
  } = props

  const renderInputs = inputData.map(input => (
    <InputField
      key={input.name}
      label={input.label}
      name={input.name}
      placeholder={input.placeholder}
      type={input.type}
    />
  ))

  const handleSaveAndNext = () => {
    setNextStep(true)
    handleSubmit()
  }
  const verticalMargin = 8
  const OnboardingButtons = (
    <>
      <div>
        <AddButton
          marginBottom={verticalMargin}
          marginTop={verticalMargin}
          onClick={submitForm}
        >
          Save and Add Another User
        </AddButton>
      </div>
      <Button color="add" disabled={disabled} onClick={handleSaveAndNext}>
        Save and Next
      </Button>
    </>
  )

  const StandardButtons = (
    <>
      <Button onClick={handleCancel}>Cancel</Button>
      <Button color="add" type="submit" disabled={disabled}>
        Save
      </Button>
    </>
  )

  return (
    <FormWrapper>
      <FormContainer>
        {renderInputs}
        <CheckboxContainer>
          <CheckboxField
            label="Admin privileges (Admins have full access to the system including billing information)"
            name="isAdmin"
            size={25}
            styles={checkboxInputStyle}
          />
        </CheckboxContainer>
      </FormContainer>
      <FormActions>
        {!onboarding ? OnboardingButtons : StandardButtons}
      </FormActions>
    </FormWrapper>
  )
})

export default withRouter(UserPage)
