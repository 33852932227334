import dateFnsParse from 'date-fns/parse'
import { Field } from 'formik'
import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'

import {
  ErrorContainer,
  IconContainer,
  InputContainer as StandardContainer,
  Input as StandardInput,
  Label as StandardLabel,
} from './styles'
import { errorHandler } from './errorHandler'
import { Error as ErrorText } from 'bvdash/ui/forms'
import { Calendar } from 'bvdash-core/icons'
import { useSettings } from 'bvdash/hooks/useSettings'

import { colorMap } from 'bvdash/styles'

// TODO(Erik): Look into this.
const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
}

const DatePicker = props => {
  const { disablePastDates, field, onChange, value } = props

  const handleDayChange = React.useCallback(
    (selectedDay, modifiers, dayPickerInput) => onChange(selectedDay),
    []
  )

  const { formatDate } = useSettings()

  const disableDaysBefore = { disabledDays: { before: new Date() } }

  return (
    <DayPickerInput
      dayPickerProps={disablePastDates && disableDaysBefore}
      formatDate={formatDate}
      inputProps={field}
      onDayChange={handleDayChange}
      parseDate={parseDate}
      value={value}
      {...props}
    />
  )
}

export const DateField = props => {
  const {
    components: {
      InputContainer = StandardContainer,
      Input = StandardInput,
      Label = StandardLabel,
    },
    disabled,
    displayErrorText,
    iconColor,
    iconStyle,
    label,
    name,
    placeholder,
  } = props

  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { errors, touched } = form

        const error = errorHandler(name, touched, errors)

        return (
          <InputContainer>
            {label && <Label error={error}>{label}</Label>}
            <IconContainer iconStyle={iconStyle}>
              <Calendar color={error ? colorMap.carnation : iconColor} />
            </IconContainer>
            <DatePicker
              component={props => (
                <Input error={error} disabled={disabled} {...props} />
              )}
              onChange={value => form.setFieldValue(name, value)}
              placeholder={placeholder}
              value={field.value}
              {...props}
            />
            {displayErrorText && (
              <ErrorContainer>
                <ErrorText show={error}>{errors[name]}</ErrorText>
              </ErrorContainer>
            )}
          </InputContainer>
        )
      }}
    />
  )
}

DateField.defaultProps = {
  components: {
    InputContainer: StandardContainer,
    Input: StandardInput,
    Label: StandardLabel,
  },
  disablePastDates: false,
  displayErrorText: true,
  iconColor: colorMap.waterloo,
  iconStyle: false,
  placeholder: 'Select Date',
}
