import { makeFilterChoices } from 'bvdash/ui/tables'

export const activeInactiveStatusChoices = makeFilterChoices([
  [true, 'Active'],
  [false, 'Inactive'],
])
export const projectTeamStatusChoices = makeFilterChoices([
  [true, 'Assigned'],
  [false, 'Unassigned'],
])

export const yesNoStatusChoices = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const openCloseStatusChoices = [
  { value: true, label: 'Open' },
  { value: false, label: 'Close' },
]
