import styled from 'react-emotion'
import { Form } from 'formik'

const VerticalForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
`

export default VerticalForm
