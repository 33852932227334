import gql from 'graphql-tag'

export const IssueFragment = gql`
  fragment Issue on IssueType {
    id
    issueId
    urgency
    importance
    priority
    content
    isOpen
    status
    canEdit
    assignedTo {
      id
      key
      fullName
    }
    __typename
  }
`
