import styled, { css } from 'react-emotion'
import { background, shadow, colorMap } from 'bvdash/styles'

export default styled.div`
  ${background.solidWhite};
  ${shadow};

  flex-grow: 1;
  line-height: 1.5;
  max-width: 100%;
  overflow: auto;

  ${props => css`
    ${props.underHeader ? `border-radius: 0 0 5px 5px` : `border-radius: 5px`};
    ${props.main && `border-left-color: ${colorMap.dodgerBlue}`};
    ${!props.noBorder && `border-left: 5px solid transparent;`};
  `};
`
