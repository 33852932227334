import Loadable from 'react-loadable'

import Loading from 'bvdash/ui/core/Loading'

// Authentication
export const Login = Loadable({
  loader: () => import('bvdash/auth/scenes/Login'),
  loading: Loading,
})

export const PasswordResetRequest = Loadable({
  loader: () => import('bvdash/auth/scenes/PasswordResetRequest'),
  loading: Loading,
})

export const PasswordReset = Loadable({
  loader: () => import('bvdash/auth/scenes/PasswordReset'),
  loading: Loading,
})

// Admin
export const AdminBase = Loadable({
  loader: () => import('bvdash/admin/scenes/AdminBase'),
  loading: Loading,
})

// Programs and Projects
export const ProgramsList = Loadable({
  loader: () => import('bvdash/programs/scenes/ProgramsList'),
  loading: Loading,
})
export const ProgramBaseDetail = Loadable({
  loader: () => import('bvdash/programs/scenes/ProgramBaseDetail'),
  loading: Loading,
})
export const ProjectBaseDetail = Loadable({
  loader: () => import('bvdash/projects/scenes/ProjectBaseDetail'),
  loading: Loading,
})
export const ProjectBaseList = Loadable({
  loader: () => import('bvdash/projects/scenes/ProjectBaseList'),
  loading: Loading,
})

// Onboarding
export const OnboardingBase = Loadable({
  loader: () => import('bvdash/onboarding/scenes/OnboardingBase'),
  loading: Loading,
})

// Accounts
export const AccountsBase = Loadable({
  loader: () => import('bvdash/accounts/scenes/AccountsBase'),
  loading: Loading,
})

// SuperUsers
export const SuperUsersBase = Loadable({
  loader: () => import('bvdash/superUsers/scenes/SuperUsersBase'),
  loading: Loading,
})

// Calendar
export const CalendarBase = Loadable({
  loader: () => import('bvdash/calendar/Calendar'),
  loading: Loading,
})

// Actions
export const ActionsBase = Loadable({
  loader: () => import('bvdash/actions/scenes/ActionsBase'),
  loading: Loading,
})

// Alerts
export const AlertsList = Loadable({
  loader: () => import('bvdash/alerts/scenes/AlertsList'),
  loading: Loading,
})

// Timesheets
export const TimesheetsBase = Loadable({
  loader: () => import('bvdash/timesheets/scenes/TimesheetsBase'),
  loading: Loading,
})

// Conversations
export const ConversationsBase = Loadable({
  loader: () => import('bvdash/conversations/scenes/ConversationsBase'),
  loading: Loading,
})

// Profile
export const ProfileBase = Loadable({
  loader: () => import('bvdash/profile/scenes/Profile'),
  loading: Loading,
})
