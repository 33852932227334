import styled from 'react-emotion'

import { Text } from './styles'
import { ButtonContainer } from 'bvdash/components'
import { background, cardShadow, color } from 'bvdash/styles'

const defaultSpacing = 16

export const Card = styled.div`
  ${cardShadow};
  ${background.solidWhite};
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: ${defaultSpacing * 2}px ${defaultSpacing}px;
  text-align: center;
  transition: background 0.1s ease;

  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  width: 360px;
`

export const CardHeader = styled.h1`
  ${color.sanJuan};
  font-weight: 500;
  font-size: 24px;
  margin-bottom: ${defaultSpacing * 2}px;
`

export const CardText = styled(Text)``

export const CardButton = styled(ButtonContainer)`
  margin-top: ${defaultSpacing * 2}px;
  text-transform: uppercase;
`
