import * as React from 'react'

import { TableButton } from './TableButton'

// TODO: Improve how this works.
import { ToggleDropdown, withToggle } from 'bvdash/components/toggle'

export const ToggleModalButton = withToggle(props => {
  const { choices = [], handleClick, text, toggle, type } = props
  return (
    <>
      <TableButton onClick={() => toggle.toggle()} type={type}>
        {text}
      </TableButton>
      <ToggleDropdown
        choices={choices}
        handleClick={handleClick}
        toggle={toggle}
      />
    </>
  )
})
