import { reverse } from 'named-urls'
import * as React from 'react'
import styled, { withTheme } from 'styled-components'

import routes from 'bvdash/routes'

import { DateComponent } from 'bvdash/components/DateComponent'

import * as Card from 'bvdash/ui/core/Card'

import { pluralize } from 'bvdash/utils'
import { setColor } from 'bvdash/css'

import { PieChart } from 'bvdash/components/charts'
import {
  LegendWrapper,
  LegendBox,
  LegendContainer,
  LegendItem,
  LegendItemText,
} from 'bvdash/components/charts/Legend'

const Text = styled.span`
  color: ${({ behindSchedule, theme }) =>
    behindSchedule ? theme.color.error : theme.color.success};
  font-size: ${({ isLarge }) => (isLarge ? 64 : 16)}px;
  margin-bottom: ${({ isLarge }) => (isLarge ? 8 : 0)}px;
`
const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 200px;
  justify-content: center;
  padding: 16px;
`

const ScheduleTotal = props => {
  const {
    forProgram,
    objectKey,
    schedule,
    scheduleStatus,
    versionsDropdown,
    theme,
    titles,
  } = props

  if (!schedule) return null

  const earlyStart = new Date(schedule.earlyStart)
  const earlyFinish = new Date(schedule.earlyFinish)
  const earlyFinishBl = new Date(schedule.earlyFinishBl)

  // TODO: Fix this function.
  const variance =
    schedule.variance != null
      ? schedule.variance
      : (earlyFinishBl - earlyFinish) / 86400000

  const { summaryText, behindSchedule } =
    variance >= 0
      ? {
          summaryText:
            variance === 1 ? 'Day ahead of schedule' : 'Days ahead of schedule',
          behindSchedule: false,
        }
      : {
          summaryText:
            variance === -1 ? 'Day behind schedule' : 'Days behind schedule',
          behindSchedule: true,
        }

  const legendLabels = (type, finish = false) => {
    let prefix = ''
    let suffix = ''
    if (type === 'program') {
      if (finish) {
        suffix = 'Finish'
      } else {
        suffix = 'Start'
      }
    } else {
      prefix = 'B.L.'
      if (finish) {
        suffix = 'Finish'
      } else {
        suffix = 'Start'
      }
    }
    return `${prefix} ${titles[type]} ${suffix}`
  }

  const scheduleStatusData = forProgram
    ? [
        {
          description: `${pluralize(titles.project)} on ${titles.schedule}`,
          label: `On ${titles.schedule}`,
          value: scheduleStatus.onSchedule,
          color: setColor('onSchedule', theme),
        },
        {
          description: `${pluralize(titles.project)} over ${titles.schedule}`,
          label: `Behind ${titles.schedule}`,
          value: scheduleStatus.behindSchedule,
          color: setColor('behindSchedule', theme),
        },
      ]
    : []

  const Legend = scheduleStatusData.map(({ label, value, color }) => {
    return (
      <LegendItem key={label}>
        <LegendBox color={color} />
        <LegendItemText>{label}</LegendItemText>
        {value}
      </LegendItem>
    )
  })

  const scheduleData = [
    {
      label: forProgram ? legendLabels('program') : legendLabels('project'),
      value: <DateComponent date={earlyStart} />,
    },
    {
      label: forProgram
        ? legendLabels('program', true)
        : legendLabels('project', true),
      value: <DateComponent date={earlyFinishBl} />,
    },
    {
      label: 'Forecasted Finish',
      value: <DateComponent date={earlyFinish} />,
    },
    {
      label: 'Variance',
      value: (
        <Text behindSchedule={behindSchedule}>
          {variance} {Math.abs(variance) === 1 ? 'day' : 'days'}
        </Text>
      ),
    },
  ]

  const title = forProgram
    ? `${titles.program} ${titles.schedule}`
    : `${titles.project} ${titles.schedule}`

  const detailsLink = forProgram
    ? reverse(routes.program.schedule, { programKey: objectKey })
    : reverse(routes.project.schedule, { projectKey: objectKey })

  return (
    <Card.Card
      title={title}
      detailsLink={detailsLink}
      versions={versionsDropdown}
    >
      {forProgram ? (
        <PieChart data={scheduleStatusData} Legend={Legend} />
      ) : (
        <TextContainer>
          <Text behindSchedule={behindSchedule} isLarge>
            {variance}
          </Text>
          <Text behindSchedule={behindSchedule}>{summaryText}</Text>
        </TextContainer>
      )}
      <LegendWrapper>
        <LegendContainer>
          {scheduleData.map(({ label, value, color }) => (
            <LegendItem key={label}>
              <LegendItemText>{label}</LegendItemText>
              {value}
            </LegendItem>
          ))}
        </LegendContainer>
      </LegendWrapper>
    </Card.Card>
  )
}

export default withTheme(ScheduleTotal)
