import gql from 'graphql-tag'

import { AlertFragment } from './alertFragment'

export const AlertsSubscription = gql`
  subscription AlertsSubscription {
    alerts {
      ...AlertFragment
    }
  }
  ${AlertFragment}
`
