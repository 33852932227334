import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { Actions, Danger, Flag, Hazard } from 'bvdash-core/icons'

// TODO(Erik): Improve these functions.

const BUDGET_OVER = 'BUDGET_OVER'
const SCHEDULE_BEHIND = 'SCHEDULE_BEHIND'
const UPDATE_ADDED = 'UPDATE_ADDED'
const ISSUE_ASSIGNED = 'ISSUE_ASSIGNED'
const ISSUE_UPDATE = 'ISSUE_UPDATE'
const ISSUE_OPENED = 'ISSUE_OPENED'
const ISSUE_CLOSED = 'ISSUE_CLOSED'
const ISSUE_REOPENED = 'ISSUE_REOPENED'
const ISSUE_ACTION_CLOSED = 'ISSUE_ACTION_CLOSED'
const ACTION_ASSIGNED = 'ACTION_ASSIGNED'

export const alertCodes = [
  {
    label: 'New Update',
    value: UPDATE_ADDED,
  },
  {
    label: 'New Issue',
    value: ISSUE_OPENED,
  },
  {
    label: 'New Task',
    value: ACTION_ASSIGNED,
  },
  {
    label: 'Issue Assigned',
    value: ISSUE_ASSIGNED,
  },
  {
    label: 'Issue Reopened',
    value: ISSUE_REOPENED,
  },
  {
    label: 'Issue Updated',
    value: ISSUE_UPDATE,
  },
  {
    label: 'Issue Closed',
    value: ISSUE_CLOSED,
  },
  {
    label: 'Issue Action Closed',
    value: ISSUE_ACTION_CLOSED,
  },
  {
    label: 'Over budget',
    value: BUDGET_OVER,
  },
  {
    label: 'Behind schedule',
    value: SCHEDULE_BEHIND,
  },
]

export const getUserAlert = code =>
  alertCodes.find(alert => alert.value === code)

export const actionProps = code =>
  code.toLowerCase().includes('action_') && { width: 19, height: 19 }

export const setAlertType = code => {
  switch (code) {
    case BUDGET_OVER:
    case SCHEDULE_BEHIND:
      return 'error'
    case UPDATE_ADDED:
      return 'success'
    case ACTION_ASSIGNED:
      return 'standard'
    case ISSUE_OPENED:
    case ISSUE_UPDATE:
    case ISSUE_ASSIGNED:
    case ISSUE_CLOSED:
    case ISSUE_ACTION_CLOSED:
    case ISSUE_REOPENED:
      return 'warning'
    default:
      return null
  }
}

export const getAlertData = alert => {
  const { code, context } = alert

  const isContext = alert.context != null

  if (isContext) {
    let alertType
    let alertTypeKey

    if (context.action !== null) {
      alertType = 'action'
      alertTypeKey = 'content'
    } else if (context.issue !== null) {
      alertType = 'issue'
      alertTypeKey = 'content'
    } else if (context.update !== null) {
      alertType = 'update'
      alertTypeKey = 'description'
    }

    const alertContextType = alert.context[alertType]

    const finalCode = code.toLowerCase().includes('action_')
      ? 'ACTION_ASSIGNED'
      : code

    return {
      author: alertContextType && alertContextType.author,
      code: finalCode,
      project: alertContextType && alertContextType.project,
      text: alertContextType[alertTypeKey],
    }
  }
}

export const getAlertType = (alert, props = null) => {
  const type = setAlertType(alert.code)

  const { author, code, project, text } = getAlertData(alert)

  const standardReturn = {
    author,
    code,
    text,
    ...(project && { project: project }),
  }

  switch (alert.code) {
    case BUDGET_OVER:
    case SCHEDULE_BEHIND:
      return {
        ...standardReturn,
        Icon: Danger,
        type,
      }
    case UPDATE_ADDED:
      return {
        ...standardReturn,
        Icon: Flag,
        type,
      }
    case ISSUE_UPDATE:
    case ISSUE_ASSIGNED:
    case ISSUE_CLOSED:
    case ISSUE_ACTION_CLOSED:
    case ISSUE_OPENED:
    case ISSUE_REOPENED:
      return {
        ...standardReturn,
        Icon: Hazard,
        type,
      }
    case ACTION_ASSIGNED:
      return {
        ...standardReturn,
        Icon: Actions,
        type,
      }
    default:
      return {}
  }
}

export const getAlertLink = (alert, props) => {
  const {
    context: { action, issue, update, project },
  } = alert

  const projectKey = ((issue || update || {}).project || project || {}).key

  switch (alert.code) {
    case UPDATE_ADDED:
      return reverse(routes.project.updates, {
        projectKey,
      })
    case ACTION_ASSIGNED:
      return reverse(routes.tasks.detail, {
        actionId: action.actionId,
      })
    case ISSUE_OPENED:
      return reverse(routes.project.issueDetail, {
        projectKey,
        issueId: issue.issueId,
      })
    case ISSUE_CLOSED:
      return reverse(routes.project.issueDetail, {
        projectKey,
        issueId: issue.issueId,
      })
    case ISSUE_REOPENED:
      return reverse(routes.project.issueDetail, {
        projectKey,
        issueId: issue.issueId,
      })
    case ISSUE_ASSIGNED:
      return reverse(routes.project.issueDetail, {
        projectKey,
        issueId: issue.issueId,
      })
    case ISSUE_UPDATE:
      return reverse(routes.project.issueDetail, {
        projectKey,
        issueId: issue.issueId,
      })
    case ISSUE_ACTION_CLOSED:
      return reverse(routes.project.issueDetail, {
        projectKey,
        issueId: issue.issueId,
      })
    case BUDGET_OVER:
      return reverse(routes.project.budget, {
        projectKey,
      })
    case SCHEDULE_BEHIND:
      return reverse(routes.project.schedule, {
        projectKey,
      })
    default:
      return routes.alerts.toString()
  }
}
