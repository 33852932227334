import React from 'react'
import styled from 'react-emotion'

import { FaUser, FaUsers } from 'react-icons/fa'

import { FlexContainer, IconContainer } from 'bvdash/admin/components/styles'
import {
  Card,
  CardButton,
  CardHeader,
  CardText,
} from 'bvdash/admin/components/StandardCard'
import { HeaderText, RegularHeaderText } from 'bvdash/components/standard/texts'

import { colorMap } from 'bvdash/styles'
import routes from 'bvdash/routes'

import * as setupStates from './constants'

const WelcomeCard = props => {
  const {
    header,
    icon: { Icon, size, bottom, top },
    onClick,
    text,
  } = props
  return (
    <Card onClick={onClick}>
      <CardHeader>{header}</CardHeader>
      <IconContainer marginBottom={bottom} marginTop={top}>
        <Icon color={colorMap.sanJuan} size={size} />
      </IconContainer>
      <CardText>{text}</CardText>
      <CardButton color="add" type="button">
        Select
      </CardButton>
    </Card>
  )
}

const welcomeCardInfo = [
  {
    header: 'Individual',
    icon: {
      Icon: FaUser,
      size: 48,
      bottom: 32,
      top: 48,
    },
    text: 'Select this path if you would like to use BVDash as a single user',
    type: setupStates.INDIVIDUAL_SETUP,
  },
  {
    header: 'Team',
    icon: {
      Icon: FaUsers,
      size: 64,
      bottom: 24,
      top: 40,
    },
    text:
      'Select this path if you would like to use BVDash with multiple users',
    type: setupStates.TEAM_SETUP,
  },
]

export const Welcome = props => {
  const {
    customer: {
      customer: { onboarding },
    },
    history,
    handleModalOpen,
    setCurrentModal,
    setCurrentModalProps,
    setSetupOption,
    setSetupState,
  } = props

  React.useEffect(() => {
    if (!onboarding) {
      setSetupState(setupStates.WELCOME)
    }
  }, [])

  const handleSelectCard = async type => {
    setSetupOption(type)
    setCurrentModal('setup')
    if (type === setupStates.INDIVIDUAL_SETUP) {
      setSetupState(setupStates.ADD_PROJECT)
      setCurrentModalProps({
        setupState: setupStates.ADD_PROJECT,
        setupOption: type,
      })
      handleModalOpen()
      return history.push(routes.admin.projectAddItem)
    }
    setSetupState(setupStates.ADD_USER)
    setCurrentModalProps({
      setupState: setupStates.ADD_USER,
      setupOption: type,
    })
    handleModalOpen()
    return history.push(routes.admin.usersAdd)
  }

  const WelcomeCards = welcomeCardInfo.map(w => {
    const { header, icon, text, to, type } = w
    return (
      <WelcomeCard
        header={header}
        icon={icon}
        key={to}
        onClick={() => handleSelectCard(type)}
        text={text}
      />
    )
  })

  return (
    <Container>
      <RegularHeaderText color="main">Welcome to BVDash</RegularHeaderText>
      <HeaderText>How would you like to use BirdView Dashboard?</HeaderText>
      <FlexContainer marginTop={16}>{WelcomeCards}</FlexContainer>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 90%;
  padding: 32px;
  text-align: center;
  width: 800px;
`
