import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

export const stripePaymentSourcesQuery = gql`
  query stripePaymentSources($stripeCustomerId: String!) {
    stripePaymentSources(stripeCustomerId: $stripeCustomerId) {
      sourceId
      expYear
      expMonth
      last4
      stripeCustomerId
      brand
      name
    }
  }
`

export const withStripePaymentSources = graphql(stripePaymentSourcesQuery, {
  options: ({ customer }) => ({
    variables: { stripeCustomerId: customer.customer.stripeCustomerId },
  }),
  props: ({ data }) => {
    const { stripePaymentSources = [], loading } = data

    return {
      stripePaymentSources: {
        isLoading: loading,
        stripePaymentSources,
      },
    }
  },
})
