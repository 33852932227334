import * as React from 'react'
import { equals } from 'ramda'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { withFormik, Field, FieldArray } from 'formik'
import { object, array, string } from 'yup'
import styled, { css } from 'react-emotion'
import { capitalize } from 'bvdash/utils'
import {
  ConfirmDialogDefault,
  FormActions,
  FormField,
  VerticalForm,
} from 'bvdash/ui'
import { AddButton, Button } from 'bvdash/components/buttons'
import { Trash } from 'bvdash-core/icons'
import { withCodeList, initialCode } from 'bvdash/admin/ui'
import { disableDelete, disableStatusField } from 'bvdash/admin/utils'
import { FlexContainer } from 'bvdash/components/standard/containers'
import { QuestionMark } from 'bvdash-core/icons'
import {
  projectCodeCodeText,
  projectCodeValueText,
  projectCodeDescriptionText,
} from 'bvdash/components/inputs'

const withCodeForm = compose(
  withRouter,
  withCodeList,
  withFormik({
    enableReinitialize: true,

    mapPropsToValues: ({ code }) => {
      // code is null when we're creating a new code
      if (code && !code.isLoading) {
        return {
          code: code.key,
          values: code.values,
        }
      }
      return {
        code: '',
        values: [initialCode],
      }
    },

    validationSchema: props => {
      const { code, type } = props

      const isProgramAndStatus =
        code.key.toLowerCase() === 'status' && type.toLowerCase() === 'program'

      const valueSchema = array()
        .of(
          object({
            value: string().required('Code value is required.'),
            description: string().required('Code description is required.'),
          })
        )
        .required()

      if (code != null && isProgramAndStatus) {
        return object({
          code: string().required('Code key is required.'),
          values: valueSchema,
        })
      }

      return object({
        code: string()
          .required('Code key is required.')
          .test(
            'match',
            'Cannot use code status',
            c => c.toLowerCase() !== 'status'
          ),
        values: valueSchema,
      })
    },

    handleSubmit: async (values, { props }) => {
      const { code, codeUpdate, codeCreate, history, redirectTo } = props

      const prevCodes =
        code != null
          ? code.values
              .map(value => ({
                key: code.key,
                ...value,
              }))
              .reduce((codes, code) => {
                codes[code.id] = code
                return codes
              }, {})
          : {}

      const nextCodes = values.values.map(value => ({
        key: values.code,
        ...value,
      }))

      const promises = nextCodes.map(code => {
        // When ID is null, we're creating a code. Editing otherwise.
        if (code.id != null) {
          // call API only when data changes
          if (!equals(code, prevCodes[code.id])) {
            const { id, ...data } = code
            return codeUpdate(id, data)
          }
        } else {
          return codeCreate(code)
        }

        // Nothing to update or create
        return Promise.resolve()
      })

      await Promise.all(promises)
      return history.push(redirectTo)
    },
  })
)

const CodeForm = props => {
  const {
    code,
    codeList,
    codeRemove,
    initialValues,
    redirectTo,
    type,
    values,
    titles,
  } = props

  const isStatus =
    values.code.toLowerCase() === 'status' && type.toLowerCase() === 'program'

  const initialEmptyCode = initialValues.code !== ''

  return (
    <VerticalForm>
      <Field
        label="Code"
        name="code"
        component={FormField}
        disabled={initialEmptyCode && disableStatusField(values.code)}
        icon={{
          Icon: QuestionMark,
          text: projectCodeCodeText(titles),
        }}
      />

      <FieldArray
        name="values"
        render={arrayHelpers => {
          return (
            <CodeValues>
              {values.values && values.values.length > 0
                ? values.values.map((value, index) => (
                    <FlexContainer flexWrap width="100%">
                      <Field
                        disabled={
                          initialEmptyCode && disableStatusField(values.code)
                        }
                        label="Value"
                        name={`values.${index}.value`}
                        component={FormField}
                        icon={{
                          Icon: QuestionMark,
                          text: projectCodeValueText(titles),
                        }}
                      />
                      <FlexContainer>
                        <Field
                          label="Description"
                          name={`values.${index}.description`}
                          component={FormField}
                          icon={{
                            Icon: QuestionMark,
                            text: projectCodeDescriptionText(titles),
                          }}
                        />
                        {index > 0 && (
                          <ConfirmDialogDefault
                            title={`Delete ${capitalize(
                              type
                            )} Code Confirmation`}
                            question={
                              <>
                                You are about to remove a {type} code:{' '}
                                {value.value}
                                {value.description
                                  ? ` (${value.description})`
                                  : ''}
                                <br />
                                <br />
                                Are you sure you want to proceed?
                              </>
                            }
                            theme="danger"
                          >
                            {confirm => {
                              const remove = codeList.removeRow(
                                value,
                                codeRemove,
                                () => arrayHelpers.remove(index)
                              )
                              // show confirmation dialog only for non-empty rows
                              const isEmpty = !value.description && !value.value

                              return code &&
                                disableDelete(code.key, value.value) ? null : (
                                <Trash
                                  className={deleteIconClass}
                                  onClick={isEmpty ? remove : confirm(remove)}
                                />
                              )
                            }}
                          </ConfirmDialogDefault>
                        )}
                      </FlexContainer>
                    </FlexContainer>
                  ))
                : null}

              {!isStatus && (
                <AddButton
                  onClick={codeList.addRow(value => arrayHelpers.push(value))}
                  fullWidth={false}
                >
                  Add another value and description
                </AddButton>
              )}

              <FormActions>
                <Button to={redirectTo}>Cancel</Button>
                <Button type="submit" color="add">
                  Save
                </Button>
              </FormActions>
            </CodeValues>
          )
        }}
      />
    </VerticalForm>
  )
}

const CodeValues = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 95px;
  position: relative;
`

const deleteIconClass = css`
  margin-top: 40px;
  margin-left: 10px;
`

export default withCodeForm(CodeForm)
