import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'

import { UserFragment } from './userFragment'
import { usersQuery } from './withUsers'

const userUpdateMutation = gql`
  mutation userUpdate($id: ID!, $user: UserInput!) {
    userUpdate(id: $id, user: $user) {
      ok
      error
      user {
        ...User
      }
    }
  }
  ${UserFragment}
`

export const withUserUpdate = graphql(userUpdateMutation, {
  props: ({ mutate }) => ({
    userUpdate: async ({ id, user }) => {
      const { data } = await mutate({
        variables: { id, user },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: usersQuery }, { query: customerQuery }],
      })

      return data.userUpdate
    },
  }),
})
