import * as React from 'react'
import { Redirect } from 'react-router-dom'

import routes from 'bvdash/routes'
import { NotFound } from 'bvdash/errors/scenes'

import {
  Login,
  PasswordResetRequest,
  PasswordReset,
  AdminBase,
  ProgramsList,
  ProgramBaseDetail,
  ProjectBaseDetail,
  ProjectBaseList,
  OnboardingBase,
  ConversationsBase,
  CalendarBase,
  ActionsBase,
  AlertsList,
  TimesheetsBase,
  ProfileBase,
  AccountsBase,
  SuperUsersBase,
} from './RouteComponents'

export const notFoundRoute = { Component: NotFound }

export const loginRoute = {
  Component: Login,
  path: routes.auth.login,
}

export const passwordResetRequestRoute = {
  Component: PasswordResetRequest,
  path: routes.auth.passwordReset,
}

export const passwordResetRoute = {
  Component: PasswordReset,
  path: routes.auth.passwordNew,
}

export const onBoardingBaseRoute = {
  Component: OnboardingBase,
  path: routes.register.toString(),
}

export const loginRedirect = {
  Component: (props, routeProps) => (
    <Redirect to={routes.auth.login} {...props} {...routeProps} />
  ),
}

export const adminBaseRoute = {
  auth: true,
  Component: AdminBase,
  path: routes.admin.toString(),
}

export const programBaseListRoute = {
  auth: true,
  Component: ProgramsList,
  path: routes.portfolio,
}

export const programBaseDetailRoute = {
  auth: true,
  Component: ProgramBaseDetail,
  path: routes.program.dashboard,
}

export const projectBaseListRoute = {
  auth: true,
  Component: ProjectBaseList,
  path: routes.projects.all,
}

export const projectBaseDetailRoute = {
  auth: true,
  Component: ProjectBaseDetail,
  path: routes.project.dashboard,
}

export const conversationBaseRoute = {
  auth: true,
  Component: ConversationsBase,
  path: routes.conversations.detail,
}

export const projectListRedirect = {
  auth: true,
  Component: (props, routeProps) => (
    <Redirect to={routes.projects.all} {...props} {...routeProps} />
  ),
}

export const actionBaseRoute = {
  auth: true,
  Component: ActionsBase,
  path: routes.tasks.toString(),
}

export const alertListRoute = {
  auth: true,
  Component: AlertsList,
  path: routes.alerts,
}

export const timesheetBaseRoute = {
  auth: true,
  Component: TimesheetsBase,
  path: routes.timesheets.toString(),
}

export const calendarBaseRoute = {
  auth: true,
  Component: CalendarBase,
  path: routes.calendar,
}

export const profileBaseRoute = {
  auth: true,
  Component: ProfileBase,
  path: routes.profile,
}

export const accountsBaseRoute = {
  auth: true,
  Component: AccountsBase,
  path: routes.accounts.toString(),
}

export const superUsersBaseRoute = {
  auth: true,
  Component: SuperUsersBase,
  path: routes.superusers.toString(),
}
