import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'react-apollo'
import { cx } from 'react-emotion'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { NotificationsDropdown, Headline } from 'bvdash/ui/core'
import * as intl from 'bvdash/utils/intl'
import { Conversation } from 'bvdash-core/icons'

import styles from './ConversationsDropdown.scss'
import { authUser } from 'bvdash/auth/queries'
import { listConversations, markRead } from 'bvdash/conversations/queries'

import { pluralize } from 'bvdash/utils'

export const ConversationsDropdown = compose(
  authUser,
  listConversations,
  markRead,
  withRouter
)(
  class extends React.Component {
    static propTypes = {
      data: PropTypes.array.isRequired,
      to: PropTypes.string.isRequired,
      subscribe: PropTypes.func.isRequired,
      markRead: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.props.subscribe()
    }

    render() {
      const {
        activeClassName,
        className,
        data,
        initialOpen,
        location,
        markRead,
        to,
        titles,
      } = this.props
      const conversations = data.filter(
        conversation => conversation.unreadMessages
      )
      const hasConversations = conversations.length !== 0
      const labelClassName = cx(className, {
        [activeClassName]: location.pathname.startsWith(to),
      })

      return (
        <NotificationsDropdown
          emptyLabel={`No ${pluralize(titles.conversation)}`}
          footerLabel={`See all ${pluralize(titles.conversation)}`}
          footerLink={to}
          icon={<Conversation />}
          initialOpen={initialOpen}
          // label={pluralize(titles.conversation)}
          labelClassName={labelClassName}
          notifications={conversations}
          onNotificationClick={notification => markRead(notification.id)}
          titleActions={[
            {
              label: 'Mark All Read',
              onClick: () => markRead(),
              disabled: !hasConversations,
            },
            {
              label: 'Start New',
              to: routes.conversations.new,
              primary: true,
            },
          ]}
          titleLabel={`Recent ${pluralize(titles.conversation)}`}
        >
          {({ notification: conversation }) => {
            const { project, latestMessage, users, date } = conversation
            return (
              <Link
                to={reverse(routes.conversations.detail, {
                  conversationId: conversation.id,
                })}
                className={styles.conversation}
              >
                <div className={styles.conversationContent}>
                  <div className={styles.header}>
                    <Headline level={3}>
                      {project
                        ? `# ${project.key} – ${project.name}`
                        : users.map(user => user.fullName).join(', ')}
                    </Headline>
                    <span className={styles.date}>
                      {intl.relativeDate(date)}
                    </span>
                  </div>
                  {latestMessage.content && (
                    <p className={styles.conversationDescription}>
                      {latestMessage.content}
                    </p>
                  )}
                </div>
              </Link>
            )
          }}
        </NotificationsDropdown>
      )
    }
  }
)
