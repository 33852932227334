import * as React from 'react'
import { cx } from 'react-emotion'
import PropTypes from 'prop-types'
import ReactDropzone from 'react-dropzone'

import * as a11y from 'bvdash/utils/a11y'
import styles from './Dropzone.scss'

export default class Dropzone extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    multiple: PropTypes.any,
  }

  dropzoneRef = React.createRef()

  state = {
    acceptedFiles: [],
  }

  handleOpen = () => this.dropzoneRef.current.open()

  handleUpload = acceptedFiles => {
    this.setState(
      state => {
        const newFilenames = acceptedFiles.map(file => file.name)
        const files = state.acceptedFiles
          .filter(Boolean) // remove any invalid references
          .filter(file => !newFilenames.includes(file.name))
        files.push(...acceptedFiles)
        return { acceptedFiles: files }
      },
      () => this.props.field.setFile(this.state.acceptedFiles)
    )
  }

  handleRemove = fileToRemove => e => {
    e.preventDefault()
    this.setState(
      state => ({
        acceptedFiles: state.acceptedFiles.filter(
          file => file.name !== fileToRemove.name
        ),
      }),
      () => this.props.field.setFile(this.state.acceptedFiles)
    )
  }

  render() {
    const { children, className } = this.props

    // Right after files are uploaded, their references are turned into null
    // and when Dropzone tries to re-render for the last time, it throws an
    // error on `file.name`.
    // Usually this doesn't happen, because after form submit the browser
    // is redirected to detail page, but if shit hits the fan, this blows up.
    const acceptedFiles = this.state.acceptedFiles.filter(Boolean)

    const files = !!acceptedFiles.length && (
      <div className={styles.files}>
        {acceptedFiles.map(file => {
          return (
            <div key={file.name} className={styles.file}>
              <span className={styles.filename}>{file.name}</span>{' '}
              <a
                className={styles.remove}
                {...a11y.clickable(this.handleRemove(file))}
              >
                X
              </a>
            </div>
          )
        })}
      </div>
    )

    return (
      <ReactDropzone
        ref={this.dropzoneRef}
        onDrop={this.handleUpload}
        className={cx(className, styles.dropzone)}
        disableClick
        disablePreview
        multiple={this.props.multiple}
        test="reactDropzone"
      >
        {children({ files, openFileDialog: this.handleOpen })}
      </ReactDropzone>
    )
  }
}
