import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { cx } from 'react-emotion'
import { StyledLinkifyLinks } from 'bvdash/components/StyledLinkifyLinks'

import {
  Box,
  Button,
  ButtonGroup,
  CardHeader,
  CreateNew,
  IconBox,
} from 'bvdash/ui/core'
import { Dropzone } from 'bvdash/projects/ui'
import { Attachments } from 'bvdash/actions/ui'
import { Form, Field, validators } from 'bvdash/ui/forms'
import { Pencil, Attach } from 'bvdash-core/icons'
import { truncate } from 'bvdash/utils'
import * as a11y from 'bvdash/utils/a11y'
import { DateComponent } from 'bvdash/components/DateComponent'

import styles from './ActionList.scss'

const validation = {
  description: validators.isRequired,
}

class ActionList extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  }

  state = {
    selectedId: null,
    expandedId: null,
    attachmentsPosition: 0,

    editingId: null,
  }

  componentDidMount() {
    this.selectDefaultUpdate()
  }

  componentDidUpdate() {
    this.selectDefaultUpdate()
  }

  selectDefaultUpdate = () => {
    const { actions } = this.props
    if (!actions.length) return

    // select first update when loaded or when selected update is filtered out
    if (!this.state.selectedId) {
      const update = actions[0]
      this.selectUpdate(update.id, true)()
    }
  }

  selectUpdate = (selectedUpdate, expanded = false) => e => {
    // disallow switching actions while in editing mode
    if (this.state.editingId) return false

    // must be outside setState callback, because SyntheticEvent is reused and
    // might already overriden in callback
    const element = e && e.currentTarget

    this.setState(
      state => ({
        selectedId: selectedUpdate,
        expandedId:
          state.expandedId !== selectedUpdate
            ? expanded
              ? selectedUpdate
              : null
            : state.expandedId,
      }),
      () => {
        const attachmentsPosition = element ? element.offsetTop : 0
        this.setState({ attachmentsPosition })
      }
    )
  }

  expandUpdate = selectedUpdate => () =>
    this.setState({ expandedId: selectedUpdate })

  handleEdit = id => () => {
    // disallow editing of other actions while in editing mode
    // Note: This should never happend with current backend implementation,
    // because user is allowed to edit one action anyway
    if (this.state.editingId) return false

    this.setState({ editingId: id })
  }

  handleCancel = () => {
    this.setState({ editingId: null })
  }

  handleSubmit = ({ data }) => {
    return this.props
      .onEdit({
        actionId: this.state.editingId,
        ...data,
      })
      .then(() => {
        this.setState({ editingId: null })
      })
  }

  render() {
    const {
      selectedId,
      attachmentsPosition,
      expandedId,
      editingId,
    } = this.state
    const { editLink, actions, header } = this.props

    const selectedUpdate =
      actions.find(action => action.id === selectedId) || actions[0] || {}

    const { attachments = [] } = selectedUpdate

    const editedAction = actions.find(action => action.id === editingId) || {}

    return (
      <Form
        onSubmit={this.handleSubmit}
        validation={validation}
        initialValues={{ description: editedAction.description }}
      >
        {({ getFieldProps, getSubmitProps, getFormProps }) => (
          <form {...getFormProps()}>
            <div className={styles.updatesWrapper}>
              <div className={styles.updates}>
                {header}
                {actions.map((action, index) => {
                  const updateClass = cx(styles.update, {
                    [styles.selected]: action.id === selectedUpdate.id,
                  })

                  const { value: description, truncated } = truncate(
                    action.description,
                    128
                  )

                  return (
                    <div
                      key={action.id}
                      className={updateClass}
                      {...a11y.clickable(this.selectUpdate(action.id))}
                    >
                      <div className={styles.header}>
                        <div className={styles.headerAuthor}>
                          Posted by: {action.author.fullName}
                        </div>
                        <div>
                          Date: <DateComponent date={action.dateCreated} />
                        </div>
                        {action.canEdit &&
                          (editLink ? (
                            <Link
                              to={editLink}
                              className={styles.headerEdit}
                              test="EditBtn"
                            >
                              <IconBox>
                                <Pencil />
                              </IconBox>
                            </Link>
                          ) : (
                            <IconBox
                              {...a11y.clickable(this.handleEdit(action.id))}
                              className={styles.headerEdit}
                            >
                              <Pencil />
                            </IconBox>
                          ))}
                      </div>

                      {editingId === action.id ? (
                        <div className={styles.editing}>
                          <Field {...getFieldProps('description')}>
                            {({ getInputProps }) => (
                              <textarea
                                {...getInputProps()}
                                className={styles.input}
                                ref={ref => ref && ref.focus()}
                              />
                            )}
                          </Field>

                          <ButtonGroup>
                            <Button
                              size="small"
                              theme="primary"
                              {...getSubmitProps()}
                            >
                              Save
                            </Button>
                            <Button
                              size="small"
                              theme="default"
                              {...a11y.clickable(this.handleCancel)}
                            >
                              Cancel
                            </Button>
                          </ButtonGroup>
                        </div>
                      ) : truncated && expandedId !== action.id ? (
                        <div
                          className={styles.description}
                          test="ActionContent"
                        >
                          {description}…{' '}
                          <Button
                            onClick={this.expandUpdate(action.id)}
                            theme="link"
                          >
                            More
                          </Button>
                        </div>
                      ) : (
                        <div
                          className={styles.description}
                          test="ActionContent"
                        >
                          <StyledLinkifyLinks>
                            {action.description}{' '}
                          </StyledLinkifyLinks>

                          {truncated && index === 0 && (
                            <Button onClick={this.expandUpdate()} theme="link">
                              Less
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>

              {editingId ? (
                <div className={styles.uploadWrapper}>
                  <div
                    style={{
                      position: 'absolute',
                      top: attachmentsPosition,
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Dropzone
                      field={getFieldProps('attachments')}
                      className={styles.upload}
                      multiple
                    >
                      {({ files, openFileDialog }) =>
                        files ? (
                          <>
                            <CardHeader>
                              Attachments{' '}
                              <IconBox onClick={openFileDialog}>
                                <Attach />
                              </IconBox>
                            </CardHeader>

                            {files}
                          </>
                        ) : (
                          <div className={styles.placeholder}>
                            <CreateNew onClick={openFileDialog}>
                              <span className={styles.placeholderText}>
                                Upload Files
                              </span>
                            </CreateNew>
                          </div>
                        )
                      }
                    </Dropzone>
                  </div>
                </div>
              ) : (
                <Box item grow={1} basis="10%">
                  <Attachments
                    attachments={attachments}
                    top={attachmentsPosition}
                  />
                </Box>
              )}
            </div>
          </form>
        )}
      </Form>
    )
  }
}

export default ActionList
