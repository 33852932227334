import styled, { css } from 'react-emotion'

import { color, backgroundAlpha } from 'bvdash/styles'

const themeMap = {
  success: 'wasabi',
  warning: 'brightSun',
  danger: 'carnation',
  info: 'dodgerBlue',
  muted: 'waterloo',

  open: 'neonCarrot',
  progress: 'dodgerBlue',
  handling: 'dodgerBlue',
  completed: 'wasabi',
  resolved: 'wasabi',
  closed: 'waterloo',

  veryHigh: 'carnation',
  high: 'neonCarrot',
  moderate: 'brightSun',
  low: 'wasabi', // low and veryLow has the same value intentionally
  veryLow: 'wasabi',
}

const Label = styled.span`
  cursor: ${({ onClick }) => (onClick != null ? 'pointer' : 'auto')};
  font-size: 11px;
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;

  ${props => {
    const colorName = themeMap[props.theme]
    return css`
      ${color[colorName]};
      ${backgroundAlpha[colorName]};
      ${props.inline
        ? `
            display: inline-block;
          `
        : 'display: block;'};
    `
  }};
`

export default Label
