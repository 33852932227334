import * as React from 'react'
import { MaybeImage } from 'bvdash/ui/core'

export default function UserPhoto({ photo, name, className }) {
  const initials = name
    .split(' ')
    .slice(0, 2)
    .map(part => part[0])
    .join('')

  return <MaybeImage className={className} alt={initials} src={photo} />
}
