import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { programsListQuery, ProgramFragment } from 'bvdash/queries/withPrograms'
import { customerQuery } from 'bvdash/queries/settings/withCustomer'

const defaultProgramQuery = gql`
  query defaultProgram($programKey: String) {
    program(program: $programKey) {
      id
      key
      name
    }
  }
`

export const withDefaultProgram = graphql(defaultProgramQuery, {
  options: props => ({
    programKey: props.programKey,
  }),
  props: ({ data }) => {
    const { loading, program = {} } = data

    return {
      defaultProgram: {
        isLoading: loading,
        program,
      },
    }
  },
})

export const withProgram = graphql(
  gql`
    query program($id: ID!) {
      program(id: $id) {
        ...Program

        description
        photo
      }
    }

    ${ProgramFragment}
  `,
  {
    options: props => ({
      variables: {
        id: props.programId,
      },
    }),
    skip: props => !props.programId,
    props: ({ data }) => ({
      program: {
        isLoading: data.loading,
        program: data.program,
      },
    }),
  }
)

export const withProgramFormData = graphql(
  gql`
    query programFormData {
      managerChoices: users {
        value: id
        label: fullName
      }
    }
  `,
  {
    options: {
      fetchPolicy: 'network-only',
    },
    props: ({ data }) => ({
      programFormData: {
        isLoading: data.loading,
        managerChoices: data.managerChoices || [],
      },
    }),
  }
)

const programCreateMutation = gql`
  mutation programCreate($program: ProgramInput!) {
    programCreate(program: $program) {
      ok
      error
      program {
        ...Program
      }
    }
  }

  ${ProgramFragment}
`

export const withProgramCreate = graphql(programCreateMutation, {
  props: ({ mutate }) => ({
    programCreate: ({ program }) => {
      return mutate({
        variables: { program },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: programsListQuery },
          { query: customerQuery },
        ],
      })
    },
  }),
})

const programUpdateMutation = gql`
  mutation programUpdate($id: ID!, $program: ProgramInput!) {
    programUpdate(id: $id, program: $program) {
      ok
      error
      program {
        ...Program
      }
    }
  }

  ${ProgramFragment}
`

export const withProgramUpdate = graphql(programUpdateMutation, {
  props: ({ mutate }) => ({
    programUpdate: ({ id, program }) => {
      return mutate({
        variables: { id, program },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: programsListQuery }],
      })
    },
  }),
})

const programRemoveMutation = gql`
  mutation programRemove($id: ID!) {
    programRemove(id: $id) {
      ok
      error
    }
  }
`

export const withProgramRemove = graphql(programRemoveMutation, {
  props: ({ mutate }) => ({
    programRemove: id =>
      mutate({
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: programsListQuery }],
      }),
  }),
})

const programRemoveCancelMutation = gql`
  mutation programRemoveCancel($id: ID!) {
    programRemoveCancel(id: $id) {
      ok
      error
    }
  }
`

export const withProgramRemoveCancel = graphql(programRemoveCancelMutation, {
  props: ({ mutate }) => ({
    programRemoveCancel: id =>
      mutate({
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: programsListQuery }],
      }),
  }),
})
