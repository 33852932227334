import { Form } from 'formik'
import styled from 'react-emotion'

import { background, buttonShadow, color } from 'bvdash/styles'

const Button = styled.button`
  ${buttonShadow};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  letter-spacing: 0.2px;
  outline: none;
  width: 141px;
`

export const ButtonCancel = styled(Button)`
  ${background.solidWhite};
  ${color.sanJuan};
`

export const ButtonSave = styled(Button)`
  ${background.dodgerBlue};
  ${color.solidWhite};
`

export const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  width: 300px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  width: 860px;
`

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  max-width: 100%;
`

export const Header = styled.p`
  ${color.dodgerBlue};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 1.25;
`

export const Spacer = styled.div`
  border: solid 2px rgba(49, 61, 90, 0.1);
  height: 2px;
  margin-top: 258px;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(50% - 12px);
`
