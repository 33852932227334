export const importInfo = ({ project }) => ({
  description: {
    steps: [
      `Download Empty ${project} Template by clicking "Empty ${project} Template" below`,
      'Fill out the template with you values in the corresponding columns, ensuring you do not change the values of the first row',
      'Save your template (.csv filetype) and import your file',
      'The file icon will turn green, indicating that your file has been successfully uploaded',
      'Click the blue Import button below to Import your template',
    ],
    notes: [
      'Make sure you fill in the ProjectID, ProjectName, and ProjectManagerEmail columns',
      'Make sure that your upload file is a .csv filetype',
    ],
  },
  files: {
    sampleFileUrl:
      'https://bvdash-import-templates.s3.amazonaws.com/ProjectSampleFile.csv',
    templateFileUrl:
      'https://bvdash-import-templates.s3.amazonaws.com/ProjectEmptyTemplate.csv',
  },
})
