/**
 * Connect `DropzoneWithPlaceholder` to Formiks' Field
 */

import * as React from 'react'
import DropzoneWithPlaceholder from './DropzoneWithPlaceholder'

function DropzoneField({ field, form }) {
  return (
    <DropzoneWithPlaceholder
      field={{ setFile: value => form.setFieldValue(field.name, value) }}
    />
  )
}

export default DropzoneField
