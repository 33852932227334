import gql from 'graphql-tag'

export const TimelineEventFragment = gql`
  fragment TimelineEvent on IssueEventType {
    id
    type
    author {
      id
      fullName
    }
    assignee {
      id
      fullName
    }
    date
    priorityBefore
    priorityAfter
  }
`
