import React from 'react'
import styled from 'styled-components'

import { setColor } from 'bvdash/css'

const Container = styled.div`
  ${({ onClick, noShadow, theme }) =>
    noShadow || onClick == null ? undefined : theme.shadow};
  background: ${({ type, theme }) =>
    `${setColor(type, theme)}${theme.opacity[30]}`};
  color: ${({ type, theme }) => setColor(type, theme)};
  cursor: ${({ onClick, noShadow }) =>
    noShadow || onClick == null ? 'auto' : 'pointer'};

  border-radius: 3px;
  font-size: 11px;
  min-width: 48px;
  padding: 6px 8px;
  text-align: center;
  text-transform: uppercase;
  ${({ width }) => (width ? `width: ${width}` : undefined)};

  filter: brightness(100%);
  transition: filter 0.2s ease;

  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
  &:hover {
    filter: ${({ disabled, noShadow }) =>
      !disabled && !noShadow && `brightness(75%)`};
    transition: filter 0.2s ease;
  }
`

export const TableButton = props => {
  const { children, onClick, type } = props
  return (
    <Container onClick={onClick} type={type} {...props}>
      {children}
    </Container>
  )
}
