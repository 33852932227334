/* eslint-disable jsx-a11y/label-has-for */
import * as React from 'react'
import { Field } from '../Field'

import styles from './CheckboxField.scss'

export default function CheckboxField({ children, fieldProps }) {
  return (
    <Field {...fieldProps}>
      {({ getLabelProps, getInputProps }) => (
        <label {...getLabelProps()} className={styles.field}>
          <input
            {...getInputProps({ type: 'checkbox' })}
            className={styles.input}
          />
          <span className={styles.checkbox} />
          <span className={styles.label}>{children}</span>
        </label>
      )}
    </Field>
  )
}
