import gql from 'graphql-tag'

import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { LinkedIssueFragment } from 'bvdash/queries/linkedIssueFragment'

export const ActionFragment = gql`
  fragment Action on ActionType {
    id
    actionId
    content
    deadline
    importance
    isOpen
    status
    priority
    urgency
    attachments {
      ...Attachment
    }
    author {
      id
      key
      fullName
    }
    assignedTo {
      id
      key
      fullName
    }
    issues {
      ...LinkedIssue
    }
    updates {
      id
      canEdit
      content
      dateCreated
      author {
        id
        fullName
      }
    }
    project {
      id
      key
      name
    }
  }
  ${AttachmentFragment}
  ${LinkedIssueFragment}
`
