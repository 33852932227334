import * as React from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { maybeProp } from 'bvdash/utils'
import { ThreeDotsMenu } from 'bvdash/ui/tables'

import { authUser } from 'bvdash/auth/queries'
import { startConversation } from 'bvdash/conversations/queries'

import { DocumentReviewDialog } from 'bvdash/projects/ui'

function DocumentContextMenu({
  document,
  projectKey,
  history,

  startConversation,
  authUser,
}) {
  const approvedBy = maybeKey(document.approvedBy)
  const canApprove = maybeKey(authUser.user) === approvedBy
  const requiresApproval = document.status !== 'INFO'

  return (
    <DocumentReviewDialog document={document}>
      {showDialog => (
        <ThreeDotsMenu
          actions={[
            {
              label: 'View Documents',
              onClick: () =>
                history.push(
                  reverse(routes.project.documentDetail, {
                    projectKey,
                    documentId: document.documentId,
                  })
                ),
            },
            {
              label: 'Contact Assignee',
              onClick: () => startConversation([approvedBy]),
              hide: approvedBy == null || canApprove,
            },
            {
              label: 'Approve',
              onClick: () => showDialog('APPROVED'),
              hide:
                document.status === 'APPROVED' ||
                !canApprove ||
                !requiresApproval,
            },
            {
              label: 'Reject',
              onClick: () => showDialog('REJECTED'),
              hide:
                document.status === 'REJECTED' ||
                !canApprove ||
                !requiresApproval,
            },
          ]}
        />
      )}
    </DocumentReviewDialog>
  )
}

const maybeKey = maybeProp('key')

export default compose(
  withRouter,
  authUser,
  startConversation
)(DocumentContextMenu)
