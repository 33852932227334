import { reverse } from 'named-urls'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'

import routes from 'bvdash/routes'
import * as columns from 'bvdash/projects/columns'
import { onClickRoute } from 'bvdash/projects/columns'
import { HelpText } from 'bvdash/admin/ui'
import {
  withTable,
  search,
  Table,
  Filters,
  ThreeDotsMenu,
} from 'bvdash/ui/tables'
import {
  withProjectsPreview,
  withProjectRemove,
  withProjectFields,
  withProjectRemoveCancel,
} from 'bvdash/admin/queries'
import { HOUReorderProjectColumns, isHou } from 'bvdash/customers/hou'

import { importInfo } from './inputs'

import { pluralize } from 'bvdash/utils'
import { withProjectImport } from 'bvdash/admin/projects/queries'

import {
  AddButton,
  ImportButton,
  ToggleModalButton,
} from 'bvdash/components/buttons'
import { NumCountCard } from 'bvdash/components/NumCountCard'
import {
  CenterSpaceBetweenContainer,
  Container,
  FlexContainer,
} from 'bvdash/components/standard/containers'
import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'

const Projects = compose(withProjectImport)(props => {
  const {
    canAddMoreProjects,
    currentPlanData,
    customer,
    history,
    program,
    projectVersion: { version },
    titles,
    // plans (ordered from least to most expensive)
    projectImport,
    isTrial,
    isFreePlan,
    isProfessionalPlan,
    isBusinessPlan,
    isEnterprisePlan,
    // Modals
    handleModalClose,
    handleModalOpen,
    setCurrentModal,
    setCurrentModalProps,

    notificationOpen,
    setNotificationContent,
  } = props

  const handleAddProjects = () => {
    if (canAddMoreProjects) {
      return history.push(routes.admin.projectAddItem)
    }
    setCurrentModal('noMoreProjects')
    return handleModalOpen()
  }

  const importData = importInfo(titles)
  const modalProps = {
    description: importData.description,
    importFunction: projectImport,
    importArguments: {
      programId: program.id,
    },
    handleClose: () => handleModalClose(),
    handleNotification: ({ type, text }) => {
      setNotificationContent({ type, text })
      notificationOpen()
    },
    sampleFileUrl: importData.files.sampleFileUrl,
    templateFileUrl: importData.files.templateFileUrl,
    type: titles.project,
  }
  const handleImport = () => {
    setCurrentModal('import')
    setCurrentModalProps(modalProps)
    return handleModalOpen()
  }

  return (
    <>
      <CenterSpaceBetweenContainer flexWrap>
        <Container marginRight={16}>
          <AdminBreadcrumbs>{`Manage ${pluralize(
            titles.project
          )}`}</AdminBreadcrumbs>
          <HelpText>
            Edit, Change, or Delete a {titles.project} using the three dotted
            line on the far right hand side.
          </HelpText>
        </Container>
        <NumCountCard
          isTrial={isTrial}
          items={[
            {
              currentCount: customer.customer.numProjects,
              maxCount: isTrial ? 'Unlimited' : currentPlanData.numProjects,
              title: 'Projects',
            },
          ]}
        />
      </CenterSpaceBetweenContainer>

      <FlexContainer>
        <AddButton onClick={handleAddProjects}>
          Add New {titles.project}
        </AddButton>
        <ImportButton onClick={handleImport} />
      </FlexContainer>
      <ProjectPreviewTable
        customer={customer}
        programKey={program.key}
        titles={titles}
        versionId={version != null ? version.id : null}
        // plans (ordered from least to most expensive)
        isFreePlan={isFreePlan}
        isProfessionalPlan={isProfessionalPlan}
        isBusinessPlan={isBusinessPlan}
        isEnterprisePlan={isEnterprisePlan}
        setCurrentModal={setCurrentModal}
        setCurrentModalProps={setCurrentModalProps}
        handleModalOpen={handleModalOpen}
      />
    </>
  )
})

export default Projects

const projectPreviewConfig = {
  name: 'adminProjects',
  data: props => props.projectsPreview.projects,
  rowKey: row => row.id,
  sortBy: 'key',
  search: value => project =>
    search(
      value,
      project.key,
      project.name,
      project.manager.fullName,
      ...Object.values(project.fields)
    ),

  columns: props => {
    const {
      customer,
      titles,
      isFreePlan,
      isProfessionalPlan,
      isBusinessPlan,
      isEnterprisePlan,
      versionId,

      projectFields,
      projectsPreview,
      projectRemove,
      projectRemoveCancel,

      handleModalOpen,
      setCurrentModal,
      setCurrentModalProps,
    } = props

    const toRoute = onClickRoute(
      isFreePlan,
      isProfessionalPlan,
      isBusinessPlan,
      isEnterprisePlan
    )

    const defaultColumns = [
      columns.projectId({ to: toRoute }),
      columns.projectName({ title: titles.project, to: toRoute }),
      columns.projectManagerName({ title: titles.project }),
      ...columns.fields(projectFields.all),
      ...columns.codes(projectsPreview.codes),
      {
        // TODO: Refactor to use contexts and make into a single component with the program remove column
        attr: 'removeStatus',
        label: 'Operational',
        render: ({ row, value }) => {
          const { id, removeStatus } = row

          const TYPE = 'project'
          const DELETE = 'delete'
          const MARK = 'mark'
          const UNMARK = 'unmark'

          const choices = removeStatus
            ? [
                { value: 'unmark', label: `Unmark for Deletion` },
                { value: 'delete', label: `Delete ${titles[TYPE]}` },
              ]
            : [{ value: 'mark', label: 'Mark for Deletion' }]
          const text = removeStatus ? 'Mark for Deletion' : 'Operational'

          const modalProps = {
            color: removeStatus ? 'error' : 'caution',
            handleSubmit: () => projectRemove(id, versionId),
            removeStatus,
            titles,
            type: TYPE,
          }
          const handleClick = ({ value }) => {
            if (value === MARK) {
              setCurrentModal('confirmDelete')
              setCurrentModalProps(modalProps)
              return handleModalOpen()
            }
            if (value === UNMARK) {
              return projectRemoveCancel(id)
            }
            if (value === DELETE) {
              setCurrentModal('confirmDelete')
              setCurrentModalProps(modalProps)
              return handleModalOpen()
            }
          }
          return (
            <ToggleModalButton
              choices={choices}
              handleClick={handleClick}
              text={text}
              type={removeStatus ? 'caution' : 'success'}
            />
          )
        },
        shrink: true,
      },
      {
        shrink: true,
        attr: '_actions',
        render: ({ row, props }) => {
          return (
            <ProjectActions
              project={row}
              versionId={versionId}
              titles={titles}
              projectRemove={projectRemove}
              projectRemoveCancel={projectRemoveCancel}
            />
          )
        },
      },
    ]

    if (isHou(customer)) {
      return HOUReorderProjectColumns(defaultColumns)
    }
    return defaultColumns
  },
}

const ProjectPreviewTable = compose(
  withProjectsPreview,
  withProjectFields,
  withProjectRemove,
  withProjectRemoveCancel,
  withTable(projectPreviewConfig)
)(props => {
  const {
    table,
    programKey,
    projectRemove,
    projectsPreview: { isLoading, codes, managers },
    titles,
  } = props

  return (
    <>
      <Filters
        table={table}
        withSearch
        filters={[
          columns.projectManagerFilter({ title: titles.project, managers }),
          ...columns.codeFilters(codes),
        ]}
      />
      <Table
        paddingBottom={200}
        loading={isLoading}
        table={table}
        renderProps={{
          projectRemove,
          programKey,
        }}
      />
    </>
  )
})

const ProjectActions = compose(withRouter)(props => {
  const {
    history,
    project,
    titles,
    projectRemove,
    projectRemoveCancel,
    versionId,
  } = props
  const { removeStatus, id } = project

  const handleEditProject = () =>
    history.push(reverse(routes.admin.projectEditItem, { id: project.id }))

  const choices = removeStatus
    ? [
        {
          label: `Edit ${titles.project}`,
          onClick: () => handleEditProject(),
        },
        {
          label: `Unmark for Deletion`,
          onClick: () => projectRemoveCancel(id),
        },
        {
          label: `Delete Project`,
          onClick: () => projectRemove(id, versionId),
        },
      ]
    : [
        {
          label: `Edit ${titles.project}`,
          onClick: () => handleEditProject(),
        },
        {
          label: 'Mark for Deletion',
          onClick: () => projectRemove(id, versionId),
        },
      ]

  const actions = choices

  return <ThreeDotsMenu actions={actions} />
})
