import * as React from 'react'
import styled from 'react-emotion'

import Box from 'bvdash/ui/core/Box'
import { SearchBox as _SearchBox } from 'bvdash/ui/tables'
import { background } from 'bvdash/styles'

export class SearchBoxSelect extends React.Component {
  state = {
    needle: '',
  }

  static defaultProps = {
    hideSearch: false,
  }

  handleSearch = event => this.setState({ needle: event.target.value })

  render() {
    const { id, placeholder, disabled, hideSearch } = this.props
    const { needle } = this.state

    return (
      <ContentBox disabled={disabled}>
        {!hideSearch && (
          <SearchBox
            id={id}
            placeholder={placeholder}
            value={needle}
            onSearch={this.handleSearch}
            disabled={disabled}
            focusOnMount={!!needle}
          />
        )}
        {this.props.children(needle)}
      </ContentBox>
    )
  }
}

const ContentBox = styled(Box)`
  display: block;
  ${background.solidWhite};
  border-radius: 5px;
  ${props => props.disabled && 'opacity: 0.5'};
`

const SearchBox = styled(_SearchBox)`
  ${background.solidWhite};
  border-radius: 5px;
  padding: 17px 20px;
`

export default SearchBoxSelect
