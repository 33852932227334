import * as React from 'react'

import { Button } from 'bvdash/components/buttons'
import { HeaderText } from 'bvdash/components/standard/texts'
import { ButtonContainer, InfoContainer, Overlay, Text } from './styles'

export const Modal = props => {
  const {
    children,
    headerColor,
    headerText,
    onCancel,
    onSubmit,
    submitText,
    submitFullWidth,
    text,
    type,
    width,
  } = props

  const ModalText =
    text != null ? text.map(t => <Text key={t}>{t}</Text>) : null

  return (
    <Overlay>
      <InfoContainer width={width}>
        {headerText != null ? (
          <HeaderText color={headerColor || type} textAlign="center">
            {headerText}
          </HeaderText>
        ) : null}
        {ModalText}
        {children}
        {onCancel != null || onSubmit != null ? (
          <ButtonContainer>
            {onCancel != null ? (
              <Button onClick={onCancel}>Cancel</Button>
            ) : null}
            {onSubmit != null && submitText != null ? (
              <Button
                onClick={onSubmit}
                color={type}
                fullWidth={submitFullWidth}
              >
                {submitText}
              </Button>
            ) : null}
          </ButtonContainer>
        ) : null}
      </InfoContainer>
    </Overlay>
  )
}

// TODO(Erik): Merge this with Modal and put throughout site.
export const ModalMultiSubmit = props => {
  const { children, headerText, onCancel, submitButtons, text, width } = props

  const SubmitButtons = submitButtons.map(button => {
    const { onSubmit, submitText, type } = button
    return (
      <Button onClick={onSubmit} color={type} key={submitText}>
        {submitText}
      </Button>
    )
  })

  const ModalText =
    text != null ? text.map(t => <Text key={t}>{t}</Text>) : null

  return (
    <Overlay>
      <InfoContainer width={width}>
        <HeaderText textAlign="center">{headerText}</HeaderText>
        {ModalText}
        {children}
        <ButtonContainer>
          {submitButtons != null ? SubmitButtons : null}
          {onCancel != null ? <Button onClick={onCancel}>Cancel</Button> : null}
        </ButtonContainer>
      </InfoContainer>
    </Overlay>
  )
}
