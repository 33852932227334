// @flow
/* eslint-disable jsx-a11y/label-has-for */
import * as React from 'react'
import { css } from 'react-emotion'

import { Select } from 'bvdash/ui/core'
import { color, textBold } from 'bvdash/styles'

const labelClass = css`
  ${color.sanJuan};
  ${textBold};
`

type Choice = {
  value: string,
  label: string,
}

type FormSelectProps = {
  label: string,
  multiple: boolean,
} & (
  | {
      // Form field
      fieldProps: Object,
      choices: Array<Choice>,
    }
  | {
      // Read-only field
      value: Object,
    }
)

export default class FormSelect extends React.Component<FormSelectProps> {
  static defaultProps = {
    multiple: false,
  }

  componentDidMount() {
    // this.initializeValue()
  }

  componentDidUpdate(prevProps: FormSelectProps) {
    // this.initializeValue()
  }

  initializeValue() {
    const { fieldProps, choices } = this.props
    if (!fieldProps) return

    const { name, value, onChange } = fieldProps
    const firstChoice = choices[0]

    if (
      firstChoice !== undefined &&
      !choices.some(choice => choice.value === value)
    ) {
      onChange({
        initial: true,
        target: {
          name,
          value: firstChoice.value,
        },
      })
    }
  }

  handleChange = (value: string) => () => {
    if (!this.props.fieldProps) return

    this.props.fieldProps.onChange({
      target: {
        name: this.props.fieldProps.name,
        value,
      },
    })
  }

  render() {
    const { fieldProps, value, label, choices, multiple } = this.props
    const readOnly = fieldProps === undefined
    return (
      <Select
        label={label}
        labelClass={labelClass}
        choices={choices}
        onChange={!readOnly && this.handleChange}
        value={readOnly ? value : fieldProps.value}
        multiple={multiple}
      />
    )
  }
}
