export const inputData = [
  {
    label: 'First Name*',
    name: 'firstName',
    placeholder: 'Enter First Name',
    type: 'string',
  },
  {
    label: 'Last Name*',
    name: 'lastName',
    placeholder: 'Enter Last Name',
    type: 'string',
  },
  {
    label: 'Phone (optional)',
    name: 'phoneNumber',
    placeholder: 'Enter Phone Number',
    type: 'string',
  },
  {
    label: 'Mobile Phone (optional)',
    name: 'mobileNumber',
    placeholder: 'Enter Mobile Number',
    type: 'string',
  },
  {
    label: 'Email (username)*',
    name: 'email',
    placeholder: 'Enter User Email',
    type: 'email',
  },
  {
    label: 'Confirm Email*',
    name: 'confirmEmail',
    placeholder: 'Re-enter User Email',
    type: 'email',
  },
  {
    label: 'Job Title (optional)',
    name: 'jobTitle',
    placeholder: 'Enter Job Title',
    type: 'string',
  },
]
