import styled from 'react-emotion'

import { ButtonContainer } from 'bvdash/components/buttons'

export const FetchMoreButton = styled(ButtonContainer)`
  display: block;
  margin: 16px auto;
  padding: 8px;
`

export const TableContainer = styled.div`
  border-radius: 5px;
  overflow-x: auto;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 48}px;
  width: 100%;
`

export const TableWrapper = styled.div`
  width: 100%;
`
