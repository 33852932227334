export const initialSettingsMenu = {
  portfolio: {
    label: 'Portfolio',
    on: true,
  },
  program: {
    label: 'BirdView',
    on: true,
  },
  project: {
    label: 'Project',
    on: true,
  },
  timesheet: {
    label: 'Timesheet',
    on: true,
  },
  task: {
    label: 'Task',
    on: true,
  },
  alert: {
    label: 'Alert',
    on: true,
  },
  conversation: {
    label: 'Conversation',
    on: true,
  },
  scope: {
    label: 'Scope',
    on: true,
  },
  update: {
    label: 'Update',
    on: true,
  },
  issue: {
    label: 'Issue',
    on: true,
  },
  risk: {
    label: 'Risk',
    on: true,
  },
  quality: {
    label: 'Quality',
    on: true,
  },
  budget: {
    label: 'Budget',
    on: true,
  },
  schedule: {
    label: 'Schedule',
    on: true,
  },
  team: {
    label: 'Team',
    on: true,
  },
  document: {
    label: 'Document',
    on: true,
  },
  survey: {
    label: 'Survey',
    on: true,
  },
}
