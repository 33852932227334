import * as React from 'react'

import { IconWithHelp } from 'bvdash/components/IconWithHelp'

export const hasIcon = icon =>
  icon != null && icon.Icon != null && icon.text != null

export const HelpIcon = props => {
  const { icon, width } = props

  return hasIcon(icon) ? <IconWithHelp icon={icon} width={width} /> : null
}

export const projectIdExampleHelpText = titles => {
  return `An ID is a unique identifier for your ${titles.project}. 
  (E.g. BV001, P1, P001, etc.)`
}

export const programIdExampleHelpText = titles => {
  return `An ID is a unique identifier for your ${titles.program}. 
  (E.g. BV001, P1, P001, etc.)`
}

export const nameExampleHelpText = titles => {
  return `(${titles.project} 1, ${titles.project} 01, Northern ${titles.project}, etc.)`
}

export const programNameHelpText = titles => {
  return `A ${titles.program} is a container for your ${titles.project}s. 
  The ${titles.program} Name can be free text to describe your ${titles.program}, 
  and can be edited at any time.`
}

export const projectNameHelpText = titles => {
  return `Enter a description to identify your ${
    titles.project
  }. ${nameExampleHelpText(titles)}`
}

export const projectCodeCodeText = titles => {
  return `The CODE will display as a Column Title in your ${titles.project} List. 
  E.g. Area, Department, Province, State, Division, etc.`
}

export const projectCodeValueText = titles => {
  return `The VALUE is an abbreviation of each CODE. 
  E.g. A10, A51, A100, AB, ON, AZ, CA, etc.`
}

export const projectCodeDescriptionText = titles => {
  return `Full DESCRIPTION for your VALUE. 
  E.g. Area 10, Area 51, Area 100, Alberta, Ontario, Arizona, California, etc.`
}
