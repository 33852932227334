import * as React from 'react'
import { compose } from 'react-apollo'
import styled from 'styled-components'

// Components
import { Modal } from 'bvdash/components/modals'

import { FlexContainer } from 'bvdash/components/standard/containers'
import { StandardText } from 'bvdash/components/standard/texts'

import { withCustomer } from 'bvdash/queries/settings/withCustomer'
import { withWelcome } from 'bvdash/admin/setup/withSetup'

import Hands from 'bvdash/images/hands.png'

const Image = styled.img`
  height: 100px;
  margin-right: 32px;
`

export const WelcomeModal = compose(
  withCustomer,
  withWelcome
)(props => {
  const {
    customer: { customer },
    closeModal,
    customerCompleteWelcome,
  } = props

  const Content = (
    <>
      <StandardText>
        We are glad to see you here! BirdView Dashboard was created to
        streamline your workflow. Quality service, customer satisfaction and
        project success will always remain at the heart of our core values. We
        are confident BVDash will be the Project Management Software you will
        love to use.
      </StandardText>
      <FlexContainer alignItems="center" marginTop={32}>
        <Image src={Hands} />
        <div>
          <StandardText marginBottom={8}>Happy collaborating,</StandardText>
          <StandardText fontWeight={500}>The BVDash Team</StandardText>
          <StandardText>BirdView Dashboard</StandardText>
        </div>
      </FlexContainer>
    </>
  )

  const onSubmit = async () => {
    const res = await customerCompleteWelcome({ customerId: customer.id })
    if (res) return closeModal()
  }

  return (
    <Modal
      headerText="Welcome to BirdView Dashboard!"
      onSubmit={onSubmit}
      submitFullWidth
      submitText="Continue"
      type="add"
    >
      {Content}
    </Modal>
  )
})
