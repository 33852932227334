/* eslint-disable jsx-a11y/label-has-for */
/*
Large text area spanning full card with dropdowns/inputs in header.

Used at:

- Project Issue Add
- Project Issue Edit
- Project Update Add
 */
import * as React from 'react'
import styled, { css } from 'react-emotion'

import { Box } from 'bvdash/ui/core'
import { Field, Error } from 'bvdash/ui/forms'
import { background, colorMap } from 'bvdash/styles'

const ContentBox = styled(Box)`
  ${background.solidWhite};

  height: 330px;
  box-shadow: 0 2px 3px 0 rgba(49, 61, 90, 0.14),
    inset 0 -1px 0 0 ${colorMap.dropShadow};
`

const HeaderBox = styled(Box)`
  height: 44px;
  padding: 10px 20px;
  border-bottom: 1px solid ${colorMap.dropShadow};
`

const inputClass = css`
  padding: 10px 20px;
  width: 100%;
  flex-grow: 1;

  border: none;
  resize: none;
  outline: none;

  font-size: 14px;
  letter-spacing: 1.1px;
  font-weight: 400;
`

const footerClass = css`
  border-top: 1px solid ${colorMap.dropShadow};
`

export default function TextareaWithHeader({
  children,
  fieldProps,
  value,
  footer,
}) {
  if (process.env.node !== 'production') {
    if (fieldProps === undefined && value === undefined) {
      // eslint-disable-next-line no-console
      console.error(
        'Either fieldProps or value must be set in TextareWithHeader'
      )
    }
  }

  const content = inputProps => (
    <ContentBox>
      <HeaderBox horizontal alignCross="center">
        {children}
      </HeaderBox>

      {inputProps ? <textarea className={inputClass} {...inputProps} /> : value}

      {footer && <footer className={footerClass}>{footer}</footer>}
    </ContentBox>
  )

  if (!fieldProps) {
    return content()
  } else {
    return (
      <Field {...fieldProps}>
        {({ getInputProps }) => (
          <Box>
            {content(getInputProps())}

            <Error show={!fieldProps.isValid}>(This field is required)</Error>
          </Box>
        )}
      </Field>
    )
  }
}
