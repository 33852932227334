import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { UserFragment } from './userFragment'
import { usersQuery } from './withUsers'

const userSendValidationEmailMutation = gql`
  mutation userSendValidationEmail($email: String!) {
    userSendValidationEmail(email: $email) {
      ok
      error
      user {
        ...User
      }
    }
  }
  ${UserFragment}
`

export const withUserSendValidationEmail = graphql(
  userSendValidationEmailMutation,
  {
    props: ({ mutate }) => ({
      userSendValidationEmail: async ({ email }) => {
        const res = await mutate({
          variables: { email },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: usersQuery }],
        })

        return res
      },
    }),
  }
)

const userSendAllValidationEmailMutation = gql`
  mutation userSendAllValidationEmail($emails: [String]!) {
    userSendAllValidationEmail(emails: $emails) {
      ok
      error
    }
  }
`

export const withUserSendAllValidationEmail = graphql(
  userSendAllValidationEmailMutation,
  {
    props: ({ mutate }) => ({
      userSendAllValidationEmail: async ({ emails }) => {
        const res = await mutate({
          variables: { emails },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: usersQuery }],
        })

        return res
      },
    }),
  }
)
