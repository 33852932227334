import * as React from 'react'
import styled from 'styled-components'

import { Export } from 'bvdash-core/icons'

import { ButtonContainer } from 'bvdash/components/buttons'

const Button = styled(ButtonContainer)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 0;
`

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 8px;
  justify-content: center;
`

export const IconTextButton = props => {
  const { label, Icon, onClick } = props
  return (
    <Button onClick={onClick} small {...props}>
      <IconContainer>
        <Icon />
      </IconContainer>
      {label}
    </Button>
  )
}

export const ExportButton = props => {
  const { onExport } = props

  return <IconTextButton label="Export" Icon={Export} onClick={onExport} />
}
