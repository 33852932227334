import * as React from 'react'
import PropTypes from 'prop-types'
import { useWith, path, split } from 'ramda'
import styled from 'react-emotion'

import { color } from 'bvdash/styles'
import { Error } from 'bvdash/ui/forms'

import TextAreaWidget from './TextAreaWidget'
import TextWidget from './TextWidget'
import DateWidget from './DateWidget'
import SelectWidget from './SelectWidget'
import { FlexContainer } from 'bvdash/components/standard/containers'
import { hasIcon, HelpIcon } from 'bvdash/components/inputs'

const inputWidgets = {
  textarea: TextAreaWidget,
  text: TextWidget,
  date: DateWidget,
  select: SelectWidget,
}

const FormField = ({
  field,
  form: { touched, errors, values, setFieldValue },
  label,
  smallFieldBox,
  widget = 'text',
  icon,
  ...props
}) => {
  const Widget = typeof widget === 'string' ? inputWidgets[widget] : widget

  return (
    <FlexContainer marginBottom={30} flexDirection="column">
      <Label htmlFor={field.name}>{label}</Label>
      <FlexContainer flexDirection="row">
        <Widget
          id={field.name}
          width={props.width}
          type="text"
          {...field}
          {...props}
          value={dottedPath(field.name, values)}
          setValue={value => setFieldValue(field.name, value)}
        />
        {hasIcon(icon) ? <HelpIcon icon={icon} /> : null}
      </FlexContainer>
      <Error
        show={dottedPath(field.name, touched) && dottedPath(field.name, errors)}
      >
        {dottedPath(field.name, errors)}
      </Error>
    </FlexContainer>
  )
}

FormField.propTypes = {
  widget: PropTypes.oneOfType([
    // custom widget component
    PropTypes.func,
    // built-in widget
    PropTypes.oneOf(Object.keys(inputWidgets)),
  ]),
  label: PropTypes.string.isRequired,
}

const dottedPath = useWith(path, [split('.')])

const Label = styled.label`
  ${color.sanJuan};
  font-size: 14px;
  font-weight: 500;
  max-width: 100%;
`

export default FormField
