import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'

import useOutsideClick from 'use-onclickoutside'

import styles from './Dropdown.scss'

export default function Dropdown({
  children,
  isOpen,
  onClose,

  position,
  withoutArrow,
  className,
  outsideClickRef,
  style,
}) {
  const ref = useRef(null)
  useOutsideClick(outsideClickRef || ref, onClose)

  if (!isOpen) return null

  return (
    <div
      ref={ref}
      className={cx(styles.root, styles[position], className, {
        [styles.arrowLess]: withoutArrow,
      })}
      style={style}
    >
      {children}
    </div>
  )
}

Dropdown.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  position: PropTypes.oneOf(['left', 'right', 'center', 'tableConfig']),
  withoutArrow: PropTypes.bool,
}

Dropdown.defaultProps = {
  position: 'left',
  withoutArrow: false,
}
