import React from 'react'
import styled from 'react-emotion'

import { withCustomer } from 'bvdash/auth/queries'
import { Logo } from 'bvdash-core/icons'

const CustomLogo = styled.img`
  width: 125px;
`

function CustomerLogo(props) {
  const {
    customer: { isLoading, customer },
  } = props

  if (isLoading) return null
  let siteLogo = ''
  if (!isLoading && customer) {
    if (customer.customerProfilePicture.length > 0)
      siteLogo = customer.customerProfilePicture[0].url
  }

  return siteLogo ? <CustomLogo src={siteLogo} alt="" /> : <Logo />
}

export default withCustomer(CustomerLogo)
