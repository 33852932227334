import { Field } from 'formik'
import * as React from 'react'

import { Card } from 'bvdash/components/styles'
import { ProjectSelectTable } from 'bvdash/components'
import { SectionHeading } from './styles'

export const ProjectTable = props => {
  const { customer, disabled, name, sectionHeading, selectKey, value } = props

  const setValue = value == null

  const [displayProjectTable, setDisplayProjectTable] = React.useState(setValue)

  return (
    <>
      <SectionHeading>{sectionHeading}</SectionHeading>
      <Card>
        <Field name={name}>
          {({ field, form }) => {
            return (
              <ProjectSelectTable
                customer={customer}
                disabled={disabled}
                display={displayProjectTable}
                onClick={() => {
                  if (!disabled) {
                    form.setFieldValue(name, null)
                    setDisplayProjectTable(true)
                  }
                }}
                onSelect={value => {
                  if (!disabled) {
                    form.setFieldValue(name, value)
                    setDisplayProjectTable(false)
                  }
                }}
                selectKey={selectKey}
                value={field.value}
              />
            )
          }}
        </Field>
      </Card>
    </>
  )
}

ProjectTable.defaultProps = {
  name: 'project',
}
