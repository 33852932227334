import * as React from 'react'
import PropTypes from 'prop-types'
import { cx } from 'react-emotion'

import * as a11y from 'bvdash/utils/a11y'

import styles from './DropdownList.scss'

export default function DropdownList({ items }) {
  return (
    <ul>
      {items.map(({ key, children, onClick, className, ...props }, index) => (
        <li
          key={key || index}
          className={cx(styles.item, className, {
            [styles.active]: props.active,
          })}
          {...a11y.clickable(onClick)}
          {...props}
        >
          {children}
        </li>
      ))}
    </ul>
  )
}

DropdownList.propTypes = {
  items: PropTypes.array.isRequired,
}
