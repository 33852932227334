import { graphql } from 'react-apollo'

import query from './programDetail.graphql'

export default graphql(query, {
  options: ({ programKey, match }) => ({
    variables: { program: programKey || match.params.programKey },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => {
    const { program = {} } = data

    return {
      program: {
        program,
        isLoading: data.loading,
        programKey:
          data.variables.program || (program !== null ? program.key : null),
      },
    }
  },
})
