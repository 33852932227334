import * as React from 'react'

import { Button } from 'bvdash/components'
import { Tooltip, Headline } from 'bvdash/ui/core'
import { Info } from 'bvdash-core/icons'
import styles from './Card.scss'

const Card = ({
  title,
  titleTooltip,
  detailsLink,
  detailsLabel = 'See Details',
  versions,
  children,
}) => (
  <article className={styles.card}>
    {title && (
      <header className={styles.header}>
        <Headline level={3}>{title}</Headline>
        {titleTooltip && (
          <Tooltip text={titleTooltip}>
            <Info />
          </Tooltip>
        )}
        {versions}
      </header>
    )}
    <section className={styles.body}>{children}</section>
    {detailsLink && (
      <nav className={styles.footer}>
        <Button to={detailsLink}>{detailsLabel}</Button>
      </nav>
    )}
  </article>
)

export default Card
