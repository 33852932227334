import * as React from 'react'
import { UserPhotoPlaceholder } from 'bvdash/conversations/components/styles'

class MaybeImage extends React.Component {
  state = {
    error: false,
  }

  handleError = e => this.setState({ error: true })

  render() {
    const { alt, src, className } = this.props
    return !src || this.state.error ? (
      <UserPhotoPlaceholder className={className}>{alt}</UserPhotoPlaceholder>
    ) : (
      <img
        src={src}
        alt={alt}
        onError={this.handleError}
        className={className}
        height={'40px'}
      />
    )
  }
}

export default MaybeImage
