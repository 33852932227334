import { useState } from 'react'

export function useToggle(initialOpen = false) {
  const [isOpen, setOpen] = useState(initialOpen)

  return {
    isOpen,
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => setOpen(!isOpen),
  }
}
