import styled from 'react-emotion'
import { color, background, shadow } from 'bvdash/styles'

const TextWidget = styled.input`
  ${color.waterloo};
  ${background.solidWhite};
  ${shadow};

  width: 420px;
  margin: 10px 20px 10px 0;
  padding: 0 25px;
  font-size: 16px;
  line-height: 59px;
  border: none;
  border-radius: 5px;
  outline: none;
  max-width: 100%;

  &:disabled {
    ${background.alabasterLight}
    cursor: auto;
  }
`

export default TextWidget
