import * as React from 'react'
import { Cell, PieChart, Pie } from 'recharts'
import styled, { withTheme } from 'styled-components'

export const ChartContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
`

const BottomLegendContainer = styled.div``

export const StandardPieChart = withTheme(props => {
  const { data, Legend, theme } = props

  return (
    <ChartContainer>
      <PieChart width={300} height={300}>
        <Pie
          data={data}
          cx={150}
          cy={150}
          labelLine={false}
          label
          outerRadius={80}
          fill={theme.color.grey}
          dataKey="value"
        >
          {data.map(entry => (
            <Cell key={entry.label} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      {Legend != null ? (
        <BottomLegendContainer>{Legend}</BottomLegendContainer>
      ) : null}
    </ChartContainer>
  )
})
