import * as React from 'react'

export default function TableFilter({ label, attr, table, render }) {
  if (!table.tableConfig.config.filters[attr]) return null

  const filterProps = { label, attr, filter: table.filter, group: table.group }

  return React.isValidElement(render)
    ? React.cloneElement(render, filterProps)
    : React.createElement(render, filterProps)
}
