import gql from 'graphql-tag'

export const LinkedIssueFragment = gql`
  fragment LinkedIssue on IssueType {
    content
    id
    importance
    isOpen
    issueId
    priority
    status
    urgency
    assignedTo {
      id
      fullName
    }
    project {
      id
      key
      name
    }
    updates {
      id
      content
      dateCreated
      author {
        id
        fullName
      }
    }
  }
`
