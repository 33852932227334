import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { setColor } from 'bvdash/css'
import { marginLeft, marginRight, marginTop, marginBottom } from 'bvdash/css'

export const ButtonContainer = styled.button`
  ${marginLeft};
  ${marginRight};
  ${marginTop};
  ${marginBottom};
  ${({ theme }) => theme.shadow};
  background: ${({ color, theme }) => setColor(color, theme)};
  color: ${({ color, theme }) =>
    color ? theme.color.white : theme.color.main};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.2px;
  min-width: 100px;
  outline: none;
  padding: ${({ thin, small }) =>
    small ? '4px 8px' : thin ? '8px 16px' : '12px 16px'};
  ${({ fullWidth, width }) =>
    fullWidth ? 'width: 100%' : width ? `width: ${width}` : undefined};

  filter: brightness(100%);
  transition: filter 0.2s ease;

  &:hover {
    filter: ${({ disabled }) => !disabled && 'brightness(90%)'};
    transition: filter 0.2s ease;
  }
  &:disabled {
    box-shadow: none;
    cursor: auto;
    opacity: 0.75;
  }
`

export const LoadMoreButton = styled(ButtonContainer)`
  margin: 0 auto;
  width: 150px;
`

export const Button = props => {
  const { children, to, fullWidth } = props

  const DefaultButton = (
    <ButtonContainer type="button" {...props}>
      {children}
    </ButtonContainer>
  )

  if (to !== undefined) {
    if (fullWidth) {
      return (
        <NavLink
          activeStyle={{
            width: '100%',
          }}
          to={to}
        >
          {DefaultButton}
        </NavLink>
      )
    } else {
      return <NavLink to={to}>{DefaultButton}</NavLink>
    }
  }

  return DefaultButton
}

Button.defaultProps = {
  to: undefined,
}

export const InlineButton = styled(Button)`
  font-size: 12px;
  min-width: 60px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 4px;
  padding-bottom: 4px;
`
