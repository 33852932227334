import gql from 'graphql-tag'
import { path } from 'ramda'
import { graphql } from 'react-apollo'

const projectIssueCreateMutation = gql`
  mutation projectsIssueCreate(
    $project: String
    $urgency: Int
    $importance: Int
    $content: String
    $assignedToId: ID
  ) {
    issueCreate(
      project: $project
      urgency: $urgency
      importance: $importance
      content: $content
      assignedToId: $assignedToId
    ) {
      ok
      error
      issue {
        id
        issueId
      }
    }
  }
`

export const withProjectIssueCreate = graphql(projectIssueCreateMutation, {
  props: ({ mutate }) => ({
    projectsIssueCreate: variables => {
      return mutate({
        variables: { ...variables },
      }).then(path(['data', 'issueCreate']))
    },
  }),
})
