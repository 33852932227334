import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { filter, pick } from 'ramda'

import { enumChoices, enumValue } from 'bvdash/utils/graphql'
import { maybeEvolve } from 'bvdash/utils'
import { assignedToChoices } from 'bvdash/projects/dataTransform'
import { AttachmentFragment } from 'bvdash/queries/attachmentFragment'
import { EnumValueFragment } from 'bvdash/core/queries'

const RiskFragment = gql`
  fragment Risk on RiskType {
    id
    riskId
    priority
    probability
    impact
    strategy
    category
    content
    value
    isOpen
    canEdit
    assignedTo {
      id
      key
      fullName
    }
  }
`

const TimelineRiskEventFragment = gql`
  fragment TimelineRiskEvent on RiskEventType {
    id
    type
    priorityBefore
    priorityAfter
    strategy
    category
    author {
      id
      fullName
    }
    assignee {
      id
      fullName
    }
    date
  }
`

const RiskUpdateFragment = gql`
  fragment RiskUpdate on RiskUpdateType {
    id
    canEdit
    content
    dateCreated
    author {
      id
      fullName
    }
  }
`

const pickRiskInputFields = pick([
  'content',
  'probability',
  'impact',
  'strategy',
  'category',
  'value',
  'attachments',
  'assignedToId',
])

const normalizeRisk = maybeEvolve({
  attachments: filter(attachment => !attachment.deleted),
  probability: enumValue,
  impact: enumValue,
})

const riskQuery = gql`
  query risk($project: String!, $riskId: Int!) {
    risk(project: $project, riskId: $riskId) {
      ...Risk
      attachments {
        ...Attachment
      }
      events {
        ...TimelineRiskEvent
      }
      updates {
        ...RiskUpdate
      }
    }
  }

  ${RiskFragment}
  ${RiskUpdateFragment}
  ${TimelineRiskEventFragment}
  ${AttachmentFragment}
`

export const withRisk = graphql(riskQuery, {
  options: props => ({
    variables: {
      project: props.projectKey,
      riskId: props.riskId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => ({
    risk: {
      isLoading: data.loading,
      risk: normalizeRisk(data.risk),
      projectKey: data.variables.project,
      riskId: data.variables.riskId,
    },
  }),
})

export const withRisks = graphql(
  gql`
    query risks($project: String!) {
      risks(project: $project) {
        ...Risk
        attachments {
          id
        }
      }
    }

    ${RiskFragment}
  `,
  {
    options: props => ({
      variables: { project: props.match.params.projectKey },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => {
      const { risks = [] } = data

      return {
        risks: {
          isLoading: data.loading,
          projectKey: data.variables.project,
          risks,
        },
      }
    },
  }
)

export const withRiskFieldsChoices = graphql(
  gql`
    query projectIssueForm($project: String!) {
      users(project: $project) {
        id
        key: id
        fullName
      }

      probability: __type(name: "RiskProbability") {
        ...EnumValues
      }
      impact: __type(name: "RiskImpact") {
        ...EnumValues
      }
      category: __type(name: "RiskCategory") {
        ...EnumValues
      }
      strategy: __type(name: "RiskStrategy") {
        ...EnumValues
      }
    }

    ${EnumValueFragment}
  `,
  {
    options: props => ({
      variables: { project: props.projectKey || props.match.params.projectKey },
    }),
    props: ({ data }) => ({
      riskFieldsChoices: {
        isLoading: data.loading,
        probability: enumChoices(data.probability),
        impact: enumChoices(data.impact),
        strategy: enumChoices(data.strategy),
        category: enumChoices(data.category),
        assignedTo: assignedToChoices(data.users),
      },
    }),
  }
)

export const withRiskCreate = graphql(
  gql`
    mutation riskCreate($project: String!, $risk: RiskCreateInput!) {
      riskCreate(project: $project, risk: $risk) {
        ok
        risk {
          id
          riskId
        }
      }
    }
  `,
  {
    props: ({ mutate, ownProps }) => ({
      riskCreate: (project, riskInput) => {
        return mutate({
          variables: {
            risk: pickRiskInputFields(riskInput),
            project,
          },
        })
      },
    }),
  }
)

export const withRiskEdit = graphql(
  gql`
    mutation riskEdit($id: ID!, $risk: RiskEditInput!) {
      riskEdit(id: $id, risk: $risk) {
        ok
        error
        risk {
          ...Risk
          attachments {
            ...Attachment
          }
        }
      }
    }

    ${RiskFragment}
    ${AttachmentFragment}
  `,
  {
    props: ({ mutate }) => ({
      riskEdit(id, riskInput, queryVariables) {
        return mutate({
          variables: {
            risk: pickRiskInputFields(riskInput),
            id,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: riskQuery,
              variables: queryVariables,
            },
          ],
        })
      },
    }),
  }
)

export const withRiskSetOpen = graphql(
  gql`
    mutation riskSetOpen($id: ID!, $open: Boolean!) {
      riskSetOpen(id: $id, open: $open) {
        ok
        error
        risk {
          id
          isOpen
          events {
            ...TimelineRiskEvent
          }
        }
      }
    }

    ${TimelineRiskEventFragment}
  `,
  {
    props: ({ mutate }) => ({
      riskSetOpen: (id, open) => {
        return mutate({
          variables: {
            id,
            open,
          },
        })
      },
    }),
  }
)

export const withRiskAddUpdate = graphql(
  gql`
    mutation riskAddUpdate($id: ID!, $update: RiskUpdateInput!) {
      riskAddUpdate(id: $id, update: $update) {
        ok
        risk {
          id
          events {
            ...TimelineRiskEvent
          }
          updates {
            ...RiskUpdate
          }
        }
      }
    }

    ${TimelineRiskEventFragment}
    ${RiskUpdateFragment}
  `,
  {
    props: ({ mutate }) => ({
      riskAddUpdate: (id, update) => {
        return mutate({
          variables: {
            id,
            update,
          },
        })
      },
    }),
  }
)

export const withRiskEditUpdate = graphql(
  gql`
    mutation riskEditUpdate($id: ID!, $update: RiskUpdateInput!) {
      riskEditUpdate(id: $id, update: $update) {
        ok
        risk {
          id
          events {
            ...TimelineRiskEvent
          }
          updates {
            ...RiskUpdate
          }
        }
      }
    }

    ${TimelineRiskEventFragment}
    ${RiskUpdateFragment}
  `,
  {
    props: ({ mutate }) => ({
      riskEditUpdate: (id, update) => {
        return mutate({
          variables: {
            id,
            update,
          },
        })
      },
    }),
  }
)

export const withRiskRemoveUpdate = graphql(
  gql`
    mutation riskRemoveUpdate($id: ID!) {
      riskRemoveUpdate(id: $id) {
        ok
        risk {
          id
          events {
            ...TimelineRiskEvent
          }
          updates {
            id
          }
        }
      }
    }

    ${TimelineRiskEventFragment}
  `,
  {
    props: ({ mutate }) => ({
      riskRemoveUpdate: id => {
        return mutate({
          variables: {
            id,
          },
        })
      },
    }),
  }
)
