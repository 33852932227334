import styled, { css } from 'react-emotion'
import { Link } from 'react-router-dom'

import { color } from 'bvdash/styles'

const mLeft = marginLeft => css`
  ${marginLeft ? `margin-left: ${marginLeft}px` : undefined};
`
const mRight = marginRight => css`
  ${marginRight ? `margin-right: ${marginRight}px` : undefined};
`
const mTop = marginTop => css`
  ${marginTop ? `margin-top: ${marginTop}px` : undefined};
`
const mBottom = marginBottom => css`
  ${marginBottom ? `margin-bottom: ${marginBottom}px` : undefined};
`

export const InlineLink = styled(Link)`
  ${color.dodgerBlue};
`

export const ProjectTableContainer = styled.div`
  margin-bottom: 24px;
`

export const FlexContainer = styled.div`
  align-items: ${({ alignItems }) => alignItems || 'center'};
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-around'};

  ${({ marginTop }) => mTop(marginTop)};
  ${({ marginBottom }) => mBottom(marginBottom)};
`

export const IconContainer = styled.div`
  ${({ marginLeft }) => mLeft(marginLeft)};
  ${({ marginRight }) => mRight(marginRight)};
  ${({ marginTop }) => mTop(marginTop)};
  ${({ marginBottom }) => mBottom(marginBottom)};
`

export const Header = styled.h1`
  ${color.dodgerBlue};
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 32px;
  text-align: center;
`

export const LinkWrapper = styled(Link)`
  text-decoration: none;
`

export const Text = styled.p`
  ${color.sanJuan};
  font-size: 16px;
  line-height: 1.5em;

  ${({ marginTop }) => mTop(marginTop)};
  ${({ marginBottom }) => mBottom(marginBottom)};
`
