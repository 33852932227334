import * as React from 'react'
import {
  equals,
  isEmpty,
  allPass,
  intersection,
  flatten,
  repeat,
  concat,
  fromPairs,
} from 'ramda'
import throttle from 'lodash.throttle'

import { capitalize } from 'bvdash/utils'

const any = predicates => row =>
  predicates.reduce((res, fn) => res || fn(row), false)

const getValueFromColumn = (column, attr) =>
  typeof column.value === 'function' ? column.value : row => row[attr]

const withTable = config => WrappedComponent => {
  const rowKey = config.rowKey || defaultRowKey

  //
  // High-order Component
  //
  return class extends React.Component {
    tableConfigKey = null

    constructor(props) {
      super(props)

      const sortBy = config.sortBy ? config.sortBy.replace('-', '') : null
      const sortAsc = sortBy === config.sortBy

      // Cache key to store tablethis.Config in local storage
      this.tableConfigKey = `table.${config.name}`

      this.state = {
        sortBy,
        sortAsc,

        filters: config.defaultFilters || {},

        // search input value
        search: '',
        // search query, used for filtering (throttled `search` value)
        searchQuery: '',

        selected: {},

        expandedGroup: null,
        expandedRow: {},

        tableConfig: {},
      }

      this.initialState = this.state

      this.state.tableConfig = this._initializeTableConfig()
    }

    _initializeTableConfig() {
      const tableConfig = {
        columns: {},
        filters: {},
      }

      // Load config from defaultTableConfig
      if (config.defaultTableConfig) {
        const { columns = [], filters = [] } = config.defaultTableConfig
        columns.forEach(key => (tableConfig.columns[key] = true))
        filters.forEach(key => (tableConfig.filters[key] = true))
      }

      // If there's no defaultTableConfig:
      // enable all columns by default
      if (isEmpty(tableConfig.columns)) {
        concat(this.columns('primary'), this.columns('secondary')).forEach(
          ({ attr }) => {
            if (!attr.startsWith('_')) tableConfig.columns[attr] = true
          }
        )
      }
      // enable all filters by default
      if (isEmpty(tableConfig.filters)) {
        this.columns('primary').forEach(({ attr }) => {
          if (!attr.startsWith('_')) tableConfig.filters[attr] = true
        })
        Object.keys(config.filters || {}).forEach(attr => {
          if (!attr.startsWith('_')) tableConfig.filters[attr] = true
        })
      }

      return tableConfig
    }

    componentDidMount() {
      this.hydrateStateWithLocalStorage()
    }

    componentDidUpdate() {
      this.setStateLocalStorage()
    }

    setStateLocalStorage() {
      const state = JSON.stringify(this.state)
      return localStorage.setItem(this.tableConfigKey, state)
    }

    hydrateStateWithLocalStorage() {
      const state = localStorage.getItem(this.tableConfigKey)
      if (!state) {
        return
      }
      try {
        const parsed = JSON.parse(state)
        const parsedArray = Object.keys(parsed)

        const initialArray = Object.keys(this.initialState)

        if (!equals(parsedArray, initialArray)) {
          return
        }
        return this.setState(parsed)
      } catch (err) {
        this.removeStateLocalStorage()
      }
    }

    removeStateLocalStorage = () => {
      return localStorage.removeItem(this.tableConfigKey)
    }

    //
    // Sorting
    //
    handleSort = column => () => {
      // Sort by `column` ascending.
      // If table is already sorted by column, switch sort direction.
      this.setState(state => ({
        sortBy: column,
        sortAsc: state.sortBy === column ? !state.sortAsc : false,
      }))
    }

    sort = data => {
      const { sortBy, sortAsc } = this.state
      if (!sortBy) {
        return data
      }

      const column = this.columns('primary').find(({ attr }) => attr === sortBy)
      if (!column) {
        return data
      }

      const dir = sortAsc ? 1 : -1

      const getValue = getValueFromColumn(column, sortBy)

      return [...data].sort((a, b) => {
        const valA = getValue(a)
        const valB = getValue(b)
        if (valA < valB) {
          return -dir
        }
        if (valA > valB) {
          return dir
        }

        return 0
      })
    }

    //
    // Filtering
    //
    handleFilter = (column, value) => () => {
      this.setState(state => {
        const filters = { ...state.filters }

        if (value === undefined) {
          delete filters[column]
        } else {
          filters[column] = value
        }

        return { filters }
      })
    }

    filter = data => {
      const { filters } = this.state
      if (isEmpty(filters)) return data

      const predicates = Object.keys(filters)
        .map(attr => {
          // value to filter by
          const value = filters[attr]
          // empty filter value
          if (value === undefined || value.length === 0) return null

          // 1. Construct a predicate:
          // Predicate is a function (value => row => bool)
          let predicate
          if (config.filters && config.filters[attr]) {
            // filter by custom function from config
            predicate = config.filters[attr]
          } else {
            // filter by column
            const column = this.columns('primary').find(
              column => column.attr === attr
            )

            // unknown column
            if (!column) return null

            const getValue = getValueFromColumn(column, attr)
            predicate = value => row => getValue(row) === value
          }

          // 2. Pass value to predicate:
          // When filtering by multiple values, predicate should match any of them
          return value.constructor === Array
            ? any(value.map(predicate))
            : predicate(value)
        })
        // Filter out invalid predicates
        // (e.g. missing filter values or unknown columns)
        .filter(Boolean)

      // Given a list of predicates, all of them must be truthy to include
      // row in dataset
      return data.filter(allPass(predicates))
    }

    clearFilters = () => {
      this.setState({ filters: {}, search: '' })
      this.setSearchQuery('')
    }

    //
    // Searching
    //
    handleSearch = search => () => {
      this.setState({ search })
      this.setSearchQuery(search)
    }

    setSearchQuery = throttle(
      value => this.setState({ searchQuery: value }),
      200,
      {
        trailing: true,
      }
    )

    search = data => {
      const { searchQuery } = this.state
      if (!config.search || !searchQuery) {
        return data
      }

      const searchBy = config.search(searchQuery)
      return data.filter(searchBy)
    }

    // Selecting

    /**
     * Toggle selection of item identified by `key`
     * @param {string} key - Item's key, the same returned by `rowKey` function (`id` prop by default)
     * @param {boolean?} selected - Selection status. Inverts selection if not specified.
     */
    handleSelect = (key, selected) => () => {
      this.setState(state => ({
        selected: {
          ...state.selected,
          [key]: selected == null ? !state.selected[key] : selected,
        },
      }))
    }

    /**
     * Toggle selection of all items
     * @param {boolean?} selected - Selection status for all items. Inverts selection if not specified.
     */
    handleSelectAll = selected => () => {
      const allKeys = this.data().map(row => rowKey(row))

      this.setState(state => {
        if (selected == null) {
          selected = allKeys.some(key => !state.selected[key])
        }

        return {
          selected: fromPairs(allKeys.map(key => [key, selected])),
        }
      })
    }

    /**
     * Return data rows which are selected
     * @param {array} data - List of items
     */
    selected = data =>
      // when grouping is active, data are actually object
      data != null && data.filter != null
        ? data.filter(row => this.state.selected[rowKey(row)])
        : []

    /**
     * Return true if item is selected, false otherwise
     * @param key - Item's key, the same returned by `rowKey` function (`id` prop by default)
     * @return {boolean}
     */
    isSelected = key => {
      // Never return undefined. The value is used in controlled inputs
      return this.state.selected[key] || false
    }

    //
    // TableConfig
    //
    handleConfig = tableConfig => {
      localStorage.setItem(this.tableConfigKey, JSON.stringify(tableConfig))
      this.setState({ tableConfig })

      // reset hidden filters
      const { filters } = this.state
      if (!isEmpty(filters)) {
        Object.keys(filters).forEach(attr => {
          if (tableConfig.filters[attr]) return
          this.handleFilter(attr, undefined)()
        })
      }
    }

    //
    // Grouping
    //

    activeTypes = () => {
      if (config.types[0] === this.props.groupBy) {
        return { primary: config.types[0], secondary: config.types[1] }
      } else {
        return { primary: config.types[1], secondary: config.types[0] }
      }
    }

    columns = (dataset = 'primary') => {
      if (typeof config.columns !== 'function') return config.columns

      return config
        .columns(this.props, dataset)
        .filter(
          column =>
            (dataset === 'primary' && !column.onlySecondary) ||
            (dataset === 'secondary' && !column.onlyPrimary)
        )
    }

    handleExpand = group => () => {
      this.setState(state => ({
        expandedGroup: state.expandedGroup === group ? null : group,
      }))
    }

    calculateColumnSizes = (primary, secondary) => {
      if (!secondary) return {}

      // 1. shared: true -> shared: 'key'
      const normalizeNames = arr =>
        arr.map(({ shared, attr }) =>
          shared && typeof shared === 'boolean' ? attr : null
        )
      const primaryPivots = normalizeNames(primary)
      const secondaryPivots = normalizeNames(secondary)

      // 2. keep only column names in both sets
      const validPivots = intersection(primaryPivots, secondaryPivots)

      // 3. split columns into groups separated by shared columns
      // [null, null, 'key', null, null, 'name', null, '_actions']
      // [null, null, null, 'key', null,  'name', null, '_actions']
      // pivots: [2, 5, 7]
      // pivots: [3, 5, 7]
      // group lengths: [2, 1', 2, 1', 1, 1']  (n' is pivot)
      // group lengths: [3, 1', 1, 1', 1, 1']  (n' is pivot)
      // colspans: [3, 1, 2, 1, 1, 1]
      const splitByPivots = arr =>
        arr
          .reduce(
            (acc, item, index) => {
              if (!item || !validPivots.includes(item)) return acc
              acc[acc.length - 1].push(index)
              acc.push([index, index + 1])

              if (index + 1 < arr.length) acc.push([index + 1])
              return acc
            },
            [[0]]
          )
          .map(([left, right]) => right - left)
          .filter(item => item >= 0)

      const primaryGroups = splitByPivots(primaryPivots)
      const secondaryGroups = splitByPivots(secondaryPivots)

      const colSizes = (primary, secondary) =>
        flatten(primary.map((cols, index) => repeat(secondary[index], cols)))

      return {
        primary: colSizes(primaryGroups, secondaryGroups),
        secondary: colSizes(secondaryGroups, primaryGroups),
      }
    }

    //
    // Rendering
    //
    prepareData = data => {
      const preparedData = this.sort(this.search(this.filter(data)))

      const groupBy = config.groupBy && config.groupBy(this.props)
      if (!groupBy) return preparedData

      const groups = {}
      preparedData.forEach(row => {
        const group = groupBy(row)

        if (!groups[group]) groups[group] = []
        groups[group].push(row)
      })

      return groups
    }

    calculateSummary = data => {
      const summaryData = {}

      // when data are grouped, don't calculate totals
      if (!data.forEach) {
        Object.keys(data).forEach(
          key => (summaryData[key] = this.calculateSummary(data[key]))
        )
        return summaryData
      }

      // currently return value when no programs is an array of [null]
      // temporary fix until table component is refactored
      if (data.length === 0 || data[0] === null) {
        return summaryData
      }

      data.forEach(row => {
        this.columns('primary').forEach(
          ({ summary, value: getValue, attr }) => {
            const value =
              typeof getValue === 'function' ? getValue(row) : row[attr]

            if (typeof summary === 'function') {
              if (summaryData[attr] === undefined) {
                summaryData[attr] = []
              }
              summaryData[attr].push(value)
            }
          }
        )
      })

      return summaryData
    }

    data = () => {
      return config.data ? config.data(this.props) : this.props.data
    }

    render() {
      const { data: _data, ...ownProps } = this.props
      const data = this.data()

      const preparedData = this.prepareData(data)

      const summaryData = this.calculateSummary(preparedData)
      const isFiltered =
        !!Object.keys(this.state.filters).filter(attr => {
          return !isEmpty(this.state.filters[attr])
        }).length || this.state.search !== ''

      const selected = this.selected(preparedData)
      const isSelectedAll = selected.length === preparedData.length

      const isVisibleColumn = ({ attr }) =>
        attr.startsWith('_') || this.state.tableConfig.columns[attr]

      const columnsPrimary = this.columns('primary')
      const columnsSecondary = this.columns('secondary')
      const visibleColumnsPrimary = columnsPrimary.filter(isVisibleColumn)
      const visibleColumnsSecondary = columnsSecondary.filter(isVisibleColumn)
      const {
        primary: primaryColumnSizes = null,
        secondary: secondaryColumnSizes = null,
      } = this.calculateColumnSizes(
        visibleColumnsPrimary,
        visibleColumnsSecondary
      )

      const isGrouped = !!(config.groupBy && config.groupBy(this.props))
      const props = {
        ...config,
        rowKey,
        columns: columnsPrimary,
        visibleColumns: visibleColumnsPrimary,
        columnSizes: primaryColumnSizes,
        data: preparedData,
        isGrouped,
        isFiltered,
        clearStorage: this.removeStateLocalStorage,

        summary: {
          has: !isGrouped && !!Object.keys(summaryData).length,
          data: summaryData,
        },

        sort: {
          onSort: this.handleSort,
          sortBy: {
            column: this.state.sortBy,
            asc: this.state.sortAsc,
          },
        },

        filter: {
          onFilter: this.handleFilter,
          filterBy: this.state.filters,
          clearFilters: this.clearFilters,
        },

        search: {
          onSearch: this.handleSearch,
          value: this.state.search,
        },

        select: {
          onSelect: this.handleSelect,
          onSelectAll: this.handleSelectAll,
          selected,
          isSelectedAll,
          isSelected: this.isSelected,
        },

        group: {
          isExpandable: Array.isArray(config.types) && config.types.length > 1,
          expanded: this.state.expandedGroup,
          onExpand: this.handleExpand,
          onGroup: this.props.onGroupByChange,
          groupBy: this.props.groupBy,
          data: row => config.secondaryData(row, this.activeTypes().secondary),
          columns: columnsSecondary,
          visibleColumns: visibleColumnsSecondary,
          columnSizes: secondaryColumnSizes,
        },

        ...(config.rowExpand
          ? {
              rowExpand: id => ({
                isExpanded: this.state.expandedRow[id],
                toggle: () =>
                  this.setState({
                    expandedRow: {
                      ...this.state.expandedRow,
                      [id]: !this.state.expandedRow[id],
                    },
                  }),
                render: config.rowExpand,
              }),
            }
          : {}),

        tableConfig: {
          onSave: this.handleConfig,
          columns: columnsPrimary.reduce((acc, { attr, label }) => {
            if (attr.startsWith('_')) return acc

            acc[attr] = label || capitalize(attr)
            return acc
          }, {}),
          config: this.state.tableConfig,
        },
      }

      return <WrappedComponent table={props} {...ownProps} />
    }
  }
}

function defaultRowKey(row) {
  if (__DEV__) {
    if (row.id === 'undefined') {
      throw new Error(
        'Provide rowKey parameter to table config which should return unique key for each row, e.g. rowKey: row => row.key'
      )
    }
  }

  return row.id
}

export default withTable
