import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

export const ProgramFragment = gql`
  fragment Program on ProgramType {
    id
    key
    name
    startDate
    endDate
    deleted
    isDefault
    removeStatus

    manager {
      id
      key
      fullName
    }
    projects {
      id
      name
      teamCount
    }
    __typename
  }
`

export const programsListQuery = gql`
  query ProgramsList {
    programs {
      ...Program
    }
  }
  ${ProgramFragment}
`

export const withPrograms = graphql(programsListQuery, {
  props: ({ data }) => ({
    programs: {
      isLoading: data.loading,
      programs: (data.programs || []).filter(program => !program.deleted),
    },
  }),
})
