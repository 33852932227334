import * as React from 'react'
import styled from 'styled-components'
import { Plus } from 'bvdash-core/icons'
import { Button } from './Button'
import { IconButton } from './IconButton'

const Container = styled.div`
  display: flex;
  align-items: center;
`

export const AddButton = props => {
  const { children, fullWidth } = props
  return (
    <Button marginBottom={8} marginTop={8} {...props} fullWidth={fullWidth}>
      <Container>
        <IconButton
          background="accent"
          color="accent"
          Icon={props.icon || Plus}
          marginRight={16}
          noShadow
          padding={2}
          size={20}
        />
        {children}
      </Container>
    </Button>
  )
}

AddButton.defaultProps = {
  fullWidth: true,
}
