import * as React from 'react'
import { compose } from 'react-apollo'
import styled from 'react-emotion'

import { color } from 'bvdash/styles'
import { withCustomer } from 'bvdash/auth/queries'
import { settings } from 'bvdash/queries/settings'

const Content = styled.span`
  ${({ positive }) => (positive ? color.sanJuan : color.carnation)};
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

const Unit = styled.span`
  font-size: 0.65em;
  font-weight: 500;
  margin-left: 3px;
`

const NumberValue = ({ value, settings, customer, numberScale = 'table' }) => {
  if (customer.isLoading || settings.isLoading) {
    return <Content />
  }
  const {
    settings: {
      dashboardNumberPrecision,
      dashboardNumberScale,
      tableNumberPrecision,
      tableNumberScale,
    },
  } = settings

  let formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  let unit = null
  if (customer.budgetUnit === 'HOURS') {
    formatOptions = {
      maximumFractionDigits: 0,
    }
  } else if (numberScale === 'table') {
    unit = tableNumberScale
    formatOptions = {
      minimumFractionDigits: tableNumberPrecision,
      maximumFractionDigits: tableNumberPrecision,
    }
  } else if (numberScale === 'dashboard') {
    unit = dashboardNumberScale
    formatOptions = {
      minimumFractionDigits: dashboardNumberPrecision,
      maximumFractionDigits: dashboardNumberPrecision,
    }
  }

  if (unit === 'A_' || unit === 'NONE' || unit === null) unit = ''

  let scale
  unit = unit.toUpperCase()
  switch (unit) {
    case 'M':
      scale = '1000000'
      break
    case 'K':
      scale = '1000'
      break
    default:
      scale = 1
  }

  let locale = 'en-US'
  if (customer.numberFormat === 'AMERICAN') {
    locale = 'en-US'
  } else if (customer.numberFormat === 'EUROPEAN') {
    locale = 'sv-SE'
  }

  const displayValue = parseFloat(value / scale).toLocaleString(
    locale,
    formatOptions
  )

  return (
    <Content positive={value >= 0}>
      {displayValue} {unit && <Unit>{unit}</Unit>}
    </Content>
  )
}

export default compose(
  settings,
  withCustomer
)(NumberValue)
