import * as React from 'react'
import styled from 'react-emotion'

const Group = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'no-wrap')};
  justify-content: space-around;
  align-items: center;
`

const ButtonGroup = props => {
  const { children, flexWrap } = props
  return <Group flexWrap={flexWrap}>{children}</Group>
}

export default ButtonGroup
