import gql from 'graphql-tag'

export const EnumValueFragment = gql`
  fragment EnumValues on __Type {
    enumValues {
      name
      description
    }
  }
`
