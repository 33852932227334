import * as React from 'react'
import styled from 'react-emotion'

import { background, colorMap, headerHeight } from 'bvdash/styles'
import Button from 'bvdash/ui/core/Button'
import CustomerLogo from 'bvdash/ui/core/CustomerLogo'

import { DesktopNav } from 'bvdash/components/navigation/DesktopNav'
import { MobileNav } from 'bvdash/components/navigation/MobileNav'

import { has_program_permissions } from 'bvdash/auth/permissions'
import { renderLinks } from 'bvdash/components/navigation/links'

import routes from 'bvdash/routes'

const Content = styled.header`
  ${background.solidWhite};
  align-items: center;
  box-shadow: inset 0 -1px 0 0 ${colorMap.dropShadow};
  display: flex;
  height: ${headerHeight}px;
  justify-content: space-between;
  position: relative;
  padding: 0 32px;

  ${({ isAuthenticated }) =>
    !isAuthenticated ? `justify-content: center` : undefined};
`

export const Header = props => {
  const {
    authUser,
    defaultProgram,
    onboarding,
    settingsMenu,
    titles,

    isBusinessPlan,
    isEnterprisePlan,
    isFreePlan,
    isProfessionalPlan,

    trialExpired,
  } = props

  if (!authUser.isAuthenticated) {
    return (
      <Content isAuthenticated={authUser.isAuthenticated}>
        <Button href="https://www.bvdash.com">
          <CustomerLogo />
        </Button>
      </Content>
    )
  }

  const hasPrograms = has_program_permissions(
    authUser.user,
    'projects.view_program'
  )

  const links = renderLinks({
    titles,
    defaultProgram,
    onboarding,
    permissions: { hasPrograms },
    plans: {
      isBusinessPlan,
      isEnterprisePlan,
      isFreePlan,
      isProfessionalPlan,
    },
    settingsMenu,
    trialExpired,
  })

  const logoLink = trialExpired
    ? routes.admin.accountSubscriptions
    : routes.projects.all

  return (
    <Content isAuthenticated={authUser.isAuthenticated}>
      <Button to={logoLink}>
        <CustomerLogo />
      </Button>
      {authUser.isAuthenticated && (
        <>
          <DesktopNav
            authUser={authUser}
            defaultProgram={defaultProgram ? defaultProgram.key : null}
            links={links}
            onboarding={onboarding}
            settingsMenu={settingsMenu}
            titles={titles}
            // plans (ordered from least to most expensive)
            isFreePlan={isFreePlan}
            isProfessionalPlan={isEnterprisePlan}
            isBusinessPlan={isBusinessPlan}
            isEnterprisePlan={isProfessionalPlan}
            trialExpired={trialExpired}
          />
          <MobileNav
            authUser={authUser}
            isProfessionalPlan={isProfessionalPlan}
            isBusinessPlan={isBusinessPlan}
            isEnterprisePlan={isEnterprisePlan}
            links={links}
            onboarding={onboarding}
            settingsMenu={settingsMenu}
            titles={titles}
            trialExpired={trialExpired}
          />
        </>
      )}
    </Content>
  )
}
