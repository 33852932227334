import { injectGlobal } from 'react-emotion'

import fontFace from './fonts/sailec.woff'
import fontFaceMedium from './fonts/sailec-medium.woff'
import fontFaceBlack from './fonts/sailec-black.woff'

import { color, background, primaryFont, textDefault } from 'bvdash/styles'

injectGlobal`
// Sailec Regular - 400
@font-face {
 font-family: ${primaryFont};
 font-style: normal;
 font-weight: 400;
 src: local('Sailec Regular'), local('Sailec-Regular'), url(${fontFace}) format('woff');
}

// Sailec Medium - 500
@font-face {
 font-family: ${primaryFont};
 font-style: normal;
 font-weight: 500;
 src: local('Sailec Medium'), local('Sailec-Medium'), url(${fontFaceMedium}) format('woff');
}

// Sailec Black - 700
@font-face {
 font-family: ${primaryFont};
 font-style: normal;
 font-weight: 700;
 src: local('Sailec Black'), local('Sailec-Black'), url(${fontFaceBlack}) format('woff');
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure,
figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  ${textDefault};

  ${color.sanJuan};
  ${background.alabaster};
  box-sizing: border-box;
  font-family: ${primaryFont};
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  font-family: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

.text {
  ${textDefault};
  ${color.sanJuan};
}

@keyframes shake {
  8%, 41% {
    transform: translateX(-7px);
  }
  25%, 58% {
    transform: translateX(7px);
  }
  75% {
    transform: translateX(-3px);
  }
  92% {
    transform: translateX(3px);
  }
  0%, 100% {
    transform: translateX(0);
  }
}

// @react/dialog styles
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
}

[data-reach-dialog-content] {
  width: 500px;
  max-width: 95%;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
}
`
