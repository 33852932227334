import * as React from 'react'
// Components
import { Modal } from 'bvdash/components/modals'
import { NavLink } from 'react-router-dom'
import routes from 'bvdash/routes'

export const NavLinkToManageTeam = (onCancel, titles) => {
  return (
    <div>
      <NavLink to={routes.admin.projectTeam} onClick={onCancel}>
        Click Here
      </NavLink>{' '}
      to assign users to a {titles.project}.
    </div>
  )
}

const texts = (titles, onCancel) => [
  `If any users were not assigned to a ${titles.project}, they were not sent a validation email`,
  `If you would like these users to receive a validation email, please assign them to a ${titles.project} and resend Validation Emails.`,
  NavLinkToManageTeam(onCancel, titles),
]

export const NoSendValidationEmailsModal = props => {
  const { closeModal, titles } = props
  return (
    <Modal
      headerText="Unable to Send All Validation Emails"
      onSubmit={closeModal}
      submitText="Continue"
      text={texts(titles, closeModal)}
      type="caution"
    />
  )
}
