import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { usersQuery } from 'bvdash/admin/users/queries/withUsers'
import { customerQuery } from 'bvdash/queries/settings/withCustomer'

import { projectQuery } from './withProject'
import { ProjectFragment } from './projectFragment'

const projectAddUsersMutation = gql`
  mutation projectAddUsers($projectId: ID!, $userIds: [ID]!) {
    projectAddUsers(projectId: $projectId, userIds: $userIds) {
      ok
      error
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`

export const withProjectAddUsers = graphql(projectAddUsersMutation, {
  props: ({ mutate }) => ({
    projectAddUsers: async (projectId, userIds) => {
      const { data } = await mutate({
        variables: { projectId, userIds },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: usersQuery },
          { query: projectQuery, variables: { id: projectId } },
          { query: customerQuery },
        ],
      })
      return data.projectAddUsers.ok
    },
  }),
})
