import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { StripeCustomerFragment } from './stripeCustomerFragment'
import { stripePaymentSourcesQuery } from './withStripePaymentSources'

const stripeAddPaymentMutation = gql`
  mutation stripeAddPaymentSource(
    $stripeCustomerId: String!
    $cardToken: String!
  ) {
    stripeAddPaymentSource(
      stripeCustomerId: $stripeCustomerId
      cardToken: $cardToken
    ) {
      ok
      error
      customer {
        ...StripeCustomer
      }
    }
  }
  ${StripeCustomerFragment}
`

export const withStripePaymentAdd = graphql(stripeAddPaymentMutation, {
  props: ({ mutate, ownProps }) => ({
    stripeAddPaymentSource: async ({ stripeCustomerId, cardToken }) => {
      const res = await mutate({
        variables: { stripeCustomerId, cardToken },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: customerQuery,
          },
          {
            variables: { stripeCustomerId: ownProps.stripeCustomerId },
            query: stripePaymentSourcesQuery,
          },
        ],
      })

      return res.data.stripeAddPaymentSource
    },
  }),
})
