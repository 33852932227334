import * as React from 'react'
import styled from 'react-emotion'

import { Trash } from 'bvdash-core/icons'
import { clickable } from 'bvdash/utils/a11y'
import { color } from 'bvdash/styles'

import * as images from './images'

const Content = styled.span`
  margin-right: 20px;

  line-height: 34px;
`

const Link = styled.a`
  ${color.dodgerBlue};
  text-decoration: none;
  white-space: pre-line;

  &:hover {
    text-decoration: underline;
  }
`

const FileIcon = styled.span`
  margin-right: 10px;

  svg {
    // magic constant to vertically align icon with text
    margin-top: -4px;
    vertical-align: middle;

    width: 18px;
    height: 22px;
    object-fit: contain;
  }
`

function normalizeFiletype(filetype) {
  const normalized = filetype.toLowerCase()
  if (normalized === 'jpeg') return 'jpg'
  return normalized
}

const Attachment = props => {
  const { file, onDelete } = props

  const filetype = normalizeFiletype(file.url.split('.').pop())

  return !onDelete ? (
    <Content>
      <Link href={file.url} download>
        <FileIcon dangerouslySetInnerHTML={{ __html: images[filetype] }} />
        {file.name}
      </Link>
    </Content>
  ) : (
    <Content>
      <Trash {...clickable(onDelete(file))} />
      <Link href={file.url} download>
        {file.name}
      </Link>
    </Content>
  )
}

export default Attachment
