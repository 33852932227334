import { colorMap } from 'bvdash/styles'

export const coxMediaStatuses = type => {
  switch (type.toLowerCase()) {
    case 'archived':
      return colorMap.sanJuan
    case 'waiting':
      return colorMap.cadetBlue
    case 'processing':
      return colorMap.dodgerBlue
    case 'submitted':
      return colorMap.neonCarrot
    default:
      return colorMap.solidWhite
  }
}
