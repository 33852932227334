import * as React from 'react'

import { Filter, CrossO } from 'bvdash-core/icons'

import { BlueCircleButton } from 'bvdash/components/buttons'

const iconStyle = {
  position: 'absolute',
  left: '14px',
  top: '9px',
}

const ClearFilters = props => {
  const { filters, disabled } = props

  return (
    <BlueCircleButton
      Icon={Filter}
      onClick={!disabled ? filters.clearFilters : undefined}
      marginRight={10}
      noShadow={disabled}
    >
      {!disabled ? <CrossO width={12} height={12} style={iconStyle} /> : null}
    </BlueCircleButton>
  )
}

export default ClearFilters
