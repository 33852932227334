/**
 * Layout used for Issues, Actions, Risks, Quality and Documents pages.
 * Originally it was used for Issues only, hence the name.
 */

import * as React from 'react'
import { css } from 'react-emotion'

import { Box } from 'bvdash/ui/core'

function IssueLayout({ main, sidebar }) {
  return (
    <Box horizontal className={columnsClass} width="100%">
      <Box grow={2} shrink={0} basis={0}>
        {main}
      </Box>
      <Box grow={1} shrink={0} basis={0} marginLeft="8px">
        {sidebar}
      </Box>
    </Box>
  )
}

const columnsClass = css`
  margin-bottom: 40px;
`

export default IssueLayout
