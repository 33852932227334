import styled from 'styled-components'

import { FlexContainer } from 'bvdash/components/standard/containers'
import { StandardText } from 'bvdash/components/standard/texts'

export const Stepper = styled.div``

export const StepContainer = styled(FlexContainer).attrs(() => ({
  alignItems: 'center',
}))`
  margin-bottom: 16px;
`

export const StepIcon = styled(FlexContainer).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))`
  background: ${({ isCompleted, isCurrent, theme: { color } }) =>
    isCompleted ? color.main : isCurrent ? color.accent : color.white};
  color: ${({ isCompleted, isCurrent, theme: { color } }) =>
    isCompleted || isCurrent ? color.white : color.main};
  border: 1px solid ${({ theme }) => theme.color.muted};
  border-radius: 100%;
  height: 24px;
  width: 24px;
`

export const StepText = styled(StandardText)`
  margin-left: 8px;
`

export const StepDescriptionContainer = styled.div`
  flex: 1;
  padding: 16px;
`
