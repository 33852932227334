import * as React from 'react'
import { cx } from 'react-emotion'

import * as a11y from 'bvdash/utils/a11y'
import styles from './IconBox.scss'

export default function IconBox({
  children,
  onClick,
  className,
  size = 'default',
  theme = 'default',
}) {
  return (
    <div
      className={cx(styles.box, className, {
        [styles.clickable]: onClick,
        [styles[`size${size}`]]: size,
        [styles[`theme${theme}`]]: theme,
      })}
      {...a11y.clickable(onClick)}
    >
      {children}
    </div>
  )
}
