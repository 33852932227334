import { format, parseISO } from 'date-fns'
import { Field } from 'formik'
import * as React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  ErrorText,
  ErrorContainer,
  InputContainer as StandardContainer,
  Input as StandardInput,
  Label as StandardLabel,
} from './styles'
import { errorHandler } from './errorHandler'

const CustomDateInput = props => {
  return (
    <StandardContainer {...props} width="100%">
      <StandardInput {...props} />
    </StandardContainer>
  )
}

export const DayPickerField = props => {
  const {
    components: {
      InputContainer = StandardContainer,
      Input = DatePicker,
      Label = StandardLabel,
    },
    containerStyle,
    inputStyle,
    disabled,
    displayErrorText,
    label,
    name,
    onClick,
    placeholder,
    type,
  } = props

  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { errors, touched } = form

        const error = errorHandler(name, touched, errors)

        const dateValue =
          field.value !== '' ? format(parseISO(field.value), 'd-MMM-yy') : ''

        return (
          <InputContainer {...containerStyle} onClick={onClick}>
            {label && (
              <Label name={name} error={error}>
                {label}
              </Label>
            )}
            <Input
              {...field}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              customInput={<CustomDateInput error={error} />}
              disabled={disabled}
              error={error}
              onChange={value => form.setFieldValue(name, value)}
              placeholder={placeholder}
              type={type || 'string'}
              value={dateValue}
              style={inputStyle}
            />
            {displayErrorText && (
              <ErrorContainer>
                {error ? <ErrorText>{errors[name]}</ErrorText> : null}
              </ErrorContainer>
            )}
          </InputContainer>
        )
      }}
    />
  )
}

DayPickerField.defaultProps = {
  components: {
    InputContainer: StandardContainer,
    Input: StandardInput,
    Label: StandardLabel,
  },
  displayErrorText: true,
  onClick: null,
}
