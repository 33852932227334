export function search(value, ...fields) {
  if (value === null || value === undefined || value === '') return false

  const normalized = value.toLowerCase()
  return fields.some(
    field =>
      field !== undefined &&
      field !== null &&
      field
        .toString()
        .toLowerCase()
        .indexOf(normalized) !== -1
  )
}
