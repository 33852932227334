export function has_permission(obj, name) {
  if (obj == null || obj.permissions == null) return false
  return obj.permissions.includes(name)
}

export function has_program_permissions(
  user,
  permission = null,
  programKey = null
) {
  if (user == null || user.permissions == null) return false
  if (user.isAdmin) return true
  if (!programKey) {
    return user.permissions.program.some(program =>
      program.permissions.includes(permission)
    )
  }
  if (permission) {
    var programs = user.permissions.program
    var program = get_object_for_key(programs, programKey)
    if (program && program.permissions)
      return has(program.permissions, permission)
  }
  return false
}

export function has_project_detail_permissions(
  user,
  permission = null,
  projectKey = null
) {
  if (user == null || user.permissions == null || projectKey == null)
    return false
  if (permission) {
    var projects = user.permissions.project
    var project = get_object_for_key_manager_id(projects, projectKey, user.id)
    if (project && project.permissions)
      return has(project.permissions, permission)
  }
  return !!projects.length
}

export function has_admin_permissions(user) {
  if (user == null || user.permissions == null) return false
  return user.isAdmin || !!user.permissions.global.length
}

export function get_object_for_key(arr, key) {
  if (arr === null || key === null) return null
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].key === key) {
      return arr[i]
    }
  }
}

export function get_object_for_key_manager_id(arr, key, id) {
  if (arr === null || key === null) return null
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].key === key) {
      if (arr[i].managerId === id) {
        return true
      }
      return arr[i]
    }
  }
}

export function has(arr, obj) {
  if (obj !== null && arr !== null) return arr.includes(obj)
  return false
}

export function is_manager(obj, id) {
  if (obj !== null && id !== null) return obj.manager.id === id
  return false
}
