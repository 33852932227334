import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'
import { standardQuery } from 'bvdash/queries/generators'

const MenuElementFragment = gql`
  fragment MenuElement on SettingsMenuElementType {
    id
    label
    on
  }
`

export const MenuFragment = gql`
  fragment Menu on SettingsMenuType {
    id
    portfolio {
      ...MenuElement
    }
    program {
      ...MenuElement
    }
    project {
      ...MenuElement
    }
    timesheet {
      ...MenuElement
    }
    task {
      ...MenuElement
    }
    alert {
      ...MenuElement
    }
    conversation {
      ...MenuElement
    }
    scope {
      ...MenuElement
    }
    update {
      ...MenuElement
    }
    issue {
      ...MenuElement
    }
    risk {
      ...MenuElement
    }
    quality {
      ...MenuElement
    }
    budget {
      ...MenuElement
    }
    schedule {
      ...MenuElement
    }
    team {
      ...MenuElement
    }
    document {
      ...MenuElement
    }
    survey {
      ...MenuElement
    }
  }
  ${MenuElementFragment}
`

export const SETTINGS_MENUS = gql`
  query settingsMenus {
    settingsMenus {
      ...Menu
    }
  }
  ${MenuFragment}
`

const SETTINGS_MENU = gql`
  query settingsMenu($id: ID!) {
    settingsMenu(id: $id) {
      ...Menu
    }
  }
  ${MenuFragment}
`

export const withSettingsMenus = standardQuery(SETTINGS_MENUS, 'settingsMenus')

export const withSettingsMenu = graphql(SETTINGS_MENU, {
  options: props => {
    const {
      customer: { customer },
    } = props
    if (customer != null && customer.settingsMenu != null) {
      return {
        variables: {
          id: customer.settingsMenu.id,
        },
      }
    }
  },
  props: ({ data }) => {
    const { loading, settingsMenu } = data
    return {
      settingsMenu: {
        isLoading: loading,
        settingsMenu,
      },
    }
  },
})

const SETTINGS_MENU_CREATE = gql`
  mutation settingsMenuCreate($customerId: ID!, $menu: SettingsMenuInput!) {
    settingsMenuCreate(customerId: $customerId, menu: $menu) {
      ok
      error
      menu {
        ...Menu
      }
    }
  }
  ${MenuFragment}
`

export const withSettingsMenuCreate = graphql(SETTINGS_MENU_CREATE, {
  props: ({ mutate }) => ({
    settingsMenuCreate: async ({ customerId, menu }) => {
      const res = await mutate({
        variables: { customerId, menu },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: customerQuery }],
      })
      return res.data.settingsMenuCreate.menu
    },
  }),
})

const SETTINGS_MENU_UPDATE = gql`
  mutation settingsMenuUpdate($id: ID!, $menu: SettingsMenuInput!) {
    settingsMenuUpdate(id: $id, menu: $menu) {
      ok
      error
      menu {
        ...Menu
      }
    }
  }
  ${MenuFragment}
`

export const withSettingsMenuUpdate = graphql(SETTINGS_MENU_UPDATE, {
  props: ({ mutate, ownProps }) => ({
    settingsMenuUpdate: ({ id, menu }) => {
      return mutate({
        variables: { id, menu },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: SETTINGS_MENU,
            variables: { id: ownProps.settingsMenu.settingsMenu.id },
          },
        ],
      })
    },
  }),
})

const SETTINGS_MENU_ELEMENT_CREATE = gql`
  mutation settingsMenuElementCreate($menu_element: SettingsMenuElementInput!) {
    settingsMenuElementCreate(menu_element: $menu_element) {
      ok
      error
    }
  }
`

export const withSettingsMenuElementCreate = graphql(
  SETTINGS_MENU_ELEMENT_CREATE,
  {
    props: ({ mutate }) => ({
      settingsMenuElementCreate: async ({ menu_element }) => {
        const res = await mutate({
          variables: { menu_element },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: customerQuery }],
        })
        return res.data.settingsMenuElementCreate
      },
    }),
  }
)
