// Setup States
export const WELCOME = 0
export const ADD_USER = 1
export const ADD_PROJECT = 2
export const ASSIGN_USERS = 3
export const SEND_VALIDATION = 4

// Setup Options
export const TEAM_SETUP = 'team'
export const INDIVIDUAL_SETUP = 'individual'
