import * as React from 'react'
import { reverse } from 'named-urls'
import { sum } from 'ramda'

import routes from 'bvdash/routes'
import { Button, Label, Number } from 'bvdash/ui/core'
import { makeFilterChoices, FilterSelect } from 'bvdash/ui/tables'
import { Star } from 'bvdash-core/icons'
import { ProgramActions, ProjectActions } from 'bvdash/projects/ui'
import { StatusLabel } from 'bvdash/components/styles'

import { title } from 'bvdash/utils'

import { yesNoStatusChoices } from 'bvdash/components/tables/filters'

import { colorMap } from 'bvdash/styles'

import { coxMediaStatuses } from 'bvdash/customers/coxMedia'

export const onClickRoute = (f, p, b, e) =>
  f ? 'documents' : p || b || e ? 'dashboard' : 'team'

const codeChoices = codes =>
  makeFilterChoices(codes, code => {
    return {
      label: code.description || code.label,
      value: code.value,
    }
  })

const managerChoices = managers =>
  managers.map(manager => ({
    label: manager.fullName,
    value: manager.key,
  }))

export const projectStatusStyles = {
  Active: 'success',
  OnHold: 'warning',
  Cancelled: 'danger',
  Completed: 'muted',
}

export function renderWithSummary(render) {
  return function(columnProps) {
    if (!columnProps.row) return columnProps.value
    return render(columnProps)
  }
}

export const projectId = ({ to }) => ({
  attr: 'key',
  label: 'ID',
  render: renderWithSummary(({ value, row: project }) => (
    <Button
      to={reverse(routes.project[to], { projectKey: project.key })}
      size="small"
    >
      {value}
    </Button>
  )),
  shrink: true,
  summary: data => `Total: ${data.length}`,
})

export const projectIsFavorite = {
  attr: '_isFavorite',
  value: row => row.isFavorite,
  render: ({ value }) => value && <Star />,
  shrink: true,
}

export const projectName = ({ title, to }) => ({
  attr: 'name',
  label: `${title} name`,
  render: renderWithSummary(({ value, row: project }) => (
    <Button
      to={reverse(routes.project[to], { projectKey: project.key })}
      size="small"
    >
      {value}
    </Button>
  )),
})

export const projectManagerName = ({ title }) => ({
  attr: 'managerName',
  label: `${title} manager`,
  render: ({ row: project }) => project.manager.fullName,
  value: project => project.manager.key,
})

export const projectManagerFilter = ({ title, managers }) => {
  return {
    label: `${title} Manager`,
    attr: 'managerName',
    render: <FilterSelect multiple choices={managerChoices(managers)} />,
  }
}

export function fields(fields, internal = false) {
  return fields.map(field => ({
    attr: `field.${field.key}`,
    label: field.label || field.key,
    value: row =>
      internal
        ? row[field.internal.toLowerCase()]
        : row.fields
        ? row.fields[field.key]
        : null,
    render: ({ value }) => (internal ? <Number value={value} /> : value),
    summary: internal && sum,
    shrink: true,
  }))
}

/**
 * Return a list of code columns.
 */
export function codes(codes) {
  return Object.keys(codes).map(code)
}

const mapStatus = status => {
  const s = status.toLowerCase()
  switch (s) {
    case 'cancelled':
      return colorMap.carnation
    case 'onhold':
    case 'on hold':
      return colorMap.brightSun
    case 'completed':
      return colorMap.darkBorder
    case 'active':
      return colorMap.wasabi
    default:
      return coxMediaStatuses(s)
  }
}

export function code(attr) {
  if (attr.toLowerCase() === 'status') {
    return {
      attr,
      value: row => row.codes[attr] !== undefined && row.codes[attr].value,
      render: ({ value, row }) => {
        const key = row.codes[attr]
        return (
          key !== undefined && (
            <StatusLabel mapFunction={mapStatus} type={value}>
              {key.description}
            </StatusLabel>
          )
        )
      },
      shrink: true,
    }
  }

  // Additional conditional is only for schedule items.
  // Rework once queries are written with the same structure for all codes.
  return {
    attr,
    value: row =>
      (row.codes[attr] != null && row.codes[attr].value) || row.codes[attr],
    render: ({ row }) =>
      (row.codes[attr] != null && row.codes[attr].description) ||
      row.codes[attr],
    shrink: true,
  }
}

export const projectActionsColumn = {
  attr: '_actions',
  render: renderWithSummary(
    ({ row: { id, key, isFavorite, manager, codes, permissions } }) => (
      <ProjectActions
        projectId={id}
        projectKey={key}
        managerKey={manager.key}
        isFavorite={isFavorite}
        codes={codes}
        permissions={permissions}
      />
    )
  ),
  shrink: true,
}

export const codeFilters = codes =>
  Object.keys(codes).map(attr => ({
    label: title(attr),
    attr,
    render: <FilterSelect multiple choices={codeChoices(codes[attr])} />,
  }))

export const programActionsColumn = () => ({
  attr: '_actions',
  render: renderWithSummary(({ row: { id, key, isFavorite, manager } }) => (
    <ProgramActions
      projectId={id}
      projectKey={key}
      isFavorite={isFavorite}
      managerKey={manager.key}
    />
  )),
  shrink: true,
})

export const riskStatus = () => ({
  attr: 'isOpen',
  label: 'Status',
  render: ({ value, summary }) => {
    if (summary) return null
    return (
      <Label theme={value ? 'open' : 'muted'}>
        {value ? 'Open' : 'Closed'}
      </Label>
    )
  },
  shrink: true,
  summary: data => data.length,
})

export const budgetStatus = ({ title }) => {
  return {
    attr: 'overBudget',
    label: `${title} Status`,
    render: ({ value, summary }) => {
      if (summary) return value

      return (
        <Label theme={value ? 'danger' : 'success'}>
          {value ? `Over ${title}` : `On ${title}`}
        </Label>
      )
    },
    shrink: true,
  }
}

export const budgetStatusFilter = ({ title }) => ({
  attr: 'overBudget',
  label: `Over ${title}`,
  render: <FilterSelect choices={yesNoStatusChoices} />,
})

export const scheduleStatus = ({ title }) => ({
  attr: 'behindSchedule',
  label: `${title} Status`,
  render: ({ value, summary }) => {
    if (summary) return value

    return (
      <Label theme={value ? 'danger' : 'success'}>
        {value ? `Behind ${title}` : `On ${title}`}
      </Label>
    )
  },
  shrink: true,
})

export const scheduleStatusFilter = ({ title }) => ({
  attr: 'behindSchedule',
  label: `Behind ${title}`,
  render: <FilterSelect choices={yesNoStatusChoices} />,
})
