import * as React from 'react'
import { compose } from 'react-apollo'

import { withTable, Filters, FilterSelect, Table } from 'bvdash/ui/tables'
import { FormActions } from 'bvdash/ui'

import routes from 'bvdash/routes'
import {
  withUsers,
  withUserSendAllValidationEmail,
  withUserSendValidationEmail,
  withUserUpdate,
} from 'bvdash/admin/users/queries'

import { activeInactiveStatusChoices } from 'bvdash/components/tables/filters'

import { EMAIL_NOT_SENT, PENDING, ACTIVE } from './constants'
import { tableConfig } from './tableConfig'

import { AddButton, Button } from 'bvdash/components/buttons'
import { NumCountCard } from 'bvdash/components/NumCountCard'
import { CenterSpaceBetweenContainer } from 'bvdash/components/standard/containers'
import { AdminBreadcrumbs } from 'bvdash/components/standard/texts'

const List = props => {
  const {
    authUser,
    canAddMoreUsers,
    customer: { customer },
    history,
    isTrial,
    titles,
    userSendAllValidationEmail,
    users: { users },

    // Modals
    handleModalOpen,
    setCurrentModal,
  } = props

  const [sending, setSending] = React.useState(false)

  const allValidationEmailsSent = users.every(
    u => u.isValidated === PENDING || u.isValidated === ACTIVE || u.isActive
  )

  const allUsersActive = users.every(u => u.isActive)

  const buttonState = allUsersActive
    ? {
        color: 'success',
        text: 'All Users Active',
      }
    : !allValidationEmailsSent
    ? {
        color: 'remove',
        text: 'Send Validation Emails',
      }
    : {
        color: 'warning',
        text: 'Validation Pending',
      }

  const handleNoProjectsNotActive = () => history.push(routes.admin.projectTeam)

  const allEmailsWithProjects = users
    .filter(
      u =>
        u.projects.length > 0 && !u.isActive && u.isValidated === EMAIL_NOT_SENT
    )
    .map(u => u.email)

  const allUsersProjects = users.every(u => u.projects.length > 0)
  const allUsersWithoutProjects = users.filter(u => u.projects.length === 0)
  const sendAllValidationEmails = async () => {
    if (allUsersWithoutProjects.length > 0) {
      setCurrentModal('noSendValidationEmails')
      return handleModalOpen()
    }
    if (allEmailsWithProjects.length > 0) {
      setSending(true)
      await userSendAllValidationEmail({ emails: allEmailsWithProjects })
      setSending(false)

      if (!allUsersProjects) {
        setCurrentModal('noSendValidationEmails')
        return handleModalOpen()
      }
    }
  }

  const handleNoMoreUsers = () => {
    setCurrentModal('noMoreUsers')
    return handleModalOpen()
  }

  const handleAddUsers = () => {
    if (canAddMoreUsers) {
      return history.push(routes.admin.usersAdd)
    }
    return handleNoMoreUsers()
  }

  const handleFinishSetup = () => {
    setCurrentModal('finishSetup')
    handleModalOpen()
  }

  return (
    <>
      <CenterSpaceBetweenContainer>
        <div>
          <AdminBreadcrumbs>Users</AdminBreadcrumbs>
          <Button
            color={buttonState.color}
            disabled={allUsersActive}
            onClick={sendAllValidationEmails}
          >
            {sending ? 'Sending Emails' : buttonState.text}
          </Button>
        </div>
        <NumCountCard
          isTrial={isTrial}
          items={[
            {
              currentCount: customer.numUsers,
              maxCount: customer.stripeUserQuantity,
              title: 'Users',
            },
          ]}
        />
      </CenterSpaceBetweenContainer>
      <AddButton onClick={handleAddUsers}>Add New User</AddButton>

      <ListTable
        authUser={authUser}
        canAddMoreUsers={canAddMoreUsers}
        handleNoMoreUsers={handleNoMoreUsers}
        titles={titles}
        users={users}
        noProjectsNotActive={handleNoProjectsNotActive}
      />
      {!customer.onboarding ? (
        <FormActions>
          <Button color="add" onClick={handleFinishSetup}>
            Finish Setup
          </Button>
        </FormActions>
      ) : null}
    </>
  )
}

const ListTable = compose(
  withUserUpdate,
  withUserSendValidationEmail,
  withTable(tableConfig)
)(props => {
  const { table } = props
  return (
    <>
      <Filters
        withSearch
        table={table}
        filters={[
          {
            label: 'Status',
            attr: 'isActive',
            render: <FilterSelect choices={activeInactiveStatusChoices} />,
          },
        ]}
      />
      <Table table={table} />
    </>
  )
})

export default compose(
  withUsers,
  withUserSendAllValidationEmail
)(List)
