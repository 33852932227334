/**
 * General concepts:
 *
 * serialize   - Form to API (Client to Server)
 * deserialize - API to Form (Server to Client)
 *
 * `flatten_fk` in deserializers is used for flattening linked foreign keys.
 * Form usually use single value (e.g. project.id) and therefore any objects
 * must be flattened.
 * Tables use multiple attributes from linked object
 * (e.g. project.name and project.id) and therefore objects shouldn't be flattened.
 */
import { format } from 'date-fns'
import {
  identity,
  evolve,
  pipe,
  toPairs,
  fromPairs,
  map,
  pick,
  zipObj,
  repeat,
  prop,
  indexBy,
  toLower,
} from 'ramda'
import { maybeEvolve, renameKeys } from 'bvdash/utils'

/**
 * initializeFields - initialize form fields with empty string
 *
 * initializeFields(["project", "key"]) => {project: "", key: ""}
 */
export const initializeFields = (keys, isProject = false) => {
  let fields = zipObj(keys, repeat('', keys.length))
  if (isProject) {
    fields.Status = 'Active'
  }
  return fields
}

/**
 * keyValueObjectToList - transform key/value object into list of
 * { key: value } objects. :thinking_face:
 *
 * Data in forms are stored in key/value object:
 *
 * {
 *   fieldOne: "one",
 *   fieldTwo: "two"
 * }
 *
 * while the API expects list of { key: value } objects:
 *
 * [
 *  {
 *    key: "fieldOne",
 *    value: "one"
 *  },
 *  {
 *    key: "fieldTwo",
 *    value: "two"
 *  },
 * ]
 */
export const keyValueObjectToList = pipe(
  toPairs,
  map(([key, value]) => ({ key, value }))
)

/**
 * keyValueObjectToList - inverse function to keyValueListToObject
 *
 * keyValueListToObject([{ key: "fieldOne", value: "one" })
 * // => {fieldOne: "one"}
 */
export const keyValueListToObject = pipe(
  map(({ key, value }) => [key, value]),
  fromPairs
)

export const deserializeDate = value => {
  if (!value) {
    return undefined
  }
  return new Date(value)
}

export const serializeBudgetItem = pipe(
  pick(['original', 'eac', 'actual', 'codes', 'fields']),
  evolve({
    codes: keyValueObjectToList,
    fields: keyValueObjectToList,
  })
)

export const deserializeBudgetItem = (value, flatten_fk = true) =>
  maybeEvolve({
    project: flatten_fk ? prop('id') : identity,
    codes: keyValueListToObject,
    fields: keyValueListToObject,
  })(value)

export const serializeScheduleItem = pipe(
  pick([
    'project',
    'description',
    'codes',
    'earlyStart',
    'earlyStartBl',
    'earlyFinish',
    'earlyFinishBl',
  ]),
  evolve({
    codes: keyValueObjectToList,
  })
)
export const deserializeScheduleItem = (value, flatten_fk = true) => {
  return maybeEvolve({
    project: flatten_fk ? prop('id') : identity,
    earlyStart: deserializeDate,
    earlyFinish: deserializeDate,
    earlyStartBl: deserializeDate,
    earlyFinishBl: deserializeDate,
    codes: keyValueListToObject,
  })(value)
}

export const getInternalFieldNames = indexBy(
  pipe(
    prop('internal'),
    toLower
  )
)

export const serializeProgram = pipe(
  pick(['key', 'name', 'managerId', 'startDate', 'endDate']),
  evolve({
    startDate: date => new Date(date).toISOString().replace(/T.*$/, ''),
    endDate: date => new Date(date).toISOString().replace(/T.*$/, ''),
  })
)

const deserializeProgramDate = date => format(new Date(date), 'yyyy-MM-dd')

export const deserializeProgram = pipe(
  maybeEvolve({
    manager: prop('id'),
    startDate: deserializeProgramDate,
    endDate: deserializeProgramDate,
  }),
  renameKeys({
    manager: 'managerId',
  })
)

export const serializeProject = pipe(
  pick(['key', 'name', 'managerId', 'codes', 'fields']),
  evolve({
    codes: keyValueObjectToList,
    fields: keyValueObjectToList,
  })
)

export const deserializeProject = pipe(
  maybeEvolve({
    manager: prop('id'),
    codes: keyValueListToObject,
    fields: keyValueListToObject,
  }),
  renameKeys({
    manager: 'managerId',
  })
)

export const deserializeProjectForTable = maybeEvolve({
  fields: keyValueListToObject,
  codes: indexBy(prop('key')),
})
