import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { path, pipe, defaultTo, prop } from 'ramda'

import { customerQuery } from 'bvdash/queries/settings/withCustomer'

export authUser from './authUser'
export login from './login'
export logout from './logout'
export passwordResetRequest from './passwordResetRequest'

/**
 * Load customer info for logged in user.
 */
export const withCustomer = graphql(customerQuery, {
  props: ({ data }) => ({
    customer: {
      isLoading: data.loading,
      customer: data.customer,
      theme: getCustomerTheme(data.customer),
    },
  }),
})

export const withPasswordReset = graphql(
  gql`
    mutation PasswordReset($token: String!, $password: String!) {
      authPasswordReset(token: $token, password: $password) {
        ok
        error
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      passwordReset: ({ password, token, initial = false }) =>
        mutate({ variables: { token, password, initial } }).then(
          path(['data', 'authPasswordReset'])
        ),
    }),
  }
)

// We can't use R.propOr, because we always want to get an object. Either
// from `theme` prop or an empty object. R.propOr would return `null` for
// customers without defined custom theme.
export const getCustomerTheme = pipe(
  prop('theme'),
  defaultTo({})
)
