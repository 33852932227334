import * as React from 'react'

import { settingsDateFormat } from 'bvdash/utils/intl'
import { SettingsContext } from 'bvdash/contexts/Settings'

export function useSettings() {
  const { settings } = React.useContext(SettingsContext)

  const dateFormat = (settings.datetimeFormat || '')
    .replace(/D/g, 'd')
    .replace(/Y/g, 'y')

  const formatDate =
    dateFormat !== '' ? settingsDateFormat(dateFormat) : () => {}

  return {
    dateFormat,
    formatDate,
  }
}
