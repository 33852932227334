import * as React from 'react'
import { Label } from 'bvdash/ui/core'

export default ({ value, children }) => (
  <Label theme={styles[value]}>{children}</Label>
)

const styles = {
  1: 'veryLow',
  2: 'low',
  3: 'moderate',
  4: 'high',
  5: 'veryHigh',
}
