import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { Button, ButtonGroup, Layout } from 'bvdash/ui/core'
import styles from './NotFound.scss'

export default function NotFound({ title, explanation, hint, back }) {
  return (
    <Layout>
      <Helmet>
        <title>{title} | BVDash</title>
      </Helmet>

      <div className={styles.root}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.explanation}>{explanation}</p>
        <p>{hint}</p>
        <div className={styles.actions}>
          <ButtonGroup>
            {back} <Button>Report a problem</Button>
          </ButtonGroup>
        </div>
      </div>
    </Layout>
  )
}

NotFound.defaultProps = {
  title: 'Page not found!',
  explanation: 'Oops, the page you are looking for does not exist!',
  hint:
    'You may want to head back to the dashboard. Report the issue to your administrator if you see this page by error.',
  back: <Button>Go to dashboard</Button>,
}

NotFound.propTypes = {
  title: PropTypes.string,
  explanation: PropTypes.string,
  hint: PropTypes.string,
  back: PropTypes.element,
}
