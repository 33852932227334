import styled, { css } from 'react-emotion'

import PropTypes from 'prop-types'
import { headerHeight, subHeaderHeight } from 'bvdash/styles'

const adminBackground = 'rgba(35,162,245,0.05)'

export const height = withSubHeader => css`
  min-height: calc(
    100vh - (${(withSubHeader ? subHeaderHeight : 0) + headerHeight}px)
  );
`

const style = (admin, paddingBottom, background) => css`
  background: ${background ? background : admin ? adminBackground : ''};
  padding-bottom: ${admin ? 105 : paddingBottom || 28}px;
  padding-top: ${admin ? '60px' : undefined};
`

const Layout = styled.main`
  ${({ noMinHeight, withSubHeader }) =>
    noMinHeight ? null : height(withSubHeader)};
  ${({ admin, paddingBottom, background }) =>
    style(admin, paddingBottom, background)}
  ${({ center }) => center && `align-items: center`};
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  flex-wrap: wrap;
  padding-bottom: 80px;
`

Layout.propTypes = {
  admin: PropTypes.bool,
  withSubHeader: PropTypes.bool,
}

export default Layout
