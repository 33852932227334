import styled from 'react-emotion'
import { colorMap } from 'bvdash/styles'

const FormActions = styled.div`
  align-items: center;
  background: #f3f3f3;
  border-top: 1px solid ${colorMap.dropShadow};
  bottom: 0;
  box-shadow: 0 -1px 10px 0px ${colorMap.dropShadow};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: ${({ justifyContent }) =>
    Boolean(justifyContent) ? justifyContent : 'flex-end'};
  left: 0;
  padding: 8px 60px 8px 8px;
  position: fixed;
  width: 100%;
  z-index: 50;

  & > button {
    margin: 8px 12px;
  }
`

export default FormActions
