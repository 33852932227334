import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { ProjectFragment } from './projectFragment'
import { projectsPreviewQuery } from 'bvdash/admin/queries'
import { versionQuery } from 'bvdash/queries/withVersions'

export const projectImportMutation = gql`
  mutation projectImport(
    $file: [Upload]!
    $programId: ID!
    $snapshotDate: Date!
  ) {
    projectImport(
      file: $file
      programId: $programId
      snapshotDate: $snapshotDate
    ) {
      ok
      error
      result {
        ...ProjectFragment
      }
      versionId
    }
  }
  ${ProjectFragment}
`

export const withProjectImport = graphql(projectImportMutation, {
  props: ({ mutate, ownProps }) => ({
    projectImport: async ({ file, programId, snapshotDate }) => {
      const { data } = await mutate({
        variables: {
          file,
          programId,
          snapshotDate: new Date().toISOString().slice(0, 10),
        },
        awaitRefetchQueries: true,
        refetchQueries: ({ data }) => {
          return [
            {
              query: versionQuery,
              variables: {
                programKey: ownProps.program.key,
                kind: 'PROJECT',
              },
            },
            {
              query: projectsPreviewQuery,
              variables: {
                programKey: ownProps.program.key,
                versionId: data.projectImport.versionId,
              },
            },
          ]
        },
      })
      return data.projectImport
    },
  }),
})
