import * as React from 'react'
import { cx } from 'react-emotion'

import { ArrowDown, ArrowUp } from 'bvdash-core/icons'
import { Cell } from '.'

import styles from './HeaderRow.scss'
import colors from 'bvdash/styles/variables.scss'
import { clickable } from 'bvdash/utils/a11y'

export default function HeaderRow({
  columns,
  sortBy = {},
  onSort = () => {},
  isExpandable = false,
  columnSizes = [],
  select,
  props,
}) {
  return (
    <tr className={styles.header}>
      {isExpandable && <td />}
      {columns.map(
        ({ attr, label, header: Header, shrink, stretch }, index) => {
          const _attr = attr.startsWith('_') ? null : attr
          const _label = label || _attr
          const sortActive = sortBy.column === _attr
          const arrowColor = sortActive ? colors.sanJuan : colors.cadetBlue

          const className = cx(styles.cell, {
            [styles.active]: sortActive,
          })

          return (
            <Cell
              key={`${attr}${index}`}
              size={columnSizes[index]}
              header
              stretch={stretch}
              shrink={shrink}
            >
              {Header && <Header props={props} select={select} />}
              {_attr && !Header && (
                <a {...clickable(onSort(attr))} className={className}>
                  <span className={styles.label}>{_label}</span>
                  {sortActive && sortBy.asc ? (
                    <ArrowUp color={arrowColor} />
                  ) : (
                    <ArrowDown color={arrowColor} />
                  )}
                </a>
              )}
            </Cell>
          )
        }
      )}
    </tr>
  )
}
