import * as React from 'react'
import { Link } from 'react-router-dom'

import { CenterPage } from 'bvdash/components'
import { Button } from 'bvdash/components/buttons'
import { DescriptionText, MainText, SceneImage } from './styles'

import PermissionDeniedImage from 'bvdash-core/artwork/PermissionDeniedNew.png'

const PermissionDenied = () => {
  return (
    <CenterPage>
      <SceneImage src={PermissionDeniedImage} alt="not found" />
      <MainText>Access Denied!</MainText>
      <DescriptionText>
        You have not been added as a member to this program or project, please
        contact your administrator.
      </DescriptionText>
      <Link to="/">
        <Button color="add">Go to dashboard</Button>
      </Link>
    </CenterPage>
  )
}

export default PermissionDenied
