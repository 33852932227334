// TODO(Erik): Use throughout the app.
// Need to decrease the number of times this is called. Should be called at the
// root level and passed in as a prop rather than recalling in multiple locations

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

export const authUserQuery = gql`
  query authUser {
    authUser {
      id
      key
      fullName
      email
      photo
      lastLogin
      isAdmin
      isBvdsuperadmin
      isBvdsuperuser
      roles {
        id
        role
      }
      permissions {
        global
        program {
          key
          managerId
          objectId
          permissions
        }
        project {
          key
          managerId
          objectId
          permissions
        }
      }
    }
  }
`

export const withAuthUser = graphql(authUserQuery, {
  props: ({ data }) => {
    const { authUser: user = {} } = data
    return {
      authUser: {
        isLoading: data.loading,
        user,

        // Must be non-strict inequality, because user is `undefined` when data
        // are being loaded and `null` when user isn't authenticated!
        isAuthenticated: user != null,
      },
    }
  },
})
