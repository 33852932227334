import { graphql } from 'react-apollo'

import authUser from './authUser.graphql'

export default graphql(authUser, {
  props: ({ data }) => {
    const { authUser: user } = data
    return {
      authUser: {
        loading: data.loading,
        user,

        // Must be non-strict inequality, because user is `undefined` when data
        // are being loaded and `null` when user isn't authenticated!
        isAuthenticated: user != null,
      },
    }
  },
})
