/**
 * `Strategy` depends on `type` field in Risk. Only `strategy` value is stored
 * in backend, `type` is used to filter `strategy` choices.
 */

import * as React from 'react'
import { SelectField } from 'bvdash/ui/core'

class RiskStrategyField extends React.Component {
  state = {
    type: 'T',
  }

  typeChoices = [
    {
      value: 'T',
      label: 'Threat',
    },
    {
      value: 'O',
      label: 'Opportunity',
    },
  ]

  /**
   * When `strategy` is changes from outside (e.g. delayed loading of initial
   * values), we must update `type` accordingly.
   */
  componentDidUpdate() {
    const { value } = this.props.field
    if (value && value[0] !== this.state.type) {
      this.setState({ type: value[0] })
    }
  }

  /**
   * Handle `type` change. Reset `strategy` to the first strategy choice,
   * becuase strategyChoices depends on `type`.
   */
  handleChangeType = value => {
    this.setState({ type: value }, () => {
      this.props.form.setFieldValue(
        this.props.field.name,
        this.strategyChoices()[0].value
      )
    })
  }

  /**
   * Filter `choices` (strategy choices) based on `type` value
   */
  strategyChoices = () =>
    this.props.choices.filter(choice => choice.value[0] === this.state.type)

  render() {
    return (
      <>
        <SelectField
          label="Type"
          value={this.state.type}
          onChange={this.handleChangeType}
          choices={this.typeChoices}
        />
        <SelectField
          {...this.props}
          label="Strategy"
          choices={this.strategyChoices()}
        />
      </>
    )
  }
}

export default RiskStrategyField
