import { Field } from 'formik'
import * as React from 'react'

import {
  ErrorContainer,
  InputContainer as SelectContainer,
  Label as SelectLabel,
  Select as SelectInput,
} from './styles'
import { errorHandler } from './errorHandler'
import { Error as ErrorText } from 'bvdash/ui/forms'

export const SelectField = props => {
  const {
    components: {
      Label = SelectLabel,
      Input = SelectInput,
      InputContainer = SelectContainer,
    },
    disabled,
    displayErrorText,
    label,
    name,
    options,
    placeholder,
  } = props

  const renderOptions = options.map((option, i) => {
    const { label, value } = option

    return (
      <option key={value} value={value}>
        {label}
      </option>
    )
  })

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched } }) => {
        const error = errorHandler(name, touched, errors)

        const isStatusCode = name === 'codes.Status' && errors.codes != null
        const ErrorValue = displayErrorText ? (
          isStatusCode ? (
            <ErrorText show={error}>{errors.codes.Status}</ErrorText>
          ) : (
            <ErrorText show={error}>{errors[name]}</ErrorText>
          )
        ) : null

        return (
          <InputContainer>
            <Label error={error}>{label}</Label>
            <Input
              {...field}
              disabled={disabled}
              error={error}
              placeholder={placeholder}
              type="select"
            >
              {placeholder != null && <option>{placeholder}</option>}
              {renderOptions}
            </Input>
            <ErrorContainer>{ErrorValue}</ErrorContainer>
          </InputContainer>
        )
      }}
    />
  )
}

SelectField.defaultProps = {
  components: {
    Label: SelectLabel,
    Input: SelectInput,
    InputContainer: SelectContainer,
  },
  displayErrorText: true,
}
