import * as React from 'react'

import { Select } from 'bvdash/ui/core'

// Connect table.filter API to Select component
export default class FilterSelect extends React.Component {
  handleChange = value => this.props.filter.onFilter(this.props.attr, value)

  render() {
    const { label, attr, filter, choices, multiple } = this.props
    const activeValue = filter.filterBy ? filter.filterBy[attr] : undefined

    return (
      <Select
        label={label}
        choices={choices}
        onChange={this.handleChange}
        value={activeValue}
        multiple={multiple}
        emptyLabel="All"
      />
    )
  }
}
