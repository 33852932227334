import { Field } from 'formik'
import * as React from 'react'

import {
  ErrorContainer,
  InputContainer as StandardContainer,
  Input as StandardInput,
  Label as StandardLabel,
} from './styles'
import { errorHandler } from './errorHandler'

import { Error as ErrorText } from 'bvdash/ui/forms'

// TODO(Erik): Refactor this to create a standard radio button for BVDash.

export const RadioField = props => {
  const {
    color,
    components: {
      InputContainer = StandardContainer,
      Input = StandardInput,
      Label = StandardLabel,
    },
    disabled,
    displayErrorText,
    id,
    label,
    name,
    placeholder,
  } = props

  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { errors, touched } = form

        const error = errorHandler(name, touched, errors)

        return (
          <InputContainer color={color} error={error}>
            <Input
              {...field}
              checked={id === field.value}
              disabled={disabled}
              error={error}
              id={id}
              placeholder={placeholder}
              type="radio"
              value={id}
            />
            {label && (
              <Label htmlFor={id} error={error}>
                {label}
              </Label>
            )}
            {displayErrorText && (
              <ErrorContainer>
                <ErrorText show={error}>{errors[name]}</ErrorText>
              </ErrorContainer>
            )}
          </InputContainer>
        )
      }}
    />
  )
}

RadioField.defaultProps = {
  components: {
    InputContainer: StandardContainer,
    Input: StandardInput,
    Label: StandardLabel,
  },
  displayErrorText: true,
}
