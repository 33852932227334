import * as React from 'react'
import { Link } from 'react-router-dom'

import { CenterPage } from 'bvdash/components'
import { Button } from 'bvdash/components/buttons'
import { DescriptionText, MainText, SceneImage } from './styles'

import routes from 'bvdash/routes'

import NotFoundImage from 'bvdash-core/artwork/NotFoundNew.png'

const NotFound = () => {
  return (
    <CenterPage>
      <SceneImage src={NotFoundImage} alt="not found" />
      <MainText>Whoops, the page you are looking for does not exist!</MainText>
      <DescriptionText>
        If you are seeing this page in error please report the problem to your
        administrator or notify us support@bvdash.com
      </DescriptionText>
      <Link to={routes.projects.all}>
        <Button color="add">Go to dashboard</Button>
      </Link>
    </CenterPage>
  )
}

export default NotFound
