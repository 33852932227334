import * as React from 'react'
import { compose } from 'react-apollo'
import { Redirect, Route, Switch } from 'react-router-dom'

import { SubNavigation } from 'bvdash/ui/core'
import { adminLinks } from 'bvdash/admin/adminLinks'

import { AdminLayout } from 'bvdash/admin/ui'

import { withLatestVersions } from 'bvdash/queries/withVersions'

import { setupRoutes } from './routes'

import { Notification } from 'bvdash/components/notifications'
import { modalOptions } from 'bvdash/components/modals'
import { useToggle } from 'bvdash/hooks/useToggle'
import routes from 'bvdash/routes'

import * as setupStates from './constants'

export const AccountSetup = compose(withLatestVersions('project'))(props => {
  const {
    customer: {
      customer: { welcome },
    },
    isBusinessPlan,
    isEnterprisePlan,
    isFreePlan,
    isProfessionalPlan,
    titles,
    trialExpired,
  } = props

  // Notification
  const {
    close: notificationClose,
    isOpen: notificationIsOpen,
    open: notificationOpen,
  } = useToggle(false)
  const [notificationContent, setNotificationContent] = React.useState({})

  // Modal
  const {
    close: handleModalClose,
    isOpen: modalIsOpen,
    open: handleModalOpen,
  } = useToggle(false)

  const [setupState, setSetupState] = React.useState(
    Number(localStorage.getItem('setupState')) || setupStates.WELCOME
  )
  const [setupOption, setSetupOption] = React.useState(
    localStorage.getItem('setupOption') || null
  )
  const [currentModal, setCurrentModal] = React.useState(null)
  const [currentModalProps, setCurrentModalProps] = React.useState({
    setupState,
  })

  const selectOption = option => {
    setSetupOption(option)
    localStorage.setItem('setupOption', option)
  }

  const setState = state => {
    setSetupState(state)
    localStorage.setItem('setupState', state)
  }

  React.useEffect(() => {
    if (!welcome) {
      setCurrentModal('welcome')
      handleModalOpen()
    }
    if (setupState > 0 && setupState < 7) {
      setCurrentModal('setup')
      setCurrentModalProps({ setupState })
      handleModalOpen()
    }
  }, [])

  const adminLinksArray = adminLinks({
    isBusinessPlan,
    isEnterprisePlan,
    isFreePlan,
    isProfessionalPlan,
    titles,
    trialExpired,
  })

  const setupRoutesArray = setupRoutes({ titles })

  const CurrentModal = currentModal != null ? modalOptions[currentModal] : null

  const SetupRoutes = setupRoutesArray.map(route => {
    const { Component, path } = route

    return (
      <Route
        exact
        key={path}
        path={path}
        render={routeProps => {
          // TODO(Erik): Refactor to be contained in the RenderRoutes in app.
          return (
            <>
              {notificationIsOpen ? (
                <Notification
                  display={notificationIsOpen}
                  handleClose={notificationClose}
                  text={notificationContent.text}
                  top={112}
                  type={notificationContent.type}
                />
              ) : null}
              <AdminLayout noCustomer>
                <Component
                  {...props}
                  {...routeProps}
                  notificationClose={notificationClose}
                  notificationIsOpen={notificationIsOpen}
                  notificationOpen={notificationOpen}
                  handleModalOpen={handleModalOpen}
                  modalIsOpen={modalIsOpen}
                  handleModalClose={handleModalClose}
                  setNotificationContent={setNotificationContent}
                  setCurrentModal={setCurrentModal}
                  setCurrentModalProps={setCurrentModalProps}
                  // For Wizard
                  setupState={Number(setupState)}
                  setSetupState={setState}
                  setupOption={setupOption}
                  setSetupOption={selectOption}
                />
              </AdminLayout>
            </>
          )
        }}
      />
    )
  })

  return (
    <>
      {modalIsOpen ? (
        <CurrentModal
          closeModal={handleModalClose}
          currentModalProps={currentModalProps}
          titles={titles}
        />
      ) : null}
      <SubNavigation admin links={adminLinksArray} welcome={welcome} />

      <Switch>
        {SetupRoutes}
        <Route component={() => <Redirect to={routes.admin.setup} />} />
      </Switch>
    </>
  )
})
