import * as React from 'react'

import { SelectField } from 'bvdash/ui/core'
import { DocumentStatusLabel } from 'bvdash/projects/ui'
import DocumentReviewDialog from './DocumentReviewDialog'

import { authUser } from 'bvdash/auth/queries'

class DocumentStatusField extends React.Component {
  render() {
    const {
      document,
      status,

      authUser: { isLoading, user },
    } = this.props

    const canApprove =
      document.approvedBy && !isLoading && user.key === document.approvedBy.key

    return canApprove ? (
      <DocumentReviewDialog document={document}>
        {showDialog => (
          <SelectField
            label="Status"
            value={document.status}
            onChange={showDialog}
            choices={status.choices.filter(item => {
              // once the status is set to APPROVED or REJECTED, it can't
              // be set back to PENDING
              if (document.status !== 'PENDING')
                return ['APPROVED', 'REJECTED'].includes(item.value)

              return item.value !== 'INFO'
            })}
          />
        )}
      </DocumentReviewDialog>
    ) : (
      <SelectField
        label="Status"
        value={
          <DocumentStatusLabel inline value={status.values[document.status]} />
        }
      />
    )
  }
}

export default authUser(DocumentStatusField)
