import { Field } from 'formik'
import * as React from 'react'
import { withApollo } from 'react-apollo'

import {
  ErrorContainer,
  GenerateIdButton,
  InputContainer as StandardContainer,
  Input as StandardInput,
  Label as StandardLabel,
  ParallelContainer,
} from './styles'
import { errorHandler } from './errorHandler'
import { HelpIcon } from './HelpIcon'

import { Error as ErrorText } from 'bvdash/ui/forms'

/*
  TODO(Erik): Refactor so I'm using the same standard boilerplate as the
  InputField component.
*/
export const GenerateIdField = withApollo(props => {
  const {
    components: {
      InputContainer = StandardContainer,
      Input = StandardInput,
      Label = StandardLabel,
    },
    disabled,
    disableGenerateId,
    displayErrorText,
    label,
    name,
    placeholder,
    type,
    icon,
  } = props

  const handleGenerateCodeId = async (setFieldValue, name) => {
    const { client, generateIdQuery, generateIdVariables } = props
    const response = await client.query({
      query: generateIdQuery,
      variables: generateIdVariables,
      fetchPolicy: 'no-cache',
    })
    const {
      data: { nextId },
    } = response
    if (nextId != null) return setFieldValue(name, nextId)
  }

  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { errors, setFieldValue, touched } = form

        const error = errorHandler(name, touched, errors)

        return (
          <InputContainer>
            {label != null && <Label error={error}>{label}</Label>}
            <ParallelContainer>
              <Input
                {...field}
                disabled={disabled}
                error={error}
                placeholder={placeholder}
                type={type}
              />
              <GenerateIdButton
                onClick={() => handleGenerateCodeId(setFieldValue, name)}
                disabled={disableGenerateId}
                type="button"
              >
                Generate ID Automatically
              </GenerateIdButton>
              <HelpIcon icon={icon} />
            </ParallelContainer>
            {displayErrorText && (
              <ErrorContainer>
                <ErrorText show={error}>{errors[field.name]}</ErrorText>
              </ErrorContainer>
            )}
          </InputContainer>
        )
      }}
    />
  )
})

GenerateIdField.defaultProps = {
  components: {
    InputContainer: StandardContainer,
    Input: StandardInput,
    Label: StandardLabel,
  },
  displayErrorText: true,
}
