import { graphql } from 'react-apollo'
import mutation from './sendMessageMutation.graphql'
import conversationQuery from './getConversation.graphql'

export default graphql(mutation, {
  props: ({ mutate }) => ({
    sendMessage: (conversationId, content, attachments) =>
      mutate({
        variables: { conversationId, content, attachments },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: conversationQuery,
            variables: { conversationId },
          },
        ],
      }),
  }),
})
