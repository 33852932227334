import * as React from 'react'
import { sum, prop, sort } from 'ramda'
import { reverse } from 'named-urls'

import routes from 'bvdash/routes'
import { Card } from 'bvdash/ui/core'
import { People } from 'bvdash-core/icons'
import styles from './DashboardTeam.scss'
import {
  LegendWrapper,
  LegendContainer,
  LegendHeader,
  LegendItem,
} from 'bvdash/components/charts/Legend'
import { FlexContainer } from 'bvdash/components/standard/containers'
const jobTitleRe = /(manager|engineer)/i

function sortByJobTitle(a, b) {
  // Sort by jobTitle (manager, engineer first) and count of members (DESC)
  const isJobTitleA = jobTitleRe.test(a.role || a.jobTitle)
  const isJobTitleB = jobTitleRe.test(b.role || b.jobTitle)

  if (isJobTitleA === isJobTitleB) {
    // If jobTitles are equal, sort by jobTitle count.
    // Must be B - A, because we sort in descending order.
    return b.count - a.count
  } else if (isJobTitleA) {
    return -1
  } else {
    return 1
  }
}

const DashboardTeam = props => {
  const { forProgram = false, team = [], objectKey, titles } = props

  const totalCount = sum(team.map(prop('count'))) || 0

  return (
    <Card.Card
      title={
        forProgram
          ? `${titles.program} ${titles.team}`
          : `${titles.project} ${titles.team}`
      }
      detailsLink={
        forProgram
          ? reverse(routes.program.team, { programKey: objectKey })
          : reverse(routes.project.team, { projectKey: objectKey })
      }
    >
      <FlexContainer
        width="364px"
        height="364px"
        justifyContent="center"
        alignItems="center"
        paddingTop={32}
        paddingRight={32}
        paddingBottom={32}
        paddingLeft={32}
      >
        {/* <People className={styles.icon} width={275} height={250} /> */}
        <People className={styles.icon} width={137} height={134} />
      </FlexContainer>
      <LegendWrapper>
        <LegendContainer>
          <LegendHeader>
            {totalCount}{' '}
            {totalCount === 1
              ? `Active ${titles.team} Member`
              : `Active ${titles.team} Members`}
          </LegendHeader>

          {sort(sortByJobTitle, team)
            .slice(0, 4)
            .map(({ count, jobTitle, role }, i) => (
              <LegendItem key={i}>
                {jobTitle || role} <FlexContainer>{count}</FlexContainer>
              </LegendItem>
            ))}
        </LegendContainer>
      </LegendWrapper>
    </Card.Card>
  )
}

export default DashboardTeam
