import * as React from 'react'
import { compose } from 'react-apollo'

import { useToggle } from 'bvdash/hooks/useToggle'
import { usePrevious } from 'bvdash/hooks/usePrevious'

import {
  HelpText,
  Breadcrumbs,
  BreadcrumbsSelectProject,
} from 'bvdash/admin/ui'
import ProjectSelectTable from 'bvdash/components/projectSelectTable/ProjectSelectTable'
import { withProjectsList } from 'bvdash/queries/withProjects'
import {
  FullWidthContainer,
  FlexContainer,
} from 'bvdash/components/standard/containers'

function search(key, array) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].key === key) {
      return true
    }
  }
}

export const withProjectSelectTable = WrappedComponent =>
  compose(withProjectsList)(props => {
    const {
      customer,
      program,
      projects: { isLoading, projects },
      titles,
    } = props

    if (isLoading) return null

    const storageProject = localStorage.getItem('activeProject') || null

    const [activeProject, setActiveProject] = React.useState(storageProject)

    const { close, isOpen, open } = useToggle(!storageProject)

    const prevProgram = usePrevious(program)

    const projectInProgram = search(activeProject, projects)

    React.useEffect(() => {
      if ((prevProgram && prevProgram.id !== program.id) || !projectInProgram) {
        setActiveProject(null)
        localStorage.removeItem('activeProject')
        return open()
      }
    }, [activeProject])

    const toggleDisplayProjects = () => (isOpen ? close() : open())

    const selectProject = project => {
      setActiveProject(project)
      localStorage.setItem('activeProject', project)
      return close()
    }

    const deselectProject = () => {
      setActiveProject(null)
      localStorage.removeItem('activeProject')
      return open()
    }

    const ActiveProjectContent =
      activeProject != null ? (
        <FlexContainer alignItems="center" marginRight={8}>
          <Breadcrumbs>Current {titles.project}: </Breadcrumbs>
        </FlexContainer>
      ) : (
        <BreadcrumbsSelectProject>
          Select a {titles.project}
        </BreadcrumbsSelectProject>
      )

    const ProjectTable = (
      <ProjectSelectTable
        customer={customer}
        onClick={deselectProject}
        onSelect={selectProject}
        programKey={program.key}
        selectKey="key"
        value={activeProject}
      />
    )

    const HelpContent = (
      <HelpText>
        Please select a {titles.project} to manage {titles.team} Members.
      </HelpText>
    )

    return (
      <FullWidthContainer>
        <WrappedComponent
          hoc={{
            ActiveProjectContent,
            activeProject,
            HelpContent,
            isOpen,
            ProjectTable,
            selectProject,
            toggleDisplayProjects,
          }}
          {...props}
        />
      </FullWidthContainer>
    )
  })
