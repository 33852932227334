export const handleEnter = eventHandler => e => {
  if (e.key !== 'Enter') return
  eventHandler(e)
}

export const handleChange = eventHandler => e => {
  eventHandler(e.target.value)(e)
}

export const callAll = (...eventHandlers) => e => {
  eventHandlers.forEach(handler => handler && handler(e))
}
