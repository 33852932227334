import { graphql } from 'react-apollo'

export const standardQuery = (query, key) =>
  graphql(query, {
    props: ({ data }) => {
      const { loading } = data
      return {
        [key]: {
          isLoading: loading,
          [key]: data[key] || [],
        },
      }
    },
  })
