import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import differenceInYears from 'date-fns/differenceInYears'
import format from 'date-fns/format'

export const dateChoices = [
  {
    label: 'Current week',
    value: 'currentWeek',
  },
  {
    label: 'Previous week',
    value: 'previousWeek',
  },
  {
    label: 'Current month',
    value: 'currentMonth',
  },
  {
    label: 'Previous month',
    value: 'previousMonth',
  },
  {
    label: 'Last two months',
    value: 'twoPreviousMonths',
  },
  {
    label: 'Last three months',
    value: 'threePreviousMonths',
  },
  {
    label: 'Current year',
    value: 'currentYear',
  },
  {
    label: 'Previous year',
    value: 'previousYear',
  },
]

const weekStart = { weekStartsOn: 1 }

export const filterDates = (value, date) => {
  const d = new Date(date)
  const now = new Date()
  switch (value) {
    case 'currentWeek':
      return differenceInCalendarWeeks(now, d, weekStart) === 0
    case 'previousWeek':
      return differenceInCalendarWeeks(now, d, weekStart) === 1
    case 'currentMonth':
      return differenceInCalendarMonths(d, now) === 0
    case 'previousMonth':
      return differenceInCalendarMonths(now, d) === 1
    case 'twoPreviousMonths':
      return differenceInCalendarMonths(now, d) <= 2
    case 'threePreviousMonths':
      return differenceInCalendarMonths(now, d) <= 3
    case 'currentYear':
      return differenceInYears(now, d) === 0
    case 'previousYear':
      return differenceInYears(now, d) === 1
    default:
      return
  }
}

export const displayTime = date => {
  return format(new Date(date), 'hh:mm:ss aa')
}
export const displaySimpleTime = date => {
  return format(new Date(date), 'hh:mm aa')
}
