import { reverse } from 'named-urls'
import React, { useState } from 'react'
import { compose } from 'react-apollo'
import { push as Menu } from 'react-burger-menu'
import {
  Alert,
  Conversation,
  Logout,
  Person,
  Settings,
} from 'bvdash-core/icons'
import * as a11y from 'bvdash/utils/a11y'
import { logout } from 'bvdash/auth/queries'
import routes from 'bvdash/routes'
import {
  BurgerButton,
  BurgerLinkButton,
  Container,
  IconContainer,
} from './styles'
import './burgerMenu.css'

export const MobileNav = compose(logout)(props => {
  const {
    links,
    onboarding,
    settingsMenu,
    trialExpired,
    logout,
    authUser: { user },
  } = props
  const [burgerOpen, setBurgerOpen] = useState(false)

  const displayLink = key => {
    if (settingsMenu != null) {
      return settingsMenu[key].on
    }
    return true
  }

  const url = window.location.href

  const alertUrl = url.includes('alerts')
  const conversationsUrl = url.includes('conversations')
  const profileUrl = url.includes('profile')
  const adminUrl = url.includes('admin')

  const closeBurgerMenu = () => setBurgerOpen(false)

  const renderLinks = links.map(link => {
    const { icon, label, to } = link
    const value = url.includes(label.toLowerCase())

    const adminExists = adminUrl ? false : value

    return (
      <BurgerLinkButton
        onClick={closeBurgerMenu}
        key={to}
        to={to}
        value={adminExists}
      >
        <IconContainer>{icon}</IconContainer>
        {label}
      </BurgerLinkButton>
    )
  })

  const AlertIcon =
    !trialExpired && displayLink('alert', settingsMenu) ? (
      <BurgerLinkButton
        onClick={closeBurgerMenu}
        to={routes.alerts.toString()}
        value={alertUrl}
      >
        <IconContainer>
          <Alert />
        </IconContainer>
        Alerts
      </BurgerLinkButton>
    ) : null

  const ConversationIcon =
    !trialExpired && onboarding && displayLink('conversation', settingsMenu) ? (
      <BurgerLinkButton
        onClick={closeBurgerMenu}
        to={routes.conversations.toString()}
        value={conversationsUrl}
      >
        <IconContainer>
          <Conversation />
        </IconContainer>
        Conversations
      </BurgerLinkButton>
    ) : null

  const ProfileIcon = !trialExpired ? (
    <>
      <IconContainer>
        <Person />
      </IconContainer>
      Profile
    </>
  ) : null

  const isAdmin = user.isAdmin ? (
    <BurgerLinkButton
      onClick={closeBurgerMenu}
      to={reverse(routes.admin.toString())}
      value={adminUrl}
    >
      <IconContainer>
        <Settings />
      </IconContainer>
      Admin
    </BurgerLinkButton>
  ) : null

  return (
    <Container>
      <Menu
        onStateChange={state => setBurgerOpen(state.isOpen)}
        isOpen={burgerOpen}
        noOverlay
      >
        {renderLinks}
        {AlertIcon}
        {ConversationIcon}
        <BurgerLinkButton
          onClick={closeBurgerMenu}
          to={routes.profile}
          value={profileUrl}
        >
          {ProfileIcon}
        </BurgerLinkButton>
        {isAdmin}
        <BurgerButton onClick={closeBurgerMenu} {...a11y.clickable(logout)}>
          <IconContainer>
            <Logout />
          </IconContainer>
          Logout
        </BurgerButton>
      </Menu>
    </Container>
  )
})
