import * as React from 'react'
import { range } from 'ramda'

import styles from './ScheduleChart.scss'

const ScheduleChart = ({ props, row }) => {
  const { earlyStart, earlyFinish, earlyStartBl, earlyFinishBl } = row

  const { milestones } = props

  const isMilestone = milestones || row.isMilestone

  const [dateMin, dateMax] = props.dateRange

  const today = new Date(props.versionDate)
  const dateRange = dateMax - dateMin
  const left = (startDate, endDate) => {
    const start = isMilestone ? startDate || endDate : startDate || dateMin
    return `${(((start || dateMin) - dateMin) / dateRange) * 100}%`
  }
  const width = (startDate, endDate) =>
    isMilestone
      ? null
      : `${(((endDate || today) - (startDate || dateMin)) / dateRange) * 100}%`

  const years = dateMax.getFullYear() - dateMin.getFullYear() + 1

  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <div className={styles.quarters}>
          {range(0, years * 4).map(index => (
            <div key={index} className={styles.quarterLabel} />
          ))}
        </div>

        {/* Currect schedule */}
        <div
          style={{
            left: left(earlyStart, earlyFinish),
            width: width(earlyStart, earlyFinish),
          }}
          className={isMilestone ? styles.milestoneCurrent : styles.current}
        />

        {/* Baseline schedule */}
        <div
          style={{
            left: left(earlyStartBl, earlyFinishBl),
            width: width(earlyStartBl, earlyFinishBl),
          }}
          className={isMilestone ? styles.milestoneBaseline : styles.baseline}
        />

        {/* Today marker */}
        <div style={{ left: left(today) }} className={styles.today} />
      </div>
    </div>
  )
}

export default ScheduleChart
