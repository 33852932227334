import * as React from 'react'
import styled, { css } from 'react-emotion'

import { colorMap, selectable, menuHoverLeft, space } from 'bvdash/styles'
import { Toggle, Dropdown } from 'bvdash/ui/core'
import { ArrowDown } from 'bvdash-core/icons'

import { clickable } from 'bvdash/utils/a11y'
import { callAll } from 'bvdash/utils/handlers'

import TextInputWidget from './TextInputWidget'

class SelectWidget extends React.Component {
  render() {
    const {
      choices,
      setValue,
      value,
      disabled,
      placeholder,
      width,
    } = this.props
    const { label } = choices.find(choice => choice.value === value) || {
      label: placeholder,
    }

    if (disabled) {
      return (
        <Container>
          <TextLabel disabled>{label}</TextLabel>
        </Container>
      )
    }

    return (
      <Toggle>
        {toggle => (
          <Container width={width}>
            <TextLabel onClick={toggle.open} width={width}>
              {label}&nbsp;
              <ArrowDown />
            </TextLabel>

            <Dropdown
              position="right"
              isOpen={toggle.isOpen}
              onClose={toggle.close}
            >
              <ChoicesList width={width}>
                {choices.map((choice, index) => {
                  return (
                    <Choice
                      width={width}
                      key={choice.value || index}
                      {...clickable(
                        callAll(toggle.close, () => setValue(choice.value))
                      )}
                      active={choice.value === value}
                    >
                      {choice.label}
                    </Choice>
                  )
                })}
              </ChoicesList>
            </Dropdown>
          </Container>
        )}
      </Toggle>
    )
  }
}

const Container = styled.div`
  margin-right: 32px;
  position: relative;
  width: ${({ width }) => width || 420}px;
  max-width: 100%;
`

const TextLabel = styled(TextInputWidget.withComponent('div'))`
  ${props =>
    props.disabled
      ? css`
          background: none;
          border: 1px solid ${colorMap.dropShadow};
          opacity: 0.7;
        `
      : selectable}
  width: ${({ width }) => width || 420}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
`

const ChoicesList = styled.ul`
  max-height: 390px;
  overflow-y: auto;
`

const Choice = styled.li`
  ${selectable};
  width: ${({ width }) => width} px;
  text-align: left;
  line-height: 20px;
  padding: ${space.smaller} ${space.normal};

  li + & {
    border-top: 1px solid ${colorMap.dropShadow};
  }

  &:hover {
    ${menuHoverLeft()};
  }

  ${props => props.active && menuHoverLeft()}
`

export default SelectWidget
