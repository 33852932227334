import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { UserFragment } from './userFragment'

export const usersQuery = gql`
  query users {
    users {
      ...User
    }
  }
  ${UserFragment}
`

export const withUsers = graphql(usersQuery, {
  options: {
    fetchPolicy: 'cache-and-network',
  },
  props: ({ data }) => {
    const { loading, users = [] } = data

    return {
      users: {
        isLoading: loading,
        users,
      },
    }
  },
})
