import gql from 'graphql-tag'

export const CustomerProfileFragment = gql`
  fragment CustomerProfile on CustomerType {
    id
    name
    removeStatus
    address {
      id
      street
      city
      postalCode
      country
    }
    customerProfilePicture {
      id
      url
    }
  }
`
