import PropTypes from 'prop-types'
import * as React from 'react'

import { Container, IconContainer } from './styles'
import { InputField } from 'bvdash/components'
import { Card, TextAreaLabel } from 'bvdash/components/styles'
import { TextArea } from 'bvdash/components/projectComponentForm/styles'
import { Trash } from 'bvdash-core/icons'

import { colorMap } from 'bvdash/styles'

const components = index => ({
  InputContainer: Container,
  Label: index === 0 ? TextAreaLabel : null,
  Input: TextArea,
})

export const UpdateFormField = props => {
  const {
    deleteOnClick,
    placeholder,
    text: { type },
    updates,
  } = props

  const renderUpdates =
    updates != null
      ? updates.map((update, i) => {
          return (
            <Card key={update.id} zIndex={-i}>
              <InputField
                components={components(i)}
                disabled={!update.canEdit}
                label={i === 0 ? `${type} Updates` : null}
                name={`update${update.id}`}
                placeholder={placeholder}
                type="text"
              />
              {update.canEdit && (
                <IconContainer>
                  <Trash
                    color={colorMap.carnation}
                    onClick={() => deleteOnClick(update.id)}
                  />
                </IconContainer>
              )}
            </Card>
          )
        })
      : null

  return renderUpdates
}

UpdateFormField.propTypes = {
  placeholder: PropTypes.string,
}

UpdateFormField.defaultProps = {
  placeholder: '',
}
